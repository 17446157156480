import { RootState } from '@/src/redux/store'
import { CustomizationOptionStored } from '@/src/types/local_storage'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

type props = {
    product_id: string,
    checked: boolean,
    onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void,
    image: string,
    onIncreaseCount: () => any,
    onDicreaseCount: () => any,
    removeFromCart: () => any,
    quantity: number,
    price: number,
    savePrice: number,
    productName: string,
    variations: ({
        variation_name: string,
        variation_id: string,
        option: ({
            option_id: string,
            option_name: string
        })
    })[],
    customizationOption?: CustomizationOptionStored[],
    mode?: 'allFunctionality' | 'RemoveBtnRemove',
    availableQuantity:number,
    isCustomizationAvailable:number
}

export function StandardCartCard({
    product_id,
    checked,
    onSelect,
    image,
    onDicreaseCount,
    onIncreaseCount,
    quantity,
    price,
    productName,
    variations,
    customizationOption,
    removeFromCart,
    savePrice,
    mode = 'allFunctionality',
    availableQuantity,
    isCustomizationAvailable
}: props) {

    return (
        <div className="rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 md:p-6">
            {/* check box */}
            <input type="checkbox" className="mb-3" checked={checked} onChange={onSelect} />
            <div className="  space-y-4 md:flex  md:justify-between md:gap-6 md:space-y-0">


                <Link to={`/product/${product_id}`}>
                    <div className="shrink-0 md:order-1">
                        <img className="h-20 w-20 " src={image} alt="Product" />

                        {/* <img className="hidden h-20 w-20 dark:block" src="https://flowbite.s3.amazonaws.com/blocks/e-commerce/imac-front-dark.svg" alt="imac image" /> */}
                    </div>
                </Link>
                <label htmlFor="counter-input" className="sr-only">Choose quantity:</label>
                <div className="flex items-end justify-between md:order-3 md:justify-end max-h-20">
                    <div className="flex items-center">
                        <button onClick={onDicreaseCount} type="button" id="decrement-button" data-input-counter-decrement="counter-input" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 2">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 1h16" />
                            </svg>
                        </button>
                        {/* <input type="text" id="counter-input" data-input-counter className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white" value={quantityChangeValue} required /> */}
                        <input type="text" id="counter-input" data-input-counter className="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 dark:text-white" value={quantity} required />
                        <button onClick={onIncreaseCount} type="button" id="increment-button" data-input-counter-increment="counter-input" className="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 dark:border-gray-600 dark:bg-gray-700 dark:hover:bg-gray-600 dark:focus:ring-gray-700">
                            <svg className="h-2.5 w-2.5 text-gray-900 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 18">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 1v16M1 9h16" />
                            </svg>
                        </button>
                    </div>
                    <div className="text-end md:order-4 md:w-32">
                        <p className="text-base font-bold text-gray-900 dark:text-white">{price * quantity}</p>
                    </div>
                </div>

                <div className="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">


                    <Link className="text-base font-medium text-gray-900 hover:underline dark:text-white" to={`/product/${product_id}`}>{productName}</Link>

                    {/* <a href="#" className="text-base font-medium text-gray-900 hover:underline dark:text-white">{customizationOption}</a> */}

                    <div></div>
                    <div className='flex gap-2'>
                        <p className='font-bold text-base'>{price}</p>
                        <p className='text-green-600 font-bold'>{((savePrice / (price + savePrice)) * 100).toFixed(1)}% off</p>
                    </div>
                    <div className=" flex flex-col">
                        {
                            variations.map(
                                variation => {
                                    return (
                                        <div className=" flex gap-2">
                                            <div className=" font-bold">{variation.variation_name}</div>
                                            <div>{variation.option.option_name}</div>
                                        </div>)
                                })
                        }
                        {/* Configuration */}
                        <div>
                            {
                                customizationOption?.length ?
                                    <div className=" flex flex-col">
                                        {/* CUSTOMIZATIONS  */}
                                        <div className=" font-bold">Customizations</div>
                                        {
                                            <table className=" w-min">
                                                <thead>
                                                    <th ></th>
                                                    <th ></th>
                                                </thead>
                                                <tbody>

                                                    {
                                                        customizationOption.map(
                                                            option => (
                                                                <tr>
                                                                    <td className=" pe-2">{option.name}</td>
                                                                    <td className=" pe-2">{
                                                                        option.type === 'dropDown'
                                                                            ?
                                                                            option.value
                                                                            : option.Inputvalue
                                                                    }</td>
                                                                </tr>
                                                            )
                                                        )
                                                    }

                                                </tbody>

                                            </table>
                                        }
                                    </div>
                                    : null
                            }
                        </div>

                    </div>
                    {availableQuantity===0&&isCustomizationAvailable===0?<div className=' text-red-500'>Out of stock</div>:null}
                    {
                        mode === 'allFunctionality'
                            ?
                            <div className="flex items-center gap-4">

                                <button onClick={removeFromCart} type="button" className="inline-flex items-center text-sm font-medium text-red-600 hover:underline dark:text-red-500">
                                    <svg className="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" viewBox="0 0 24 24">
                                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18 17.94 6M18 18 6.06 6" />
                                    </svg>
                                    Remove
                                </button>
                            </div>
                            :
                            null
                    }
                </div>
            </div>
        </div>
    )
}
