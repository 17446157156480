import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CartLocalstorateType, localStorageKeys } from '../../types/local_storage'
import { Currency } from '../../types/index'
import React from 'react'


type alertModalSliceType = {
  show:boolean,
  title:string,
  content:string,
  footer:React.ReactNode
}

const initialState: {
  alertModal:alertModalSliceType

} = {
  alertModal:{
    content:'',
    footer:'',
    show:false,
    title:''
  }
}

export const modalSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    showAlertModal: (state, action:PayloadAction<alertModalSliceType> )=>{
      
      state.alertModal.show=true;
      state.alertModal.content=action.payload.content;
      state.alertModal.footer= action.payload.footer;
      state.alertModal.title = action.payload.title;

    },
    closeAlertModal: (state)=>{
      
      state.alertModal.show=false;
      state.alertModal.content='';
      state.alertModal.footer= '';
      state.alertModal.title = '';

    },

  },
})

// Action creators are generated for each case reducer function
export const {
  showAlertModal,
  closeAlertModal
} = modalSlice.actions

export const modalSliceReducer = modalSlice.reducer