import { Alert, AlertDescription } from '@/src/components/ui/alert';
import { Button } from '@/src/components/ui/button';
import { Card, CardContent, CardHeader } from '@/src/components/ui/card';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/src/components/ui/select';
import React, { useState, useEffect } from 'react';

interface VariationOption {
  M07_VariationOptionID: number;
  M07_Value: string;
  M07_M06_VariationID: number;
  M07_M11_ProductID: number;
}

interface Variation {
  M06_VariationID: number;
  M06_M04_CategoryID: number;
  M06_Name: string;
  M06_M11_ProductID: number;
  options: VariationOption[];
}

interface SelectedSku {
  M06_VariationID: number;
  M06_M04_CategoryID: number;
  M06_Name: string;
  M06_M11_ProductID: number;
  variation_option: VariationOption;
}

interface SkuCombination {
  productItemId: number;
  options: number[];
}

interface ProductVariationSelectorProps {
  selectedSku: SelectedSku[];
  allVariations: Variation[];
  skuCombinations: SkuCombination[];
  onChangeVariation: (productItemId: number | null) => void;
}

const ProductVariationSelector: React.FC<ProductVariationSelectorProps> = ({
  selectedSku,
  allVariations,
  skuCombinations,
  onChangeVariation
}) => {
  const [selectedOptions, setSelectedOptions] = useState<Record<string, string>>({});
  const [selectedProductItemId, setSelectedProductItemId] = useState<number | null>(null);

  useEffect(() => {
    if (Array.isArray(selectedSku)) {
      const initialOptions: Record<string, string> = {};
      selectedSku.forEach(item => {
        if (item && item.variation_option) {
          initialOptions[item.M06_Name] = item.variation_option.M07_VariationOptionID.toString();
        }
      });
      setSelectedOptions(initialOptions);
    }
  }, [selectedSku]);

  useEffect(() => {
    if (Array.isArray(skuCombinations)) {
      const currentCombination = Object.values(selectedOptions).map(Number).sort((a, b) => a - b);
      const matchingSku = skuCombinations.find(sku =>
        JSON.stringify(sku.options.sort((a, b) => a - b)) === JSON.stringify(currentCombination)
      );

      const newProductItemId = matchingSku ? matchingSku.productItemId : null;
      setSelectedProductItemId(newProductItemId);
      onChangeVariation(newProductItemId);
    }
  }, [selectedOptions, skuCombinations]);

  const handleOptionChange = (variationName: string, optionId: string) => {
    setSelectedOptions(prev => ({
      ...prev,
      [variationName]: optionId
    }));
  };

  const isOptionDisabled = (variationName: string, optionId: string): boolean => {
    if (!Array.isArray(skuCombinations)) return true;
    const otherSelections = { ...selectedOptions, [variationName]: optionId };
    const combinationExists = skuCombinations.some(sku =>
      Object.values(otherSelections).every(id => sku.options.includes(Number(id)))
    );
    return !combinationExists;
  };

  if (!Array.isArray(allVariations) || allVariations.length === 0) {
    return <div></div>;
  }

  return (
    <>
      <div className='w-full max-w-md flex flex-col mt-4  '>
        <div className='mt-2'>
          {allVariations.map(variation => (
            <div key={variation.M06_VariationID} className='flex flex-col gap-2 w-full'>
              <div className='text-base text-gray-700 font-bold'>
                {variation.M06_Name}:
              </div>
              <div className='flex gap-2 w-full'>
                {Array.isArray(variation.options) && variation.options.map(opt => (
                  <Button
                    key={opt.M07_VariationOptionID}
                    className={`min-w-[53px] h-[35px] flex items-center justify-center rounded-full cursor-pointer border-[3px] font-bold ${selectedOptions[variation.M06_VariationID] === opt.M07_VariationOptionID.toString()
                      ? 'border-[#232f3e] '
                      : 'border-gray-500 hover:bg-gray-500 hover:text-white'
                      }`}
                    onClick={() => handleOptionChange(variation.M06_Name, opt.M07_VariationOptionID.toString())}
                    disabled={isOptionDisabled(variation.M06_Name, opt.M07_VariationOptionID.toString())}
                    variant={selectedOptions[variation.M06_Name] === opt.M07_VariationOptionID.toString() ? "default" : "outline"}
                  >
                    {opt.M07_Value}
                  </Button>
                ))}
              </div>
            </div>
          ))}
          {/* {selectedProductItemId ? (
            <Alert>
              <AlertDescription>
                Selected Product Item ID: {selectedProductItemId}
              </AlertDescription>
            </Alert>
          ) : (
            <Alert variant="destructive">
              <AlertDescription>
                This combination is not available.
              </AlertDescription>
            </Alert>
          )} */}
        </div>
      </div>
    </>
  );
};

export default ProductVariationSelector;