import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Textarea } from '@/src/components/ui/textarea'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { ProductItemHighlights } from '@/src/types'
import React, { useEffect, useState } from 'react'
import { productHighlightOverview } from '../addmodal'

type Props = {
    show:boolean,
    setShow:(show:boolean)=>any,
    addHighlight: (hightlights: productHighlightOverview) => any
    mode:'add'|'edit',
    selectedHighlight?:productHighlightOverview,
    selectedIndex?:number,
    editHighlight:(index:number,highlight:productHighlightOverview)=>any
}

export  function ModalAddHighlights(props:Props) {

    const [formData,setFormData] = useState<productHighlightOverview>({
        M24_highlight_text:'',
        mode:'local'
    })

    const [errorData,setErrorData] = useState<ProductItemHighlights>({
        M24_highlight_text:'',
    })

    const handleOnChange = (e:React.ChangeEvent<HTMLTextAreaElement>)=>{
        setFormData(
            {...formData,
                [e.target.name]:e.target.value
            }
        )
    }

    const clearData= ()=>{
        setFormData({
            M24_highlight_text:'',
            mode:'local'
        })
    }

    const onAddhighlight = ()=>{
        if(formData.M24_highlight_text.length<5){
            setErrorData({
                M24_highlight_text:'highlight must be minmum 5 word'
            })
            return;
        }
        setErrorData({
            M24_highlight_text:''
        })
        props.addHighlight(formData)
        props.setShow(false)
        clearData()
    }
    const onEditHighlight = ()=>{
        if(formData.M24_highlight_text.length<5){
            setErrorData({
                M24_highlight_text:'highlight must be minmum 5 word'
            })
            return;
        }
        setErrorData({
            M24_highlight_text:''
        })
        props.editHighlight(props.selectedIndex!,formData)
        props.setShow(false)
        clearData()
    }

    useEffect(
        ()=>{

            if(props.show){

                switch (props.mode) {
                    case 'add':
                        clearData();
                        break;
                    case 'edit':
                        if(props.selectedHighlight){
                            console.log(props.selectedHighlight)
                            setFormData(props.selectedHighlight)
                        }
                        break;
                
                    default:
                        break;
                }

            }


        },[props.show]
    )


  return (
    <AdminModal show={props.show} setShow={props.setShow} >
        <AdminModalContent >
            <AdminModalHeader>
                <AdminModalTitle>Add Highlights</AdminModalTitle>
            </AdminModalHeader>
            <AdminModalMiddle>
                <div className=' flex flex-col'>
                    <Textarea name='M24_highlight_text' value={formData.M24_highlight_text} placeholder='highlights' onChange={handleOnChange} />
                    <div className=' text-red-600'>{errorData.M24_highlight_text}</div>
                </div>
            </AdminModalMiddle>
            <AdminModalFooter>
                {
                    props.mode==='add'?
                    <Button onClick={onAddhighlight}>Add</Button>
                    :<Button onClick={onEditHighlight}>Edit</Button>
                }
            </AdminModalFooter>
        </AdminModalContent>
    </AdminModal>
  )
}
