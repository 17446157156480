// src/components/Modal.tsx

import React from 'react';
import { motion } from 'framer-motion';
import { Button } from '@/src/components/ui/button';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';
import { OrderType } from '@/src/types';

type ModalProps = {
  isOpen: boolean;
  orderDetails?:OrderType

};

export const ConformedModal: React.FC<ModalProps> = ({ isOpen,orderDetails }) => {

    const navigate = useNavigate()

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
      <motion.div
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.9 }}
        transition={{duration:0.6}}
        className="bg-white p-8 rounded shadow-lg flex flex-col items-center gap-3"
      >
        <h2 className="text-lg font-bold mb-4">Order Confirmation</h2>
        <img className=' h-40' src="/assets/tickMark.png" alt="" />
        <p>Order succesfully placed</p>
        <p>Order Id: <span className=' font-bold pl-1'>{orderDetails?.I19_order_id}</span></p>
        <Button className=' gap-3' onClick={()=>navigate('/')} >Continue Purchase <ArrowRight className='h-4 w-4'/></Button>
      </motion.div>
    </div>
  );
};

