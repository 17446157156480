import { IconLoader, IconX } from '@tabler/icons-react'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import useFormValidatonCustom, { validationObject } from '../../../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../../../redux/store'
import { Brand, ProductCategory, ProductPrice, ProductPriceKeys, employee, role } from '../../../../../../../../types'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Label } from '@/src/components/ui/label'
import { Switch } from '@/src/components/ui/switch'
import { useAlertModal } from '@/src/hooks/alert_modal'




type priceOverview = ProductPrice

type imageIds = string[]



export function AddPriceModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [ProductPriceKeys.M10_End_date]: {
            message: '',
            validation: [
                (value) => {

                    if (value) {

                        if (formData.M10_Start_date==value){
                            return 'End date must be after the start date';

                        }

                        if (formData.M10_Start_date) {
                            const start = new Date(formData.M10_Start_date);
                            const end = new Date(value);

                            if (start > end) {
                               
                                return 'End date must be after the start date';
                              }

                        }

                    }
                    return true
                }
            ]
        },
        [ProductPriceKeys.M10_Start_date]: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductPriceKeys.M10_Is_Active]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'please select category'
                    }
                    return true
                }
            ]
        },
        [ProductPriceKeys.M10_List_price]: {
            message: '',
            validation: [
                (value: string) => {
                    if (!value && mode === 'add') {
                        return 'required'
                    }
                    if (value?.length === 0) {
                        return 'required'
                    }
                    return true
                },
                (value: string) => {
                    if (/^[\-\+]?[0-9]*(\.[0-9]+)?$/.test(value)) {
                        return true
                    }
                    return 'enter valid amount'
                }
            ]
        },
        [ProductPriceKeys.M10_MRP_price]: {
            message: '',
            validation: [
                (value: string) => {
                    if (!value && mode === 'add') {
                        return 'required'
                    }
                    if (value?.length === 0) {
                        return 'required'
                    }
                    return true
                },
                (value: string) => {
                    if (/^[\-\+]?[0-9]*(\.[0-9]+)?$/.test(value)) {
                        return true
                    }
                    return 'enter valid amount'
                },
                (value: string) => {
                    if (Number(formData.M10_List_price) <= Number(value)) {
                        return true
                    }
                    return 'MRP should greater than list price'
                },
            ]
        }
    } satisfies validationObject


    const { id: productIitemId } = useParams()

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<priceOverview>({
        M10_End_date: '',
        M10_Is_Active: 1,
        M10_List_price: '',
        M10_M01_Created_by: '',
        M10_M08_Product_Item_id: productIitemId!,
        M10_MRP_price: '',
        M10_Start_date: '',
        M10_T02_Tax_Rate_id: '1',
    })
    const alertModal = useAlertModal()
    const [roles, setRoles] = useState<role[]>([])
    const [brands, setBrands] = useState<Brand[]>([])
    const [categories, setCategories] = useState<ProductCategory[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<ProductPrice>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)




    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: ProductPrice;

        switch (e.target.name) {

            case ProductPriceKeys.M10_Is_Active:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_price() {

        setIsLoading(true)
        formData.M10_M01_Created_by = employee.employeeData.M15_Employee_id!
        formData.M10_M08_Product_Item_id = productIitemId!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductPriceKeys.M10_End_date:
                            case ProductPriceKeys.M10_Is_Active:
                            case ProductPriceKeys.M10_List_price:
                            case ProductPriceKeys.M10_M01_Created_by:
                            case ProductPriceKeys.M10_M08_Product_Item_id:
                            case ProductPriceKeys.M10_MRP_price:
                            case ProductPriceKeys.M10_Start_date:
                            case ProductPriceKeys.M10_T02_Tax_Rate_id:
                                formDataReal.append(data[0], String(data[1]))
                                break;

                            default:
                                break;
                        }

                    }
                )

                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                const res = await api.post<{ success: boolean, data: ProductPrice, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/prices`,
                    body: formDataReal,
                    mode: 'admin',
                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert('added price')
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.M10_M01_Created_by = employee.employeeData.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductPriceKeys.M10_End_date:
                            case ProductPriceKeys.M10_Is_Active:
                            case ProductPriceKeys.M10_List_price:
                            case ProductPriceKeys.M10_M01_Created_by:
                            case ProductPriceKeys.M10_M08_Product_Item_id:
                            case ProductPriceKeys.M10_MRP_price:
                            case ProductPriceKeys.M10_Start_date:
                            case ProductPriceKeys.M10_T02_Tax_Rate_id:
                                formDataReal.append(data[0], String(data[1]))
                                break;

                            default:
                                break;
                        }

                    }
                )



                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/prices/${id}`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    updateUi();
                    closeModal()
                    alert(res.data?.message || 'updated price')
                    
                } else {
                    

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }


    const load_product = async () => {

        const res = await api.get<{ success: boolean, data: ProductPrice, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/prices/${id}`,
            mode: 'admin',

        })
        if (res.data.success === true) {
            res.data.data.M10_Start_date = res.data.data.M10_Start_date ? new Date(res.data.data.M10_Start_date).toISOString().split('T')[0] : ''
            res.data.data.M10_End_date = res.data.data.M10_End_date ? new Date(res.data.data.M10_End_date).toISOString().split('T')[0] : ''
            setFormData(res.data.data)
        } else {
            throw res
        }


    }


    useEffect(
        () => {

            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    M10_End_date: '',
                    M10_Is_Active: 1,
                    M10_List_price: '',
                    M10_M01_Created_by: '',
                    M10_M08_Product_Item_id: productIitemId!,
                    M10_MRP_price: '',
                    M10_Start_date: '',
                    M10_T02_Tax_Rate_id: '1',
                })

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                        alertModal.open_alert_modal({
                            content: 'Error loading price',
                            footer: <Button onClick={alertModal.close_alert_modal} >Ok</Button>,
                            title: 'Error'
                        })
                        closeModal()
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_product()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                        alertModal.open_alert_modal({
                            content: 'Error loading price',
                            footer: <Button onClick={() => {
                                alertModal.close_alert_modal();
                                closeModal()
                            }} >Ok</Button>,
                            title: 'Error'
                        })
                        // closeModal()
                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )

    const clear_data = () => {
        setFormData({
            M10_End_date: '',
            M10_Is_Active: 1,
            M10_List_price: '',
            M10_M01_Created_by: '',
            M10_M08_Product_Item_id: productIitemId!,
            M10_MRP_price: '',
            M10_Start_date: '',
            M10_T02_Tax_Rate_id: '1',
        })
        setErrorData({})
        setIsLoading(false)
    }


    return (
        <AdminModal
            show={show} setShow={(show) => {
                setShow(show)
                if (show === false) clear_data()
            }}
        >
            <AdminModalContent>
                <AdminModalHeader>
                    <AdminModalTitle>Add Price</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className={`${isLoading || loading ? 'bg-gray-300' : ''}`}>
                    <div className=''>
                        <Label>Start Date</Label>
                        <Input disabled={isLoading || loading} value={formData.M10_Start_date} onChange={handleChangeInput} type="date" name={ProductPriceKeys.M10_Start_date} placeholder='Start Date' />
                        <div className=' text-red-500'>{errorData.M10_Start_date ? errorData.M10_Start_date : null}</div>
                        <div className=' h-2'></div>
                        <Label>End Date</Label>
                        <Input disabled={isLoading || loading} value={formData.M10_End_date} onChange={handleChangeInput} type="date" name={ProductPriceKeys.M10_End_date} placeholder='End Date' />
                        <div className=' text-red-500'>{errorData.M10_End_date ? errorData.M10_End_date : null}</div>
                        <div className=' h-2'></div>

                        <Label>List Price</Label>
                        <Input disabled={isLoading || loading} value={formData.M10_List_price} onChange={handleChangeInput} type="text" name={ProductPriceKeys.M10_List_price} placeholder='List Price' />
                        <div className=' text-red-500'>{errorData.M10_List_price ? errorData.M10_List_price : null}</div>
                        <div className=' h-2'></div>

                        <Label>MRP Price</Label>
                        <Input disabled={isLoading || loading} value={formData.M10_MRP_price} onChange={handleChangeInput} type="text" name={ProductPriceKeys.M10_MRP_price} placeholder='MRP Price' />
                        <div className=' text-red-500'>{errorData.M10_MRP_price ? errorData.M10_MRP_price : null}</div>
                        <div className=' h-2'></div>

                        <div className=' flex gap-1'>
                            <Switch
                                disabled={isLoading || loading}
                                checked={formData.M10_Is_Active == 1 ? true : false}
                                name={ProductPriceKeys.M10_Is_Active}
                                onCheckedChange={
                                    (checked) => handleChangeInput({
                                        target: {
                                            name: ProductPriceKeys.M10_Is_Active,
                                            value: checked ? 1 : '0',
                                            checked: checked
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }

                            // onChange={handleChangeInput}
                            />
                            <div>Is Active</div>
                        </div>
                    </div>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={loading} variant={'outline'} onClick={closeModal} >Cancel</Button>

                    {
                        mode === 'add' ?
                            <Button disabled={isLoading} onClick={add_price} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
