import React, { useState, useRef, useEffect } from 'react';
import './ScrollableDiv.css';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';



const ScrollableDiv = ({ thumbnails, curr, setCurr }: { thumbnails: string[], curr: number, setCurr: React.Dispatch<React.SetStateAction<number>> }) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isOverflowing, setIsOverflowing] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                setScrollPosition(containerRef.current.scrollTop);
            }
        };

        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);

            // Check for overflow
            if (container.scrollHeight > container.clientHeight) {
                setIsOverflowing(true);
            } else {
                setIsOverflowing(false);
            }

            return () => {
                container.removeEventListener('scroll', handleScroll);
            };
        }
    }, []);

    const scrollDown = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ top: 70, behavior: 'smooth' });
        }
    };

    const scrollUp = () => {
        if (containerRef.current) {
            containerRef.current.scrollBy({ top: -70, behavior: 'smooth' });
        }
    };

    const isAtTop = scrollPosition === 0;
    const isAtBottom = containerRef.current
        ? scrollPosition + 400 >= containerRef.current.scrollHeight
        : false;

    return (
        <div className="relative w-20 h-full  border-r rounded-lg overflow-hidden">
            {!isAtTop && (
                <div className="h-[20px]  flex justify-center items-center absolute top-0 w-full z-10">
                    <IconChevronUp onClick={scrollUp} className="cursor-pointer" />
                </div>
            )}
            <div
                ref={containerRef}
                className="no-scrollbar w-full h-full overflow-y-scroll  border-gray-300 bg-white"
            >
                {thumbnails.map((t, i) => (
                    <div key={i} onClick={() => setCurr(i)} className="bg-white h-20  border-gray-200 flex items-center justify-center cursor-pointer p-1">
                        <img
                            className={` w-full h-auto ${curr === i && "opacity-50"}`}
                            src={t}
                            alt=""
                        />
                    </div>
                ))}

            </div>
            {!isAtBottom && isOverflowing && (
                <div className="h-[20px] bg-white flex justify-center items-center  absolute bottom-0 w-full z-10">
                    <IconChevronDown onClick={scrollDown} className="cursor-pointer" />
                </div>
            )}
        </div>
    );
};

export default ScrollableDiv;
