import React, { useEffect, useState } from 'react'
import { Button } from '@/src/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/src/components/ui/dialog';
import { Input } from '@/src/components/ui/input';
import { Label } from '@/src/components/ui/label';
import { customizationOptionType } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Switch } from '@/src/components/ui/switch';
import { ProductCustomizationOptionClient, ProductCustomizationOptionEnumClient } from '../../../addmodal';
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn';

// Create a type with all properties set to boolean
type Displayble = {
    [K in keyof ProductCustomizationOptionClient]: boolean;
}



type props = {
    variantId: string,
    onCreateOption: (option: ProductCustomizationOptionClient) => void,
    open: boolean,
    setOpen: (open: boolean) => any,
    disabled: boolean,
    customizationOptions: ProductCustomizationOptionClient[]
    mode: 'add' | 'edit',
    setModalMode: (mode: 'add' | 'edit') => any,
    editOptionModalDetails?: ProductCustomizationOptionClient
    setEditModalOptionDetails: (option: ProductCustomizationOptionClient) => any,
    editOption: (option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation',oldName?:string) => void,

}



export function AddOptionModal({
    variantId,
    onCreateOption,
    open,
    setOpen,
    disabled,
    customizationOptions,
    mode,
    setModalMode,
    setEditModalOptionDetails,
    editOptionModalDetails,
    editOption
}: props) {


    const [openModeModal, setModeModal] = useState<boolean>(false)
    const [formData, setFormData] = useState<ProductCustomizationOptionClient>({
        uniqueId:'',
        baseRate: 0,
        customization_name: '',
        hikePerc: null,
        hikePercInRate: 100,
        isDefault: 0,
        isSlab: 1,
        maxVal: null,
        minVal: null,
        order: null,
        rate: null,
        type: 'normal',
        value: "",
        varientID: variantId
    })

    const displayable = {
        baseRate: false,
        customization_name: false,
        hikePerc: false,
        hikePercInRate: false,
        isDefault: false,
        isSlab: false,
        minVal: false,
        maxVal: false,
        order: false,
        rate: false,
        type: false,
        value: false,
        varientID: false
    }

    const validationObject = {
        [ProductCustomizationOptionEnumClient.customization_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.value]: {
            message: '',
            validation: [
                (value) => {
                    if (!value && formData.type === 'dropDown') {
                        return 'required in dropDown'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.type]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'please select a type'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.varientID]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value) {
                        return 'please create varientID'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.rate]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal') {
                        return 'please enter rate'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.hikePerc]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal' && formData.isSlab == 1) {
                        return 'please enter hike percentage'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.hikePercInRate]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal' && formData.isSlab == 1) {
                        return 'please enter rate hike percentage'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.minVal]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value && formData.type === 'normal') {
                        return 'please enter minimum value'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.maxVal]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value && formData.type === 'normal') {
                        return 'please enter maximum value'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.baseRate]: {
            message: '',
            validation: [
                (value: any) => {
                    if (value == 0) {
                        return true
                    }
                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value) {
                        return 'please enter base rate'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.isDefault]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<ProductCustomizationOptionClient>({
        validationObject: validationObject
    })


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: ProductCustomizationOptionClient;

        switch (e.target.name) {

            case ProductCustomizationOptionEnumClient.isSlab:

                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value == '1' ? 1 : 0
                }
                break;
            case ProductCustomizationOptionEnumClient.minVal:

                formdata = {
                    ...formData,
                    [e.target.name]: isNaN(Number(e.target.value))||Number(e.target.value)===0?null:Number(e.target.value)
                }
                break;
            case ProductCustomizationOptionEnumClient.maxVal:

                formdata = {
                    ...formData,
                    [e.target.name]: isNaN(Number(e.target.value))||Number(e.target.value)===0?null:Number(e.target.value)
                }
                break;
            case ProductCustomizationOptionEnumClient.hikePerc:

                formdata = {
                    ...formData,
                    [e.target.name]: isNaN(Number(e.target.value))||Number(e.target.value)===0?null:Number(e.target.value)
                }
                break;
            case ProductCustomizationOptionEnumClient.hikePercInRate:

                formdata = {
                    ...formData,
                    [e.target.name]: isNaN(Number(e.target.value))||Number(e.target.value)===0?null:Number(e.target.value)
                }
                break;
            case ProductCustomizationOptionEnumClient.baseRate:

                formdata = {
                    ...formData,
                    [e.target.name]: isNaN(Number(e.target.value))?null:Number(e.target.value)
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)

    }

    function handleDisplayable() {

        switch (formData.type) {

            case 'dropDown':
                displayable.baseRate = true
                displayable.customization_name = true
                displayable.hikePerc = false
                displayable.hikePercInRate = false
                displayable.isDefault = false
                displayable.isSlab = false
                displayable.minVal = false
                displayable.maxVal = false
                displayable.order = false
                displayable.rate = false
                displayable.type = false
                displayable.value = true
                displayable.varientID = false
                break;

            case 'normal':
                displayable.baseRate = true
                displayable.customization_name = true
                displayable.hikePerc = true
                displayable.hikePercInRate = false
                displayable.isDefault = false
                displayable.isSlab = true
                displayable.minVal = true
                displayable.maxVal = true
                displayable.order = false
                displayable.rate = true
                displayable.type = false
                displayable.value = false
                displayable.varientID = false

                if (formData.isSlab === 0) {
                    displayable.hikePerc = false
                    displayable.hikePercInRate = false
                }
                break;

        }


    }


    function clearData() {
        setFormData(
            {
                uniqueId:'',
                baseRate: 0,
                customization_name: '',
                hikePerc: null,
                hikePercInRate: 100,
                isDefault: 0,
                isSlab: 1,
                maxVal: null,
                minVal: null,
                order: null,
                rate: null,
                type: 'normal',
                value: "",
                varientID: variantId
            }
        )
        setErrorData({})
        setModeModal(false)
    }

    function add_option() {
        formData.varientID = variantId
        if (performAllValidation(formData) === true) {
            setModeModal(true)

        }
    }

    function create_option(mode: 'forAllVariation' | 'forThisVariation') {
        switch (mode) {
            case 'forAllVariation':
                const uniqueVariantIDs = new Set<string>();
                customizationOptions.forEach(option => {
                    uniqueVariantIDs.add(option.varientID);
                });
                const varientIDs: string[] = Array.from(uniqueVariantIDs);
                if (varientIDs.length) {
                    varientIDs.forEach(
                        (varientId) => {
                            const optionData = { ...formData }
                            optionData.varientID = varientId
                            onCreateOption(optionData)
                        }
                    )
                } else {
                    onCreateOption(formData)
                }


                break;

            case 'forThisVariation':
                onCreateOption(formData)
                break;

            default:
                break;
        }
        clearData()
        setOpen(false)
        setModeModal(false)
    }

    function edit_option(mode: 'forAllVariation' | 'forThisVariation') {
        switch (mode) {
            case 'forAllVariation':
                editOption(formData,'forAllVariation',editOptionModalDetails?.customization_name)
                break;

            case 'forThisVariation':
                editOption(formData,'forThisVariation')
                break;

            default:
                break;
        }
        clearData()
        setOpen(false)
        setModeModal(false)
    }

    useEffect(
        () => {

            if (open) {
                switch (mode) {
                    case 'edit': {
                        if (editOptionModalDetails) {
                            setFormData(editOptionModalDetails)
                        }
                        break;
                    }

                    case 'add': {
                        clearData()
                        break;
                    }

                    default:
                        break;
                }
            }

        }, [open]
    )

    handleDisplayable()

    return (
        <>
            <Dialog open={open} onOpenChange={(open) => { clearData(); setOpen(open) }}>
                <DialogTrigger asChild>
                    <Button onClick={() => setModalMode('add')} disabled={disabled} >Add Option</Button>
                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px] max-h-[90vh] overflow-auto">
                    <DialogHeader>
                        <DialogTitle>{mode === 'add' ? 'Create option' : 'Edit option'}</DialogTitle>
                    </DialogHeader>
                    <div className="grid gap-4 py-4 ">
                        {displayable.type && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="typeCustomization" className="text-right col-span-2">
                                type
                            </Label>
                            <div className='col-span-4'>
                                <SelectShadcn
                                    options={[
                                        { name: 'Dropdown', value: 'dropDown' },
                                        { name: 'normal', value: 'normal' },
                                    ]}
                                    value={formData.type}
                                    onChange={handleChangeInput}
                                    name={ProductCustomizationOptionEnumClient.type}
                                />
                            </div>
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.value}</div>
                        </div>}
                        {displayable.customization_name && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="name" className="text-right col-span-2">
                                Name
                            </Label>
                            <Input
                                id="name"
                                name={ProductCustomizationOptionEnumClient.customization_name}
                                value={formData.customization_name}
                                placeholder='customization name'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.customization_name}</div>
                        </div>}
                        {displayable.value && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="value" className="text-right col-span-2">
                                Value
                            </Label>
                            <Input
                                id="value"
                                name={ProductCustomizationOptionEnumClient.value}
                                value={formData.value === null ? '' : formData.value}
                                placeholder='value'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.value}</div>
                        </div>}
                        {displayable.isSlab && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <div className='col-span-2 flex justify-end'>

                                <Switch onCheckedChange={(isChecked) => {
                                    handleChangeInput({
                                        target: {
                                            name: ProductCustomizationOptionEnumClient.isSlab,
                                            value: isChecked ? '1' : '0'
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }} id="isSlab" className='' checked={formData.isSlab === 1 ? true : false} />
                            </div>
                            <Label className=' col-span-4' htmlFor="isSlab">Is Slab</Label>
                        </div>}
                        {displayable.rate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="rate" className="text-right col-span-2">
                                Hike Rate
                            </Label>
                            <Input
                                id="rate"
                                name={ProductCustomizationOptionEnumClient.rate}
                                value={formData.rate === null ? '' : formData.rate}
                                placeholder='rate'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.rate}</div>
                        </div>}
                        {displayable.hikePerc && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="hikePerc" className="text-right col-span-2">
                                hike percentage
                            </Label>
                            <Input
                                id="hikePerc"
                                name={ProductCustomizationOptionEnumClient.hikePerc}
                                value={formData.hikePerc === null ? '' : formData.hikePerc}
                                placeholder='hike percentage'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.hikePerc}</div>
                        </div>}
                        {displayable.hikePercInRate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="hikePercInRate" className="text-right col-span-2">
                                hike rate
                            </Label>
                            <Input
                                id="hikePercInRate"
                                name={ProductCustomizationOptionEnumClient.hikePercInRate}
                                value={formData.hikePercInRate === null ? '' : formData.hikePercInRate}
                                placeholder='hike percentage'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.hikePercInRate}</div>
                        </div>}
                        {displayable.minVal && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="minValueModal" className="text-right col-span-2">
                                Minmum Value
                            </Label>
                            <Input
                                id="minValueModal"
                                name={ProductCustomizationOptionEnumClient.minVal}
                                value={formData.minVal === null ? '' : formData.minVal}
                                placeholder='minimum value'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.minVal}</div>
                        </div>}
                        {displayable.maxVal && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="maxValueModal" className="text-right col-span-2">
                                Maximum Value
                            </Label>
                            <Input
                                id="maxValueModal"
                                name={ProductCustomizationOptionEnumClient.maxVal}
                                value={formData.maxVal === null ? '' : formData.maxVal}
                                placeholder='maximum value'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.maxVal}</div>
                        </div>}
                        {displayable.baseRate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                            <Label htmlFor="baseRateModal" className="text-right col-span-2">
                                Base Rate
                            </Label>
                            <Input
                                id="baseRateModal"
                                name={ProductCustomizationOptionEnumClient.baseRate}
                                value={formData.baseRate === null ? '' : formData.baseRate}
                                placeholder='Base Rate'
                                onChange={handleChangeInput}
                                className=' col-span-4'
                            />
                            <div className='col-span-2'></div>
                            <div className=' text-sm text-red-500 col-span-4'>{errorData.baseRate}</div>
                        </div>}

                    </div>
                    <DialogFooter>
                        <Button onClick={add_option} type="submit">{mode === 'add' ? 'Create' : 'Edit'}</Button>
                    </DialogFooter>
                </DialogContent>
            </Dialog>
            <Dialog open={openModeModal} onOpenChange={setModeModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>{mode==='add'?'create option for':'Edit Option for'}</DialogTitle>
                    </DialogHeader>
                    <div className=' flex justify-end gap-2'>
                        {
                            mode === 'add'
                                ?
                                <>
                                    <Button onClick={() => create_option('forThisVariation')}>This Variant</Button>
                                    <Button onClick={() => create_option('forAllVariation')} >All Variant</Button>
                                </>
                                :
                                <>
                                <Button onClick={() => edit_option('forThisVariation')}>This Variant</Button>
                                <Button onClick={() => edit_option('forAllVariation')} >All Variant</Button>
                            </>
                        }
                    </div>
                </DialogContent>
            </Dialog>
        </>
    )
}
