import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook"
import { useRESTapi } from "@/src/hooks/rest_API"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { ProductCategory } from "@/src/types"
import { useState } from "react"

type props = {
    show: boolean,
    setShow: (show: boolean) => any,
    updateUi: () => void,
    orderId?: string
}


type formDataType = {
    trackingNumber: string,
    expectedDeliveryDate: string
}

export function ShippedModal(props: props) {


    const [formData, setFormData] = useState<formDataType>({
        trackingNumber: '',
        expectedDeliveryDate: ''
    })
    const api = useRESTapi()
    const validationObject = {
        trackingNumber: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
        validationObject: validationObject
    })



    const onhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {


        let formdata: formDataType;

        formdata = {
            ...formData,
            [e.target.name]: e.target.value
        }
        switch (e.target.name) {
            case 'trackingNumber':
                formdata = {
                    ...formData,
                    trackingNumber: e.target.value
                }
                break;
        }

        performValidationOf(e.target.name, formdata)
        setFormData(formdata)

    }

    const shipOrder = async () => {

        if (performAllValidation(formData) === true) {
            const res = await api.post<{ success: boolean, data: string,message:string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/confirm_service`,
                body: {
                    orderId: props.orderId,
                    trackingNumber: formData.trackingNumber,
                },
                mode: 'admin',
            })
            if (res.data.success === true) {
                props.updateUi()
                props.setShow(false)
            }else{
                alert(res.data.message)
            }
        }
    }

    return (
        <AdminModal show={props.show} setShow={props.setShow}>
            <AdminModalContent>
                <AdminModalHeader>
                    <AdminModalTitle>Ship details</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle>
                    <div className=" flex flex-col gap-2">
                        <Label>Tracking number</Label>
                        <Input value={formData.trackingNumber} name='trackingNumber' onChange={onhandleChange} />
                        <div className=" text-red-500 text-xs">{errorData.trackingNumber}</div>
                    </div>
                    {/* <div className=" flex flex-col gap-2">
                        <Label>Expected Date</Label>
                        <Input type="date" value={formData.expectedDeliveryDate} name='expectedDeliveryDate' onChange={onhandleChange} />
                        <div className=" text-red-500 text-xs">{errorData.expectedDeliveryDate}</div>
                    </div> */}
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button onClick={shipOrder}>Conform Service</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>)


}