import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, ProductKeys, employee, role } from '../../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../../redux/store'
import { IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'
import { Switch } from '@/src/components/ui/switch'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { VariationTable } from './variationComponent/variationComponent'
import { Textarea } from '@/src/components/ui/textarea'



export type productOverview = Product & {
    images?: ProductImage[],
    variations: ({
        VariationID: string | number,
        CategoryID: string | number,
        Name: string,
        ProductID: string | number,
        options: ({
            VariationOptionID: string | number,
            Value: string,
            VariationID: string | number,
            ProductID: string | number
        })[]
    })[]
}

type imageIds = string[]

export type variationOptionItem = {
    name: string,
    id: string | number,
    error?: string
}

export interface VariationItem {
    id: string | number;
    variation: string;
    options: variationOptionItem[];
    error?: string;
}

export function AddProductModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {


    const validationObject = {
        [ProductKeys.M11_Name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductKeys.M11_Description]: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductKeys.M11_M04_CategoryID]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'please select category'
                    }
                    return true
                }
            ]
        },
        [ProductKeys.M11_ProductImages]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && mode === 'add') {
                        return 'required'
                    }
                    if (value?.length === 0) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductKeys.M11_M14_brand_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductKeys.M11_created_by]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        console.log(value)
                        alert('created by employee not submitted')
                        return 'required'
                    }
                    return true
                }
            ]
        },
    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<productOverview>({
        M11_M14_brand_id: '',
        M11_Description: '',
        M11_is_active: 1,
        M11_M04_CategoryID: '',
        M11_Name: '',
        M11_ProductImages: [],
        M11_created_by: employee.employeeData.M15_Employee_id!,
        images: [],
        variations: []
    })
    const [variationObject, setvariationObject] = useState<VariationItem[]>([

    ]);
    const [roles, setRoles] = useState<role[]>([])
    const [brands, setBrands] = useState<Brand[]>([])
    const [categories, setCategories] = useState<ProductCategory[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<Product>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [images_url, set_image_url] = useState<Partial<Product>>({
        M11_ProductImages: [],
        images: []
    })
    const [images_url_deleted, set_image_url_deleted] = useState<imageIds>([])

    const image_input_ref = useRef<HTMLInputElement>(null)
    const [add_progress, set_add_progress] = useState(0)



    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: productOverview;
        let image_urls: ProductImage[] = [];//for image urls

        switch (e.target.name) {
            case ProductKeys.M11_ProductImages:

                let total_image: CustomFile[] = []

                if (e.target.files) {
                    //adding image if there is image
                    if (formData.M11_ProductImages) {
                        const new_images: CustomFile[] = [...e.target.files].map(
                            item => {
                                const uniqueId = uuidv4();
                                const url = URL.createObjectURL(item)
                                image_urls.push(
                                    {
                                        P13_ProductImagesId: uniqueId,
                                        P13Image_path: url,
                                        mode: 'local'
                                    }
                                )
                                return ({
                                    file: item,
                                    id: uniqueId

                                })
                            }
                        )
                        total_image = [...formData.M11_ProductImages as CustomFile[], ...new_images]
                    } else {
                        const new_images: CustomFile[] = [...e.target.files].map(
                            item => {
                                const uniqueId = uuidv4();
                                const url = URL.createObjectURL(item)
                                image_urls.push(
                                    {
                                        P13_ProductImagesId: uniqueId,
                                        P13Image_path: url,
                                        mode: 'local'
                                    }
                                )
                                return ({
                                    file: item,
                                    id: uniqueId

                                })
                            }
                        )
                        total_image = [...new_images]
                    }
                }
                formdata = {
                    ...formData,
                    M11_ProductImages: total_image
                }
                // const images = formdata.M11_ProductImages as CustomFile[]

                //CREATING IMAGE DISPLAY URLS
                set_image_url({
                    ...images_url,
                    images: [...images_url.images!, ...image_urls]
                });
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_product() {

        setIsLoading(true)
        set_loading(true)
        formData.M11_created_by = employee.employeeData.M15_Employee_id!
        if (performAllValidation(formData) === true && validateVariations() === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductKeys.M11_ProductImages:
                                const images = data[1] as CustomFile[]
                                images.map(
                                    image => formDataReal.append(`${data[0]}[]`, image.file)
                                )
                                break;

                            case ProductKeys.M11_created_by:
                                formDataReal.append(data[0], String(employee.employeeData.M15_Employee_id))
                                break;
                            case ProductKeys.M11_Name:
                            case ProductKeys.M11_Description:
                            case ProductKeys.M11_M04_CategoryID:
                            case ProductKeys.M11_M14_brand_id:
                            case ProductKeys.M11_created_on:
                            case ProductKeys.M11_is_active:
                                formDataReal.append(data[0], String(data[1]))
                                break;

                        }

                    }
                )

                // VARIATIONS
                if (variationObject.length > 0) {
                    variationObject.map(
                        (variationData, index) => {
                            formDataReal.append(`variations[${index}][name]`, String(variationData.variation))
                            variationData.options.forEach(
                                (option, index2) => {
                                    formDataReal.append(`variations[${index}][options][${index2}]`, String(option.name))
                                }
                            )
                        }
                    )
                }

                // Print FormData values
                for (let [key, value] of formDataReal.entries()) {
                    console.log(`${key}: ${value}`);
                }



                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    closeModal();

                    alert('added product')
                }

            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)
        set_loading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.M11_created_by = employee.employeeData.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case ProductKeys.M11_ProductImages:
                                const images = data[1] as CustomFile[]
                                images.map(
                                    image => formDataReal.append(`${data[0]}[]`, image.file)
                                )
                                break;

                            case ProductKeys.M11_created_by:
                                formDataReal.append(data[0], String(employee.employeeData.M15_Employee_id))
                                break;

                            default:
                                if (data[0] === ProductKeys.M11_Name || data[0] === ProductKeys.M11_Description || data[0] === ProductKeys.M11_M04_CategoryID || data[0] === ProductKeys.M11_M14_brand_id || data[0] === ProductKeys.M11_created_on || data[0] === ProductKeys.M11_is_active) {
                                    formDataReal.append(data[0], String(data[1]))
                                }
                                break;
                        }

                    }
                )

                images_url_deleted.forEach(
                    item => {
                        formDataReal.append('delete_image_ids[]', item)
                    }
                )


                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                // console.log(images_url_deleted)

                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${id}`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    updateUi();
                    closeModal();
                    alert(res.data?.message || 'updated product')

                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    const loadCategory = async () => {

        const res = await api.get<{ success: boolean, data: ProductCategory[] }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_categories`,
            mode: 'admin',

        })


        if (res.data.success === true) {
            setCategories(res.data.data)
        } else {
            throw res.data
        }

    }
    const loadBrands = async () => {

        const res = await api.get<{ success: boolean, data: Brand[], message: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands`,
            mode: 'admin',

        })


        if (res.data.success === true) {
            setBrands(res.data.data)
        } else {
            throw res.data
        }

    }

    const load_product = async () => {

        const res = await api.get<{ success: boolean, data: productOverview, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${id}`,
            mode: 'admin',

        })
        if (res.data.success === true) {
            images_url.images = res.data.data.images ? res.data.data.images : []
            images_url.images = images_url.images.map(
                item => ({
                    ...item,
                    mode: 'cloude'
                })
            )
            // res.data.data.M11_ProductImages = []
            setFormData(res.data.data)
            if (res.data.data.variations) {
                setvariationObject(
                    res.data.data.variations.map(item => ({
                        id: item.VariationID,
                        variation: item.Name,
                        options: item.options.map(option => ({
                            id: option.VariationOptionID,
                            name: option.Value,
                        } satisfies variationOptionItem)),
                        error: ''
                    }))
                )
            }
            set_image_url(images_url)
        } else {
            throw res.data
        }


    }

    // 
    const validateVariations = (): boolean => {
        let error = false
        const validatedData = variationObject.map(item => {
            const validatedItem: VariationItem = { ...item, error: undefined };
            if (item.variation.trim() === '') {
                error = true
                validatedItem.error = 'Variation name is empty';
            }
            if (item.options.length === 0) {
                error = true
                validatedItem.error = 'Variation must have at least one option';
            }
            const validatedOptions = item.options.map(option => {
                if (option.name.trim() === '') {
                    error = true
                    return { ...option, error: 'Option name is empty' };
                }
                return { ...option, error: undefined };
            });
            validatedItem.options = validatedOptions;
            return validatedItem;
        });
        setvariationObject(validatedData);
        return !error
    };

    const delete_image = (image_id: string | undefined | null) => {

        const fileArray: CustomFile[] = formData.M11_ProductImages as CustomFile[]
        //DELETING FROM BLOB FILE
        if (fileArray) {
            const newFileArray = fileArray.filter(
                item => item.id !== image_id
            )
            formData.M11_ProductImages = newFileArray
        }
        // console.log(newFileArray)
        setFormData(formData)

        //DELETING FROM URLS
        const new_image_urls = images_url.images?.filter(
            item => {
                if (item.P13_ProductImagesId === image_id) {

                    if (item.mode === 'cloude') {
                        images_url_deleted.push(String(item.P13_ProductImagesId))
                        const uniqueArray = [...new Set(images_url_deleted)];
                        set_image_url_deleted(uniqueArray)
                    }

                    return false
                }
                return true
            }
        )

        set_image_url(
            {
                ...images_url,
                images: new_image_urls
            }
        )


    }

    const clear_data = () => {
        setFormData({
            M11_M14_brand_id: '',
            M11_Description: '',
            M11_is_active: 1,
            M11_M04_CategoryID: '',
            M11_Name: '',
            M11_ProductImages: [],
            M11_created_by: employee.employeeData.M15_Employee_id!,
            images: [],
            variations: []
        })
        setErrorData({})
        set_add_progress(0)
        setIsLoading(false)
        setvariationObject([
            // {
            //     id: 187656868,
            //     variation: 'color',
            //     options: [
            //         { id: 17858586, name: 'blue' }
            //     ]
            // }
        ])
    }

    const initEditSetup = () => {
        set_loading(true)
        Promise.all(
            [loadCategory(), loadBrands(), load_product()]
        ).then(
            () => {
                set_loading(false)

            }
        ).catch(
            (err) => {
                set_loading(false)

            }
        )

    }


    useEffect(
        () => {
            const initListSetup = () => {
                set_loading(true)
                clear_data()

                Promise.all(
                    [loadCategory(), loadBrands()]
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                clear_data()
                initEditSetup()
            } else {
                set_loading(true)
                clear_data()

                set_image_url({
                    M11_ProductImages: [],
                    images: []
                })
            }


        }, [show]
    )

    const onclick_add_image_input = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }



    return (
        <AdminModal show={show} setShow={(show) => {
            setShow(show)
            if (show === false) clear_data()
        }}  >
            <AdminModalContent >
                <AdminModalHeader progressValue={add_progress} >
                    <AdminModalTitle>Add Product</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle>
                    <Label >Name</Label>
                    <Input disabled={loading} value={formData.M11_Name} onChange={handleChangeInput} type="text" name={ProductKeys.M11_Name} placeholder='Name' />
                    <div className=' text-red-500'>{errorData.M11_Name ? errorData.M11_Name : null}</div>
                    <div className="h-3"></div>
                    <Label>Description</Label>
                    <Textarea disabled={loading} value={formData.M11_Description} onChange={handleChangeInput as unknown as React.ChangeEventHandler<HTMLTextAreaElement>} name={ProductKeys.M11_Description} placeholder='Description' />
                    <div className=' text-red-500'>{errorData.M11_Description ? errorData.M11_Description : null}</div>
                    <div className="h-3"></div>
                    <div>Category</div>
                    <SelectShadcn
                        value={String(formData.M11_M04_CategoryID) === '' ? undefined : String(formData.M11_M04_CategoryID)}
                        name={ProductKeys.M11_M04_CategoryID}
                        onChange={handleChangeInput}
                        options={categories.map(item => ({ value: String(item.M04_ProductCategoryId), name: item.M04_ProductCategoryName }))}
                        placeHolder='Select category'
                        disabled={loading}
                    />
                    <div className=' text-red-500'>{errorData.M11_M04_CategoryID ? errorData.M11_M04_CategoryID : null}</div>
                    <div className="h-3"></div>
                    <div>Image</div>
                    {
                        <div className=' flex gap-1 max-w-full overflow-auto'>
                            {images_url.images && Array.isArray(images_url.images) ? images_url.images.map(
                                (image, index) => <div key={index} className=' relative w-20- h-20 min-w-20 min-h-20 max-w-20 max-h-20   group'>
                                    <img src={`${image.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${image.P13Image_path}` : image.P13Image_path}`} alt="" className=' h-full w-full' />
                                    <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                        <button onClick={() => delete_image(image.P13_ProductImagesId as string)} >delete</button>
                                    </div>
                                </div>
                                //    (image) => <img src={`${process.env.REACT_APP_BACKEND_URL}/${image as string}`} alt="" height={80} width={80} />
                            ) : null}
                            <div onClick={onclick_add_image_input} className=' min-h-20 min-w-20 border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>
                            <Input disabled={loading} ref={image_input_ref} id='imageInput' className=' hidden' onChange={handleChangeInput} type="file" multiple accept='.png, .jpg' name={ProductKeys.M11_ProductImages} placeholder='Image' />
                        </div>
                    }
                    <div className=' text-red-500'>{errorData.M11_ProductImages ? errorData.M11_ProductImages as string : null}</div>
                    <div className="h-3"></div>
                    <div>Brand</div>
                    <SelectShadcn
                        disabled={loading}
                        value={String(formData.M11_M14_brand_id)}
                        name={ProductKeys.M11_M14_brand_id}
                        onChange={handleChangeInput as any}
                        options={brands.map(item => ({ value: String(item.M14_Brand_id), name: item.M14_Title }))}
                        placeHolder='Select Brand'
                    />

                    <div className=' text-red-500'>{errorData.M11_M14_brand_id ? errorData.M11_M14_brand_id : null}</div>


                    <div className="h-3"></div>
                    <VariationTable
                        disabled={loading}
                        setvariationObject={setvariationObject}
                        variationObject={variationObject}
                        reload_product={initEditSetup}
                        productDetails={formData}
                        mode={mode}
                    />
                    <div className="h-3"></div>

                    <div className=' flex gap-1'>
                        <Switch
                            disabled={loading}
                            checked={formData.M11_is_active == 1 ? true : false}
                            name={ProductKeys.M11_is_active}
                            onCheckedChange={
                                (checked) => handleChangeInput({
                                    target: {
                                        name: ProductKeys.M11_is_active,
                                        value: checked ? 1 : '0',
                                        checked: checked
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }

                        // onChange={handleChangeInput}
                        />
                        <div>is active</div>
                    </div>

                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={loading} variant={'outline'} onClick={closeModal} >Cancel</Button>

                    {
                        mode === 'add' ?
                            <Button disabled={isLoading || loading} onClick={add_product} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading || loading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
