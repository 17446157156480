import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Store, StoreKeys, pagination } from '../../../../../types'
import { AddPurchase } from './components/addmodal'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { Button } from '@/src/components/ui/button'

type Props = {
  setSelectedId:(id:string)=>any
}

export function PurchaseList(props:Props) {
  const { warehouseId } = useParams()
  const [store, set_store] = useState<(Store&{page_number:number,delete:any,edit:any})[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected,set_page_selected] = useState<number>(1)
  const [products_total,set_store_total] = useState<number>(1)
  const [per_page,set_per_page] = useState<number>(30)
  const navigate = useNavigate()
  
  const header: {
    label: StoreKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: StoreKeys.I02_Store_name,
        name: "name"
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id: number | string) {

    try {

      const res = await api.Delete<{ success: boolean, data: Store[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
        mode:'admin',

      })
      updateUi();
      if (res.data.success) {
        alert(res.data.message)
      } else {
        alert(res.data?.message||'somthing went wrong...')
      }


    } catch (error) {
      console.log(error)
    }

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {
      props.setSelectedId('A5')

      const initData = async () => {

        const res = await api.get<{ success: boolean, data: {data:(Store&{page_number:number,delete:any,edit:any})[]}&pagination }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/purchase_order`,
          mode:'admin',

          params:{
            perPage:per_page,
            page:page_selected
          }
        })

        if (res.data.success === true) {
          // console.log(res.data)
          set_store(res.data.data.data.map(
            (item,index) => {
              item.page_number = ((page_selected-1)*per_page)+(index+1)
              item.delete = <Button onClick={() => delete_product(String(item.I02_Store_id))}>Delete</Button>
              item.edit = <Button onClick={() => clickEditButton(String(item.I02_Store_id))}>Edit</Button>
              return item;
            }
          ))
          set_store_total(res.data.data.total)
        }

      }

      initData()

    }, [update]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddPurchase
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        warehouseId={warehouseId!}
        setShow={setShowModal}
      />
      <div className=' p-2 text-2xl font-bold'>Purchases</div>
      <div className=' w-full flex justify-end' >
        <Button onClick={open_add_modal} className=' '>Purchase product</Button>
      </div>
      <div className=' w-full border rounded-sm'>
        <Table className='styled-table w-full'>
          <TableHeader >
            <TableRow>
              {
                header.map(
                  (header, index) => <TableHead key={index}>{header.name}</TableHead>
                )
              }
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              store.length === 0
                ?
                'No data'
                :
                store.map(
                  (row: any, index) => (
                    <TableRow key={index} className=' cursor-pointer' onClick={()=>navigate(`${row.I02_Store_id}`)}>
                      {
                        header.map(
                          (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                        )
                      }
                    </TableRow>
                  )
                )

            }
          </TableBody>
        </Table>
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e)=>{set_page_selected(e);updateUi()}} selectedPage={page_selected} />
      </div>
    </div>
  )
}
