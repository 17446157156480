import { useRESTapi } from '@/src/hooks/rest_API'
import { CustomerAddress, CustomerAddressKeys, Emirates, ProductCategory, pagination } from '@/src/types'
import React, { useEffect, useState } from 'react'
import { SelectShadcn } from '../Select/selectShadcn'
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook'
import { Loader2 } from 'lucide-react'
import { Input } from '@/src/components/ui/input'
import { Label } from '@/src/components/ui/label'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'
import { customerFeeds } from '@/src/types/databaseFeeds'
import { PhoneNumberInput } from '@/src/components/Inputs/mobile_input'

type customerOverview = CustomerAddress
type Props = {
    updateUi: () => void,
    updatingFunction: (command: boolean) => void,
    cancelAddress: (command: boolean) => void,
    addressess:CustomerAddress[],
    setAddress:(address:CustomerAddress[])=>any,
    setSelectedAddress:(address:CustomerAddress)=>any
}


export default function UserAddress(props: Props) {

    const api = useRESTapi()
    const [emirates, setEmirates] = useState<{
        name: string,
        value: string
    }[]>([])
    const customerData = useSelector((state: RootState) => state.customer.customerData)


    const validationObject = {
        [CustomerAddressKeys.M02_phone_no]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required';
                    }
        
                    // UAE phone number validation
                    const uaePhoneNumberRegex = /^(\+971|0)?(50|51|52|54|55|56|58)\d{7}$/;
                    if (!uaePhoneNumberRegex.test(value)) {
                        return 'Invalid UAE phone number';
                    }
        
                    return true;
                }
            ]
        },
        [CustomerAddressKeys.M02_StreetNo]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_City]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_IsDefault]: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

        [CustomerAddressKeys.M02_M20_emirateID]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_email]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_PostalCode]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_Region]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    if (value.length > 30) {
                        return 'name is too long'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<CustomerAddress>({
        validationObject: validationObject
    })
    const [isLoading, setIsLoading] = useState(false)


    const [formData, setFormData] = useState<customerOverview>({
        M02_City: '',
        M02_IsDefault: 1,
        M02_M03_CountryID: '20',
        M02_M20_emirateID: '',
        M02_PostalCode: '',
        M02_Region: '',
        M02_StreetNo: '',
        M02_M01_CustomerID: '',
        M02_Name: '',
        M02_phone_no: '',
        M02_email: '',

    })

    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: CustomerAddress;
        console.log('name is ', e.target.name)
        switch (e.target.name) {

            case CustomerAddressKeys.M02_IsDefault:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    const loadEmirates = async () => {

        try {

            const res = await api.get<{ success: boolean, data: ({ data: Emirates[] } & pagination), message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/emirates`,
                mode: 'customer',
            })

            if (res.data.success === true) {
                setEmirates(
                    res.data.data.data.map(
                        item => ({
                            name: item.M20_Emirate_name,
                            value: String(item.M20_Emirate_id)
                        })
                    )
                )
            } else if (res.data.success === false) {
                alert(res.data.message)
                throw new Error('error')
            }


        } catch (error) {
            console.log(error)
            throw new Error('error')
        }

    }
    async function add_user_address(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        e.preventDefault()
        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        console.log(data[0])
                        switch (data[0]) {
                            case CustomerAddressKeys.M02_email:
                            case CustomerAddressKeys.M02_City:
                            case CustomerAddressKeys.M02_phone_no:
                            case CustomerAddressKeys.M02_IsDefault:
                            case CustomerAddressKeys.M02_M03_CountryID:
                            case CustomerAddressKeys.M02_M20_emirateID:
                            case CustomerAddressKeys.M02_PostalCode:
                            case CustomerAddressKeys.M02_Region:
                            case CustomerAddressKeys.M02_StreetNo:
                            case CustomerAddressKeys.M02_name:
                                formDataReal.append(data[0], String(data[1]))
                                // case CustomerAddressKeys.M02_M01_CustomerID:
                                break;
                        }
                    }

                )

                formDataReal.append('userId', String(customerData.M01_CustomerID))


                const res = await api.post<{ success: boolean, data: CustomerAddress, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address`,
                    body: formDataReal,
                    mode: 'customerPrivate'
                })


                if (res.data.success === true) {
                    props.updatingFunction(false)
                    // if it is logout customer only use one address. else address load from online
                    if (res.data.data.M02_M01_CustomerID == customerFeeds.logoutCustomer.M01_CustomerID) {
                        res.data.data.M02_IsDefault=1
                        props.setAddress([res.data.data])
                        props.setSelectedAddress(res.data.data)
                    }else{
                        props.updateUi()
                    }
                    // alert(res.data.message || 'address added')

                } else {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }



                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }

    useEffect(
        () => {
            loadEmirates()
        }, []
    )
    // function cancel_address(){
    //     props.cancel_addressClick(false)
    // }

    return (
        // <div className='pt-[46px] bg-[#4487a4] w-full h-[100vh] xl:px-20'>
        <div className=" block rounded-lg bg-white p-5 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">

            <div >
                <h5 className="mb-2 text-base leading-tight text-neutral-800 dark:text-neutral-50 flex  font-bold">
                    DELIVERY ADDRESS
                </h5>
                <p className="mb-1 text-base text-neutral-600 dark:text-neutral-200 text-green-400">
                    ADD NEW ADDRESS
                </p>
            </div>
            <div>
                <form action="" className='flex flex-col gap-2 lg:pr-20 xl:pr-32'>
                    <div className='flex sm:flex-row flex-col gap-2'>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Name</Label>
                            <Input
                                type="text"
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                id="exampleFormControlInput1"
                                name={CustomerAddressKeys.M02_name}
                                placeholder="Name"
                                onChange={handleChangeInput}
                            />
                            <div className=' text-red-500'>{errorData.M02_Name ? errorData.M02_Name : null}</div>
                        </div>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Mobile No</Label>
                            <PhoneNumberInput
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                name={CustomerAddressKeys.M02_phone_no}
                                onChange={handleChangeInput}
                            />
                            <div className=' text-red-500'>{errorData.M02_phone_no ? errorData.M02_phone_no : null}</div>
                        </div>

                    </div>

                    <div className='flex sm:flex-row flex-col gap-2'>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Region</Label>
                            <Input
                                type="text"
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                id="exampleFormControlInput1"
                                placeholder='Region'
                                name={CustomerAddressKeys.M02_Region}
                                value={formData.M02_Region}
                                onChange={handleChangeInput}
                            // placeholder="10-digit mobile number"
                            />
                            <div className=' text-red-500'>{errorData.M02_Region ? errorData.M02_Region : null}</div>
                        </div>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>E-mail</Label>
                            <Input
                                type="email"
                                required
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                id="exampleFormControlInput1"
                                placeholder='E-mail'
                                name={CustomerAddressKeys.M02_email}
                                value={formData.M02_email}
                                onChange={handleChangeInput}
                            // placeholder="10-digit mobile number"
                            />
                            <div className=' text-red-500'>{errorData.M02_email ? errorData.M02_email : null}</div>
                        </div>


                    </div>



                    <div className='flex sm:flex-row flex-col gap-2'>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Emirates</Label>
                            <SelectShadcn
                                options={emirates}
                                classNameOuter='w-full'
                                onChange={handleChangeInput}
                                value={String(formData.M02_M20_emirateID)}
                                placeHolder="Select Emirates"
                                name={CustomerAddressKeys.M02_M20_emirateID}
                            />
                            <div className=' text-red-500'>{errorData.M02_M20_emirateID ? errorData.M02_M20_emirateID : null}</div>
                        </div>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Street No</Label>
                            <Input
                                type="number"
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                id="exampleFormControlInput1"
                                placeholder="Street No"
                                name={CustomerAddressKeys.M02_StreetNo}
                                value={formData.M02_StreetNo}
                                onChange={handleChangeInput}
                            />
                            <div className=' text-red-500'>{errorData.M02_StreetNo ? errorData.M02_StreetNo : null}</div>
                        </div>


                    </div>
                    <div className='flex sm:flex-row flex-col gap-2'>
                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>City</Label>
                            <Input
                                type="text"
                                id="exampleFormControlInput1"
                                placeholder="City"
                                name={CustomerAddressKeys.M02_City}
                                value={formData.M02_City}
                                onChange={handleChangeInput}
                            />
                            <div className=' text-red-500'>{errorData.M02_City ? errorData.M02_City : null}</div>
                        </div>

                        <div className=" flex flex-col w-full sm:w-[50%] gap-1" data-twe-input-wrapper-init>
                            <Label>Postal Code</Label>
                            <Input
                                type="number"
                                // className="peer block min-h-[auto] w-full rounded border-0 bg-transparent px-3 py-[0.32rem] leading-[2.15] outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 peer-focus:text-primary data-[twe-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-white dark:placeholder:text-neutral-300 dark:autofill:shadow-autofill dark:peer-focus:text-primary [&:not([data-twe-input-placeholder-active])]:placeholder:opacity-0"
                                id="exampleFormControlInput1"
                                placeholder="Postal Code"
                                name={CustomerAddressKeys.M02_PostalCode}
                                value={formData.M02_PostalCode}
                                onChange={handleChangeInput}
                            />
                            <div className=' text-red-500 '>{errorData.M02_PostalCode ? errorData.M02_PostalCode : null}</div>
                        </div>
                    </div>

                    <div className='gap-2 flex '>
                        <button
                            onClick={add_user_address}
                            className='px-4 py-2 bg-[#495372] text-white'>
                            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                            SAVE AND DELIVER HERE
                        </button>
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                props.cancelAddress(false)
                            }}
                            className='px-4 py-2 bg-[gray] text-white'
                        >
                            CANCEL
                        </button>
                    </div>



                </form>
            </div>

        </div>
        // </div>
    )
}
