import { Button } from "@/src/components/ui/button"
import { Label } from "@/src/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/src/components/ui/popover"
import { useAlertModal } from "@/src/hooks/alert_modal"
import { useRESTapi } from "@/src/hooks/rest_API"
import { SelectShadcn } from "@/src/pages/customer/components/Select/selectShadcn"
import { Brand, pagination, Product, ProductCategory } from "@/src/types"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { CustomSelectCustomOptionOption, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from "../../../../components/input_loading_searching"
import { sortBy, sortDirections, sortObjectType, sortObjectTypeKeys } from "../page"

type props = {
  filterObj: sortObjectType,
  setFilterObj: (obj: sortObjectType) => any,
  updateUi: () => any,
  show: boolean,
  setshow: (show: boolean) => any
}

type selectOptionType = {
  name: string,
  value: string
}

export function SortComponent(props: props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [brands, setBrands] = useState<selectOptionType[]>([])
  const [loading, set_laoding] = useState(true)
  const alertModal = useAlertModal()

  const api = useRESTapi()
  const navigate = useNavigate();

  //load all product options
  const load_product_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Product & CustomSelectCustomOptionOption>> = async ({
    pagination,
    search = ''
  }: {
    pagination: pagination,
    search?: string
  }) => {
    // set_product_search_query(e.target.value);
    const res = await api.get<{ success: boolean, data: { data: Product[] } & pagination }>({
      url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products`,
      params: {
        perPage: pagination.per_page,
        page: 1,
        searchTerm: search
      },
      mode: 'admin',

    })


    const pagination_return: pagination = {
      per_page: res.data.data.per_page,
      total: res.data.data.total
    }

    if (res.data.success === true) {
      // console.log(res.data)
      return {
        options: res.data.data.data.map(
          (item, index) => {
            return {
              ...item,
              value: item.M11_ProductID!,
              name: item.M11_Name
            } satisfies CustomSelectCustomOptionOption & Product
          }
        ),
        pagination: pagination_return
      }

    } else {
      return {
        options: [],
        pagination: {
          total: 30,
          per_page: 30
        } as pagination
      }
    }
  }

  const fetch_selected_product: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Product & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
    if (props.value) {
      const res = await api.get<{ success: boolean, data: Product, errors: any, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${props.value}`,
        mode: 'admin',

      })

      if (res.data.success === false) {

        alert(res.data?.message || 'something went wrong')
        return {
          options: null
        }
      } else {

        return {
          options: {
            ...res.data.data,
            name: res.data.data.M11_Name,
            value: res.data.data.M11_ProductID,

          } as (Product & CustomSelectCustomOptionOption)
        }
      }
    }
    return {
      options: null
    }
  }

  async function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    let formdata: sortObjectType = {
      ...props.filterObj
    }

    switch (e.target.name) {



      case sortObjectTypeKeys.sortBy:

        formdata = {
          ...formdata,
          [e.target.name]: e.target.value as sortBy
        }

        break

      case sortObjectTypeKeys.sortDirection:

        formdata = {
          ...formdata,
          [e.target.name]: e.target.value as sortDirections
        }

        break

      default:
        formdata = {
          ...formdata,
          [e.target.name]: e.target.value
        }
        break;
    }




    props.setFilterObj(formdata)
    console.log(formdata)

  }

  const handleSearch = (params: sortObjectType) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(sortObjectTypeKeys.sortBy, params.sortBy ? params.sortBy : 'created_date');
    newSearchParams.set(sortObjectTypeKeys.sortDirection, params.sortDirection ? params.sortDirection : 'asc');
    navigate(`?${newSearchParams.toString()}`, { replace: true });
    // searchParams.set('productid',params.productId?params.productId:'')
    props.updateUi()
    props.setshow(false)
  }


  const loadBrands = async () => {

    const res = await api.get<{ success: boolean, data: Brand[], message: string }>({
      url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands`,
      mode: 'admin',

    })


    if (res.data.success === true) {
      setBrands([
        {
          name: 'ALL',
          value: '!!!!!!!!!'
        },
        ...res.data.data.map(item => ({ name: item.M14_Title, value: String(item.M14_Brand_id) }))])
    } else {
      throw res.data
    }

  }

  useEffect(
    () => {
      if (props.show) {
        set_laoding(true)
        Promise.all(
          []
        ).then(
          () => {
            set_laoding(false)
            props.setFilterObj({
              sortBy: searchParams.get(sortObjectTypeKeys.sortBy) as sortBy ? searchParams.get(sortObjectTypeKeys.sortBy)! as sortBy : 'created_date',
              sortDirection: searchParams.get(sortObjectTypeKeys.sortDirection) as sortDirections ? searchParams.get(sortObjectTypeKeys.sortDirection)! as sortDirections : 'asc'
            })
          }
        ).catch(
          (err) => {
            console.log(err)
            alertModal.open_alert_modal({
              title: 'Error',
              content: 'Error loading data',
              footer: <Button onClick={alertModal.close_alert_modal}>ok</Button>
            })
          }
        )
      }
    }, [props.show]
  )

  console.log('filter object is ',props.filterObj)


  return (
    <Popover open={props.show} onOpenChange={props.setshow} >
      <PopoverTrigger asChild>
        <Button variant={'white'} >Sort</Button>
      </PopoverTrigger>
      <PopoverContent onOpenAutoFocus={event => event.preventDefault()} className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Sort</h4>
            <p className="text-sm text-muted-foreground">
              These are available Sort
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <Label>Sort Based On</Label>
            <SelectShadcn
              value={props.filterObj.sortBy}

              name={sortObjectTypeKeys.sortBy}
              onChange={handleChangeInput}
              options={[
                { name: 'name', value: 'name' },
                { name: 'created date', value: 'created_date' },
                { name: 'updated date', value: 'updated_date' },
              ]}
              placeHolder='Based on'
              disabled={loading}
            />
            <Label className=" pt-2">Sort Direction</Label>
            <SelectShadcn
              value={props.filterObj.sortDirection}

              name={sortObjectTypeKeys.sortDirection}
              onChange={handleChangeInput}
              options={[
                { name: 'Ascending', value: 'asc' },
                { name: 'Descending', value: 'desc' },
              ]}
              placeHolder='Based on'
              disabled={loading}
            />

          </div>
          <Button onClick={() => handleSearch(props.filterObj)}>Search</Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
