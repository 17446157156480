import React from 'react'
import { CustomizationOptionStored } from 'src/types/local_storage';

export function useCustomizationApi() {

    const findTotalValue = (basePrice: number, customizationOption: CustomizationOptionStored[]): number => {

        let Total = basePrice;

        customizationOption.forEach(storedOption => {

            switch (storedOption.type) {
                case 'dropDown':
                    // finding totals for base price for dropdowns
                    if (storedOption.baseRate) {
                        Total += Number(storedOption.baseRate)
                    }
                    break;

                case 'normal':
                    // if there is base rate, add it to total
                    if (storedOption.baseRate) {
                        Total += Number(storedOption.baseRate)
                    }
                    // if not slab
                    if (storedOption.isSlab === false || storedOption.isSlab === null) {
                        Total += storedOption.rate! * Number(storedOption.Inputvalue)
                    } else {
                        // if slab
                        
                        const percMinVal = (storedOption.minVal! / 100) * storedOption.hikePerc!
                        const percRate = (storedOption.rate! / 100) * storedOption.hikePercInRate!
                        console.log('percRate',percRate)
                        
                        const priceDiff = Number(storedOption.Inputvalue) - storedOption.minVal!
                        const nubmerOfCountPerc = Math.trunc(priceDiff / percMinVal)
                        console.log('numberOfcount',nubmerOfCountPerc)
                        Total += percRate * nubmerOfCountPerc

                    }
                    break

                default:
                    break;
            }


        });

        return Total

    }

    return {
        findTotalValue
    }
}
