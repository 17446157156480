import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { TaxConfig } from './Tax/page'
import { ShippingSystem } from './shipping_system/page'


type Props = {
  setSelectedId:(id:string)=>any
}

export default function OthersRoot(props:Props) {
  return (
    <Routes>
      <Route index element={<Navigate to='tax_config' />} />
      <Route path='tax_config' element={<TaxConfig setSelectedId={props.setSelectedId} />} />
      <Route path='shipping_system' element={<ShippingSystem setSelectedId={props.setSelectedId} />} />
    </Routes>
  )
}
