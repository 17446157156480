import React, { useEffect, useState } from 'react'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import outlined from '@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined';
import { edit_variation_props } from '../variationComponent';
import { Input } from '@/src/components/ui/input';
import { Button } from '@/src/components/ui/button';
import { useRESTapi } from '@/src/hooks/rest_API';
import { ProductVariation } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Loader2 } from 'lucide-react';

type props = {
    edit_variation_modal_config: edit_variation_props,
    set_edit_variation_modal_config: (value: edit_variation_props) => any,
    reload_product:()=>any
}

type formDataType = {
    variationName: string
}
export default function VariationEditModal(props: props) {

    const [formData, setFormData] = useState<formDataType>({
        variationName: ''
    })
    const validationObject = {
        variationName: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        }
    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
        validationObject: validationObject
    })

    const [is_loading, set_is_loading] = useState(false)
    const api = useRESTapi()

    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formDataNew: formDataType = { ...formData }
        switch (e.target.name) {
            case 'variationName':
                formDataNew.variationName = e.target.value
                break;

            default:
                break;
        }
        performValidationOf(e.target.name, formDataNew)
        setFormData(formDataNew)
    }



    const updateVariation = async () => {
        set_is_loading(true)
        if (performAllValidation(formData)===true) {
            try {

                const res = await api.put<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                    mode: 'admin',
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variations/${props.edit_variation_modal_config.variation?.id}`,
                    body: {
                        Name: formData.variationName
                    }
                })
                if (res.data.success === true) {
                    props.set_edit_variation_modal_config({ show: false,showDeleteModal:false })
                    props.reload_product()
                } else {
                    alert(res.data.message)
                }

            } catch (error) {
                console.log(error)
                alert('server error')
            }
        }
        set_is_loading(false)
    }

    useEffect(
        () => {
            if (props.edit_variation_modal_config.show && props.edit_variation_modal_config.variation) {
                setFormData({
                    variationName: props.edit_variation_modal_config.variation.variation
                })
            }
        }, [props.edit_variation_modal_config.show]
    )

    return (
        <AdminModal show={props.edit_variation_modal_config.show} setShow={(show) => {
            if(is_loading===false){
                props.set_edit_variation_modal_config({
                    show: show,
                    variation: show ? props.edit_variation_modal_config.variation : undefined,
                    showDeleteModal:false
                })
            }
        }} >
            <AdminModalContent disableCloseButton={is_loading} >
                <AdminModalHeader>
                    <AdminModalTitle>
                        Variation
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-48 !min-w-48'>
                    <Input disabled={is_loading} name='variationName' onChange={onchange} value={formData.variationName} />
                    <p className=' text-red-500 text-xs mt-2'>{errorData.variationName}</p>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={is_loading} onClick={() => props.set_edit_variation_modal_config({ show: false,showDeleteModal:false })} variant={'outline'}>Cancel</Button>
                    <Button disabled={is_loading} onClick={updateVariation}>{is_loading&&<Loader2 className="mr-2 h-4 w-4 animate-spin" />}Edit</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
