import React, { useEffect, useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from "@/src/components/ui/select";

type option = {
    value:string,
    name:string
}
type SelectShadcnProps = {
    options:option[],
    placeHolder?:string,
    onChange?:(value:React.ChangeEvent<HTMLInputElement>)=>void,
    value?:string,
    name?:string,
    disabled?:boolean,
    classNameOuter?:string
}


export function SelectShadcn({options,placeHolder,onChange,value,name,disabled=false,classNameOuter}:SelectShadcnProps) {

    const [selectedValue,setSelectedValue] = useState('')

    useEffect(
        ()=>{
            if(value){
                setSelectedValue(value)
            }
        },[value]
    )

    return (
        <Select 
            
            disabled={disabled}
            value={selectedValue} onValueChange={
            (value1)=>{
                if(onChange){
                    const event  ={
                        target:{
                            name:name,
                            value:value1
                        }
                    } as React.ChangeEvent<HTMLInputElement>
                    onChange(event)
                }
                if(!value){
                    setSelectedValue(value1)
                }
            }
        
            
            
        } >
            <SelectTrigger  className={`w-[180px] ${classNameOuter}`}>
                <SelectValue placeholder={placeHolder} />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {
                        options.map(
                            (option,index)=><SelectItem key={index} value={option.value}>{option.name}</SelectItem>
                        )
                    }
                </SelectGroup>
            </SelectContent>
        </Select>
    )
}
