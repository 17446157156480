import React, { useState, useEffect } from 'react';

interface ResendOTPButtonProps {
  onResend: any;
  initialTime?: number;
}

export const ResendOTPButton: React.FC<ResendOTPButtonProps> = ({ 
  onResend, 
  initialTime = 30 
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(initialTime);
  const [isActive, setIsActive] = useState<boolean>(true);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (isActive && remainingTime > 0) {
      interval = setInterval(() => {
        setRemainingTime((time) => time - 1);
      }, 1000);
    } else if (remainingTime === 0) {
      setIsActive(false);
      if (interval) clearInterval(interval);
    }
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, remainingTime]);

  const handleResend = (): void => {
    setRemainingTime(initialTime);
    setIsActive(true);
    onResend();
  };

  return (
    <button 
      onClick={handleResend} 
      disabled={isActive}
      className={`${isActive===false?'text-blue-500 font-bold':''}`}
    >
      {isActive 
        ? `Resend OTP (${remainingTime}s)` 
        : 'Resend OTP'}
    </button>
  );
};