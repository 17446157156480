import { useRESTapi } from '@/src/hooks/rest_API';
import { orderStatusFlags } from '@/src/types';
import React, { useState } from 'react'
import { ShippedModal } from './shippedModal';
import { DeliveredModal } from './deliveredModal';


type Props = {
    status: orderStatusFlags,
    isActionalble: boolean,
    orderId?: string,
    updateUi: () => void
}

export function OrderStatus(props: Props) {

    const api = useRESTapi()
    const [showShippedModal, setShowShippedModal] = useState<boolean>(false)
    const [showDeliveryModal, setShowDeliveryModal] = useState<boolean>(false)

    const changeStatus = async () => {
        if (!props.isActionalble) {
            return;
        }

        if (props.orderId) {
            let success: boolean = false
            switch (props.status) {

                case 'bookedService': {
                    const res = await api.post<{ success: boolean, data: string }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/confirm_order_admin`,
                        body: {
                            orderId: props.orderId
                        },
                        mode: 'admin',
                    })
                    success = res.data.success
                    break
                }
                case 'confirmedService': {
                    setShowShippedModal(true)

                    break
                }
                case 'completedService': {

                    setShowDeliveryModal(true)
                    break
                }
            }

            if (success) {
                props.updateUi()
            }
        }
    }

    switch (props.status) {
        case 'ordered':
            return <>
                <div onClick={changeStatus} className=' px-2 py-1 bg-red-800 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Service Request</div>
            </>
        case 'bookedService':
            return <div onClick={changeStatus} className=' px-2 py-1 bg-red-800 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Service Request</div>
        case 'confirmedService':

            return (
                <>
                    <ShippedModal orderId={props.orderId} show={showShippedModal} setShow={setShowShippedModal} updateUi={props.updateUi} />
                    <div onClick={changeStatus} className=' px-2 py-1 bg-light-green-300 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Conform Service</div>
                </>
            )
        case 'completedService':

            return <>
                <DeliveredModal orderId={props.orderId} show={showDeliveryModal} setShow={setShowDeliveryModal} updateUi={props.updateUi} />
                <div onClick={changeStatus} className=' px-2 py-1 bg-light-green-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Order Completed</div>
            </>

        case 'cancelled':

            return <>
                <div onClick={changeStatus} className=' px-2 py-1 bg-red-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Cancelled</div>
            </>

        default:
            return <div className=' px-2 py-1 bg-green-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Unavailable</div>
    }


}
