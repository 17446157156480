import React from 'react'
import { CustomerAddress, pagination } from '../types'
import { useRESTapi } from './rest_API'
import { useSelector } from 'react-redux'
import { RootState } from '../redux/store'
import { customerFeeds } from '../types/databaseFeeds'

type responseType<T> = {
    success:boolean,
    data?:T,
    message:string
}

export  function useAddressAPI() {


    const api = useRESTapi()

    const customerData = useSelector((state:RootState)=>state.customer.customerData)

    //get all address of user full
    const loadAddressAll:() => Promise<responseType<({ data: CustomerAddress[] } & pagination)>> = async () => {


        try {

            // if customer is not logged in 
            if(customerData.M01_CustomerID==customerFeeds.logoutCustomer.M01_CustomerID){
                console.log(customerData)
                return {
                    success:true,
                    data:{
                        data:[],
                        per_page:1,
                        total:1,
                        
                    },
                    message:'succesfully fetched'
                } as responseType<({ data: CustomerAddress[] } & pagination)>
            }

            const res = await api.get<{ success: boolean, data: { data: CustomerAddress[] } & pagination,message:string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address`,
                mode: 'customer',
            })

            if (res.data.success === true) {
                return {
                    success:true,
                    data:res.data.data
                } as responseType<({ data: CustomerAddress[] } & pagination)>
            } else if (res.data.success === false) {
                return {
                    success:false,
                    message:res.data.message || 'something went wrong'
                } as responseType<({ data: CustomerAddress[] } & pagination)>
            }


        } catch (error) {
            console.log(error)
        }
        return {
            success:false,
            message:'server error'
        } as responseType<({ data: CustomerAddress[] } & pagination)>

    }

    // get address
    const getAddressById:({id}:{id:string}) => Promise<responseType<CustomerAddress>> = async ({id})=>{
        try {

            const res = await api.get<{ success: boolean, data: CustomerAddress,message:string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address/${id}`,
                mode: 'customerPrivate',
            })

            if (res.data.success === true) {
                return {
                    success:true,
                    data:res.data.data
                } as responseType<CustomerAddress>
            } else if (res.data.success === false) {
                return {
                    success:false,
                    message:res.data.message || 'something went wrong'
                } as responseType<CustomerAddress>
            }


        } catch (error) {
            console.log(error)
        }
        return {
            success:false,
            message:'server error'
        } as responseType<CustomerAddress>
    }

  return {
    loadAddressAll,
    getAddressById
  }
}
