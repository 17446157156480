
import React from 'react';
import { ProductCarousel } from './ProductCarousel';




export const Slider = ({
  slides,
  thumbnails
}:{slides:string[],thumbnails:string[]}) => {



  return (
    <div className='relative md2:sticky md2:top-10 h-min'>
      <ProductCarousel thumbnails={thumbnails}>
        { 
          slides.map( s => (
            <img className=' h-full w-full object-contain object-center' key={s} src={ s } alt="" />
          ))
        }   
      </ProductCarousel>
    </div>
  );    
};

