import React, { useEffect, useState } from 'react'
import { Button } from '@/src/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/src/components/ui/dialog';
import { Input } from '@/src/components/ui/input';
import { Label } from '@/src/components/ui/label';
import { customizationOptionType } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Switch } from '@/src/components/ui/switch';
import { ProductCustomizationOptionClient, ProductCustomizationOptionEnumClient } from '../../../addmodal';
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import { customizationVariationLevels } from '../../customizationMaster';
// import { ProductCustomizationOptionClient, ProductCustomizationOptionEnumClient } from '../../testingPage';

// Create a type with all properties set to boolean
type Displayble = {
    [K in keyof ProductCustomizationOptionClient]: boolean;
}



type props = {
    onEditVariation: (variatonIndex: number) => void,
    open: boolean,
    setOpen: (open: boolean) => any,
    variationIndex:number|undefined,
    variationLevels:customizationVariationLevels,
    setVariationLevels:(leves:customizationVariationLevels)=>any,
    optionIndex:number|undefined
}

type formDataType  = {
    variationOption:string
}

export function EditVariationOptionModal({
    onEditVariation,
    open,
    setOpen,
    variationIndex,
    variationLevels,
    setVariationLevels,
    optionIndex
}: props) {


    const [formData, setFormData] = useState<formDataType>({
        variationOption: '',
    })



    const validationObject = {
        'variationOption': {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }

                    const occurence = variationLevels[variationIndex!].variationOptions.filter(variation=>variation.value==value).length;
                    if(occurence>0){
                        return 'this variation option already exist'
                    }

                    return true
                }
            ]
        },


    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
        validationObject: validationObject
    })


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: formDataType;

        switch (e.target.name) {


            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)

    }


    function clearData() {
        setFormData(
            {
                variationOption:''
            }
        )
        setErrorData({})
    }

    function add_option() {
        // formData.varientID = generateId()
        if (performAllValidation(formData) === true && variationIndex!==undefined && optionIndex!==undefined) {

            variationLevels[variationIndex].variationOptions[optionIndex].value = formData.variationOption
            variationLevels[variationIndex].selectedValue = formData.variationOption
            setVariationLevels([...variationLevels])
            clearData()
            setOpen(false)
        }
    }

    useEffect(
        ()=>{
            if(open&&variationIndex!==undefined && optionIndex!== undefined ){

                const data:formDataType = {
                    variationOption: variationLevels[variationIndex].variationOptions[optionIndex].value!
                }

                setFormData(data)

            }else{
                clearData()
            }
        },[open]
    )


    return (
        <AdminModal show={open} setShow={(show)=>{
            setOpen(show)
        }} >
            <AdminModalContent className=' !max-w-[300px] !min-w-[300px]'>

                <AdminModalHeader>
                    <AdminModalTitle>
                        Edit Variation option Name
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-[300px] !min-w-[300px]'>
                    <div className=' flex flex-col gap-2'>
                    <Label >Variation Option</Label>
                    <Input name='variationOption' onChange={handleChangeInput} value={formData.variationOption} placeholder='option name' />
                    <div className=' text-xs text-red-500'>{errorData.variationOption?errorData.variationOption:null}</div>
                    </div>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button variant={'outline'} onClick={()=>setOpen(false)}>Cancel</Button>
                    <Button onClick={
                        add_option
                    } >Edit</Button>
                </AdminModalFooter>

            </AdminModalContent>

        </AdminModal>
    )
}
