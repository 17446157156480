import React, { useRef } from 'react';
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function ProcessSection() {
    const sectionRef = useRef(null);
    
    useGSAP(() => {
        const circles:any[] = gsap.utils.toArray('.circle');

        circles.forEach((circle, i) => {
            gsap.fromTo(
                circle,
                { backgroundColor: 'transparent',scale:2 },
                {
                    backgroundColor: circle.dataset.color,
                    duration: 0.4,
                    delay: i * 0.2,
                    ease: 'power1.out',
                    scale:1,
                    scrollTrigger: {
                        trigger: sectionRef.current,
                        start: 'top 70%',
                        once: true,
                        toggleActions: 'play reverse play reverse',
                    },
                }
            );
        });
    }, []);

    return (
        <section ref={sectionRef} className="container mx-auto py-16">
            <div className="text-center mb-16">
                <h2 className="text-3xl font-bold">How It Works</h2>
            </div>
            <div className="grid md:grid-cols-3 gap-8">
                <div className="text-center">
                    <div
                        className="circle h-20 w-20 mx-auto rounded-full flex items-center justify-center"
                        data-color="#bfdbfe"  // Tailwind's blue-200
                    >
                        {/* Step 1 SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-10 w-10 text-gray-800">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold mt-4">Step 1: Browse Products</h3>
                    <p className="mt-2">Explore our extensive range of kitchenware products.</p>
                </div>
                <div className="text-center">
                    <div
                        className="circle h-20 w-20 mx-auto rounded-full flex items-center justify-center"
                        data-color="#bbf7d0"  // Tailwind's green-200
                    >
                        {/* Step 2 SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-10 w-10 text-gray-800">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13l-1.38 5.55a1 1 0 00.38 1.05l.6.4h10.8l.6-.4a1 1 0 00.38-1.05L17 13M7 13L5.4 5M10 21a1 1 0 100-2 1 1 0 000 2zM16 21a1 1 0 100-2 1 1 0 000 2z" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold mt-4">Step 2: Place Your Order</h3>
                    <p className="mt-2">Order through WhatsApp or directly on our website. No login required!</p>
                </div>
                <div className="text-center">
                    <div
                        className="circle h-20 w-20 mx-auto rounded-full flex items-center justify-center"
                        data-color="#fef08a"  // Tailwind's yellow-200
                    >
                        {/* Step 3 SVG */}
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-10 w-10 text-gray-800">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 20l-5.447-2.724A2 2 0 013 15.382V8.618a2 2 0 011.553-1.894L9 4m6 16l5.447-2.724A2 2 0 0021 15.382V8.618a2 2 0 00-1.553-1.894L15 4M9 20V4m6 16V4m-6 0L15 4" />
                        </svg>
                    </div>
                    <h3 className="text-xl font-semibold mt-4">Step 3: Track Your Order</h3>
                    <p className="mt-2">Easily track your order status and delivery through our platform.</p>
                </div>
            </div>
        </section>
    );
}
