import { useEffect, useState } from "react";

type props = {
    totalRows: number,
    rowsPerPage: number,
    selectedPage: number,
    onClickIndex: (index: number) => void
}

type buttonContent = {
    value: number | string,
    type: 'number' | 'leftDrop' | 'rightDrop'
}

export function TablePagination({
    // indexSelected=1,
    totalRows,
    rowsPerPage,
    selectedPage = 1,
    onClickIndex = (index) => { }
}: props) {

    const [totalPages, setTotalPages] = useState(0);
    const diff = 2;



    const [leftButtons, setLeftButtons] = useState<buttonContent[]>([])      //left side hide buttons
    const [buttonArray, setButtonArray] = useState<buttonContent[]>([])
    const [rightButtons, setRightButtons] = useState<buttonContent[]>([])      //right side hide buttons

    // left side buttons listing
    useEffect(
        () => {
            const leftButtons: buttonContent[] = []
            const buttonArray: buttonContent[] = []
            const rightButtons: buttonContent[] = []
            setTotalPages(Math.ceil(totalRows / rowsPerPage))
            const totalPages = Math.ceil(totalRows / rowsPerPage)
            if (selectedPage - diff > 1) {
                buttonArray.push({
                    value: '...',
                    type: 'leftDrop'
                });
                for (let i = selectedPage - diff; i <= selectedPage; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
                for (let j = 1; j < selectedPage - diff; j++) {
                    leftButtons.push({
                        value: j,
                        type: 'number'
                    })
                }
            } else {
                for (let i = 1; i <= selectedPage; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
            }
            // right side button listing
            if (selectedPage + diff < totalPages) {
                for (let i = selectedPage + 1; i <= selectedPage + diff; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
                buttonArray.push({
                    value: '...',
                    type: 'rightDrop'
                })
                for (let j = totalPages; j >= selectedPage + diff + 1; j--) {
                    rightButtons.push({
                        value: j,
                        type: 'number'
                    })
                }
            } else {
                for (let i = selectedPage + 1; i <= totalPages; i++) {
                    buttonArray.push({
                        value: i,
                        type: 'number'
                    })
                }
            }
            setButtonArray(buttonArray)
            setLeftButtons(leftButtons)
            setRightButtons(rightButtons)
        }, [totalRows, rowsPerPage, selectedPage]
    )


    // const buttons = Array.from({ length: totalPages }, (_, index) => {
    //     const buttonNumber = index + 1; // Add 1 to get 1 to 5
    //     return (
    //         <div onClick={()=>onClickIndex(buttonNumber)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${ buttonNumber===selectedPage? 'bg-primaryy-500 text-white':'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{buttonNumber}</div>
    //         );
    // });
    const buttons = buttonArray.map((item, index) => {
        switch (item.type) {
            case 'number':

                return (
                    <div key={index} onClick={() => onClickIndex(item.value as number)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${item.value === selectedPage ? 'bg-gray-500 text-white' : 'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{item.value}</div>
                );
            case 'leftDrop':

                if (leftButtons.length > 1) {
                    return (
                        <LeftList
                            key={index}
                            list={leftButtons}
                            label={<div className={` cursor-pointer   rounded-md shadow-sm h-full min-h-7  min-w-7  bg-white hover:bg-gray-200 flex justify-center items-center  text-xs`} >{item.value}</div>}
                            onSelect={onClickIndex}
                        />
                    );
                } else if (leftButtons.length === 1) {
                    return <div key={index} onClick={() => onClickIndex(leftButtons[0].value as number)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${leftButtons[0].value === selectedPage ? 'bg-gray-500 text-white' : 'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{leftButtons[0].value}</div>
                }
                break

            case 'rightDrop':

                if (rightButtons.length > 1) {
                    return (
                        <RightList
                            key={index}
                            list={rightButtons}
                            label={<div className={` cursor-pointer   rounded-md shadow-sm h-full min-h-7  min-w-7  bg-white hover:bg-gray-200 flex justify-center items-center  text-xs`} >{item.value}</div>}
                            onSelect={onClickIndex}
                        />);
                } else if (rightButtons.length === 1) {
                    return <div key={index} onClick={() => onClickIndex(rightButtons[0].value as number)} className={` cursor-pointer   rounded-md shadow-sm h-full  min-w-7  ${rightButtons[0].value === selectedPage ? 'bg-gray-500 text-white' : 'bg-white hover:bg-gray-200'} flex justify-center items-center  text-xs`} >{rightButtons[0].value}</div>
                }
                break




            default:
                break;
        }
    });




    // if only one page found or no data
    if (totalRows <= rowsPerPage) {
        return null
    }
    return (
        <div className=" flex">
            <div className=" flex h-7 min-h-7 gap-1">
                {/* <div className="rounded-md shadow-sm h-full min-w-7  bg-white flex justify-center items-center"  ><IconArrowLeft size={17} /></div> */}
                {buttons}
                {/* <div className="rounded-md shadow-sm h-full  min-w-7 bg-white leading-none flex justify-center items-center"><IconArrowRight size={17} /></div> */}
            </div>
        </div>
    )

}

type buttonProps = {
    list: buttonContent[],
    label: any,
    onSelect: (value: number) => void,
}

function LeftList({ list = [], label, onSelect = (value) => { } }: buttonProps) {



    return (


        <select
        defaultValue={"#"}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onSelect(Number(e.target.value)) }}
        >
            <option disabled value="#">...</option>
            {
                list.map(
                    (item, index) => (
                        <option
                            key={index}
                            value={item.value}
                        > {item.value} </option>
                    )
                )
            }
        </select >
    )
}
function RightList({ list = [], label, onSelect = (value) => { } }: buttonProps) {




    return (


        <select
        defaultValue={'#'}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { onSelect(Number(e.target.value)) }}
        >
            <option disabled value="#">...</option>
           
            {
                list.map(
                    (item, index) => (
                        <option
                            key={index}
                            value={item.value}
                        > {item.value} </option>
                    )
                )
            }
        </select >
    )
}

