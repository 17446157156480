import React, { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { OrderStatus, ProductCategory, ProductCategoryKeys, ProductCustomizationOptionOrder, ProductItem, SalesOrder, SalesOrderLine, orderStatusFlags, pagination } from '../../../../../types'
import { AddCategoryModal } from './component/addmodal'
import { Button } from '@/src/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Eye, Pencil, Search, Trash } from 'lucide-react'
import { OrderStatus as OrderStatusComponent } from './component/orderStatus'
import { OrderStatusAction } from './component/orderStatusActions'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { InputIcon } from '@/src/components/ui/input'
import { FilterComponent } from './component/filterComponent'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { useDebounce } from '@/src/hooks/debounce_hook'
import { useDateFormatDate } from '@/src/hooks/format_date'



interface SalesOrderResponse extends SalesOrder {
  orderLines: ({
    product: Partial<ProductItem>,
  } & SalesOrderLine)[],
  orderStatus: OrderStatus,
}

type salesOrderOverview = {
  orderLines: ({
    quantity: number,
    price: number,
    productName: string,
    productImage: string,
  })[],
  orderId: string,
  date: React.ReactNode,
  orderSource: string,
  orderStatus: React.ReactNode,
  orderAction: React.ReactNode,
  details: any,
  page_number: number,
  Description:string
}

export type filterObjectType = {
  status: string,
  start_date: string,
  end_date: string,
  orderId: string,
}
export enum filterObjectTypeKeys {
  status = 'status',
  startDate = 'start_date',
  endDate = 'end_date',
  orderId = 'orderId',
}

type Props = {
  setSelectedId: (id: string) => any
}

export function ServiceOrders(props: Props) {

  const [products, set_salesOrders] = useState<salesOrderOverview[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilterPopover, setShowFilterPopover] = useState(false)
  const [isServiceLoading, setIsServiceLoading] = useState(false)
  const navigate = useNavigate()
  const alertModal = useAlertModal()
  const {
    convertToIndianFormat
  } = useDateFormatDate();
  
  const [filterObject, setfilterObject] = useState<filterObjectType>({
    end_date: searchParams.get(filterObjectTypeKeys.endDate) ? searchParams.get(filterObjectTypeKeys.endDate)! : '',
    start_date: searchParams.get(filterObjectTypeKeys.startDate) ? searchParams.get(filterObjectTypeKeys.startDate)! : '',
    status: searchParams.get(filterObjectTypeKeys.status) ? searchParams.get(filterObjectTypeKeys.status)! : '',
    orderId: searchParams.get(filterObjectTypeKeys.status) ? searchParams.get(filterObjectTypeKeys.status)! : '',
  })
  const debouncedSearchTerm = useDebounce<string>(filterObject.orderId ? filterObject.orderId : '', 600);


  const header: {
    label: ProductCategoryKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'orderId',
        name: "Order Id"
      },
      {
        label: 'date',
        name: "Date"
      },
      {
        label: 'orderStatus',
        name: "Status"
      },
      {
        label: 'orderAction',
        name: "Actions"
      },
      {
        label: 'details',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {

      props.setSelectedId('E2')

      const initData = async () => {
        setIsServiceLoading(true)
        try {
          const res = await api.get<{ success: boolean, data: { data: SalesOrderResponse[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/Servicelist`,
            params: {
              perPage: per_page,
              page: page_selected,
              status: searchParams.get(filterObjectTypeKeys.status),
              start_date: searchParams.get(filterObjectTypeKeys.startDate),
              end_date: searchParams.get(filterObjectTypeKeys.endDate),
              orderID: searchParams.get(filterObjectTypeKeys.orderId)
            },
            mode: 'admin',
          })

          if (res.data.success === true) {
            // console.log(res.data)
            // res.data.data.data = [
            //   {
            //     I19_order_id: '001',
            //     I19_M01_Customer_id: 'C001',
            //     I19_customer_address_name: 'John Doe',
            //     I19_customer_address: '123 Main St',
            //     I19_P16_payment_method_id: 'PM01',
            //     I19_I20_shiping_method: 'SM01',
            //     I19_sale_date: '2023-06-08',
            //     I19_completed: true,
            //     I19_cancelled: false,
            //     I19_order_source: 'Online',
            //     I19_I07_ship_via: 'FedEx',
            //     I19_I030_order_status: 'Completed',
            //     orderStatus: {
            //       I030_order_status_id: 'OS01',
            //       I030_order_name: 'ordered',
            //     },
            //     orderLines: [
            //       {
            //         I21_Order_id: '001',
            //         I21_M08_Product_item_id: 'P001',
            //         I21_I19_Sales_order_id: 'SO001',
            //         I21_Quantity: 2,
            //         I21_Price: 20.0,
            //         I21_I030_order_status: 'OS01',
            //         I21_is_Active: 1,
            //         product: {
            //           M08_ProductItemID: 'P001',
            //           M08_M11_ProductID: 'PR001',
            //           M08_ProductImage: 'image1.jpg',
            //           M08_ProductItemName: 'Product 1',
            //         },

            //       },
            //     ],
            //   },
            // ]
            set_salesOrders(res.data.data.data.map(
              (item, index) => {
                const eachData: salesOrderOverview = {
                  page_number: ((page_selected - 1) * per_page) + (index + 1),
                  details: <ButtonIcon toolTipText='order details' icon={<Eye className="h-4 w-4" />} onClick={() => clickEditButton(item.I19_order_id!.toString())}></ButtonIcon>,
                  date: <div className=' whitespace-nowrap'>{convertToIndianFormat(item.I19_sale_date,{
                    day:'2-digit',
                    month:'short',
                    year:'numeric'
                  })}</div>,
                  orderId: String(item.I19_order_id),
                  orderSource: item.I19_order_source,
                  orderStatus: <OrderStatusComponent updateUi={updateUi} isActionalble={false} status={item.orderStatus.I030_order_name} />,
                  orderAction: <OrderStatusAction updateUi={updateUi} orderID={String(item.I19_order_id)} currentStatus={item.orderStatus.I030_order_name} />,
                  orderLines: item.orderLines.map(
                    item => ({
                      price: item.I21_Price,
                      productImage: item.product.M08_ProductImage! as string,
                      productName: item.product.M08_ProductItemName!,
                      quantity: item.I21_Quantity,
                    })
                  ),
                  Description:item.I19_description!

                }
                return eachData;
              }
            ))

            set_products_total(res.data.data.total)
            set_per_page(res.data.data.per_page)
            setIsServiceLoading(false)
            // set_page_selected(res.data.)
          }
        } catch (error) {
          console.log(error)
          alertModal.open_alert_modal({
            content: 'Server Error',
            footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
            title: 'Error'
          })
        }

      }

      initData()

    }, [update, debouncedSearchTerm]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddCategoryModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <div className=' p-2 text-2xl font-bold'>Service Orders list</div>
      <div className=' w-full flex justify-between' >
        <InputIcon
          value={filterObject.orderId}
          onChange={(e) => {
            // searchParams.set(filterObjectTypeKeys.productSkuName, e.target.value)
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(filterObjectTypeKeys.orderId, e.target.value);
            navigate(`?${newSearchParams.toString()}`, { replace: true });

            setfilterObject({
              ...filterObject,
              orderId: e.target.value
            })
          }} classOuter=' md:min-w-[400px]' icon={<Search />}
          placeholder='Search'
        />
        <div className=' flex gap-2'>
          <FilterComponent
            filterObj={filterObject}
            setFilterObj={setfilterObject}
            updateUi={updateUi}
            show={showFilterPopover}
            setshow={setShowFilterPopover}
          />
        </div>
      </div>
      <div className='grow overflow-auto  w-full border rounded-md'>
        {
          isServiceLoading
            ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => { set_page_selected(e); updateUi() }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
