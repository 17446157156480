import React, { useEffect, useState } from 'react'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import outlined from '@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined';
import { edit_variation_props, option_add_modal_props, variation_add_modal_props } from '../variationComponent';
import { Input } from '@/src/components/ui/input';
import { Button } from '@/src/components/ui/button';
import { useRESTapi } from '@/src/hooks/rest_API';
import { ProductVariation } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Loader2 } from 'lucide-react';

type props = {
    add_option_config: option_add_modal_props,
    set_add_option_config: (value: option_add_modal_props) => any,
    reload_product: () => any
}

type formDataType = {
    optionName: string
}
export function OptionAddModal(props: props) {


    const [is_loading, set_is_loading] = useState(false)
    const api = useRESTapi()
    const [formData, setFormData] = useState<formDataType>({
        optionName: ''
    })
    const validationObject = {
        optionName: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        }
    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
        validationObject: validationObject
    })

    const onchange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let formDataNew: formDataType = { ...formData }
        switch (e.target.name) {
            case 'optionName':
                formDataNew.optionName = e.target.value
                break;

            default:
                break;
        }
        performValidationOf(e.target.name, formDataNew)
        setFormData(formDataNew)
    }

    const addOption = async () => {
        set_is_loading(true)
        try {

            const res = await api.post<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                mode: 'admin',
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variation_options`,
                body: {
                    M07_Value: formData.optionName,
                    M07_M011_ProductId: props.add_option_config.productId,
                    M07_M06_VariationID: props.add_option_config.variation_id,
                }
            })
            if (res.data.success === true) {
                props.set_add_option_config({ show: false, productId: '', variation_id: '' })
                props.reload_product()
                clear_data()
                
            } else {
                alert(res.data.message)
            }

        } catch (error) {
            console.log(error)
            alert('server error')
        }

        set_is_loading(false)
    }
    function clear_data(){
        setFormData({
            optionName:''
        })
    }
    return (
        <AdminModal show={props.add_option_config.show} setShow={(show) => {
            if (is_loading === false) {
                props.set_add_option_config({
                    ...props.add_option_config,
                    show: show,
                })
                setFormData({
                    optionName:''
                })
            }
        }} >
            <AdminModalContent disableCloseButton={is_loading} >
                <AdminModalHeader>
                    <AdminModalTitle>
                        Add Option
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-48 !min-w-48'>
                    <Input disabled={is_loading} name='optionName' onChange={onchange} value={formData.optionName} />
                    <p className=' text-red-500 text-xs mt-2'>{errorData.optionName}</p>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={is_loading} onClick={() => props.set_add_option_config({ show: false, productId: '', variation_id: '' })} variant={'outline'}>Cancel</Button>
                    <Button disabled={is_loading} onClick={addOption}>{is_loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Add</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
