import { orderStatusFlags } from '@/src/types';
import React from 'react';


interface ColorChangeProps {
  Data: orderStatusFlags;
}

export function ColorChange({ Data }: ColorChangeProps) {
  // Function to get the appropriate color class
  const getColorClass = (status: orderStatusFlags): string => {
    switch (status) {
      case 'bookedService':
        return 'text-red-500'; 
      case 'confirmedService':
        return 'text-orange-500';
      case 'completedService':
        return 'text-green-700'; 
      default:
        return 'text-gray-500'; 
    }
  };
  const getStatusName = (status: orderStatusFlags): string => {
    switch (status) {
      case 'bookedService':
        return 'Booked'; 
      case 'confirmedService':
        return 'Confirmed';
      case 'completedService':
        return 'Completed'; 
      default:
        return status; 
    }
  };

  return (
    <>
      <p className={`uppercase ${getColorClass(Data)}`}>{getStatusName(Data)}</p>
    </>
  );
}
