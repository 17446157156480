import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, CarouselKeys, role, Carousel, CarouselType } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Switch } from '@/src/components/ui/switch'
import { Button } from '@/src/components/ui/button'




type CarouselOverview = Carousel
type imageIds = string[]



export function AddCategoryModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [CarouselKeys.C301_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CarouselKeys.C301_image_path]: {
            message: '',
            validation: [
                (value) => {
                    if (!value && mode !== 'edit') {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CarouselKeys.C301_small_screen_image]: {
            message: '',
            validation: [
                (value) => {
                    if (!value && mode !== 'edit') {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CarouselKeys.C301_C302_carousel_type]: {
            message: '',
            validation: [
                (value) => {

                    if (value === 0) {
                        return true
                    }

                    if (!value) {
                        return 'please select category'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<CarouselOverview>({
        C301_C302_carousel_type: 1,
        C301_image_path: '',
        C301_is_active: 1,
        C301_name: '',
        C301_P309_promotion_group_id: null,
        C301_image_most_used_color: '',
        C301_small_screen_image: '',
        C301_small_screen_image_most_color: ''
    })

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<CarouselOverview>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSpinLoading, setSpinLoading] = useState(false)
    const [add_progress, set_add_progress] = useState(0)

    const [images_url_deleted, set_image_url_deleted] = useState<imageIds>([])

    const image_input_ref = useRef<HTMLInputElement>(null)
    const small_image_input_ref = useRef<HTMLInputElement>(null)

    // 3.8 ratio image
    const [largeImageImage, setlargeImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })
    // 3.8 ratio image
    const [smallImageImage, setsmallImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: CarouselOverview;

        switch (e.target.name) {
            case CarouselKeys.C301_image_path:

                if (e.target.files && e.target.files.length > 0) {
                    setSpinLoading(true)
                    const image = new Image();
                    const file = e.target.files[0];

                    image.onload = () => {
                        const aspectRatio = image.width / image.height;
                        console.log(aspectRatio)
                        if (aspectRatio >= 3.77 && aspectRatio <= 3.82) {
                            const uniqueId = uuidv4();
                            const url = URL.createObjectURL(file)
                            setlargeImageImage({
                                imageUrl: url,
                                mode: 'local'
                            })
                            const total_image: CustomFile = {
                                file: file,
                                id: uniqueId
                            };

                            formdata = {
                                ...formData,
                                C301_image_path: total_image
                            };



                            setFormData(formdata);
                            performValidationOf(e.target.name, formdata);
                            console.log(formdata);
                        } else {
                            if (image_input_ref.current) {
                                image_input_ref.current.value = ''
                            }
                            alert("Image aspect ratio must be 3.8");
                        }
                        setSpinLoading(false)
                    };

                    image.src = URL.createObjectURL(file);

                    formdata = {
                        ...formData,
                        C301_image_path: ''
                    };


                } else {
                    formdata = {
                        ...formData,
                        C301_image_path: ''
                    }
                }


                break;

            case CarouselKeys.C301_small_screen_image:

                if (e.target.files && e.target.files.length > 0) {
                    setSpinLoading(true)
                    const image = new Image();
                    const file = e.target.files[0];

                    image.onload = () => {
                        const aspectRatio = image.width / image.height;
                        console.log(aspectRatio)
                        if (aspectRatio >= 0.99 && aspectRatio <= 1.19) {
                            const uniqueId = uuidv4();
                            const url = URL.createObjectURL(file)
                            setsmallImageImage({
                                imageUrl: url,
                                mode: 'local'
                            })
                            const total_image: CustomFile = {
                                file: file,
                                id: uniqueId
                            };

                            formdata = {
                                ...formData,
                                C301_small_screen_image: total_image
                            };



                            setFormData(formdata);
                            performValidationOf(e.target.name, formdata);
                            console.log(formdata);
                        } else {
                            if (small_image_input_ref.current) {
                                small_image_input_ref.current.value = ''
                            }
                            alert("Image aspect ratio must be 1");
                        }
                        setSpinLoading(false)
                    };

                    formdata = {
                        ...formData,
                        C301_small_screen_image: ''
                    };
                    image.src = URL.createObjectURL(file);



                } else {
                    formdata = {
                        ...formData,
                        C301_small_screen_image: ''
                    }
                }


                break;


            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_carousel() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case CarouselKeys.C301_image_path:
                                if (largeImageImage.mode === 'local') {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }
                                break;
                            case CarouselKeys.C301_small_screen_image:
                                if (smallImageImage.mode === 'local') {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }

                                break;


                            case CarouselKeys.C301_C302_carousel_type:
                            case CarouselKeys.C301_name:
                            case CarouselKeys.C301_is_active:
                            case CarouselKeys.C301_order_index:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: CarouselOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert('added carousel')
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case CarouselKeys.C301_image_path:
                                if (largeImageImage.mode === 'local' && largeImageImage.imageUrl) {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }
                                break;
                            case CarouselKeys.C301_small_screen_image:
                                if (smallImageImage.mode === 'local' && smallImageImage.imageUrl) {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }

                                break;


                            case CarouselKeys.C301_C302_carousel_type:
                            case CarouselKeys.C301_name:
                            case CarouselKeys.C301_is_active:
                            case CarouselKeys.C301_order_index:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                        }
                    }

                )

                images_url_deleted.forEach(
                    item => {
                        formDataReal.append('delete_image_ids[]', item)
                    }
                )


                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                // console.log(images_url_deleted)

                const res = await api.post<{ success: boolean, data: CarouselOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {
                    closeModal()
                    updateUi();
                    alert(res.data?.message || 'updated Carousel')
                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }


    const load_carousel = async () => {

        const res = await api.get<{
            success: boolean, data: Carousel
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            setlargeImageImage({
                imageUrl: `${res.data.data.C301_image_path}`,
                mode: 'cloude'
            })
            setsmallImageImage({
                imageUrl: `${res.data.data.C301_small_screen_image}`,
                mode: 'cloude'
            })
            setFormData(res.data.data)
        } else {
            throw res.data
        }
    }


    const delete_image_large = () => {
        formData.C301_image_path = ''
        setFormData({ ...formData })
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }
    const delete_image_small = () => {
        formData.C301_small_screen_image = ''
        setFormData({ ...formData })
        setsmallImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }

    const clear_datas = () => {
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setsmallImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setErrorData({})
        setFormData({
            C301_C302_carousel_type: '',
            C301_image_most_used_color: '',
            C301_image_path: '',
            C301_is_active: 1,
            C301_name: '',
            C301_small_screen_image: '',
            C301_small_screen_image_most_color: '',
        })
    }


    useEffect(
        () => {

            clear_datas()

            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    C301_C302_carousel_type: 1,
                    C301_image_path: '',
                    C301_is_active: 1,
                    C301_name: '',
                    C301_P309_promotion_group_id: null,
                    C301_image_most_used_color: '',
                    C301_small_screen_image: '',
                    C301_small_screen_image_most_color: ''
                })

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_carousel()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )

    const onclick_add_image_input_small = () => {
        small_image_input_ref.current?.click();
        small_image_input_ref.current!.value = '';

    }
    const onclick_add_image_input_large = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }


    return (
        <AdminModal show={show} setShow={setShow}>
            <AdminModalContent disableCloseButton={isLoading}>

                <AdminModalHeader progressValue={add_progress}>
                    <AdminModalTitle >Add Product</AdminModalTitle>

                </AdminModalHeader>
                <AdminModalMiddle>
                    {
                        isSpinLoading
                        &&
                        <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                            <div className=' animate-spin '><IconLoader /></div>
                        </div>
                    }
                    {
                        loading === true
                            ?
                            <div className=' bg-white p-3'>Loading ...</div>
                            :
                            < >
                                <Input disabled={loading || isLoading} value={formData.C301_name} onChange={handleChangeInput} type="text" name={CarouselKeys.C301_name} placeholder='Name' />
                                <div className=' text-red-500'>{errorData.C301_name ? errorData.C301_name : null}</div>
                                <div>Big Screen Image(Ratio 3.8)</div>
                                {largeImageImage.imageUrl ?
                                    <div className=' relative h-20 aspect-[3.8] group'>
                                        <img src={`${largeImageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${largeImageImage.imageUrl}` : largeImageImage.imageUrl}`} alt="" className=' h-full w-full' />
                                        <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                            <Button disabled={loading || isLoading} onClick={() => delete_image_large()} >delete</Button>
                                        </div>
                                    </div>
                                    : <div onClick={onclick_add_image_input_large} className=' h-20 aspect-[3.8] border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}
                                <Input ref={image_input_ref} onChange={handleChangeInput} type="file" accept="image/*" className='hidden' name={CarouselKeys.C301_image_path} placeholder='Image' />
                                <div className=' text-red-500'>{errorData.C301_image_path ? errorData.C301_image_path as string : null}</div>
                                <div>Small Screen Image (Ratio 1)</div>
                                {smallImageImage.imageUrl ?
                                    <div className=' relative h-20 aspect-[1] group'>
                                        <img src={`${smallImageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${smallImageImage.imageUrl}` : smallImageImage.imageUrl}`} alt="" className=' h-full w-full' />
                                        <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                            <Button disabled={loading || isLoading} onClick={() => delete_image_small()} >delete</Button>
                                        </div>
                                    </div>
                                    : <div onClick={onclick_add_image_input_small} className=' h-20 aspect-[1] border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}
                                <Input ref={small_image_input_ref} onChange={handleChangeInput} className=' hidden' type="file" accept="image/*" name={CarouselKeys.C301_small_screen_image} placeholder='Image' />
                                <div className=' text-red-500'>{errorData.C301_small_screen_image ? errorData.C301_small_screen_image as string : null}</div>


                                <div className=' flex gap-1 mt-2'>
                                    <Switch checked={formData.C301_is_active == 1 ? true : false} onCheckedChange={checked => handleChangeInput({
                                        target: {
                                            value: checked ? '1' : '0',
                                            name: CarouselKeys.C301_is_active
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)} />
                                    <div>is active</div>
                                </div>
                            </>
                    }
                </AdminModalMiddle>
                <AdminModalFooter>
                    {
                        mode === 'add' ?
                            <Button disabled={isLoading} onClick={add_carousel} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )

}
