import React, { useEffect, useState } from 'react'
import { CardContent } from "@/src/components/ui/card";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    type CarouselApi,
} from "@/src/components/ui/carousel";

export default function CarouselForMob({ thumbnails }: { thumbnails: string[] }) {
    const [api, setApi] = React.useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [isVisible, setIsVisible] = React.useState(!document.hidden);

    React.useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);

        api.on("select", () => {
            setCurrent(api.selectedScrollSnap() + 1);
        });

        const interval = setInterval(() => {
            if (isVisible) {
                api.scrollNext();
            }
        }, 3000); // Change slide every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [api, isVisible]);

    React.useEffect(() => {
        const handleVisibilityChange = () => {
            setIsVisible(!document.hidden);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const handleIndicatorClick = (index: number) => {
        if (api) {
            api.scrollTo(index);
        }
    };

    return (
        <div>
            <Carousel
                setApi={setApi}
                opts={{ loop: true }}
                className="bg-transparent aspect-1 h-[350px] w-full rounded-lg overflow-hidden"
            >
                <CarouselContent>
                    {
                        thumbnails.map((image, index) => (
                            <CarouselItem key={index}>
                                <CardContent className="p-0 w-full">
                                    <img
                                        src={image}
                                        alt={`carousel ${index + 1}`} // Alt text with dynamic index
                                        className="h-full w-full"
                                    />
                                </CardContent>
                            </CarouselItem>
                        ))
                    }
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
                <div className="absolute bottom-1 left-1/2 -translate-x-1/2 py-2 text-center text-sm text-white flex space-x-1">
                    {Array.from({ length: count }).map((_, index) => (
                        <div
                            key={index}
                            onClick={() => handleIndicatorClick(index)}
                            className={`h-2 w-2 rounded-full cursor-pointer ${index === current - 1 ? "bg-white w-3" : "bg-gray-500"
                                }`}
                        />
                    ))}
                </div>
            </Carousel>
        </div>
    );
}
