import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook"
import { useRESTapi } from "@/src/hooks/rest_API"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { ProductCategory } from "@/src/types"
import { useState } from "react"

type props = {
    show: boolean,
    setShow: (show: boolean) => any,
    updateUi: () => void,
    orderId?: string
}


type formDataType = {
    arrival_date: string
}

export function DeliveredModal(props: props) {


    const [formData, setFormData] = useState<formDataType>({
        arrival_date: new Date().toISOString().split('T')[0]
    })
    const api = useRESTapi()
    const validationObject = {
        arrival_date: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                },
                (value) => {

                    const enteredDate = new Date(value);
                    const today = new Date();
                    const enterDateString = enteredDate.toISOString().split('T')[0]
                    const todayString = enteredDate.toISOString().split('T')[0]

                    // Check if entered date is today or day after tomorrow
                    if (enteredDate >= today||enterDateString==todayString) {
                        return true
                    } else {
                        // Reset to today's date if invalid
                        return 'enter date valid'
                    }
                },
            ]
        },

    } satisfies validationObject
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
        validationObject: validationObject
    })



    const onhandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {


        let formdata: formDataType;

        formdata = {
            ...formData,
            [e.target.name]: e.target.value
        }
        switch (e.target.name) {
            case 'arrival_date':
                formdata = {
                    ...formData,
                    arrival_date: e.target.value
                }
                break;
        }

        performValidationOf(e.target.name, formdata)
        setFormData(formdata)

    }

    const deliverOrder = async () => {

        if (performAllValidation(formData) === true) {
            const res = await api.post<{ success: boolean, data: string,message:string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/delivered_order`,
                body: {
                    orderId: props.orderId,
                    arrival_date:formData.arrival_date
                },
                mode: 'admin',
            })
            if (res.data.success === true) {
                props.updateUi()
                props.setShow(false)
            }else{
                alert(res.data.message)
            }
        }
    }

    return (
        <AdminModal show={props.show} setShow={props.setShow}>
            <AdminModalContent>
                <AdminModalHeader>
                    <AdminModalTitle>Ship details</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle>
                    <div className=" flex flex-col gap-2">
                        <Label>Arrival Date</Label>
                        <Input type="date" value={formData.arrival_date} name='arrival_date' onChange={onhandleChange} />
                        <div className=" text-red-500 text-xs">{errorData.arrival_date}</div>
                    </div>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button onClick={deliverOrder}>Deliver Order</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>)


}