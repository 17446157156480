import { Button } from "@/src/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/src/components/ui/dialog"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"


type PropsModal = {
    show: boolean,
    setShow: (show: boolean) => any,
    children: any,

}

export function AdminModal(
    props: PropsModal
) {
    return (
        <Dialog open={props.show} onOpenChange={props.setShow}   >
            {props.children}
        </Dialog>
    )
}

type PropsContent = {
    children: any,
    className?: string,
    disableCloseButton?:boolean
}

export function AdminModalContent(props: PropsContent) {
    return (
        <DialogContent onOpenAutoFocus={(e)=>e.preventDefault()} disableCloseButton={props.disableCloseButton} className={` p-0 overflow-hidden w-fit h-fit max-w-none gap-0 `}>
            {props.children}
        </DialogContent>
    )
}
export function AdminModalMiddle(props: PropsContent) {
    return (
        <div className={`sm:max-h-[80vh] max-h-[80vh] min-w-[100vw] sm:min-w-[600px] sm:max-w-[600px] md:min-w-[800px] md:max-w-[800px] overflow-auto p-2 sm:p-5 ${props.className}`}>
            {props.children}
        </div>
    )
}

type PropsHeader = {
    children: any,
    progressValue?:number,
    className?:string
}

export function AdminModalHeader(props: PropsHeader) {
    return (
        <>
            <DialogHeader className={`p-2 border-b shadow-sm ${props.className}`} >
                {props.children}
            </DialogHeader>
            <AdminModalProgress value={props.progressValue||0} />
        </>
    )
}
type PropsTitle = {
    children: any
}

export function AdminModalTitle(props: PropsTitle) {
    return (
        <DialogTitle>
            {props.children}
        </DialogTitle>
    )
}

type PropsProgress = {
    value: number
}
function AdminModalProgress(props: PropsProgress) {
    return (
        <div className=" w-full h-1">
            <div style={{
                width: `${props.value}%`
            }} className=" bg-blue-gray-500 h-full" ></div>
        </div>
    )
}
type PropsFooter = {
    children: any
}

export function AdminModalFooter(props: PropsFooter) {
    return (
        <DialogFooter className="  bg-white m-0 p-2 border-t gap-2">
            {props.children}
        </DialogFooter>
    )
}

