import React from 'react'
import { useDispatch } from 'react-redux'
import { closeAlertModal, showAlertModal } from '../redux/modals/modal_slice'

type commonModalType = {
    content:string,
    title:string,
    footer:React.ReactNode
}

export function useAlertModal() {


    const dispatch = useDispatch()

    const close_alert_modal = ()=>{

        dispatch(closeAlertModal())

    }
    const open_alert_modal = (modal:commonModalType)=>{

        dispatch(showAlertModal({
            content:modal.content,
            footer:modal.footer,
            show:true,
            title:modal.title
        }))

    }


  return {
    close_alert_modal,
    open_alert_modal
  }
}
