import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, BrandKeys, employee, role } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'
import { Switch } from '@/src/components/ui/switch'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { useAlertModal } from '@/src/hooks/alert_modal'



export type BrandOverview = Brand

export type variationOptionItem = {
    name: string,
    id: string | number,
    error?: string
}

export interface VariationItem {
    id: string | number;
    variation: string;
    options: variationOptionItem[];
    error?: string;
}

export function AddBrandModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {


    const validationObject = {
        [BrandKeys.M14_Title]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [BrandKeys.M14_Brand_image]: {
            message: '',
            validation: [
                (value: CustomFile) => {

                    if (value.file || mode === 'edit') {
                        return true
                    }
                    return 'required'
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const alertModal = useAlertModal()

    const [formData, setFormData] = useState<BrandOverview>({
        M14_Brand_image: '',
        M14_Content: '',
        M14_is_brand_promoted: 0,
        M14_Summary: '',
        M14_Title: '',
        M14_is_Active: 1,
        M14_M15_created_by: employee.employeeData.M15_Employee_id ? employee.employeeData.M15_Employee_id : ''
    })
    const [add_progress, set_add_progress] = useState(0)

    const [brands, setBrands] = useState<Brand[]>([])
    const [categories, setCategories] = useState<ProductCategory[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<Brand>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [imageImage, setImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })
    const image_input_ref = useRef<HTMLInputElement>(null)



    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: BrandOverview;

        switch (e.target.name) {

            case BrandKeys.M14_Brand_image:

                let total_image: CustomFile
                if (!e.target.files) {
                    formdata = {
                        ...formData,
                        M14_Brand_image: ''
                    }
                    break;
                }


                const item = e.target.files[0]
                const uniqueId = uuidv4();
                const url = URL.createObjectURL(item)
                total_image = {
                    file: item,
                    id: uniqueId

                }
                formdata = {
                    ...formData,
                    M14_Brand_image: total_image
                }

                setImageImage({
                    imageUrl: url,
                    mode: 'local'
                })
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_brand() {

        setIsLoading(true)
        set_loading(true)
        formData.M14_M15_created_by = employee.employeeData.M15_Employee_id!
        formData.M14_Content = formData.M14_Title
        formData.M14_Summary = formData.M14_Title
        if (performAllValidation(formData) === true) {
            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case BrandKeys.M14_Brand_image:
                                if (imageImage.mode === 'local') {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }
                                break;


                            case BrandKeys.M14_Title:
                            case BrandKeys.M14_Content:
                            case BrandKeys.M14_Summary:
                            case BrandKeys.M14_M15_created_by:
                            case BrandKeys.M14_is_brand_promoted:
                            case BrandKeys.M14_is_Active:
                                formDataReal.append(data[0], String(data[1]))
                                break;

                        }

                    }
                )



                const res = await api.post<{ success: boolean, data: Brand, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }

                })

                if (res.data.success === true) {
                    updateUi();
                    closeModal();

                    alertModal.open_alert_modal({
                        content: res.data?.message || 'Brand Created',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Created'
                    })
                } else {

                    alertModal.open_alert_modal({
                        content: res.data?.message || 'something went wrong',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Error'
                    })
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }

            } catch (error) {
                console.log(error)
                alertModal.open_alert_modal({
                    content: 'Server Error',
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
            }
        }
        setIsLoading(false)
        set_loading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.M14_M15_created_by = employee.employeeData.M15_Employee_id!
        formData.M14_Content = formData.M14_Title
        formData.M14_Summary = formData.M14_Title
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case BrandKeys.M14_Brand_image:
                                if (imageImage.mode === 'local') {
                                    const images = data[1] as CustomFile
                                    formDataReal.append(`${data[0]}`, images.file)
                                }

                                break;


                            case BrandKeys.M14_Title:
                            case BrandKeys.M14_Content:
                            case BrandKeys.M14_Summary:
                            case BrandKeys.M14_M15_created_by:
                            case BrandKeys.M14_is_brand_promoted:
                            case BrandKeys.M14_is_Active:
                                formDataReal.append(data[0], String(data[1]))
                                break;

                        }

                    }
                )






                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                // console.log(images_url_deleted)

                const res = await api.post<{ success: boolean, data: Brand, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }

                })

                if (res.data.success === true) {
                    updateUi();
                    alertModal.open_alert_modal({
                        content: res.data?.message || 'Brand updated',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Updated'
                    })
                    closeModal()
                } else {

                    alertModal.open_alert_modal({
                        content: res.data?.message || 'Something went wrong',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Error'
                    })
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
                alertModal.open_alert_modal({
                    content: 'Server Error',
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
            }
        }
        setIsLoading(false)

    }

    const load_brand = async () => {

        const res = await api.get<{ success: boolean, data: Brand, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands/${id}`,
            mode: 'admin',

        })
        if (res.data.success === true) {

            setFormData(res.data.data)
            setImageImage({
                imageUrl: res.data.data.M14_Brand_image as string,
                mode: 'cloude'
            })
        } else {
            throw res.data
        }


    }



    const clear_data = () => {
        setFormData({
            M14_Brand_image: '',
            M14_Content: '',
            M14_is_brand_promoted: 0,
            M14_Summary: '',
            M14_Title: '',
            M14_is_Active: 1,
            M14_M15_created_by: employee.employeeData.M15_Employee_id ? employee.employeeData.M15_Employee_id : ''
        })
        setErrorData({})
        set_add_progress(0)
        setImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }

    const initEditSetup = () => {
        set_loading(true)
        Promise.all(
            [load_brand()]
        ).then(
            () => {
                set_loading(false)

            }
        ).catch(
            (err) => {
                set_loading(false)

            }
        )

    }


    useEffect(
        () => {
            const initListSetup = () => {
                set_loading(true)
                clear_data()

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                clear_data()
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )


    const delete_image = () => {
        setImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }

    const onclick_add_image_input = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }



    return (
        <AdminModal show={show} setShow={(show) => {
            setShow(show)
            if (show === false) clear_data()
        }}  >
            <AdminModalContent disableCloseButton={isLoading} >
                <AdminModalHeader progressValue={add_progress} >
                    <AdminModalTitle>{mode === 'add' ? 'Add Brand' : 'Edit Brand'}</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle>
                    <Label >Title</Label>
                    <Input disabled={loading || isLoading} value={formData.M14_Title} onChange={handleChangeInput} type="text" name={BrandKeys.M14_Title} placeholder='Title' />
                    <div className=' text-red-500'>{errorData.M14_Title ? errorData.M14_Title : null}</div>
                    <div className="h-3"></div>

                    <div>Image (Recommended 355 x 213)</div>
                    {
                        <div className=' flex gap-1 max-w-full overflow-auto'>
                            {imageImage.imageUrl ?
                                <div className=' relative w-20- h-20 min-w-20 min-h-20 max-w-20 max-h-20   group'>
                                    <img src={`${imageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/${imageImage.imageUrl}` : imageImage.imageUrl}`} alt="" className=' h-full w-full' />
                                    <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                        <Button disabled={loading || isLoading} onClick={() => delete_image()} >delete</Button>
                                    </div>
                                </div>
                                : <div onClick={onclick_add_image_input} className=' min-h-20 min-w-20 border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}

                            <Input disabled={loading || isLoading} ref={image_input_ref} id='imageInput' className=' hidden' onChange={handleChangeInput} type="file" multiple accept='.png, .jpg' name={BrandKeys.M14_Brand_image} placeholder='Image' />
                        </div>
                    }
                    <div className=' text-red-500'>{errorData.M14_Brand_image ? errorData.M14_Brand_image as string : null}</div>




                    <div className="h-3"></div>

                    <div className=' flex gap-1'>
                        <Switch
                            disabled={loading || isLoading}
                            checked={formData.M14_is_Active == 1 ? true : false}
                            name={BrandKeys.M14_is_Active}
                            onCheckedChange={
                                (checked) => handleChangeInput({
                                    target: {
                                        name: BrandKeys.M14_is_Active,
                                        value: checked ? 1 : '0',
                                        checked: checked
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }

                        // onChange={handleChangeInput}
                        />
                        <div>is active</div>
                    </div>

                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={loading || isLoading} variant={'outline'} onClick={closeModal} >Cancel</Button>

                    {
                        mode === 'add' ?
                            <Button disabled={isLoading || loading} onClick={add_brand} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading || loading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
