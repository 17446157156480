import React from 'react'
import { Link } from 'react-router-dom'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function HeaderSection() {

    useGSAP(() => {
        
        gsap.fromTo(
           '#AboutUstitleId',
            {  opacity: 0,y:20 },
            { opacity: 1,y:0,duration:1, scrollTrigger: { trigger:'#AboutUstitleId',start: 'top bottom',  end: 'bottom top' } }
        );

    

    });


    return (
        <header className="bg-[#232F3E] text-white px-8 pb-12">
            <div className=' h-[2px] rounded-full w-[80%] bg-white mx-auto mb-10'></div>
            <div id='AboutUstitleId' className="container mx-auto">
                <h1 className="text-4xl font-bold">Exquisite Kitchenware for Every Need</h1>
                <p className="mt-4 max-w-2xl">
                    We provide high-quality kitchen products and services for hotels, homes, and bulk orders. Enhance your culinary experiences with our premium kitchenware.
                </p>
                <Link to={'/'} className=" block w-min whitespace-nowrap mt-8 px-6 py-3 bg-white text-[#232F3E] font-semibold rounded shadow">
                    Explore Our Products
                </Link>
            </div>
        </header>
    )
}
