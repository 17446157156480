import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useRESTapi } from '../../../hooks/rest_API'
import { employee } from '../../../types'
import { useDispatch } from 'react-redux'
import { setEmployee } from '../../../redux/employee/employee_slice'
import { IconLoader } from '@tabler/icons-react'
import { localStorageKeys } from 'src/types/local_storage'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@/src/components/ui/card'
import { Label } from '@/src/components/ui/label'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { Loader2 } from 'lucide-react'
import { ResendOTPButton } from './components/resentOTP'

export function LoginPage() {

  const api = useRESTapi()
  const [email, setEmail] = useState('')
  const [otp_mode, set_otp_mode] = useState(false)
  const [otp, set_otp] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)


  const sent_otp = async () => {
    setIsLoading(true)
    try {
      const res = await api.post<{ success: boolean, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/send_otp`,
        body: {
          email: email
        },
        mode: 'customer'
      })

      if (res.data.success === true) {
        set_otp_mode(true)
      } else {
        alert(res.data.message)
      }
    } catch (error) {
      console.log(error)
      alert('something went wrong')
    }
    setIsLoading(false)
  }

  const resent_otp = async () => {
    try {
      const res = await api.post<{ success: boolean, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/send_otp`,
        body: {
          email: email
        },
        mode: 'admin'
      })

      if (res.data.success === true) {
        set_otp_mode(true)
      } else {
        alert(res.data.message)
      }
    } catch (error) {
      console.log(error)
      alert('something went wrong')
    }
  }

  const verify_otp = async () => {
    setIsLoading(true)
    try {
      const res = await api.post<{ success: boolean, data: employee, message: string, access_token: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/verify_otp`,
        mode: 'customer',
        body: {
          email: email,
          M15_OTP: otp
        }
      })

      if (res.data.success === true) {
        localStorage.setItem(process.env.REACT_APP_TOKEN!, res.data.access_token)
        localStorage.setItem(localStorageKeys.customerLoggedIn, '0')
        dispatch(setEmployee(res.data.data))
        navigate('/Admin')

      } else {
        alert(res.data.message)
      }
    } catch (error) {
      console.log(error)
      alert('something went wrong')
    }
    setIsLoading(false)
  }

  return (
    <div className=' fixed inset-0 flex items-center justify-center bg-white '>
      {/* {isLoading === true && <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
        <div className=' animate-spin '><IconLoader /></div>
      </div>} */}
      <Card className="w-[350px]">
        {
          otp_mode === false ?
            <>
              <CardHeader>
                <CardTitle>Signup</CardTitle>
              </CardHeader>
              <CardContent>

                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="email">Email</Label>
                  <Input disabled={isLoading} id='email' type="email" placeholder='email' value={email} onChange={e => setEmail(e.target.value)} />
                </div>
              </CardContent>
              <CardFooter className=' justify-end'>
                {
                  isLoading === true
                    ?
                    <Button disabled>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                      Please wait
                    </Button>
                    :
                    <Button onClick={sent_otp} > Sent OTP</Button>
                }
                {/* <button onClick={sent_otp} >Sent OTP</button> */}
              </CardFooter>
            </>
            :
            <>
              <CardHeader>
                <CardTitle>OTP</CardTitle>
              </CardHeader>
              <CardContent>

                <div className="grid w-full max-w-sm items-center gap-1.5">
                  <Label htmlFor="otp">OTP</Label>
                  <Input disabled={isLoading} id='otp' type="text" placeholder='otp' value={otp} onChange={e => set_otp(e.target.value)} />
                </div>
              </CardContent>
              <CardFooter >
                <div className=' flex flex-col w-full gap-2'>
                  <div className="flex justify-end">
                    {
                      isLoading === true
                        ?
                        <Button disabled>
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          Please wait
                        </Button>
                        :
                        <Button onClick={verify_otp} >Verify OTP</Button>
                    }
                  </div>
                  <ResendOTPButton
                    onResend={resent_otp}
                    initialTime={30}

                  />
                </div>

              </CardFooter>
            </>
        }
      </Card>
    </div>
  )
}
