import React from 'react'
import { AlertDialog,
    AlertDialogAction,
    AlertDialogCancel,
    AlertDialogContent,
    AlertDialogDescription,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogTitle,
    AlertDialogTrigger, } from '../ui/alert-dialog'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'

export  function AlertModal() {

    const alertModal = useSelector((state:RootState)=>state.modal.alertModal)

    return (
        <AlertDialog  open={alertModal.show} >
            {/* <AlertDialogTrigger>Open</AlertDialogTrigger> */}
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>{alertModal.title}</AlertDialogTitle>
                    <AlertDialogDescription>
                        {alertModal.content}
                    </AlertDialogDescription>
                </AlertDialogHeader>
                <AlertDialogFooter>
                    {alertModal.footer}
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>

    )
}
