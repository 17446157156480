import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook"
import { useRESTapi } from "@/src/hooks/rest_API"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { ProductCategory } from "@/src/types"
import { IconReload } from "@tabler/icons-react"
import { useState } from "react"

type props = {
    show: boolean,
    setShow: (show: boolean) => any,
    setProcessing: ()=>any,
    isLoading:boolean

}


export function ReadyToShipModal(props: props) {


    return (
        <AdminModal show={props.show} setShow={props.setShow}>
            <AdminModalContent disableCloseButton={props.isLoading} className=" !max-w-[500px] !min-w-[500px]">
                <AdminModalHeader>
                    <AdminModalTitle>Change to Ready to ship?</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=" !max-w-[500px] !min-w-[500px]">
                        <Label>Change to Ready to ship?</Label>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button onClick={props.setProcessing} disabled={props.isLoading} >{props.isLoading&&<IconReload className="mr-2 h-4 w-4 animate-spin" />}Yes</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>)


}