import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { CustomSelectCustomOptionOption, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../../components/input_loading_searching';
import { Product, ProductCategory, ProductImage, Store, Warehouse, StoreKeys, employee, } from '../../../../../../../types';
import useFormValidatonCustom, { validationObject } from '../../../../../../../hooks/form_validation_hook';
import { useRESTapi } from '../../../../../../../hooks/rest_API';
import { RootState } from '../../../../../../../redux/store';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import { Label } from '@/src/components/ui/label';
import { Input } from '@/src/components/ui/input';
import { Switch } from '@/src/components/ui/switch';
import { Button } from '@/src/components/ui/button';




type StoreOverview = Store

type imageIds = string[]



export function AddWarehouseModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        warehouseId,
        setShow
    }:
        {
            show: boolean,
            setShow: (show: boolean) => any,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            warehouseId: string | number
        }
) {


    const validationObject = {
        [StoreKeys.I02_Store_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },


    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<StoreOverview>({
        I02_I01_Warehouse_Id: '',
        I02_Is_Active: 1,
        I02_M15_created_by: employee.employeeData.M15_Employee_id,
        I02_Store_name: '',
    })
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<Store>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)



    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: Store;

        switch (e.target.name) {


            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_store() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formData.I02_M15_created_by = employee.employeeData.M15_Employee_id
                formData.I02_I01_Warehouse_Id = warehouseId
                console.log(formData)
                Object.entries(formData).map(
                    data => {


                        formDataReal.append(data[0], String(data[1]))

                    }
                )

                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                const res = await api.post<{ success: boolean, data: Store, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores`,
                    body: formDataReal,
                    mode: 'admin'
                })

                if (res.data.success === true) {

                    updateUi();
                    alert(res.data?.message || 'added store')
                    setShow(false)
                    
                } else {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_store() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        formDataReal.append(data[0], String(data[1]))

                    }
                )



                const res = await api.post<{ success: boolean, data: Store, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
                    body: formDataReal,
                    mode: 'admin'
                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data?.message || 'updated store')
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }


    const fetch_selected_Employee: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<employee & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: employee, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/Employees/${props.value}`,
                mode: 'admin'
            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M15_Name,
                        value: res.data.data.M15_Employee_id,

                    } as (employee & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }


    const load_product = async () => {

        const res = await api.get<{ success: boolean, data: Store, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
            mode: 'admin'
        })
        if (res.data.success === true) {
            // images_url.images = res.data.data.images ? res.data.data.images : []

            // res.data.data.M11_ProductImages = []
            setFormData(res.data.data)
        } else {
            throw res.data
        }


    }




    useEffect(
        () => {

            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    I02_I01_Warehouse_Id: '',
                    I02_Is_Active: 1,
                    I02_M15_created_by: employee.employeeData.M15_Employee_id,
                    I02_Store_name: '',
                })
                setErrorData({})
                set_loading(false)

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_product()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()

            } else {
                set_loading(true)
            }


        }, [show]
    )



    return (
        <AdminModal show={show} setShow={setShow} >

            <AdminModalContent>
                <>
                    <AdminModalHeader>
                        <AdminModalTitle>{mode === 'add' ? 'Add Store' : 'Edit Store'}</AdminModalTitle>
                    </AdminModalHeader>
                    <AdminModalMiddle>
                        <Input disabled={loading || isLoading} value={formData.I02_Store_name} onChange={handleChangeInput} type="text" name={StoreKeys.I02_Store_name} placeholder='store name' />
                        <div className=' text-red-500'>{errorData.I02_Store_name ? errorData.I02_Store_name : null}</div>

                        <div className=' min-h-2'></div>
                        <div className=' flex gap-1'>
                            <Switch disabled={loading || isLoading} checked={formData.I02_Is_Active === 1 ? true : false} name={StoreKeys.I02_Is_Active} onCheckedChange={(checked) => handleChangeInput({
                                target: {
                                    name: StoreKeys.I02_Is_Active,
                                    value: checked ? 1 : '0',
                                    checked: checked
                                }
                            } as React.ChangeEvent<HTMLInputElement>)} />
                            <div>is active</div>
                        </div>


                    </AdminModalMiddle>

                    <AdminModalFooter>
                        <div className=' flex justify-end'>
                            {
                                mode === 'add' ?
                                    <Button disabled={isLoading || loading} onClick={add_store} >{isLoading ? 'adding...' : 'Add'}</Button>
                                    :
                                    <Button disabled={isLoading || loading} onClick={edit_store}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                        </div>
                    </AdminModalFooter>
                </>
            </AdminModalContent>

        </AdminModal>
    )
}
