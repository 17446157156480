import React, { useEffect, useState } from 'react'
import "./signUp.css"
import { Customer, CustomerAddress, CustomerAddressKeys, CustomerKeys, Emirates, pagination } from '../../../../types'
import useFormValidatonCustom, { validationObject } from '../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../hooks/rest_API'
import { verify } from 'crypto'
import { Link, useNavigate } from 'react-router-dom'
import { IconLoader } from '@tabler/icons-react'
import { RootState } from '../../../../redux/store'
import { setCustomer } from '../../../../redux/customer/customer_slice'
import { useDispatch } from 'react-redux'
import { Input } from '@/src/components/ui/input'
import { SelectShadcn } from '../../components/Select/selectShadcn'
import { Button } from '@/src/components/ui/button'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/src/components/ui/input-otp'
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp'

type CustomerOverview = Customer

export default function LoginPage() {

    const navigate = useNavigate()

    const [formData, setFormData] = useState<CustomerOverview>({
        email: '',
        M01_CustomerPhone: '',
        M01_CustomerUsername: '',
        // M02_City: '',
        // M02_IsDefault: 1,
        // M02_M03_CountryID: 20,
        // M02_PostalCode: '',
        // M02_Region: '',
        // M02_StreetNo: '',
        M01_M18_Role_id: '1',
        M01_CustomerPreferences: '{}',
        M01_Customeris_active: '0',
        M01_M20_emirateID: ''
        // M02_M20_emirateID: '',
        // M02_name: ''

    })
    const [isLoading, setIsLoading] = useState(false)
    const api = useRESTapi()
    const [otp_mode, set_otp_mode] = useState(false)
    const [otp, set_otp] = useState('')
    const [emirates, setEmirates] = useState<Emirates[]>([])
    // const customer = useSelector((state: RootState) => state.customer)
    const dispatch = useDispatch()


    const validationObject = {
        [CustomerKeys.M01_CustomerUsername]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerKeys.M01_CustomerPhone]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerKeys.email]: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required'
                    }
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    const isValid = emailRegex.test(value);
                    if (!isValid) {
                        return 'email is not valid'
                    }

                    return true
                }
            ]
        },
        // [CustomerAddressKeys.M02_M03_CountryID]: {
        //     message: '',
        //     validation: [
        //         (value) => {

        //             if (!value) {
        //                 return 'required'
        //             }
        //             return true
        //         }
        //     ]
        // },
        // [CustomerAddressKeys.M02_City]: {
        //     message: '',
        //     validation: [
        //         (value) => {

        //             if (!value) {
        //                 return 'required'
        //             }
        //             return true
        //         }
        //     ]
        // },
        // [CustomerAddressKeys.M02_PostalCode]: {
        //     message: '',
        //     validation: [
        //         (value) => {

        //             if (!value) {
        //                 return 'required'
        //             }
        //             return true
        //         }
        //     ]
        // },
        // [CustomerAddressKeys.M02_Region]: {
        //     message: '',
        //     validation: [
        //         (value) => {

        //             if (!value) {
        //                 return 'required'
        //             }
        //             return true
        //         }
        //     ]
        // },
        [CustomerKeys.M01_M20_emirateID]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
    } satisfies validationObject

    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: CustomerOverview;

        switch (e.target.name) {

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }
    function handleChangeOTPInput(e: React.ChangeEvent<HTMLInputElement>) {


        set_otp(e.target.value)



    }



    async function requestOTP(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        setIsLoading(true)
        if (performAllValidation(formData) === true) {

            try {
                const res = await api.post<{ success: boolean, error: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/register`,
                    body: formData,
                    mode: 'customer'
                })


                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.error) {

                        const errs = res.data.error;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        console.log(errData)
                        setErrorData(errData)

                    }

                } else {

                    set_otp_mode(true)

                }

            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function verifyOTP(e: React.FormEvent<HTMLFormElement>) {

        e.preventDefault();

        setIsLoading(true)
        if (formData.email) {

            try {
                const res = await api.post<{ success: boolean, errors: any, user: Customer, message: string, access_token: string, token_type: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/verify_signinotp`,
                    body: {
                        email: formData.email,
                        M01_OTP: otp
                    },
                    mode: 'customer'
                })


                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        // setErrorData(errData)

                    }

                } else {

                    localStorage.setItem(process.env.REACT_APP_TOKEN!, res.data.access_token)
                    localStorage.setItem('user_mode', 'customer')
                    dispatch(setCustomer(res.data.user))
                    navigate('/')

                }

            } catch (error) {
                console.log(error)
            }
        } else {
            alert('please type email first')
        }
        setIsLoading(false)

    }

    async function loadEmirates() {

        try {
            const res = await api.get<{ success: boolean, data: { data: Emirates[] } & pagination, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/emirates`,
                body: {
                    email: formData.email,
                    M01_OTP: otp
                },
                mode: 'customer'
            })


            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')


            } else {

                setEmirates(res.data.data.data)

            }

        } catch (error) {
            alert('something went wrong')
            console.log(error)
        }

    }



    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<CustomerOverview>({
        validationObject: validationObject
    })

    useEffect(
        () => {
            setIsLoading(true)
            Promise.all([
                loadEmirates()
            ]).then(
                res => {
                    setIsLoading(false)
                }
            )
        }, []
    )

    return (
        <div className='relative min-h-[100vh] font-roboto  flex justify-center overflow-auto my-auto py-10'>
            {/* <div className='gradiantCircle-01 bg-[#bbc497] h-[400px] w-[800px] rounded-[50%] absolute top-[-290px] blur-[64px]'></div> */}
            {/* LOADING SCREEN */}
            {isLoading === true && <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                <div className=' animate-spin '><IconLoader /></div>
            </div>}
            <div className='loginMainDiv   min-h-[70%]  w-[70%]  flex  z-10 overflow-hidden rounded-[62px]  shadow-md border-[2px] '>


                <div className='imageDiv w-[40%] h-full '>
                    <img src={'/assets/loginpage/S.png'} alt="" className='absolute right-[35%] top-[18%] w-[800px] z-40' />
                </div>
                <div className='formParentDiv  grow  w-[75%]  flex justify-center items-center z-50 p-4 md:p-10' >
                    <div className='formChildDiv   w-[60%] flex flex-col gap-4 justify-center'>
                        {
                            otp_mode === false
                                ?
                                <>
                                    <h1 className='smalHead text-[20px] font-bold'>Sign up.</h1>
                                    <h1 className='heading text-[33px] font-bold'>Create Account!</h1>
                                    <form action="" className='flex flex-col mt-6 gap-3' onSubmit={requestOTP} >

                                        <label htmlFor="">Name*</label>
                                        <Input onChange={handleChangeInput} value={formData.M01_CustomerUsername} name={CustomerKeys.M01_CustomerUsername} placeholder='Enter Name' type="text" />
                                        <div className=' text-red-500'>{errorData.M01_CustomerUsername ? errorData.M01_CustomerUsername : null}</div>

                                        <label htmlFor="">E mail*</label>
                                        <Input onChange={handleChangeInput} value={formData.email} name={CustomerKeys.email} placeholder='Enter email' type="text" />
                                        <div className=' text-red-500'>{errorData.email ? errorData.email : null}</div>

                                        <label htmlFor="">Phone*</label>
                                        <Input onChange={handleChangeInput} value={formData.M01_CustomerPhone} name={CustomerKeys.M01_CustomerPhone} placeholder='Enter PhoneNo' type="text" />
                                        <div className=' text-red-500'>{errorData.M01_CustomerPhone ? errorData.M01_CustomerPhone : null}</div>
                                        {/* <label htmlFor="">Country*</label>
                                        <select name={CustomerAddressKeys.M02_M03_CountryID} value={formData.M02_M03_CountryID!} onChange={handleChangeInput as any} >
                                            <option disabled value={''} selected  >select</option>
                                            <option value="1">india</option>
                                            <option value="1">UAE</option>
                                        </select> */}
                                        <label htmlFor="">Emirates</label>
                                        <SelectShadcn
                                            name={CustomerKeys.M01_M20_emirateID}
                                            value={String(formData.M01_M20_emirateID)}
                                            onChange={handleChangeInput as any}
                                            options={emirates.map(item => ({ value: String(item.M20_Emirate_id), name: item.M20_Emirate_name }))}
                                            placeHolder='Select Emirates'
                                        />
                                        <div className=' text-red-500'>{errorData.M01_M20_emirateID ? errorData.M01_M20_emirateID : null}</div>
                                        {/* <label htmlFor="">City*</label>
                                        <input onChange={handleChangeInput} value={formData.M02_City} name={CustomerAddressKeys.M02_City} placeholder='City' type="text" />
                                        <div className=' text-red-500'>{errorData.M02_City ? errorData.M02_City : null}</div>
                                        <label htmlFor="">Postal Code*</label>
                                        <input onChange={handleChangeInput} value={formData.M02_PostalCode} name={CustomerAddressKeys.M02_PostalCode} placeholder='City' type="text" />
                                        <div className=' text-red-500'>{errorData.M02_PostalCode ? errorData.M02_PostalCode : null}</div>
                                        <label htmlFor="">Region*</label>
                                        <input onChange={handleChangeInput} value={formData.M02_Region} name={CustomerAddressKeys.M02_Region} placeholder='Region' type="text" />
                                        <div className=' text-red-500'>{errorData.M02_Region ? errorData.M02_Region : null}</div> */}
                                        {/* <div className='self-end underline'><a href="">Forgot Password</a></div> */}
                                        <Button className='shadow-2xl rounded-md bg-[#cacbc1]'>Sign Up</Button>

                                    </form>
                                </>
                                :
                                <>
                                    <h1 className='smalHead text-[20px] font-bold'>Enter OTP</h1>
                                    <h1 className='heading text-[33px] font-bold'>Almost there!</h1>
                                    <div className=' grow h-full flex '>
                                        <form onSubmit={verifyOTP} className='my-auto flex grow flex-col gap-4 text-center items-center'>
                                            <label htmlFor="">OTP</label>
                                            {/* <input onChange={handleChangeOTPInput} value={otp} name={'M01_OTP'} placeholder='Enter OTP' type="text" /> */}
                                            <InputOTP value={otp} onChange={e => handleChangeOTPInput({
                                                target: {
                                                    name: 'M01_OTP',
                                                    value: e
                                                }
                                            } as React.ChangeEvent<HTMLInputElement>)} maxLength={6} pattern={REGEXP_ONLY_DIGITS_AND_CHARS}>
                                                <InputOTPGroup>
                                                    <InputOTPSlot index={0} />
                                                    <InputOTPSlot index={1} />
                                                    <InputOTPSlot index={2} />
                                                    <InputOTPSlot index={3} />
                                                    <InputOTPSlot index={4} />
                                                    <InputOTPSlot index={5} />
                                                </InputOTPGroup>
                                            </InputOTP>
                                            <Button className='shadow-2xl rounded-md bg-[#cacbc1]'>Verify OTP</Button>

                                        </form>
                                    </div>
                                </>

                        }
                        {/* <div className="flex items-center w-[80%] self-center">
                            <span className="border-b border-gray-400 flex-grow"></span>
                            <span className="px-4">Or</span>
                            <span className="border-b border-gray-400 flex-grow"></span>
                        </div> */}
                        <div className='gap-4 flex flex-col'>
                            {/* <div className='flex gap-3 items-center justify-center cursor-pointer'>
                                <img className='h-6' src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" alt="G" />
                                <p>Sign up with Google</p>
                            </div> */}
                            <div className='flex justify-center cursor-pointer'>
                                <p className='logORsign underline'>Allready have an account?<Link to={'/login'} className='font-bold '>Log in</Link></p>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>

    )
}
