import { setAdminNavbarState } from '@/src/redux/configuration/configuration'
import { RootState } from '@/src/redux/store'
import { IconList } from '@tabler/icons-react'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

export default function TopNavBar() {

    const employeeData = useSelector((state:RootState) => state.employee.employeeData)
    const state = useSelector((state:RootState) => state.configuration.adminConfig.sideBarState)

    const dispatch = useDispatch()

    const toggleNavbar = ()=>{
        if(state==='open'){
            dispatch(setAdminNavbarState('closed'))
        }else{
            dispatch(setAdminNavbarState('open'))
        }
    }


    return (
        <div className=' w-full min-h-14 bg-white py-2 px-1 sm:px-2 lg:px-4 flex s '>
            <div className=' flex'>
                <div className=' sm:hidden p-2 hover:bg-gray-500/10 cursor-pointer' onClick={toggleNavbar} ><IconList/></div>
                <div className=' text-3xl font-bold w-min text-nowrap whitespace-nowrap text-center font-bungee'>Chefs Souq</div>

            </div>
            {/* Employee avatar */}
            <div className=' ms-auto flex gap-1'>
                <div className='  h-10 w-10 bg-blue-600 aspect-1 rounded-full flex justify-center items-center font-bold text-2xl text-white'>{employeeData?.M15_Name?.[0]}</div>
                <div className=' hidden sm:flex flex-col max-w-24'>
                    <div className=' font-bold'>{employeeData?.M15_Name}</div>
                    <div className=' truncate'>{employeeData?.email}</div>
                </div>
            </div>
        </div>
    )
}
