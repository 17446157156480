import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { CartLocalstorateType, localStorageKeys } from '../../types/local_storage'
import { Currency } from '../../types/index'

type categeryTabConfigType = {
  show:boolean,
  position:'absolute'|'block',
  colorMode:'transparent'|'colorful',
}

type AdminNavbarState = 'closed'|'open'

type adminConfigType = {
  sideBarState:AdminNavbarState
}

const initialState: {
  categoryTab:categeryTabConfigType,
  currencyDetails:Currency,
  adminConfig:adminConfigType

} = {
  categoryTab:{
    position:'absolute',
    show:true,
    colorMode:'colorful'
  },
  currencyDetails:{
    M05_Conversions_rate:NaN,
    M05_CurrencyName:'',
    M05_CurrencySymbol:'',
    M05_IsActive:1,
    M05_M15_CreatedBy:''
  },
  adminConfig:{
    sideBarState:'closed'
  }
}

export const ConfigurationSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    updateCategoryTabConfig: (state, action: PayloadAction<Partial<categeryTabConfigType>>)=>{
      
      if(action.payload){
        state.categoryTab = {
          ...state.categoryTab,
          ...action.payload
        }
      }

    },
    
    setCurrencyDetails:(state, action: PayloadAction<Currency>)=>{
      
      state.currencyDetails = action.payload

    },
    setAdminNavbarState:(state, action: PayloadAction<AdminNavbarState>)=>{
      
      state.adminConfig.sideBarState = action.payload

    }

  },
})

// Action creators are generated for each case reducer function
export const {
  updateCategoryTabConfig,
  setCurrencyDetails,
  setAdminNavbarState
} = ConfigurationSlice.actions

export const ConfigurationSliceReducer = ConfigurationSlice.reducer