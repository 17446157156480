import React, { useState } from 'react'
import { Button } from '@/src/components/ui/button'
import { AddOptionModal } from './modal/option_modal'
import { DataTableDemo } from './table/table'
import { ProductCustomizationOptionClient } from '../../addmodal'
import { step } from '../customizationMaster'

type Props = {
    isStepActive: boolean,
    varientID: string | undefined
    customizationOptions: ProductCustomizationOptionClient[]
    setCustomizationOptions: (options: ProductCustomizationOptionClient[]) => any,
    addOption:(option:ProductCustomizationOptionClient)=>any,
    deleteOption: (option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation') => void,
    setStep:(step:step)=>any
    editOption:(option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation') => void,
}

export const Step2Component = (props: Props) => {

    const [IsOptionModalOpen, setIsOptionModalOpen] = useState(false)
    const [mode,setMode] = useState<'add'|'edit'>('add')
    const [editModalDetails,setEditModalDetails] = useState<ProductCustomizationOptionClient>()



    return (
        <div
            className={`
               
                flex flex-col gap-2
            `}
        >
            <div className='text-center'><AddOptionModal
                open={IsOptionModalOpen}
                disabled={!props.isStepActive}
                setOpen={setIsOptionModalOpen}
                onCreateOption={props.addOption}
                variantId={props.varientID?props.varientID:''}
                customizationOptions={props.customizationOptions}
                mode={mode}
                setModalMode={setMode}
                editOptionModalDetails={editModalDetails}
                setEditModalOptionDetails={setEditModalDetails}
                editOption={props.editOption}
            /></div>
            <DataTableDemo
                customizationOptions={props.customizationOptions}
                varientID={props.varientID}
                deleteOption={props.deleteOption}
                setStep={props.setStep}
                ModalMode={mode}
                setEditModalDetails={setEditModalDetails}
                setModalMode={setMode}
                setOptionModalOpen={setIsOptionModalOpen}
            />
        </div>
    )
}

