import React, { useEffect, useState } from 'react'
import { AddressCard } from './components/addressCard'
import { CustomerAddress } from '@/src/types'
import { useAddressAPI } from '@/src/hooks/address_api'
import { IconLoader } from '@tabler/icons-react'
import { ButtonIconRounded } from '@/src/components/buttons/buttonIcon'
import { PlusIcon } from '@heroicons/react/20/solid'
import { AddAddressModal } from './components/addressAddModal'
import { useRESTapi } from '@/src/hooks/rest_API'

export function AddressPage() {

  const [is_address_loading, set_is_address_loading] = useState(true)
  const [addresses, setAddresses] = useState<CustomerAddress[]>([])
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [updateUi, setUpdateUI] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const [mode, setMode] = useState<'add' | 'edit'>('add')
  const api = useRESTapi();
  const {
    loadAddressAll
  } = useAddressAPI()

  useEffect(
    () => {
      set_is_address_loading(true)
      loadAddressAll().then(
        addressData => {
          if (addressData.success === true) {
            setAddresses(addressData.data!.data)
          } else {
            setAddresses([])
            alert(addressData.message)
          }
          set_is_address_loading(false)
        }
      )
    }, [updateUi]
  )

  // open edit modal
  const showEditModal = (id: string) => {
    setMode('edit')
    setOpenModal(true)
    setSelectedId(id)
  }
  // open add modal
  const showAddModal = () => {
    setMode('add')
    setOpenModal(true)
  }

  const delete_address = async (id: string) => {
    set_is_address_loading(true)
    try {
      const res = await api.Delete<{ success: boolean, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address/${id}`,
        mode: 'customer'
      })

      if (res.data.success === false) {

        alert(res.data?.message || 'something went wrong')
        
      } else {
        alert(res.data.message || 'address deleted')
        setUpdateUI(!updateUi)
      }
    } catch (error) {
      alert('server error')
      console.log(error)
    }
    set_is_address_loading(false)
  }

  return (
    <div className=' w-full flex flex-col items-center md:px-10 px-2 sm:px-5 gap-2'>
      {is_address_loading === true && <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
        <div className=' animate-spin '><IconLoader /></div>
      </div>}

      {
        addresses.map(
          addresses => <AddressCard
            showEditModal={showEditModal}
            customerAddress={addresses}
            deleteAddress={delete_address}
          />
        )
      }
      <div className=' fixed bottom-4 right-4'>

        <AddAddressModal
          open={openModal}
          closeModal={() => setOpenModal(false)}
          mode={mode}
          setModal={(value) => setOpenModal(value)}
          updateUi={() => { setUpdateUI(!updateUi) }}
          id={selectedId}

        />
      </div>
      <ButtonIconRounded toolTipText='Add Address' className=' fixed bottom-4 right-4' onclick={showAddModal} icon={<PlusIcon />} />

    </div>
  )
}
