// import { Button } from "@/components/ui/button"
// import {
//   Dialog,
//   DialogContent,
//   DialogDescription,
//   DialogFooter,
//   DialogHeader,
//   DialogTitle,
//   DialogTrigger,
// } from "@/components/ui/dialog"
// import { Input } from "@/components/ui/input"
// import { Label } from "@/components/ui/label"

import { ButtonIconRounded } from "@/src/components/buttons/buttonIcon";
import { PhoneNumberInput } from "@/src/components/Inputs/mobile_input";
import { Button } from "@/src/components/ui/button";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/src/components/ui/dialog";
import { Input } from "@/src/components/ui/input";
import { Label } from "@/src/components/ui/label";
import { Switch } from "@/src/components/ui/switch";
import { useAddressAPI } from "@/src/hooks/address_api";
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook";
import { useRESTapi } from "@/src/hooks/rest_API";
import { CustomerModal, CustomerModalContent, CustomerModalFooter, CustomerModalHeader, CustomerModalMiddle, CustomerModalTitle } from "@/src/pages/customer/components/Modal/cosModal";
import { SelectShadcn } from "@/src/pages/customer/components/Select/selectShadcn";
import { CustomerAddress, CustomerAddressKeys, Emirates, ProductCategory, pagination } from "@/src/types";
import { Loader2, PlusIcon } from "lucide-react";
import { useEffect, useState } from "react";

type customerOverview = CustomerAddress

type props = {
    open: boolean,
    closeModal: () => void,
    setModal: (show: boolean) => void,
    id?: string,
    updateUi: () => void,
    mode: 'add' | 'edit'
}


export function AddAddressModal(
    {
        open,
        closeModal,
        setModal,
        id,
        updateUi,
        mode
    }: props
) {

    const validationObject = {
        [CustomerAddressKeys.M02_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_phone_no]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required';
                    }
        
                    // UAE phone number validation
                    const uaePhoneNumberRegex = /^(\+971|0)?(50|51|52|54|55|56|58)\d{7}$/;
                    if (!uaePhoneNumberRegex.test(value)) {
                        return 'Invalid UAE phone number';
                    }
        
                    return true;
                }
            ]
        },
        [CustomerAddressKeys.M02_City]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        // [CustomerAddressKeys.M02_IsDefault]: {
        //     message: '',
        //     validation: [
        //         (value) => {
        //             if (!value) {
        //                 return 'required'
        //             }
        //             return true
        //         }
        //     ]
        // },

        [CustomerAddressKeys.M02_M20_emirateID]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_PostalCode]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_Region]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerAddressKeys.M02_email]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<CustomerAddress>({
        validationObject: validationObject
    })
    const [formData, setFormData] = useState<customerOverview>({
        M02_City: '',
        M02_IsDefault: 1,
        M02_M03_CountryID: '20',
        M02_M20_emirateID: '',
        M02_PostalCode: '',
        M02_Region: '',
        M02_StreetNo: '',
        M02_M01_CustomerID: '',
        M02_Name: '',
        M02_phone_no: '',
        M02_email: '',
    })
    const [emirates, setEmirates] = useState<{
        name: string,
        value: string
    }[]>([])

    const [isLoading, setIsLoading] = useState(false)
    const api = useRESTapi()
    const {
        getAddressById
    } = useAddressAPI()


    async function add_user_address() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        console.log(data[0])
                        switch (data[0]) {

                            case CustomerAddressKeys.M02_email:
                            case CustomerAddressKeys.M02_City:
                            case CustomerAddressKeys.M02_phone_no:
                            case CustomerAddressKeys.M02_IsDefault:
                            case CustomerAddressKeys.M02_M03_CountryID:
                            case CustomerAddressKeys.M02_M20_emirateID:
                            case CustomerAddressKeys.M02_PostalCode:
                            case CustomerAddressKeys.M02_Region:
                            case CustomerAddressKeys.M02_StreetNo:
                            case CustomerAddressKeys.M02_name:
                                formDataReal.append(data[0], String(data[1]))
                                // case CustomerAddressKeys.M02_M01_CustomerID:
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: ProductCategory, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address`,
                    body: formDataReal,
                    mode: 'customer'
                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data.message || 'address added')
                    
                    closeModal()
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_user_address() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                formDataReal.append('_method', 'PUT')
                Object.entries(formData).map(
                    data => {

                        console.log(data[0])
                        switch (data[0]) {

                            case CustomerAddressKeys.M02_email:
                            case CustomerAddressKeys.M02_City:
                            case CustomerAddressKeys.M02_phone_no:
                            case CustomerAddressKeys.M02_IsDefault:
                            case CustomerAddressKeys.M02_M03_CountryID:
                            case CustomerAddressKeys.M02_M20_emirateID:
                            case CustomerAddressKeys.M02_PostalCode:
                            case CustomerAddressKeys.M02_Region:
                            case CustomerAddressKeys.M02_StreetNo:
                            case CustomerAddressKeys.M02_name:
                                formDataReal.append(data[0], String(data[1]))
                                // case CustomerAddressKeys.M02_M01_CustomerID:
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: ProductCategory, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/customer_address/${id}`,
                    body: formDataReal,
                    mode: 'customer'
                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data.message || 'address added')
                    closeModal()
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: CustomerAddress;
        console.log('name is ', e.target.name)
        switch (e.target.name) {

            case CustomerAddressKeys.M02_IsDefault:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.checked ? 1 : 0
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    const clearDatas = () => {
        setFormData({
            M02_City: '',
            M02_IsDefault: 1,
            M02_M03_CountryID: '20',
            M02_M20_emirateID: '',
            M02_PostalCode: '',
            M02_Region: '',
            M02_StreetNo: '',
            M02_M01_CustomerID: '',
            M02_Name: '',
            M02_phone_no: "",
            M02_email: "",

        })
        setErrorData({})
        setIsLoading(false)
    }

    const loadEmirates = async () => {

        try {

            const res = await api.get<{ success: boolean, data: ({ data: Emirates[] } & pagination), message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/emirates`,
                mode: 'customer',
            })

            if (res.data.success === true) {
                setEmirates(
                    res.data.data.data.map(
                        item => ({
                            name: item.M20_Emirate_name,
                            value: String(item.M20_Emirate_id)
                        })
                    )
                )
            } else if (res.data.success === false) {
                alert(res.data.message)
                throw new Error('error')
            }


        } catch (error) {
            console.log(error)
            throw new Error('error')
        }

    }


    useEffect(
        () => {
            if (open === false) {
                clearDatas()
            }

            setIsLoading(true)
            if (open === true) {
                loadEmirates().then(
                    res => {
                        if (mode === 'edit') {
                            if (id) {
                                getAddressById({
                                    id: id
                                }).then(
                                    res => {
                                        if (res.success === true) {
                                            setFormData(res.data!)
                                            setIsLoading(false)
                                        } else {
                                            alert(res.message)
                                        }

                                    }
                                )
                            }
                        } else {
                            setIsLoading(false)
                        }
                    }
                ).catch(
                    err => console.log(err)
                )

            }

        }, [open]
    )


    return (
        <CustomerModal show={open} setShow={setModal}>
            <CustomerModalContent>
                <CustomerModalHeader>
                    <CustomerModalTitle>Add Address</CustomerModalTitle>
                </CustomerModalHeader>
                <CustomerModalMiddle>
                    <div className="grid gap-4 py-4 ">
                        <div className="grid grid-cols-4 items-center gap-4 ">
                            <Label htmlFor="name" className="text-right">
                                Name
                            </Label>
                            <Input
                                id="name"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_name}
                                value={formData.M02_Name}
                                onChange={handleChangeInput}
                                disabled={isLoading}
                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_Name ? errorData.M02_Name : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                                Phone No
                            </Label>
                            <PhoneNumberInput
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_phone_no}
                                value={formData.M02_phone_no}
                                onChange={handleChangeInput}
                                disabled={isLoading}
                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_phone_no ? errorData.M02_phone_no : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                                E-mail
                            </Label>
                            <Input
                                id="E-mail"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_email}
                                value={formData.M02_email}
                                onChange={handleChangeInput}
                                disabled={isLoading}
                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_email ? errorData.M02_email : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="name" className="text-right">
                                Region
                            </Label>
                            <Input
                                id="Region"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_Region}
                                value={formData.M02_Region}
                                onChange={handleChangeInput}
                                disabled={isLoading}
                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_Region ? errorData.M02_Region : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right">
                                Postal Code
                            </Label>
                            <Input
                                type="number"
                                id="PostalCode"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_PostalCode}
                                value={formData.M02_PostalCode}
                                onChange={handleChangeInput}
                                disabled={isLoading}

                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_PostalCode ? errorData.M02_PostalCode : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right">
                                Emirates
                            </Label>
                            <SelectShadcn
                                // value={'2'}
                                disabled={isLoading}
                                options={emirates}
                                onChange={handleChangeInput}
                                value={String(formData.M02_M20_emirateID)}
                                placeHolder="Select Emirates"
                                name={CustomerAddressKeys.M02_M20_emirateID}

                            />

                        </div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right">
                                City
                            </Label>
                            <Input
                                type="text"
                                id="City"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_City}
                                value={formData.M02_City}
                                onChange={handleChangeInput}
                                disabled={isLoading}

                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_City ? errorData.M02_City : null}</div>
                        <div className="grid grid-cols-4 items-center gap-4">
                            <Label htmlFor="username" className="text-right">
                                Street No
                            </Label>
                            <Input
                                type="number"
                                id="StreetNo"
                                className="col-span-3"
                                name={CustomerAddressKeys.M02_StreetNo}
                                value={formData.M02_StreetNo}
                                onChange={handleChangeInput}
                                disabled={isLoading}

                            />
                        </div>
                        <div className=' text-red-500'>{errorData.M02_StreetNo ? errorData.M02_StreetNo : null}</div>
                        <div className="flex items-center space-x-2">
                            <Switch name={CustomerAddressKeys.M02_IsDefault} onCheckedChange={(isChecked) => {
                                handleChangeInput({
                                    target: {
                                        name: CustomerAddressKeys.M02_IsDefault,
                                        checked: isChecked,
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }}
                                checked={formData.M02_IsDefault === 1 ? true : false}
                                id="airplane-mode"
                                disabled={isLoading}
                            />
                            <Label htmlFor="airplane-mode">Default</Label>
                        </div>
                    </div>
                </CustomerModalMiddle>
                <CustomerModalFooter>
                    {
                        mode === 'add'
                            ?
                            <Button
                                onClick={add_user_address}
                                type="submit"
                                variant={'destructive'}
                                disabled={isLoading}
                            >
                                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                                Add
                            </Button>
                            :
                            <Button
                                onClick={edit_user_address}
                                type="submit"
                                variant={'destructive'}
                                disabled={isLoading}
                            >
                                {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
                                Edit
                            </Button>
                    }
                </CustomerModalFooter>
            </CustomerModalContent>

        </CustomerModal>
    )
}
