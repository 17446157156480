import { Button } from "@/src/components/ui/button"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { Loader2 } from "lucide-react"

export  function SubmitConfirmationModal({show,setShow,onSubmit,isSubmitting}:{show:boolean,setShow:(show:boolean)=>any,onSubmit:()=>any,isSubmitting:boolean}) {
    return (
      <AdminModal show={show} setShow={(show)=>{
          if(isSubmitting===false){
              setShow(show)
          }
      }} >
              <AdminModalContent disableCloseButton={isSubmitting} >
                 
                      <>
                          <AdminModalHeader>
                              <AdminModalTitle>Confirm?</AdminModalTitle>
                          </AdminModalHeader>
                          <AdminModalMiddle>
                          Do you want to update customization?
                          </AdminModalMiddle>
                          <AdminModalFooter>
                              <Button variant={'outline'} onClick={()=>setShow(false)} >No</Button>
                              <Button onClick={onSubmit} disabled={isSubmitting} >{isSubmitting&&<Loader2 className="mr-2 h-4 w-4 animate-spin" />}Yes</Button>
                          </AdminModalFooter>
                      </>
              </AdminModalContent>
          </AdminModal>
    )
  }