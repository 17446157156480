import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { CarouselCRUD } from './carousel/page'
import { RightCarouselCRUD } from './right_carousel/page'
type Props = {
  setSelectedId:(id:string)=>any
}

export  function PromotionRoot(props:Props) {
  return (
    <Routes>
      <Route index element={<Navigate to='carousel' />} />
      <Route path='carousel' element={<CarouselCRUD setSelectedId={props.setSelectedId}/>} />
      <Route path='rightcarousel' element={<RightCarouselCRUD setSelectedId={props.setSelectedId}/>} />
    </Routes>
  )
}
