import { useEffect, useState } from 'react'
import { AddProductModal } from './component/addmodal'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, Product, ProductCategory, ProductKeys, employee, pagination } from '../../../../../../types'
import { TablePagination } from '../../../../../../components/table'
import { Button } from '@/src/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Search, Trash } from 'lucide-react'
import { DeleteModal } from './component/deleteModal'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '../../../../components/skeltonTable'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { InputIcon } from '@/src/components/ui/input'
import { FilterComponent } from './component/filterComponent'
import { useDebounce } from '@/src/hooks/debounce_hook'
import { SortComponent } from './component/sortComponent'

interface productList extends Product {
  image: any,
  delete: any,
  edit: any,
  page_number: number,
  categoryName: string,
  brandName: string,
  category: ProductCategory,
  brand: Brand
}
type Props = {
  setSelectedId: (id: string) => any
}

export type filterObjectType = {
  productName?: string,
  categoryId?: string,
}
export enum filterObjectTypeKeys {
  productSkuName = 'productName',
  categoryId = 'categoryId',
  pageNo = 'pageNo',
}

export type sortDirections = 'asc'|'desc'
export type sortBy = 'name'|'created_date'|'updated_date'
export type sortObjectType = {
  sortBy:sortBy,
  sortDirection:sortDirections
}
export enum sortObjectTypeKeys {
  sortBy = 'sortBy',
  sortDirection = 'sortDirection'
}

export function AddProduct(props: Props) {

  const [products, set_products] = useState<productList[]>([])
  const [searchParams, setSearchParams] = useSearchParams();
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selected_product_id, set_selected_product_id] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(searchParams.get(filterObjectTypeKeys.pageNo) ? Number(searchParams.get(filterObjectTypeKeys.pageNo)) : 1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)
  const [isProductLoading, setisProductLoading] = useState(false)
  const alertModal = useAlertModal()
  const navigate = useNavigate();
  const [showFilterPopover, setShowFilterPopover] = useState(false)
  const [showSortPopover, setShowSortPopover] = useState(false)
  const [filterObject, setfilterObject] = useState<filterObjectType>({
    categoryId: searchParams.get(filterObjectTypeKeys.categoryId) ? searchParams.get(filterObjectTypeKeys.categoryId)! : '',
    productName: searchParams.get(filterObjectTypeKeys.productSkuName) ? searchParams.get(filterObjectTypeKeys.productSkuName)! : ''
  })
  const [sortObject, setSortObject] = useState<sortObjectType>({
    sortBy: searchParams.get(sortObjectTypeKeys.sortBy) as sortBy ? searchParams.get(sortObjectTypeKeys.sortBy)! as sortBy : 'created_date',
    sortDirection: searchParams.get(sortObjectTypeKeys.sortDirection) as sortDirections ? searchParams.get(sortObjectTypeKeys.sortDirection)! as sortDirections : 'asc'
  })
  const debouncedSearchTerm = useDebounce<string>(filterObject.productName ? filterObject.productName : '', 600);


  const header: {
    label: ProductKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'image',
        name: "Image"
      },
      {
        label: ProductKeys.M11_Name,
        name: "name"
      },
      {
        label: 'categoryName',
        name: "Category"
      },
      {
        label: 'brandName',
        name: "Brand"
      },

      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id?: number | string) {

    setIsDeleteLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: productList[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success) {
        alert(res.data.message)
        setshowDeleteModal(false)
      } else {
        alert(res.data.message || 'somthing went wrong...')
      }


    } catch (error) {
      alert('server error')
      console.log(error)
    }

    setIsDeleteLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product_id(id)
    setShowModal(true)
    set_mode('edit')
  }
  function clickDeleteButton(id: string) {
    set_selected_product_id(id)
    setshowDeleteModal(true)
  }
  function open_add_modal() {
    set_selected_product_id(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {
      props.setSelectedId('A3')
      const initData = async () => {

        setisProductLoading(true)
        try {
          const res = await api.get<{ success: boolean, message: string, data: { data: productList[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products`,
            params: {
              perPage: per_page,
              page: page_selected,
              searchTerm: searchParams.get(filterObjectTypeKeys.productSkuName),
              categoryId: searchParams.get(filterObjectTypeKeys.categoryId),
              sortBy:sortObject.sortBy,
              sortOrder:sortObject.sortDirection,
            },
            mode: 'admin',

          })

          if (res.data.success === true) {
            // console.log(res.data)
            set_products(res.data.data.data.map(
              (item, index) => {
                item.page_number = ((page_selected - 1) * per_page) + (index + 1)
                item.image = <img className=' h-10 aspect-1' src={`${process.env.REACT_APP_BACKEND_URL}/${item.images?.[0]?.P13Image_path || ''}`} alt="" />
                item.delete = <ButtonIcon icon={<Trash className="h-4 w-4" />} onClick={() => { set_selected_product_id(String(item.M11_ProductID!)); setshowDeleteModal(true) }}></ButtonIcon>
                item.edit = <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={() => clickEditButton(item.M11_ProductID!.toString())}></ButtonIcon>
                item.categoryName = item.category.M04_ProductCategoryName
                item.brandName = item.brand?.M14_Title
                return item;
              }
            ))
            set_products_total(res.data.data.total)
            setisProductLoading(false)
          } else {
            alertModal.open_alert_modal({
              content: res.data.message,
              footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
              title: 'Error'
            })
          }
        } catch (error) {
          console.log(error)
          alertModal.open_alert_modal({
            content: 'Server Error',
            footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
            title: 'Error'
          })
        }

      }

      initData()

    }, [update, debouncedSearchTerm]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5'>
      <div className=' p-2 text-2xl font-bold'>Product list</div>
      <AddProductModal
        id={selected_product_id}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selected_product_id);
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' w-full flex justify-between' >
        <InputIcon
          value={filterObject.productName}
          onChange={(e) => {
            // searchParams.set(filterObjectTypeKeys.productSkuName, e.target.value)
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.set(filterObjectTypeKeys.productSkuName, e.target.value);
            navigate(`?${newSearchParams.toString()}`, { replace: true });

            setfilterObject({
              ...filterObject,
              productName: e.target.value
            })
          }}
          classOuter=' md:min-w-[400px]'
          icon={<Search />}
          placeholder='Search'
        />
        <div className=' flex gap-2'>
          <SortComponent
            filterObj={sortObject}
            setFilterObj={setSortObject}
            updateUi={updateUi}
            show={showSortPopover}
            setshow={setShowSortPopover}
          />
          <FilterComponent
            filterObj={filterObject}
            setFilterObj={setfilterObject}
            updateUi={updateUi}
            show={showFilterPopover}
            setshow={setShowFilterPopover}
          />
          <Button onClick={open_add_modal} className=' '>Add</Button>
        </div>
      </div>
      <div className=' overflow-auto w-full  border rounded-md'>
        {
          isProductLoading ?
            <SkeletonTableAdmin />
            : <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => {
          set_page_selected(e);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(filterObjectTypeKeys.pageNo, e ? String(e) : '');
          navigate(`?${newSearchParams.toString()}`, { replace: true });
          updateUi()
        }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
