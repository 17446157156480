import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Orders } from './orders/page'
import { ServiceOrders } from './serviceOrders/page'


type Props = {
  setSelectedId:(id:string)=>any
}

export default function OrderRoot(props:Props) {
  return (
    <Routes>
      <Route index element={<Navigate to='orders' />} />
      <Route path='orders' element={<Orders setSelectedId={props.setSelectedId} />} />
      <Route path='serviceOrders' element={<ServiceOrders setSelectedId={props.setSelectedId} />} />
    </Routes>
  )
}
