import React from 'react'
import { useRESTapi } from './rest_API'
import { localStorageKeys } from 'src/types/local_storage'
import { useDispatch } from 'react-redux'
import { setCustomer, setCustomerLoggedIn } from 'src/redux/customer/customer_slice'
import { useCartApi } from './cart_api'
import { customerFeeds } from '../types/databaseFeeds'

export function useCustomerLogin() {
    const api = useRESTapi()
    const dispatch = useDispatch()
    const {clear_cart} = useCartApi()

    const logoutUser = async ()=>{

        try {
            const res = await api.get<{ success: boolean, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/logout`,
                mode: 'customer'
            })
            if(res.data.success===true){
                localStorage.removeItem(localStorageKeys.accesstocken)
                localStorage.setItem(localStorageKeys.customerLoggedIn,'0')
                dispatch(setCustomerLoggedIn('0'))
                dispatch(setCustomer({
                    email:'',
                    M01_CustomerPhone:'',
                    M01_CustomerUsername:'',
                    M01_M20_emirateID:'',
                    M01_CustomerID:customerFeeds.logoutCustomer.M01_CustomerID,    // logout mode
                }))
                clear_cart()
                return true
            }
        } catch (error) {
            console.log(error)
            alert('server error')
        }

        return false

    }

  return {
    logoutUser
  }
}
