import React, { useState, useRef } from 'react';
import { Play, Pause } from 'lucide-react';

interface VideoSectionProps {
  videoSrc: string;
}

const VideoSection: React.FC<VideoSectionProps> = ({ videoSrc }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <section className="bg-[#3a4656] relative">
      <div className=" max-w-[1400px] mx-auto ">
        <div className=" top-20  relative w-[90vw] sm:w-[80%] aspect-[1.777777] mx-auto rounded-lg overflow-hidden shadow-md shadow-gray-800">
          <video
            ref={videoRef}
            className="w-full h-full object-cover"
            src={videoSrc}
            playsInline
          />
          <button
            onClick={togglePlay}
            className="absolute inset-0 w-full h-full flex items-center justify-center  transition-opacity duration-300 hover:bg-opacity-30"
          >
            {isPlaying ? (
              <Pause className="w-16 h-16 text-white" />
            ) : (
              <Play className="w-16 h-16 text-white" />
            )}
          </button>
        </div>
      </div>
    </section>
  );
};

export  {VideoSection};