import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { Customer } from '../../types'
import { customerLoggedInLocalStorageType } from 'src/types/local_storage'

type customerSlice = {
  customerData:Customer,
  customerLoggedIn:customerLoggedInLocalStorageType
}

const initialState:customerSlice ={
  customerData: {
    email: '',
    M01_CustomerPhone: '',
    M01_CustomerUsername: '',
    M01_CustomerID: '',
    M01_Customeris_active: '',
    M01_CustomerPreferences: '',
    M01_M18_Role_id: '',
    M01_M20_emirateID:''
  },
  customerLoggedIn:'0'
}

export const customerSlice = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomer: (state, action: PayloadAction<Customer>) => {
      state.customerData = action.payload
    },
    setCustomerLoggedIn:(state,action:PayloadAction<customerLoggedInLocalStorageType>)=>{
      state.customerLoggedIn = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setCustomer,
  setCustomerLoggedIn
} = customerSlice.actions

export const customerSliceReducer = customerSlice.reducer