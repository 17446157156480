// import React, { useEffect, useState } from 'react'
// import { Button, Carousel } from "@material-tailwind/react";
// import CategoryTab from '../catagoryTab/categoryTab';
// import { useRESTapi } from 'src/hooks/rest_API';
// import { Carousel as CarouselType, pagination } from '../../../../types/index'

// type carouselType = {
//     image: string,
//     smallScreenImage:string,
//     imageColor:string,
//     smallImageColor:string
// }
// export default function CarauselShadecn({
//     onChangeColor
// }: {
//     onChangeColor: (color: string) => void
// }) {

//     const [imageIndex, setImageIndex] = useState<number>(0)
//     const [carosel, setCarousel] = useState<carouselType[]>([])
//     const [isLoading, setIsLoading] = useState<boolean>(false)

//     function reduceImageSize(image: HTMLImageElement, scale: number = 0.5): HTMLCanvasElement {
//         const canvas = document.createElement('canvas');
//         const context = canvas.getContext('2d');
//         if (!context) return canvas;

//         canvas.width = image.width * scale;
//         canvas.height = image.height * scale;
//         context.drawImage(image, 0, 0, canvas.width, canvas.height);

//         return canvas;
//     }

//     function quantizeColor(color: number, binSize: number = 10): number {
//         return Math.floor(color / binSize) * binSize;
//     }

//     const restApi = useRESTapi()





//     function extractMostUsedColor(image: HTMLImageElement, scale: number = 0.5, binSize: number = 25): string {
//         try {
//             const canvas = reduceImageSize(image, scale);
//             const context = canvas.getContext('2d');
//             if (!context) return '';

//             const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
//             const data = imageData.data;
//             const colorCount: Record<string, number> = {};

//             for (let i = 0; i < data.length; i += 4) {
//                 const color = `${quantizeColor(data[i], binSize)},${quantizeColor(data[i + 1], binSize)},${quantizeColor(data[i + 2], binSize)}`;
//                 colorCount[color] = (colorCount[color] || 0) + 1;
//             }

//             let maxCount = 0;
//             let mostFrequentColor: string | null = null;
//             for (const color in colorCount) {
//                 if (colorCount[color] > maxCount) {
//                     maxCount = colorCount[color];
//                     mostFrequentColor = color;
//                 }
//             }

//             return mostFrequentColor ? `rgb(${mostFrequentColor})` : '';
//         } catch (error) {
//             console.log(error);
//             return ''
//         }
//     }

//     const loadcarousel = async () => {
//         setIsLoading(true)
//         try {
//             const res = await api.get<{ success: boolean, data: { data: CarouselType[] } & pagination }>({
//                 url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/carousel`,
//                 mode: 'customer',
//             })
//             if (res.data.success === true) {
//                 setCarousel(
//                     res.data.data.data.map(
//                         res => ({
//                             image: `${process.env.REACT_APP_BACKEND_URL}/${res.C301_image_path as string}`,
//                             imageColor:res.C301_image_most_used_color,
//                             smallImageColor:res.C301_small_screen_image_most_color,
//                             smallScreenImage:`${process.env.REACT_APP_BACKEND_URL}/${res.C301_small_screen_image as string}`
//                         })
//                     )
//                 )
//                 setIsLoading(false)
//             }
//         } catch (error) {
//             console.log(error)
//         }
//     }

//     useEffect(
//         () => {
//             loadcarousel()
//         }, []
//     )

//     return (
//         <div>
//             {isLoading ? <div className="relative w-full aspect-[3.8] bg-gray-700 animate-pulse"></div>
//                 :
//                 <Carousel
//                     className=" w-full aspect-1 sm:aspect-[3.8] "
//                     loop
//                     autoplay

//                     transition={{ type: "tween", duration: 1, }}
//                     navigation={({ setActiveIndex, activeIndex, length }) => {

//                         onChangeColor(carosel[activeIndex]?.imageColor)

//                         return (
//                             <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
//                                 {/* <CategoryTab/> */}
//                                 {new Array(length).fill("").map((_, i) => (
//                                     <span
//                                         key={i}
//                                         className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${activeIndex === i ? "w-8 bg-white    " : "w-4 bg-white/50"}`}
//                                         onClick={() => setActiveIndex(i)} />
//                                 ))}
//                             </div>
//                         )
//                     }} placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} >
//                     {carosel.map((image, index) => (
//                         <>
//                         <img
//                             key={index} // Use index as key, but ideally should have unique keys
//                             src={image.image}
//                             alt={`carousel ${index + 1}`} // Alt text with dynamic index
//                             className="h-full w-full object-cover hidden sm:block "
//                         />
//                         <img
//                             key={index} // Use index as key, but ideally should have unique keys
//                             src={image.smallScreenImage}
//                             alt={`carousel ${index + 1}`} // Alt text with dynamic index
//                             className="h-full w-full object-cover sm:hidden block "
//                         />
//                         </>
//                     ))}
//                 </Carousel>
//             }
//         </div>
//     )
// }
















import React, { useEffect, useState } from 'react'
import { CardContent } from "@/src/components/ui/card";
import { useRESTapi } from 'src/hooks/rest_API';
import { Carousel as CarouselType, pagination } from '../../../../types/index'


import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
    type CarouselApi,
} from "@/src/components/ui/carousel";

type carouselType = {
    image: string,
    smallScreenImage: string,
    imageColor: string,
    smallImageColor: string
}

export default function CarauselShadecn({
    onChangeColor
}: {
    onChangeColor: (color: string) => void
}) {
    const [api, setApi] = React.useState<CarouselApi>();
    const [current, setCurrent] = React.useState(0);
    const [count, setCount] = React.useState(0);
    const [isVisible, setIsVisible] = React.useState(!document.hidden);

    React.useEffect(() => {
        if (!api) {
            return;
        }

        setCount(api.scrollSnapList().length);
        setCurrent(api.selectedScrollSnap() + 1);

        api.on("select", () => {
            setCurrent(api.selectedScrollSnap() + 1);
        });

        const interval = setInterval(() => {
            if (isVisible) {
                api.scrollNext();
            }
        }, 3000); // Change slide every 3 seconds
        if(carosel.length>0){
            onChangeColor(carosel[current-1]?.imageColor)
        }
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [api, current, isVisible]);

    React.useEffect(() => {
        const handleVisibilityChange = () => {
            console.log(new Date());
            console.log(!document.hidden);
            setIsVisible(!document.hidden);
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
        };
    }, []);

    const handleIndicatorClick = (index: number) => {
        if (api) {
            api.scrollTo(index);
        }
    };

    const [imageIndex, setImageIndex] = useState<number>(0)
    const [carosel, setCarousel] = useState<carouselType[]>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)




    function reduceImageSize(image: HTMLImageElement, scale: number = 0.5): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        if (!context) return canvas;

        canvas.width = image.width * scale;
        canvas.height = image.height * scale;
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        return canvas;
    }

    function quantizeColor(color: number, binSize: number = 10): number {
        return Math.floor(color / binSize) * binSize;
    }

    const restApi = useRESTapi()
    function extractMostUsedColor(image: HTMLImageElement, scale: number = 0.5, binSize: number = 25): string {
        try {
            const canvas = reduceImageSize(image, scale);
            const context = canvas.getContext('2d');
            if (!context) return '';

            const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
            const data = imageData.data;
            const colorCount: Record<string, number> = {};

            for (let i = 0; i < data.length; i += 4) {
                const color = `${quantizeColor(data[i], binSize)},${quantizeColor(data[i + 1], binSize)},${quantizeColor(data[i + 2], binSize)}`;
                colorCount[color] = (colorCount[color] || 0) + 1;
            }

            let maxCount = 0;
            let mostFrequentColor: string | null = null;
            for (const color in colorCount) {
                if (colorCount[color] > maxCount) {
                    maxCount = colorCount[color];
                    mostFrequentColor = color;
                }
            }

            return mostFrequentColor ? `rgb(${mostFrequentColor})` : '';
        } catch (error) {
            console.log(error);
            return ''
        }
    }

    const loadcarousel = async () => {
        setIsLoading(true)
        try {
            const res = await restApi.get<{ success: boolean, data: { data: CarouselType[] } & pagination }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/carousel`,
                mode: 'customer',
            })
            if (res.data.success === true) {
                setCarousel(
                    res.data.data.data.map(
                        res => ({
                            image: `${process.env.REACT_APP_BACKEND_URL}/${res.C301_image_path as string}`,
                            imageColor: res.C301_image_most_used_color,
                            smallImageColor: res.C301_small_screen_image_most_color,
                            smallScreenImage: `${process.env.REACT_APP_BACKEND_URL}/${res.C301_small_screen_image as string}`
                        })
                    )
                )
                if(res.data.data.data.length>0){
                    onChangeColor(res.data.data.data[0].C301_image_most_used_color)
                }
                setIsLoading(false)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(
        () => {
            loadcarousel()
        }, []
    )

    return (
        <div>
            <Carousel
                setApi={setApi}
                opts={{ loop: true }}
                className="bg-transparent aspect-1 sm:aspect-[3.8] rounded-lg overflow-hidden"
                // orientation="vertical"   
            >
                
                <CarouselContent>
                    {
                        carosel.map((image, index) => (
                            <CarouselItem key={index}>
                                <CardContent className="p-0 w-full">
                                    <>
                                        <img
                                            key={index} // Use index as key, but ideally should have unique keys
                                            src={image.image}
                                            alt={`carousel ${index + 1}`} // Alt text with dynamic index
                                            className="h-full w-full object-cover hidden sm:block "
                                        />
                                        <img
                                            key={index+1} // Use index as key, but ideally should have unique keys
                                            src={image.smallScreenImage}
                                            alt={`carousel ${index + 1}`} // Alt text with dynamic index
                                            className="h-full w-full object-cover sm:hidden block aspect-1"
                                        />
                                    </>
                                </CardContent>
                            </CarouselItem>
                        ))
                    }
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
                <div className="absolute bottom-1 left-1/2 -translate-x-1/2 py-2 text-center text-sm text-white flex space-x-1">
                    {Array.from({ length: count }).map((_, index) => (
                        <div
                            key={index}
                            onClick={() => handleIndicatorClick(index)}
                            className={`h-2 w-2 rounded-full cursor-pointer ${index === current - 1 ? "bg-white w-3" : "bg-gray-500"
                                }`}
                        />
                    ))}
                </div>
            </Carousel>
        </div>
    );
}















// import React from "react";
// import { TECarousel, TECarouselItem } from "tw-elements-react";

// export default function CarauselShadecn(): JSX.Element {
//   return (
//     <>
//       <TECarousel showControls showIndicators ride="carousel">
//         <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
//           <TECarouselItem
//             itemID={1}
//             className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
//           >
//             <img
//               src="https://mdbcdn.b-cdn.net/img/new/slides/041.webp"
//               className="block w-full"
//               alt="..."
//             />
//           </TECarouselItem>
//           <TECarouselItem
//             itemID={2}
//             className="relative float-left hidden -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
//           >
//             <img
//               src="https://mdbcdn.b-cdn.net/img/new/slides/042.webp"
//               className="block w-full"
//               alt="..."
//             />
//           </TECarouselItem>
//           <TECarouselItem
//             itemID={3}
//             className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
//           >
//             <img
//               src="https://mdbcdn.b-cdn.net/img/new/slides/043.webp"
//               className="block w-full"
//               alt="..."
//             />
//           </TECarouselItem>
//         </div>
//       </TECarousel>
//     </>
//   );
// }



