import { useCallback } from 'react';
import { parse, format } from 'date-fns';
import { enIN } from 'date-fns/locale';

type DateFormatOption = 'numeric' | 'short' | 'long';

interface DateFormatOptions {
  day?: 'numeric' | '2-digit';
  month?: DateFormatOption;
  year?: 'numeric' | '2-digit';
}

const useDateFormatDate = () => {
  const convertToIndianFormat = useCallback((
    dateTimeString: string,
    options: DateFormatOptions = { day: 'numeric', month: 'short', year: 'numeric' }
  ): string => {
    try {
      const parsedDate = parse(dateTimeString, 'yyyy-MM-dd HH:mm:ss', new Date());
      
      let formatString = '';
      
      if (options.day) {
        formatString += options.day === '2-digit' ? 'dd' : 'd';
      }
      
      formatString += '-';
      
      if (options.month) {
        switch (options.month) {
          case 'numeric':
            formatString += 'MM';
            break;
          case 'short':
            formatString += 'MMM';
            break;
          case 'long':
            formatString += 'MMMM';
            break;
        }
      }
      
      formatString += '-';
      
      if (options.year) {
        formatString += options.year === '2-digit' ? 'yy' : 'yyyy';
      }
      
      return format(parsedDate, formatString, { locale: enIN });
    } catch (error) {
      console.error('Error parsing date:', error);
      return 'Invalid Date';
    }
  }, []);

  return { convertToIndianFormat };
};

export { useDateFormatDate };