import React, { useState } from 'react'
import { CustomerModal, CustomerModalContent, CustomerModalFooter, CustomerModalHeader, CustomerModalMiddle, CustomerModalTitle } from '../../../components/Modal/cosModal';
import { Button } from '@/src/components/ui/button';
import { Label } from '@/src/components/ui/label';
import { Input } from '@/src/components/ui/input';
import { Textarea } from '@/src/components/ui/textarea';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { useRESTapi } from '@/src/hooks/rest_API';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { Loader2Icon, LoaderIcon } from 'lucide-react';
import { useSelector } from 'react-redux';
import { RootState } from '@/src/redux/store';

type props = {
  show: boolean;
  setShow: (show: boolean) => void;
  productItemId: string,
  updateUi:()=>any
}

type formDataType = {
  name: string,
  address: string,
  phoneNo: string,
  email: string,
  description: string,
  order_source: string,
  product: {
    productItemId: string
  }
}

export function ServiceDetailsModal(props: props) {

  const api = useRESTapi();
  const customerData = useSelector((state: RootState) => state.customer.customerData)
  const [loading, setIsLoading] = useState(false)
  const {
    close_alert_modal,
    open_alert_modal
  } = useAlertModal()

  const [formData, setFormData] = useState<formDataType>({
    address: '',
    description: '',
    email: '',
    name: '',
    order_source: 'e-commerce website',
    phoneNo: '',
    product: {
      productItemId: props.productItemId
    }
  })
  const validationObject = {
    address: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },
    description: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },
    email: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },
    name: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },
    order_source: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },
    phoneNo: {
      message: '',
      validation: [
        (value) => {

          if (!value) {
            return 'required'
          }
          return true
        }
      ]
    },

  } satisfies validationObject
  const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<formDataType>({
    validationObject: validationObject
  })

  function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    let formdata;
    formdata = {
      ...formData,
      [e.target.name]: e.target.value
    }

    performValidationOf(e.target.name, formdata)
    setFormData(formdata)
    console.log(formdata)

  }

  async function bookService() {

    if (performAllValidation(formData) === true) {

      setIsLoading(true)

      const res = await api.post<{ success: boolean, message: string, errors: any }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/services`,
        mode: 'customerPrivate',
        body: {
          address: formData.address,
          name: formData.name,
          email: formData.email,
          phoneNo: formData.phoneNo,
          description: formData.description,
          order_source: formData.order_source,
          product: {
            productItemId: formData.product.productItemId
          },
          userId:customerData.M01_CustomerID
        }
      })

      if (res.data.success === true) {
        console.log('Service Booked')
        props.updateUi()
        open_alert_modal({
          content: 'Service Booked Successfully',
          footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
          title: 'Service Booked'
        })
        props.setShow(false);
      } else {
        if (res.data.errors) {

          const errs = res.data.errors;
          const errData: any = {}

          for (const key in errs) {
            errData[key] = errs[key][0]
          }

          setErrorData(errData)

        }else{
          open_alert_modal({
            content: res.data.message,
            footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
            title: 'Error'
          })
        }
      }

      setIsLoading(false)

    }

  }

 

  return (
    <CustomerModal show={props.show} setShow={(show)=>{
      if(show===false){
        setIsLoading(false)
        setErrorData({})
      }
      props.setShow(show)
    }} >
      <CustomerModalContent>
        <CustomerModalHeader>
          <CustomerModalTitle>Details</CustomerModalTitle>
        </CustomerModalHeader>
        <CustomerModalMiddle>
          <div className=' w-full flex flex-col gap-3'>
            <div className=' flex flex-col gap-2'>
              <Label>Name</Label>
              <Input disabled={loading} onChange={handleChangeInput} name='name' placeholder='name' ></Input>
              <div className=' text-red-500'>{errorData.name}</div>
            </div>
            <div className=' flex flex-col gap-2'>
              <Label>Phone no</Label>
              <Input disabled={loading} onChange={handleChangeInput} name='phoneNo' placeholder='contact' ></Input>
              <div className=' text-red-500'>{errorData.phoneNo}</div>
            </div>
            <div className=' flex flex-col gap-2'>
              <Label>Email</Label>
              <Input disabled={loading} onChange={handleChangeInput} name='email' placeholder='email' ></Input>
              <div className=' text-red-500'>{errorData.email}</div>

            </div>
            <div className=' flex flex-col gap-2'>
              <Label>Description</Label>
              <Textarea disabled={loading} onChange={handleChangeInput as unknown as React.ChangeEventHandler<HTMLTextAreaElement>} name='description' placeholder='description' ></Textarea>
              <div className=' text-red-500'>{errorData.description}</div>
            </div>
            <div className=' flex flex-col gap-2'>
              <Label>Address</Label>
              <Textarea disabled={loading} name='address' onChange={handleChangeInput as unknown as React.ChangeEventHandler<HTMLTextAreaElement>} placeholder='address' />
              <div className=' text-red-500'>{errorData.address}</div>
            </div>
          </div>
        </CustomerModalMiddle>
        <CustomerModalFooter>
          <Button disabled={loading} onClick={bookService} >
            {loading?<Loader2Icon className="mr-2 h-4 w-4 animate-spin" />:null}
            Book Now
          </Button>
        </CustomerModalFooter>
      </CustomerModalContent>
    </CustomerModal>
  )
}
