import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import { useAlertModal } from "@/src/hooks/alert_modal"
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook"
import { useRESTapi } from "@/src/hooks/rest_API"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { SelectShadcn } from "@/src/pages/customer/components/Select/selectShadcn"
import { RootState } from "@/src/redux/store"
import { CancelReasonCustomer, pagination, ProductCategory } from "@/src/types"
import { IconReload } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

type props = {
    show: boolean,
    setShow: (show: boolean) => any,
    updateUi: () => any,
    orderID: string

}


export function CancelByAdminModal(props: props) {

    const employeeData = useSelector((state: RootState) => state.employee.employeeData)
    const [isLoading, setIsLoading] = useState(false)
    const api = useRESTapi();
    const [cancelReasons, setCancelReasons] = useState<CancelReasonCustomer[]>([]);
    const alertModal = useAlertModal()
    const [selectedOption, setSelectedOption] = useState<string>("");
    const handleSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setSelectedOption(value);
        // setTextareaVisible(value === '6');
    };

    const [errMsg, setErrMsg] = useState('')

    const loadCancelReason = async () => {
        try {
            const res = await api.get<{
                success: boolean, data: ({
                    data: CancelReasonCustomer[]
                }) & pagination
                , message: string
            }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cancel_reasons`,
                mode: 'customer',
            })

            if (res.data.success === true) {
                setCancelReasons(res.data.data.data)

            } else {
                alertModal.open_alert_modal({
                    content: res.data.message,
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
            }
        } catch (error) {
            alertModal.open_alert_modal({
                content: 'Server Error',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
        }

    }

    const cancelOrderByAdmin = async () => {
        if (selectedOption) {
            setIsLoading(true)
            const res = await api.post<{ success: boolean, data: string, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/cancel_order`,
                body: {
                    orderId: props.orderID,
                    employeeId: employeeData.M15_Employee_id,
                    reasonId: selectedOption
                },
                mode: 'admin',
            })

            if (res.data.success === true) {
                props.updateUi()
                props.setShow(false)
            } else {
                alertModal.open_alert_modal({
                    content: 'Error',
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: res.data.message
                })
            }
            setIsLoading(false)
        } else {
            setErrMsg('Please chooose reason')
        }



    }


    useEffect(
        () => {

            if (props.show) {
                loadCancelReason();
            }
            setIsLoading(false)
            setSelectedOption('');

        }, [props.show]
    )


    return (
        <AdminModal show={props.show} setShow={props.setShow}>
            <AdminModalContent disableCloseButton={isLoading} className=" !max-w-[500px] !min-w-[500px]">
                <AdminModalHeader>
                    <AdminModalTitle>Cancell?</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=" !max-w-[500px] !min-w-[500px]">
                    <Label>Cancell reason</Label>
                    <div className="h-2"></div>
                    <SelectShadcn
                        options={cancelReasons.map(item => (
                            {
                                name: item.I33_reason,
                                value: String(item.I33_reason_id)
                            }
                        ))}
                        value={selectedOption}
                        onChange={handleSelectChange}
                        classNameOuter="w-1/2 min-w-[250px]"
                        placeHolder="Reason"
                    />
                    {errMsg.length > 0 && (
                        <div className="text-red-500">{errMsg}</div>
                    )}
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button onClick={cancelOrderByAdmin} disabled={isLoading} >{isLoading && <IconReload className="mr-2 h-4 w-4 animate-spin" />}Yes</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>)


}