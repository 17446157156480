import React from 'react'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

export default function ServiceSection() {

    useGSAP(() => {
        
        gsap.fromTo(
           '.cardService',
            { y:20, opacity: 0 },
            {y:0, opacity: 1,duration:1,stagger:0.3, scrollTrigger: { trigger:'.mainContainer',start: 'top 80%',  end: 'bottom center',scrub:true } }
        );

    });

    
    return (
        <section className="bg-[#232F3E] text-white py-16">
            <div className=" mainContainer container mx-auto">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold">Comprehensive Kitchenware Services</h2>
                </div>
                <div className="grid md:grid-cols-3 gap-8">
                    <div className= "cardService bg-[#566475] p-8 rounded shadow">
                        <h3 className="text-xl font-semibold">Hotel Kitchenware</h3>
                        <p className="mt-4">Supplying high-quality kitchen products for hotels, ensuring a top-notch culinary experience.</p>
                    </div>
                    <div className= "cardService bg-[#566475] p-8 rounded shadow">
                        <h3 className="text-xl font-semibold">Home Kitchenware</h3>
                        <p className="mt-4">Upgrade your home kitchen with our exclusive range of products designed for everyday cooking.</p>
                    </div>
                    <div className= "cardService bg-[#566475] p-8 rounded shadow">
                        <h3 className="text-xl font-semibold">Bulk Orders & Services</h3>
                        <p className="mt-4">We accept bulk orders for events and businesses, with tailored services to meet your specific needs.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}
