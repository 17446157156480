import { IconX } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../redux/store'
import { BinItem, ProductItem, ProductItemImage, ProductItemKeys, Rack, StockItem, StockKeys, Store, Warehouse, employee, pagination } from '../../../../../../types'
import { CustomSelectCustomOptionOption, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../components/input_loading_searching'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { Switch } from '@/src/components/ui/switch'
import { StockResponse } from '../page'


type StockOverview = StockItem & {
    warehouseID: string,
    rackId: string
}


export function AddStockModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [StockKeys.I04_I02_Store_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [StockKeys.I04_I08_bin_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [StockKeys.I04_I26_Stock_Type_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [StockKeys.I04_M08_product_item_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [StockKeys.I04_Quantity]: {
            message: '',
            validation: [
                (value) => {
                    if (value == 0) {
                        return 'quantity must greater than 0'
                    }
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [StockKeys.I04_is_product_serial_available]: {
            message: '',
            validation: [
                (value) => {

                    if (value == 0) {
                        return true
                    }

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<StockOverview>({
        I04_I02_Store_id: '',
        I04_I08_bin_id: '',
        I04_I26_Stock_Type_id: '1',
        I04_Is_active: 1,
        I04_is_product_serial_available: 0,
        I04_M08_product_item_id: '',
        I04_M15_CreatedBy: '',
        I04_Quantity: 0,
        rackId: '',
        warehouseID: '',
        I04_Reserved_quantity: 0
    })
    // const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<StockOverview>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [images_url, set_image_url] = useState<Partial<ProductItem>>({
        M08_ProductImages: [],
        images: []
    })
    const [thumbnail_image, set_thumbnail_image] = useState<{
        imageID: string,
        imagePath: string,
        mode: 'local' | 'cloud'
    }>()

    const image_input_ref = useRef<HTMLInputElement>(null)
    const thumbnail_image_input_ref = useRef<HTMLInputElement>(null)
    const [warehouse_loaded, set_warehouse_loaded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)


    //load all product options
    const load_warehouse_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Warehouse & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: Warehouse[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.I01_Warehouse_Id!,
                            name: item.I01_Warehouse_name
                        } satisfies CustomSelectCustomOptionOption & Warehouse
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all store options
    const load_store_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Store & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        if (!formData.warehouseID) {
            alert('select warehouse first');
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
        const res = await api.get<{ success: boolean, data: { data: Store[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                warehouseID: formData.warehouseID
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.I02_Store_id!,
                            name: item.I02_Store_name
                        } satisfies CustomSelectCustomOptionOption & Store
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all store options
    const load_rack_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Rack & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        if (!formData.warehouseID || !formData.I04_I02_Store_id) {
            alert('select Store first');
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
        const res = await api.get<{ success: boolean, data: { data: Rack[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse_rack`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                warehouseID: formData.warehouseID,
                storeID: formData.I04_I02_Store_id
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.I03_Warehouse_RackId!,
                            name: item.I03_Warehouse_Rackname
                        } satisfies CustomSelectCustomOptionOption & Rack
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all store options
    const load_bin_option: (props: loadOptionProps) => Promise<loadOptionReturnProps<BinItem & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {

        if (!formData.warehouseID || !formData.I04_I02_Store_id || !formData.rackId) {
            alert('select rack first');
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: BinItem[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/bin`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                storeID: formData.I04_I02_Store_id,
                rackID: formData.rackId,
                warehouseID: formData.warehouseID,
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.I08_Bin_id!,
                            name: item.I08_Name
                        } satisfies CustomSelectCustomOptionOption & BinItem
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all product options
    const load_product_items: (props: loadOptionProps) => Promise<loadOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: ProductItem[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                isCustomizationAvailable:0,
                avoidServices:1
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M08_ProductItemID!,
                            name: item.M08_ProductItemName
                        } satisfies CustomSelectCustomOptionOption & ProductItem
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    const fetch_selected_product_item: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: ProductItem, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                // set_selected_product(res.data.data)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M08_ProductItemName,
                        value: res.data.data.M08_ProductItemID,

                    } as (ProductItem & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_warehouse: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Warehouse & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Warehouse, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.I01_Warehouse_name,
                        value: res.data.data.I01_Warehouse_Id,

                    } as (Warehouse & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_store: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Store & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Store, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.I02_Store_name,
                        value: res.data.data.I02_Store_id,

                    } as (Store & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_rack: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Rack & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Rack, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse_rack/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.I03_Warehouse_Rackname,
                        value: res.data.data.I03_Warehouse_RackId,

                    } as (Rack & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_bin: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<BinItem & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: BinItem, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/bin/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.I08_Name,
                        value: res.data.data.I08_Bin_id,

                    } as (BinItem & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }

    const load_stock_by_product = async (productItemId: string) => {


        try {
            const res = await api.get<{ success: boolean, data: { data: StockResponse[] } & pagination, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock`,
                params: {
                    ProductItemId: productItemId
                },
                mode: 'admin',

            })
            if (res.data.success === true) {

                return res.data.data.data
            } else {
                alert('error laoding product')
            }
        } catch (error) {
            console.log(error)
            alert('server error')
        }
        return false

    }


    async function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: StockOverview = formData;

        switch (e.target.name) {

            case StockKeys.I04_M08_product_item_id:
                if (e.target.value) {
                    set_warehouse_loaded(false)
                    setIsLoading(true)
                    const res = await load_stock_by_product(e.target.value)
                    if(res!==false)
                        set_warehouse_loaded(true)
                    if (res !== false && res.length !== 0) {
                        
                        const stockdetails = res[0]
                        formdata = {
                            I04_I02_Store_id: stockdetails.I04_I02_Store_id,
                            I04_I08_bin_id: stockdetails.I04_I08_bin_id,
                            I04_I26_Stock_Type_id: stockdetails.I04_I26_Stock_Type_id,
                            I04_Is_active: stockdetails.I04_Is_active,
                            I04_is_product_serial_available: stockdetails.I04_is_product_serial_available,
                            I04_M08_product_item_id: stockdetails.I04_M08_product_item_id,
                            I04_M15_CreatedBy: stockdetails.I04_M15_CreatedBy,
                            I04_Quantity: stockdetails.I04_Quantity,
                            rackId: stockdetails.store.warehouse?.racks?.[0].I03_Warehouse_RackId as string,
                            warehouseID: stockdetails.store.I02_I01_Warehouse_Id as string,
                            I04_Reserved_quantity: 0
                        }
                        setIsLoading(false)
                    }
                    setIsLoading(false)
                    formdata = {
                        ...formdata,
                        [e.target.name]: e.target.value
                    }
                    break;
                }
                if(mode==='add'){
                    formdata = {
                        I04_I02_Store_id: '',
                        I04_I08_bin_id: '',
                        I04_I26_Stock_Type_id: '1',
                        I04_Is_active: 1,
                        I04_is_product_serial_available: 0,
                        I04_M08_product_item_id: '',
                        I04_M15_CreatedBy: '',
                        I04_Quantity: 0,
                        rackId: '',
                        warehouseID: '',
                        I04_Reserved_quantity: 0
                    }
                }
                break;

            case 'rackId':
                if (e.target.value) {
                    formdata.I04_I08_bin_id = ''
                }
                formdata = {
                    ...formdata,
                    [e.target.name]: e.target.value
                }
                break;
            case StockKeys.I04_I02_Store_id:
                if (e.target.value) {
                    formdata.rackId = '';
                    formdata.I04_I08_bin_id = ''
                }
                formdata = {
                    ...formdata,
                    [e.target.name]: e.target.value
                }
                break;
            case 'warehouseID':
                if (e.target.value) {
                    formdata.rackId = '';
                    formdata.I04_I08_bin_id = ''
                    formdata.I04_Stock_id = ''
                }
                formdata = {
                    ...formdata,
                    [e.target.name]: e.target.value
                }
                break;
            default:
                formdata = {
                    ...formdata,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }







    async function add_actual_stock() {

        setIsLoading(true)
        formData.I04_M15_CreatedBy = String(employee.employeeData.M15_Employee_id)!

        if (performAllValidation(formData) === true) {


            try {

                const formDataReal = new FormData();

                // console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {

                            case StockKeys.I04_M08_product_item_id:
                            case StockKeys.I04_I02_Store_id:
                            case StockKeys.I04_Is_active:
                            case StockKeys.I04_I08_bin_id:
                            case StockKeys.I04_I26_Stock_Type_id:
                            case StockKeys.I04_M15_CreatedBy:
                            case StockKeys.I04_Quantity:
                            case StockKeys.I04_is_product_serial_available:
                            case StockKeys.I04_Reserved_quantity:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                            default:

                                break;
                        }

                    }
                )



                const res = await api.post<{ success: boolean, data: StockItem, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/create_stock`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    updateUi();
                    alert(res.data.message)
                    closeModal()

                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.I04_M15_CreatedBy = String(employee.employeeData.M15_Employee_id!)

        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                // console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {

                            case StockKeys.I04_M08_product_item_id:
                            case StockKeys.I04_I02_Store_id:
                            case StockKeys.I04_Is_active:
                            case StockKeys.I04_I08_bin_id:
                            case StockKeys.I04_I26_Stock_Type_id:
                            case StockKeys.I04_M15_CreatedBy:
                            case StockKeys.I04_Quantity:
                            case StockKeys.I04_is_product_serial_available:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                            default:

                                break;
                        }

                    }
                )





                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_customer_stock/${id}`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    updateUi();
                    alert(res.data?.message || 'updated product')
                    closeModal();
                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            alert('validation error')
        }
        setIsLoading(false)

    }


    const load_warehouse = async () => {

        const res = await api.get<{
            success: boolean, data: StockOverview
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            setFormData(res.data.data)
        } else {
            throw res.data
        }
    }



    const clear_form = () => {
        setFormData({
            I04_I02_Store_id: '',
            I04_I08_bin_id: '',
            I04_I26_Stock_Type_id: '1',
            I04_Is_active: 1,
            I04_is_product_serial_available: 0,
            I04_M08_product_item_id: '',
            I04_M15_CreatedBy: '',
            I04_Quantity: 0,
            rackId: '',
            warehouseID: '',
            I04_Reserved_quantity: 0
        })
        if(mode==='add'){
            set_warehouse_loaded(false);
        }else{
            set_warehouse_loaded(true)
        }
        setIsLoading(false)
        setErrorData({})

    }

    useEffect(
        () => {

            const initListSetup = () => {
                // set_loading(true)
                clear_form()
                set_loading(false)


            }
            const initEditSetup = () => {
                set_loading(true)
                clear_form()
                Promise.all(
                    [load_warehouse()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        console.log(err)
                        set_loading(false)

                    }
                )
                // set_loading(false)


            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                clear_form()
                set_loading(true)
                set_image_url({
                    M08_ProductImages: [],
                    images: []
                })
            }


        }, [show]
    )

    const onclick_add_image_input = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }
    const onclick_add_thumbnailImage_image_input = () => {
        thumbnail_image_input_ref.current?.click();
        thumbnail_image_input_ref.current!.value = '';
    }


    return (
        <AdminModal
            show={show}
            setShow={setShow}

        >
            <AdminModalContent  >
                {
                    < >
                        {/* <div onClick={closeModal} className=" absolute right-0 top-0 p-1 cursor-pointer"><IconX /></div> */}
                        <AdminModalHeader>
                            <AdminModalTitle>{mode === 'edit' ? 'Edit Stock' : 'Add Stock'}</AdminModalTitle>
                        </AdminModalHeader>
                        <AdminModalMiddle>

                            <SelectCustomLoadingSearching<ProductItem & CustomSelectCustomOptionOption>
                                loadOption={
                                    load_product_items
                                }
                                fetchSelectOption={fetch_selected_product_item}
                                disable={ isLoading === true ? true : false}
                                onselect={handleChangeInput}
                                value={formData.I04_M08_product_item_id}

                                customItem={{
                                    element: (props) => {
                                        return <div key={props.key} onClick={() => {
                                            props.select_item()
                                            props.close_menu()
                                        }} className='px-2 py-2  hover:bg-gray-400 cursor-pointer flex items-center gap-1'>
                                            <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                                            <div>{props.M08_ProductItemName}</div>
                                        </div>
                                    },
                                    valueName: ProductItemKeys.M08_ProductItemID,
                                    selectedItem: (props) => {
                                        return (<div className=' w-full p-2 border flex relative'>
                                            <div style={{ display: 'block' }} onClick={props.close} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                                            <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                                            <div>{props.M08_ProductItemName}</div>
                                        </div>)
                                    }
                                }}
                                title='Product'

                                // value={}

                                name={StockKeys.I04_M08_product_item_id}
                                placeholder='product'
                            />
                            <div className=' text-red-500'>{errorData.I04_M08_product_item_id ? errorData.I04_M08_product_item_id : null}</div>

                            <SelectCustomLoadingSearching<Warehouse & CustomSelectCustomOptionOption>
                                loadOption={
                                    load_warehouse_options
                                }
                                fetchSelectOption={fetch_selected_warehouse}
                                onselect={handleChangeInput}
                                value={formData.warehouseID}
                                title='Warehouse'
                                name={'warehouseID'}
                                placeholder='Warehouse'
                                disable={isLoading === true || warehouse_loaded === false}
                            />
                            <div className=' text-red-500'>{errorData.warehouseID ? errorData.warehouseID : null}</div>

                            <SelectCustomLoadingSearching<Store & CustomSelectCustomOptionOption>
                                loadOption={
                                    load_store_options
                                }
                                fetchSelectOption={fetch_selected_store}
                                onselect={handleChangeInput}
                                value={formData.I04_I02_Store_id}
                                title='Store'
                                name={StockKeys.I04_I02_Store_id}
                                placeholder='Store '
                                disable={isLoading === true || warehouse_loaded === false}

                            />
                            <div className=' text-red-500'>{errorData.I04_I02_Store_id ? errorData.I04_I02_Store_id : null}</div>

                            <SelectCustomLoadingSearching<Rack & CustomSelectCustomOptionOption>
                                loadOption={
                                    load_rack_options
                                }
                                fetchSelectOption={fetch_selected_rack}
                                onselect={handleChangeInput}
                                value={formData.rackId}
                                title='Rack'
                                name={'rackId'}
                                placeholder='Rack'
                                disable={isLoading === true || warehouse_loaded === false}
                            />
                            <div className=' text-red-500'>{errorData.rackId ? errorData.rackId : null}</div>

                            <SelectCustomLoadingSearching<BinItem & CustomSelectCustomOptionOption>
                                loadOption={
                                    load_bin_option
                                }
                                fetchSelectOption={fetch_selected_bin}
                                onselect={handleChangeInput}
                                value={formData.I04_I08_bin_id}
                                title='Bin'
                                name={StockKeys.I04_I08_bin_id}
                                placeholder='Bin'
                                disable={isLoading === true || warehouse_loaded === false}
                            />
                            <div className=' text-red-500'>{errorData.I04_I08_bin_id ? errorData.I04_I08_bin_id : null}</div>



                            <div>Quantity</div>
                            <Input disabled={isLoading === true || warehouse_loaded === false} value={formData.I04_Quantity} onChange={handleChangeInput} type="number" name={StockKeys.I04_Quantity} placeholder='quantity' />
                            <div className=' text-red-500'>{errorData.I04_Quantity ? errorData.I04_Quantity : null}</div>

                            <div className=' flex gap-1'>
                                {/* <Input checked={formData.I04_Is_active === 1 ? true : false} type="checkbox" name={StockKeys.I04_Is_active} onChange={handleChangeInput} /> */}
                                <Switch
                                    checked={formData.I04_Is_active == 1 ? true : false}
                                    name={StockKeys.I04_Is_active}
                                    onCheckedChange={
                                        (checked) => handleChangeInput({
                                            target: {
                                                name: StockKeys.I04_Is_active,
                                                value: checked ? 1 : '0',
                                                checked: checked
                                            }
                                        } as React.ChangeEvent<HTMLInputElement>)
                                    }
                                    disabled={isLoading === true || warehouse_loaded === false}

                                // onChange={handleChangeInput}
                                />
                                <div>is active</div>
                            </div>
                        </AdminModalMiddle>

                        <AdminModalFooter>
                            {
                                mode === 'add' ?
                                    <Button disabled={isLoading} onClick={add_actual_stock} >{isLoading ? 'adding...' : 'Add'}</Button>
                                    :
                                    <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                        </AdminModalFooter>
                    </>
                }
            </AdminModalContent>
        </AdminModal>
    )
}
