import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Label } from '@/src/components/ui/label'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Loader2 } from 'lucide-react'
import React from 'react'

export  function DeleteModal({show,setShow,onDelete,isDeleteing}:{show:boolean,setShow:(show:boolean)=>any,onDelete:()=>any,isDeleteing:boolean}) {
  return (
    <AdminModal show={show} setShow={(show)=>{
        if(isDeleteing===false){
            setShow(show)
        }
    }} >
            <AdminModalContent disableCloseButton={isDeleteing} >
               
                    <>
                        <AdminModalHeader>
                            <AdminModalTitle>Delete Bin?</AdminModalTitle>
                        </AdminModalHeader>
                        <AdminModalMiddle>
                           Are you sure to delete?
                        </AdminModalMiddle>
                        <AdminModalFooter>
                            <Button variant={'outline'} disabled={isDeleteing} onClick={()=>setShow(false)} >Cancel</Button>
                            <Button onClick={onDelete} disabled={isDeleteing} >{isDeleteing&&<Loader2 className="mr-2 h-4 w-4 animate-spin" />}Delete</Button>
                        </AdminModalFooter>
                    </>
            </AdminModalContent>
        </AdminModal>
  )
}
