import { useRESTapi } from '@/src/hooks/rest_API';
import { pagination, Shipper } from '@/src/types';
import React, { useEffect, useState } from 'react';

type shippingSystem = {
  name: string;
  src: string;
};

const ShippingSystem = () => {
  const [shippingMethods, setShippingMethods] = useState<shippingSystem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const api = useRESTapi();

  const load_shipping_System = async () => {
    setIsLoading(true);
    try {
      const res = await api.get<{ success: boolean; data: { data: Shipper[] } & pagination; message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/Shipper_user`,
        mode: 'customer',
      });

      if (res.data.success === true) {
        setShippingMethods(
          res.data.data.data.map((shipper) => ({
            name: shipper.I07_Shipper_name,
            src: `${process.env.REACT_APP_BACKEND_URL}/${shipper.I07_image_url as string}`,
          }))
        );
      }
    } catch (error) {
      console.error('Error loading shipping methods:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    load_shipping_System();
  }, []);

  const Skeleton = () => (
    <div className="animate-pulse">
      <div className="h-14 w-24 bg-gray-500 "></div>
    </div>
  );

  return (
    <div className="flex flex-col items-center p-4 z-10 relative">
      <h2 className="text-lg font-semibold mb-2">Shipping Systems</h2>
      <div className="flex space-x-4">
        {isLoading
          ? Array.from({ length: 3 }).map((_, index) => <Skeleton key={index} />)
          : shippingMethods.map((method) => (
              <img
                key={method.name}
                src={method.src}
                alt={method.name}
                className="h-14"
              />
            ))}
      </div>
    </div>
  );
};

export default ShippingSystem;
