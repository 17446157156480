
import * as React from "react"

import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/src/components/ui/table"
import { ButtonIcon } from "@/src/components/buttons/buttonIcon"
import { IconTrash } from "@tabler/icons-react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/src/components/ui/dialog"
import { Button } from "@/src/components/ui/button"
import { ProductCustomizationOptionClient, ProductCustomizationOptionEnumClient } from "../../../addmodal"
import { step } from "../../customizationMaster"
import { Pencil } from "lucide-react"



type Props = {
    customizationOptions: ProductCustomizationOptionClient[]
    varientID: string | undefined,
    deleteOption: (option: ProductCustomizationOptionClient, mode: 'forAllVariation' | 'forThisVariation') => any,
    setStep:(step:step)=>any,
    setModalMode:(mode:'add'|'edit')=>any,
    ModalMode:'add'|'edit',
    setEditModalDetails:(option:ProductCustomizationOptionClient)=>any,
    setOptionModalOpen:(show:boolean)=>any
}

enum CustomHeadter {
    'delete' = 'delete',
    'edit' = 'edit'
}

type ProductCustomizationOptionClientOverview = ProductCustomizationOptionClient & {
    delete: any,
    edit:any
}

export function DataTableDemo(props: Props) {


    const [deleteModal, setDeleteModal] = React.useState<boolean>(false)
    const [deleteVarient, setdeleteVarient] = React.useState<ProductCustomizationOptionClient>()


    function delete_option(mode: 'forAllVariation' | 'forThisVariation', option: ProductCustomizationOptionClient) {
        if (deleteVarient)
            switch (mode) {
                case 'forAllVariation':
                    
                    props.deleteOption(option,'forAllVariation')


                    break;

                case 'forThisVariation':
                    props.deleteOption(deleteVarient,'forThisVariation')
                    break;

                default:
                    break;
            }
        clearData()

        
    }

    function clearData (){
        setDeleteModal(false)
        setdeleteVarient(undefined)
    }

    const headers: {
        name: string,
        id: string,
        key: ProductCustomizationOptionEnumClient | CustomHeadter
    }[] = [
            { name: 'name', id: '1', key: ProductCustomizationOptionEnumClient.customization_name },
            { name: 'Is Slab', id: '2', key: ProductCustomizationOptionEnumClient.isSlab },
            { name: 'Min Val', id: '7', key: ProductCustomizationOptionEnumClient.minVal },
            { name: 'Max Val', id: '8', key: ProductCustomizationOptionEnumClient.maxVal },
            { name: 'Hike %', id: '5', key: ProductCustomizationOptionEnumClient.hikePerc },
            { name: 'HikeRate', id: '3', key: ProductCustomizationOptionEnumClient.rate },
            // { name: 'Hike % in Rate', id: '9', key: ProductCustomizationOptionEnumClient.hikePercInRate },
            { name: 'Base Rate', id: '10', key: ProductCustomizationOptionEnumClient.baseRate },
            { name: '', id: '11', key: CustomHeadter.edit },
            { name: '', id: '12', key: CustomHeadter.delete },

        ]

    const variationOptionList: ProductCustomizationOptionClientOverview[] = props.customizationOptions.filter(item => item.type === 'normal' && item.varientID === props.varientID).map(
        item => ({
            ...item,
            delete: <ButtonIcon onClick={() => {
                setDeleteModal(true)
                setdeleteVarient(item)
            }} icon={<IconTrash />} />,
            edit: <ButtonIcon onClick={() => {
                props.setModalMode('edit')
                props.setEditModalDetails(item)
                props.setOptionModalOpen(true)
            }} icon={<Pencil />} />,
        })
    )


    return (
        <div className="w-full">

            <div className="rounded-md border">
                <Table>
                    <TableHeader>
                        <TableRow >
                            {headers.map((headerGroup) => (
                                <TableHead key={headerGroup.id}>
                                    {headerGroup.name}
                                </TableHead>
                            ))
                            }

                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {variationOptionList.length ? (
                            variationOptionList.map((row, index) => (
                                <TableRow
                                    key={index}
                                >
                                    {headers.map((headerGroup, index) => (
                                        <TableCell key={index}>
                                            {row[headerGroup.key]}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell
                                    colSpan={headers.length}
                                    className="h-24 text-center"
                                >
                                    No results.
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </div>
            <Dialog open={deleteModal} onOpenChange={setDeleteModal}>
                <DialogContent>
                    <DialogHeader>
                        <DialogTitle>create option for</DialogTitle>
                    </DialogHeader>
                    <div className=' flex justify-end gap-2'>
                        <Button onClick={() => delete_option('forThisVariation', deleteVarient!)}>This Variant</Button>
                        <Button onClick={() => delete_option('forAllVariation', deleteVarient!)} >All Variant</Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}
