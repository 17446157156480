import React, { useEffect, useState } from 'react'
import { Product, ProductItem, ProductItemKeys, ProductKeys, Store, StoreKeys, Warehouse, pagination } from '../../../../../../types'
import { AddWarehouseModal } from './components/addmodal'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { TablePagination } from '../../../../../../components/table'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { Button } from '@/src/components/ui/button'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '@/src/pages/admin/components/skeltonTable'
import { DeleteModal } from '../component/deleteModal'



export function StoreDetails() {
  const { warehouseId } = useParams()
  const [store, set_store] = useState<(Store & { page_number: number, delete: any, edit: any })[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(1)
  const [products_total, set_store_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const navigate = useNavigate()
  const [isLoadingStore, setLoadingStore] = useState(false)
  const alertModal = useAlertModal();
  const [isDeleteoading, setIsDeleteLoading] = useState(false)
  const [showDeleteModal, setshowDeleteModal] = useState(false)

  const header: {
    label: StoreKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: StoreKeys.I02_Store_name,
        name: "name"
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id: number | string) {

    setIsDeleteLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: Store[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
        mode: 'customer'
      })
      updateUi();
      if (res.data.success) {
        alert(res.data.message)
        setIsDeleteLoading(false)
        setshowDeleteModal(false)
      } else {
        alert(res.data?.message || 'somthing went wrong...')
        setIsDeleteLoading(false)

      }


    } catch (error) {
      console.log(error)
      alert('something went wrong')
      setIsDeleteLoading(false)
    }

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {

      const initData = async () => {
        setLoadingStore(true)

        try {
          const res = await api.get<{ success: boolean, data: { data: (Store & { page_number: number, delete: any, edit: any })[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores`,
            mode: 'customer',
            params: {
              perPage: per_page,
              page: page_selected,
              warehouseID:warehouseId
            }
          })

          if (res.data.success === true) {
            // console.log(res.data)
            set_store(res.data.data.data.map(
              (item, index) => {
                item.page_number = ((page_selected - 1) * per_page) + (index + 1)
                item.edit = <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={(e) => { e?.stopPropagation(); clickEditButton(String(item.I02_Store_id)) }}></ButtonIcon>
                item.delete = <ButtonIcon icon={<Trash className="h-4 w-4" />} onClick={(e) => { e?.stopPropagation(); set_selected_product(String(item.I02_Store_id)); setshowDeleteModal(true) }}></ButtonIcon>
                return item;
              }
            ))
            set_store_total(res.data.data.total)
            setLoadingStore(false)

          } else {
            alertModal.open_alert_modal({
              content: 'Server error',
              footer: <div className=' flex'>
                <Button onClick={alertModal.close_alert_modal} variant={'outline'}>Cancel</Button>
                <Button onClick={alertModal.close_alert_modal}>Ok</Button>
              </div>,
              title: 'Error'
            })
          }
        } catch (error) {
          console.log(error)
          alertModal.open_alert_modal({
            content: 'Server error',
            footer: <div className=' flex'>
              <Button onClick={alertModal.close_alert_modal} variant={'outline'}>Cancel</Button>
              <Button onClick={alertModal.close_alert_modal}>Ok</Button>
            </div>,
            title: 'Error'
          })
        }

      }

      initData()

    }, [update]
  )

  return (
    <div className='  w-full h-full flex flex-col items-center  overflow-auto py-10 gap-2 '>
      <AddWarehouseModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        warehouseId={warehouseId!}
        setShow={setShowModal}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selectedEmployeeId!);
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' flex w-full font-bold'>
        <Link to={'../'}>warehouse/</Link>
        <div>store</div>
      </div>
      <div className=' p-2 text-2xl font-bold'>Store List</div>
      <div className=' w-full flex justify-end' >
        <Button onClick={open_add_modal} className=' '>Add Store</Button>
      </div>
      <div className=' overflow-auto  w-full border rounded-md'>
        {
          isLoadingStore ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  store.length === 0
                    ?
                    'No data'
                    :
                    store.map(
                      (row: any, index) => (
                        <TableRow key={index} className=' cursor-pointer' onClick={() => navigate(`${row.I02_Store_id}`)}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => { set_page_selected(e); updateUi() }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
