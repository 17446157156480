import React from 'react';

const SkeletonTableAdmin: React.FC = () => {
  return (
    <div className="w-full p-4">
      <div className="animate-pulse">
        <div className="flex space-x-4 mb-4">
          <div className="w-1/4 min-w-[130px] h-4 bg-gray-400 rounded"></div>
          <div className="w-1/4 min-w-[130px] h-4 bg-gray-400 rounded"></div>
          <div className="w-1/4 min-w-[130px] h-4 bg-gray-400 rounded"></div>
          <div className="w-1/4 min-w-[130px] h-4 bg-gray-400 rounded"></div>
        </div>
        {[...Array(5)].map((_, index) => (
          <div key={index} className="flex space-x-4 mb-4">
            <div className="w-1/4 min-w-[130px] h-6 bg-gray-300 rounded"></div>
            <div className="w-1/4 min-w-[130px] h-6 bg-gray-300 rounded"></div>
            <div className="w-1/4 min-w-[130px] h-6 bg-gray-300 rounded"></div>
            <div className="w-1/4 min-w-[130px] h-6 bg-gray-300 rounded"></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export  {SkeletonTableAdmin};
