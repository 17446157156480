import { Customer, orderStatusFlags } from ".";

export enum ProductCategoryIds {
    'services' = '123'
}



export enum paymentTypeId {
    CashOnDelivery = 1,
    ApplePay = 2,
    GooglePay = 3,
    Card = 4
}

export const paymentTypesSeeds:({id:paymentTypeId,method:string})[] = [
    {id:1,method:'cash on delivery'}
]

export enum CarouselTypeId {
    Normal = 1,
    Promotion = 2,
    Image = 3,
    Video = 4
}


export type OrderStatusObject = {
    [key in orderStatusFlags]: any;
};

export const  orderStatusesIds:OrderStatusObject = {
    processing:'2',
    bookedService:'9',
    completedService:'11',
    confirmedService:'10',
    delivered:'7',
    ordered:'1',
    readyToShip:"8",
    shipped:'6',
    cancelled:'3',
    CancelledPending:'12'
}

type customerTypes = 'logoutCustomer'

export const customerFeeds:{[key in customerTypes]: Customer;} = {
    logoutCustomer:{
        email:'',
        M01_CustomerPhone:'',
        M01_CustomerUsername:'',
        M01_M20_emirateID:'',
        M01_CustomerID:'60000'
    }
}