import { orderStatusFlags } from '@/src/types';
import React from 'react';


interface ColorChangeProps {
  Data: orderStatusFlags;
}

export function ColorChange({ Data }: ColorChangeProps) {
  // Function to get the appropriate color class
  const getColorClass = (status: orderStatusFlags): string => {
    switch (status) {
      case 'ordered':
        return 'text-red-500'; 
      case 'processing':
        return 'text-orange-500';
      case 'readyToShip':
        return 'text-yellow-800'; 
      case 'shipped':
        return 'text-green-500';
      case 'delivered':
        return 'text-green-700'; 
      default:
        return 'text-gray-500'; 
    }
  };

  return (
    <>
      <p className={`uppercase ${getColorClass(Data)}`}>{Data}</p>
    </>
  );
}
