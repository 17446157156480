import { Button } from '@/src/components/ui/button';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { useRESTapi } from '@/src/hooks/rest_API';
import { Brand, pagination } from '@/src/types';
import React, { useEffect, useState } from 'react';
import Marquee from "react-fast-marquee";


export default function TopBrands() {

  const api = useRESTapi()
  const [brands, setBrands] = useState<Brand[]>([])
  const [isLoadingBrands, setIsLoadingBrands] = useState(false)
  const alertModal = useAlertModal()

  const loadBrand = async () => {

    setIsLoadingBrands(true)

    try {

      const res = await api.get<{ success: boolean, message: string, data: Brand[] }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/brands_costomers`,
        params: {

        },
        mode: 'customer',
      })

      if (res.data.success) {
        setBrands(res.data.data)
        setIsLoadingBrands(false)
      } else {
        alertModal.open_alert_modal({
          content: res.data.message || 'Error loading brand',
          footer: <div>
            <Button onClick={alertModal.close_alert_modal}>Ok</Button>
          </div>,
          title: 'Error'
        })
      }

    } catch (error) {
      console.log(error)
      alertModal.open_alert_modal({
        content: 'Server error',
        footer: <div>
          <Button onClick={alertModal.close_alert_modal}>Ok</Button>
        </div>,
        title: 'Error'
      })
    }

  }

  useEffect(
    () => {
      loadBrand()
    }, []
  )

  return (
    <Marquee pauseOnHover>
      <div className='relative'>
        <div className="brands flex mx-auto p-9 overflow-x-scroll gap-7" style={{ scrollbarWidth: 'none' }}>
          {
            isLoadingBrands ?
              Array(5).fill(1).map(
                item => (
                  <div className=' min-h-11 min-w-20 animate-pulse '>

                  </div>
                )
              ) :
              brands.map((brand, index) => (
                <div key={index} className='bg-blue-gray-500  aspect-[1.68] min-h-[59px]'>
                  <img className='transition-transform duration-500 hover:scale-125 hover:-translate-x-4 hover:-translate-y-3 cursor-pointer  max-h-[60px]' src={`${process.env.REACT_APP_BACKEND_URL}/${brand.M14_Brand_image}`} alt="" width={100} />
                </div>
              ))
          }
        </div>
      </div>
    </Marquee>
  );
}
