import { IconArrowNarrowLeft, IconArrowNarrowRight } from '@tabler/icons-react';
import React, { useEffect, useRef, useState } from 'react'
import { useRESTapi } from '../../../../hooks/rest_API';
import { ProductItem, ProductPrice } from '../../../../types';
import { useDispatch, useSelector } from 'react-redux';
import { addToCart, removeFromCart } from '../../../../redux/cart/cart_slice';
import { RootState } from '../../../../redux/store';
import { useCartApi } from 'src/hooks/cart_api';
import { ProductCard, ProductCardSkeleton, ServiceCard } from '../product/productCard';
import { useNavigate } from 'react-router-dom';
import { ProductCategoryIds } from '@/src/types/databaseFeeds';

type ProductItemOverview = ProductItem & { primary_image: string, price: ProductPrice,customer_stock_quantity:number,categoryID:string|number }

export default function CarouselProducts() {

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [top_selling_products, set_top_selling_products] = useState<ProductItemOverview[]>([]);
    const [is_loading_products, set_is_loading_products] = useState<boolean>(false)
    const api = useRESTapi()
    const navigate = useNavigate()


    const loadTopProducts = async () => {
        set_is_loading_products(true)
        try {
            const res = await api.get<{ success: boolean, data: ProductItemOverview[], message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/topsellingproduct_items`,
                mode: 'customer'
            })

            if (res.data.success === true) {
                console.log(res.data.data)
                set_top_selling_products(res.data.data)
            }
            set_is_loading_products(false)
        } catch (error) {
            alert('something went wrong')
        }

    }


    useEffect(
        () => {
            loadTopProducts()
        }, []
    )



    const scrollToRight = () => {
        if (scrollContainerRef.current) {
            const scrollContainer = scrollContainerRef.current;
            const currentScrollPosition = scrollContainer.scrollLeft;
            const targetScrollPosition = currentScrollPosition + 100; // Adjust as needed

            scrollContainer.scrollTo({
                left: targetScrollPosition,
                behavior: 'smooth' // This enables smooth scrolling
            });
        }
    };
    const scrollToLeft = () => {
        if (scrollContainerRef.current) {
            const scrollContainer = scrollContainerRef.current;
            const currentScrollPosition = scrollContainer.scrollLeft;
            const targetScrollPosition = currentScrollPosition - 100; // Adjust as needed

            scrollContainer.scrollTo({
                left: targetScrollPosition,
                behavior: 'smooth' // This enables smooth scrolling
            });
        }
    };


    return (
        <div className='relative'>
            <div onClick={scrollToRight} className=' p-3 rounded-full absolute right-2 top-1/2 -translate-y-1/2 z-50 hover:bg-gray-300 cursor-pointer bg-white border '><IconArrowNarrowRight /></div>
            <div onClick={scrollToLeft} className=' p-3 rounded-full absolute left-2 top-1/2 -translate-y-1/2 z-50 hover:bg-gray-300 cursor-pointer bg-white border '><IconArrowNarrowLeft /></div>

            <div ref={scrollContainerRef} style={{ scrollbarWidth: 'none' }} className="mainProducts flex gap-4 p-6  justify-items-center  overflow-x-scroll " >
                {
                    is_loading_products ?
                        Array(8).fill(5).map(
                            any => <ProductCardSkeleton />
                        )
                        :
                        top_selling_products.map((product, index) => {
                            if(product.categoryID==ProductCategoryIds.services){
                                return (
                                    <ServiceCard
                                        key={product.M08_ProductItemID}
                                        MRPPrice={Number(product?.price?.M10_MRP_price)}
                                        listPrice={Number(product?.price?.M10_List_price)}
                                        description={product.M08_Description}
                                        imagePath={`${process.env.REACT_APP_BACKEND_URL}/${product.M08_ProductImage}`}
                                        productId={String(product.M08_ProductItemID)}
                                        title={product.M08_ProductItemName}
                                        onclick={
                                            () => window.open(`/servicePage/${product.M08_ProductItemID}`,'_blank')
                                        }
                                        isCustomizationAvailable={product.M08_is_customization_available}
                                        quantity={product.customer_stock_quantity}
                                    />
                                )
                            }else{
                                return (
                                    <ProductCard
                                        key={product.M08_ProductItemID}
                                        MRPPrice={Number(product.price?.M10_MRP_price)}
                                        listPrice={Number(product.price?.M10_List_price)}
                                        description={product.M08_Description}
                                        imagePath={`${process.env.REACT_APP_BACKEND_URL}/${product.M08_ProductImage}`}
                                        productId={String(product.M08_ProductItemID)}
                                        title={product.M08_ProductItemName}
                                        onclick={
                                            () => window.open(`/product/${product.M08_ProductItemID}`,'_blank')
                                        }
                                        isCustomizationAvailable={product.M08_is_customization_available}
                                        quantity={product.customer_stock_quantity}
                                    />
                                )
                            }
                        })}

            </div>


        </div>

    )
}
