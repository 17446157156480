import { IconTrash, IconX } from '@tabler/icons-react';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook';
import { useRESTapi } from '../../../../../../hooks/rest_API';
import { RootState } from '../../../../../../redux/store';
import { ProductItem, ProductKeys, PurchaseOrder, PurchaseOrderKeys, PurchaseOrderLine, PurchaseOrderLineKeys, Store, StoreKeys, Supplier, employee, pagination } from '../../../../../../types';
import { CustomSelectCustomOptionOption, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../components/input_loading_searching';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import { Button } from '@/src/components/ui/button';
import { Table, TableHead, TableHeader } from '@/src/components/ui/table';




type StoreOverview = PurchaseOrder & PurchaseOrderLine


type Productline = {
    I22_M08_Product_item_id?: string | number,
    I22_Order_quantity: number | null,
    I22_Total_cost: number | null,
    I22_Price: number | null,
    productItemName: string,
    productItemImage: string,
    mode: 'completed' | 'create' | 'edit',
    source: 'local' | 'cloude',
    error: string | null
}



export function AddPurchase(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        warehouseId,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            warehouseId: string | number,
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [PurchaseOrderKeys.I12_M12_supplier_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },


    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    //product selected full details
    const [selected_product_item, set_selected_product_item] = useState<ProductItem>()
    const [formData, setFormData] = useState<StoreOverview>({
        I12_I030_order_status_id: '',
        I12_is_active: 1,
        I12_M01_created_by: employee.employeeData.M15_Employee_id!,
        I12_M12_supplier_id: '',
        I12_order_date: '',
        I12_total_amount: 0,
        I22_I030_order_status_id: undefined,
        I22_I12_Purchase_order_id: undefined,
        I22_Is_active: 1,
        I22_M08_Product_item_id: undefined,
        I22_Order_quantity: 0,
        I22_Price: null,
        I22_Received_quantity: null,
        I22_Total_cost: null
    })
    const [product_line, set_product_line] = useState<Productline[]>([])
    const [isLoading, setIsLoading] = useState(false)
    const [is_customization_add_pressed, set_is_customization_add_pressed] = useState<boolean>(false)

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<PurchaseOrder & PurchaseOrderLine>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)



    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: PurchaseOrder & PurchaseOrderLine;

        switch (e.target.name) {


            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_purchase() {

        setIsLoading(true)
        // formData.I12_M01_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formData.I12_M01_created_by = employee.employeeData.M15_Employee_id!
                formDataReal.append('I12_M12_supplier_id', String(formData.I12_M12_supplier_id))
                formDataReal.append('I12_M01_created_by', String(formData.I12_M01_created_by))

                let totalAmount = 0
                product_line.forEach(
                    (obj, index) => {
                        if (obj.I22_Total_cost && obj.I22_Order_quantity && obj.I22_Price) {
                            formDataReal.append(`purchase_line[${index}][I22_M08_Product_item_id]`, String(obj.I22_M08_Product_item_id))
                            formDataReal.append(`purchase_line[${index}][I22_Order_quantity]`, String(obj.I22_Order_quantity))
                            formDataReal.append(`purchase_line[${index}][I22_Total_cost]`, String(obj.I22_Total_cost))
                            formDataReal.append(`purchase_line[${index}][I22_Price]`, String(obj.I22_Price))
                            totalAmount += obj.I22_Total_cost
                        }
                        else {
                            alert('complete the product item')
                            throw new Error('no total object')
                        }
                    }
                )

                formDataReal.append('I12_total_amount', String(totalAmount))
                console.log(formData)
                // Object.entries(formData).map(
                //     data => {

                //         formDataReal.append(data[0], String(data[1]))

                //     }
                // )

                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                const res = await api.post<{ success: boolean, data: Store, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/purchase_order`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data?.message || 'added store')
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_store() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        formDataReal.append(data[0], String(data[1]))

                    }
                )



                const res = await api.post<{ success: boolean, data: Store, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data?.message || 'updated store')
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }



    const load_product_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: ProductItem[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M08_ProductItemID!,
                            name: item.M08_ProductItemName
                        } satisfies CustomSelectCustomOptionOption & ProductItem
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    const fetch_selected_product: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: ProductItem, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/products/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                set_selected_product_item(res.data.data)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M08_ProductItemName,
                        value: res.data.data.M08_ProductItemID!,

                    } as (ProductItem & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }

    const load_product = async () => {

        const res = await api.get<{ success: boolean, data: PurchaseOrder & PurchaseOrderLine, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/stores/${id}`,
            mode: 'admin',

        })
        if (res.data.success === true) {
            // images_url.images = res.data.data.images ? res.data.data.images : []

            // res.data.data.M11_ProductImages = []
            setFormData(res.data.data)
        } else {
            throw res.data
        }


    }


    //load all suppliers
    const load_supplier_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<Supplier & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: Supplier[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/suppliers`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M12_Supplier_id,
                            name: item.M12_Name
                        } satisfies CustomSelectCustomOptionOption & Supplier
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }


    const fetch_selected_supplier: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<Supplier & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: Supplier, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/suppliers/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M12_Name,
                        value: res.data.data.M12_Supplier_id,

                    } as (Supplier & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }

    // on customization change
    const handleChangeProductLine = ({ name, value, index, productImageUrl, productItemName }:
        {
            name: string,
            value: string,
            index: number,
            productImageUrl?: string,
            productItemName?: string
        }
    ) => {

        const customization: any = product_line[index]
        customization[name] = value
        product_line[index] = customization as Productline
        if (product_line[index].I22_Order_quantity && product_line[index].I22_Price) {
            product_line[index].I22_Total_cost = product_line[index].I22_Order_quantity! * product_line[index].I22_Price!
        } else {
            product_line[index].I22_Total_cost = null
        }

        switch (name) {
            case PurchaseOrderLineKeys.I22_M08_Product_item_id:
                product_line[index].productItemImage = productImageUrl!
                product_line[index].productItemName = productItemName!

                break;

            default:
                break;
        }

        set_product_line([...product_line])
        console.log(product_line)

    }

    useEffect(
        () => {

            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    I12_I030_order_status_id: '',
                    I12_is_active: 1,
                    I12_M01_created_by: employee.employeeData.M15_Employee_id!,
                    I12_M12_supplier_id: '',
                    I12_order_date: '',
                    I12_total_amount: 0,
                    I22_I030_order_status_id: undefined,
                    I22_I12_Purchase_order_id: undefined,
                    I22_Is_active: 1,
                    I22_M08_Product_item_id: undefined,
                    I22_Order_quantity: 0,
                    I22_Price: null,
                    I22_Received_quantity: null,
                    I22_Total_cost: null
                })
                setErrorData({})
                set_loading(false)

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_product()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
                initListSetup()

            } else {
                set_loading(true)
            }


        }, [show]
    )

    return (
        <AdminModal show={show} setShow={setShow} >
            <AdminModalContent>
                <AdminModalHeader>
                    <AdminModalTitle>Purchase</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle >
                    {
                        loading === true
                            ?
                            <div className=' bg-white p-3'>Loading ...</div>
                            :
                            <div className=' p-3 border rounded-md flex flex-col  h-min  gap-2 mx-auto my-auto bg-white relative '>
                                <SelectCustomLoadingSearching<Supplier & CustomSelectCustomOptionOption>
                                    loadOption={load_supplier_options}
                                    fetchSelectOption={fetch_selected_supplier}
                                    value={formData.I12_M12_supplier_id}
                                    name={PurchaseOrderKeys.I12_M12_supplier_id}
                                    placeholder='supplier'
                                    onselect={handleChangeInput}
                                    title='Supplier'
                                />
                                <div className=' text-red-500'>{errorData.I12_M12_supplier_id ? errorData.I12_M12_supplier_id : null}</div>

                                <div>customization</div>
                                {
                                    product_line.length === 0 ?
                                        <div>Products</div>
                                        :
                                        <Table className='styled-table'>
                                            <TableHeader>
                                                <TableHead>Product</TableHead>
                                                <TableHead>Price</TableHead>
                                                <TableHead>Quantity</TableHead>
                                                <TableHead>Total</TableHead>
                                                {<TableHead className='w-[50px]'></TableHead>}
                                            </TableHeader>
                                            <tbody>
                                                {
                                                    product_line.map(
                                                        (product, index) => {
                                                            switch (product.mode) {
                                                                case 'completed':
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                {/* <td>{product.M17_product_option}</td> */}
                                                                                <td>
                                                                                    <div className=' w-full p-2 border flex relative'>
                                                                                        <img src={`${process.env.REACT_APP_BACKEND_URL}/${product.productItemImage}`} alt="" height={60} width={60} />
                                                                                        <div>{product.productItemName}</div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>{product.I22_Price}</td>
                                                                                <td>{product.I22_Order_quantity}</td>
                                                                                <td>{product.I22_Total_cost}</td>
                                                                                <td onClick={() => {
                                                                                    if (product.source === 'cloude') {
                                                                                        // set_product_line([...customization_deleted, String(customization.M17_ProductCustomizationID)])
                                                                                    }

                                                                                    const filter = product_line.filter(
                                                                                        (item, index2) => index !== index2
                                                                                    )

                                                                                    set_product_line([...filter])


                                                                                }} ><IconTrash className=' cursor-pointer' size={15} /></td>
                                                                            </tr>
                                                                        </>
                                                                    )

                                                                case 'edit':
                                                                case 'create':
                                                                    return (
                                                                        <>
                                                                            <tr key={index}>
                                                                                <td>
                                                                                    <SelectCustomLoadingSearching<ProductItem & CustomSelectCustomOptionOption>
                                                                                        loadOption={
                                                                                            load_product_options
                                                                                        }
                                                                                        className=' min-w-[200px]'
                                                                                        fetchSelectOption={fetch_selected_product}
                                                                                        disable={mode === 'edit' ? true : false}
                                                                                        onselect={(e) => {
                                                                                            if (e.target.object) {
                                                                                                handleChangeProductLine({
                                                                                                    name: e.target.name,
                                                                                                    value: e.target.value,
                                                                                                    index: index,
                                                                                                    productImageUrl: e.target!.object!.images![0].M18_ImagePath,
                                                                                                    productItemName: e.target!.object!.M08_ProductItemName
                                                                                                })
                                                                                            } else {
                                                                                                alert('no images found')
                                                                                            }
                                                                                        }}
                                                                                        value={formData.I22_M08_Product_item_id}

                                                                                        customItem={{
                                                                                            element: (props) => {
                                                                                                return <div key={props.key} onClick={() => {
                                                                                                    props.select_item()
                                                                                                    props.close_menu()
                                                                                                }} className='px-2 py-2  hover:bg-gray-400 cursor-pointer flex items-center gap-1'>
                                                                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.images?.[0]?.M18_ImagePath}`} alt="" height={60} width={60} />
                                                                                                    <div>{props.M08_ProductItemName}</div>
                                                                                                </div>
                                                                                            },
                                                                                            valueName: ProductKeys.M11_Product_id,
                                                                                            selectedItem: (props) => {
                                                                                                return (<div className=' w-full p-2 border flex relative'>
                                                                                                    <div style={{ display: mode === 'edit' ? 'none' : 'block' }} onClick={props.close} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                                                                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.images?.[0]?.M18_ImagePath}`} alt="" height={60} width={60} />
                                                                                                    <div>{props.M08_ProductItemName}</div>
                                                                                                </div>)
                                                                                            }
                                                                                        }}

                                                                                        // value={}

                                                                                        name={PurchaseOrderLineKeys.I22_M08_Product_item_id}
                                                                                        placeholder='product'
                                                                                    />
                                                                                </td>
                                                                                <td><input className='w-full' value={product_line[index].I22_Price ? String(product_line[index].I22_Price) : ''} name={PurchaseOrderLineKeys.I22_Price} placeholder='price' type="number" onChange={(e) => handleChangeProductLine({ name: e.target.name, value: e.target.value, index: index })} /></td>
                                                                                <td><input className='w-full' value={product_line[index].I22_Order_quantity ? String(product_line[index].I22_Order_quantity) : ''} name={PurchaseOrderLineKeys.I22_Order_quantity} placeholder='unit' type="number" onChange={(e) => handleChangeProductLine({ name: e.target.name, value: e.target.value, index: index })} /></td>
                                                                                <td><input disabled className='w-full' value={product_line[index].I22_Total_cost ? String(product_line[index].I22_Total_cost) : ''} name={PurchaseOrderLineKeys.I22_Total_cost} placeholder='total' type="number" onChange={(e) => handleChangeProductLine({ name: e.target.name, value: e.target.value, index: index })} /></td>
                                                                                <td></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td colSpan={5} className=' text-red-500'>{product.error}</td>
                                                                            </tr>
                                                                        </>
                                                                    )

                                                                default:
                                                                    break;
                                                            }

                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </Table>
                                }
                                {
                                    is_customization_add_pressed
                                        ?
                                        <Button onClick={() => {
                                            const product = product_line[product_line.length - 1]
                                            if (!product.I22_Order_quantity || !product.I22_Price || !product.I22_Order_quantity || !product.I22_Total_cost) {
                                                product_line[product_line.length - 1].error = 'please fill'
                                                set_product_line([...product_line])
                                                return;
                                            }
                                            product_line[product_line.length - 1].error = ''
                                            product_line[product_line.length - 1].mode = 'completed'
                                            set_product_line([...product_line])
                                            set_is_customization_add_pressed(false)
                                        }}>submit</Button>
                                        :
                                        <button onClick={() => {
                                            set_is_customization_add_pressed(true)
                                            const productLine = {
                                                I22_M08_Product_item_id: '',
                                                I22_Order_quantity: null,
                                                I22_Price: null,
                                                I22_Total_cost: null,
                                                productItemImage: '',
                                                productItemName: '',
                                                mode: 'create',
                                                error: '',
                                                source: 'local'
                                            } satisfies Productline
                                            product_line.push(productLine)
                                        }} >Add Product</button>
                                }

                            </div>
                    }
                </AdminModalMiddle>
                <AdminModalFooter>
                    {
                        mode === 'add' ?
                            <Button disabled={isLoading} onClick={add_purchase} >{isLoading ? 'Processing...' : 'Purchase'}</Button>
                            :
                            <Button disabled={isLoading} onClick={edit_store}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )


}
