import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { LoginPage } from './login_page/page'
import AdminDashboard from './dashboard/page'
// import './presetted.css';
import { ProtectedRouteAdmin } from './components/protectRoute';

export default function AdminRoot() {


    return (
        <div className='test_presetted '>
            <Routes>
                <Route index element={<Navigate to='dashboard' />} />
                <Route path='admin_login' element={<LoginPage />} />
                <Route path='dashboard/*' element={
                    <ProtectedRouteAdmin>
                        <AdminDashboard />
                    </ProtectedRouteAdmin>
                } />
            </Routes>
        </div>
    )
}
