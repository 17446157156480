import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { employee } from '../../types'

type employeeSlice = {
  employeeData:Partial<employee>
}

const initialState: employeeSlice = {
  employeeData: {
    M15_created_by: '',
    email: '',
    M15_Hire_date: '',
    M15_is_active: 1,
    M15_M13_role_id: '',
    M15_Name: '',
    M15_Phone: '',
    M15_Employee_id: 1,
  }
}

export const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployee: (state, action: PayloadAction<employee>) => {
      state.employeeData = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const {
  setEmployee
} = employeeSlice.actions

export const employeeSliceReducer = employeeSlice.reducer