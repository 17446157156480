import { IconChevronRight } from '@tabler/icons-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

type subtab = {
    name: string,
    link: string,
    id: string
}

interface propsType {
    title: string
    subTab: subtab[],
    selectedId: string
}

export default function SideBarOptions({ title, subTab, selectedId }: propsType) {
    const [isOpen, setIsOpen] = useState(false);
    const isSelected = subTab.some(item => item.id === selectedId)

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className={`flex flex-col ${isOpen?'':'hover:bg-adminPrimary-600/30'} rounded-md  ${isOpen?' bg-adminPrimary-600/30':''}`}>

            <div className={`${isOpen ? " bg-adminPrimary-600/30" : " " } ${isSelected&&!isOpen?'text-white':'hover:text-adminPrimary-900 '} ${isSelected ? " bg-adminPrimary-700 shadow-md" : " "} text-center py-2   rounded-md  font-bold flex h-[50px] gap-1 cursor-pointer px-5 `} onClick={toggleDropdown}>
                {/* <div  className={`${isSelected ? " bg-adminPrimary-700" : "bg-transparent "} w-[3px] h-full rounded-md`}></div> */}
                <div className=' flex  items-center grow'>{title}</div>
                <div className=' flex items-center'>
                    <IconChevronRight  className={`${isOpen?' rotate-90':''} transition-transform duration-200`} />
                </div>
            </div>
            <div
                className={`transition-all duration-200 ease-in-out overflow-hidden `}
                style={{ maxHeight: isOpen ? 500 : 0, marginTop: isOpen ? '0px' : '0'}}
            >
                <div className='w-full py-2 flex flex-col  gap-1 pl-4 '>
                    {
                        subTab.map(
                            tab => (
                                <Link to={tab.link} className={`${tab.id === selectedId ? 'bg-adminPrimary-700 text-white' : 'bg-transparent hover:bg-adminPrimary-600/30'}   cursor-pointer rounded-s-md px-2 py-3 `}>
                                    {tab.name}
                                </Link>
                            )
                        )
                    }
                </div>
            </div>

        </div>
    )
}
