import React, { useEffect, useState } from 'react';
import UserAddress from 'src/pages/customer/components/userAddress/userAddress';
import { Step } from '../checkout';
import { useAddressAPI } from '@/src/hooks/address_api';
import { CustomerAddress } from '@/src/types';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { Button } from '@/src/components/ui/button';

interface AddressComponentProps {
    isFold: boolean;
    changeStep: (step: Step) => void,
    setAddressLoading: (isLoading: boolean) => any,
    setSelectedAddress: (address: CustomerAddress) => any,
    selectedAddress: CustomerAddress | undefined
}

export function AddressComponent({ isFold, changeStep, setAddressLoading, setSelectedAddress, selectedAddress }: AddressComponentProps) {
    const [isFormVisible, setIsFormVisible] = useState(false);
    const { loadAddressAll } = useAddressAPI();
    const [updateUi, setUpdateUi] = useState(false);
    const [addresses, setAddresses] = useState<CustomerAddress[]>([]);
    const { close_alert_modal, open_alert_modal } = useAlertModal();

    function toggleFormVisibility() {
        setIsFormVisible(!isFormVisible);
    }

    function handleAddressClick(index: number) {
        addresses.forEach((address, indexOg) => {
            address.M02_IsDefault = indexOg === index ? 1 : 0;
        });
        setAddresses([...addresses]);
        setSelectedAddress(addresses[index]);
        setIsFormVisible(false);
    }

    const updateUiFuntion = () => {
        setUpdateUi(!updateUi);
    }

    console.log(addresses)

    useEffect(() => {
        setAddressLoading(true);
        loadAddressAll().then(addresses => {
            if (addresses.success) {
                setAddresses(
                    addresses.data!.data.map(address => {
                        if (address.M02_IsDefault) {
                            setSelectedAddress(address);

                        }
                        return address;
                    })
                );
                const isDefaultIndex = addresses.data?.data.findIndex(address => address.M02_IsDefault === 1)
                if (isDefaultIndex === -1) {
                    setIsFormVisible(true)
                }
                setAddressLoading(false);
                return;
            }
            open_alert_modal({
                content: addresses.message,
                footer: <div><Button onClick={close_alert_modal}>Ok</Button></div>,
                title: addresses.message
            });


        });
    }, [updateUi]);

    const [addAddressClicked, setAddAddressClicked] = useState(false);

    function changingState(command: boolean) {
        setAddAddressClicked(command);
    }
    function cancel_addressClick(command: boolean) {
        setAddAddressClicked(command);
    }

    return (
        <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
            <div className="p-5">
                <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                    <span className='font-bold'>ADDRESS</span>
                    {isFold && (
                        <button
                            type="button"
                            onClick={() => changeStep('address')}
                            className="text-blue-500"
                        >
                            Change
                        </button>
                    )}
                </h5>
            </div>
            <div className={`overflow-hidden transition-all duration-1000 ease-in-out ${isFold ? 'max-h-0 opacity-0' : 'h-auto opacity-100'}`}>
                <div className="p-5">
                    {addresses.length > 0 && !addAddressClicked ? (
                        <div className="block h-auto rounded-lg p-5 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                            {addresses.filter(address => address.M02_IsDefault === 1).map((address, index) => (
                                <div key={index}>
                                    <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                                        Address
                                        {isFold ? null : (
                                            <button
                                                type="button"
                                                onClick={toggleFormVisibility}
                                                className="text-blue-500"
                                            >
                                                Choose another account
                                            </button>
                                        )}
                                    </h5>
                                    <p className="mb-1 text-base text-neutral-600 dark:text-neutral-200 font-bold">
                                        {address.M02_Name}
                                    </p>
                                    <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                                        {address.M02_StreetNo}, {address.M02_City}, {address.M02_Region}, {address.M02_PostalCode}
                                    </p>
                                    <button onClick={() => changeStep('orderSummery')} className='px-4 py-2 bg-[orange] rounded-md'>Deliver here</button>
                                </div>
                            ))}

                            <form className={`mt-4 overflow-hidden transition-all duration-1000 ease-in-out ${isFormVisible ? 'max-h-max opacity-100' : 'max-h-0 opacity-0'} `}>
                                <div className='w-full flex justify-end items-end mb-2'>
                                    <button
                                        onClick={() => setAddAddressClicked(true)}
                                        type='button'
                                        className='p-2 bg-blue-gray-500 rounded-md'>Add Address
                                    </button>
                                </div>
                                {addresses.map((address, index) => (
                                    address.M02_IsDefault === 0 && (
                                        <div key={index} className="border-t bg border-[grey] py-2 cursor-pointer hover:bg-[#c5c5c5]" onClick={() => handleAddressClick(index)}>
                                            <div className="pl-3">
                                                <p className="mb-1 text-base text-neutral-600 dark:text-neutral-200 font-bold">
                                                    {address.M02_Name}
                                                </p>
                                                <p className="text-base text-neutral-600 dark:text-neutral-200">
                                                    {address.M02_StreetNo}, {address.M02_City}, {address.M02_Region}, {address.M02_PostalCode}
                                                </p>
                                            </div>
                                        </div>
                                    )
                                ))}
                            </form>
                        </div>
                    ) : (
                        <UserAddress
                            updateUi={updateUiFuntion}
                            updatingFunction={changingState}
                            cancelAddress={cancel_addressClick}
                            addressess={addresses}
                            setAddress={setAddresses}
                            setSelectedAddress={setSelectedAddress}

                        />
                    )}

                </div>
            </div>
            {
                isFold === true && selectedAddress !== undefined &&
                (
                    <div className=' p-3' >

                        <p className="mb-1 text-base text-neutral-600 dark:text-neutral-200 font-bold">
                            {selectedAddress.M02_Name}
                        </p>
                        <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
                            {selectedAddress.M02_StreetNo}, {selectedAddress.M02_City}, {selectedAddress.M02_Region}, {selectedAddress.M02_PostalCode}
                        </p>
                    </div>
                )
            }
        </div>
    );
}
