import { useRESTapi } from '@/src/hooks/rest_API';
import { orderStatusFlags } from '@/src/types';
import React, { useState } from 'react'
import { ShippedModal } from './shippedModal';
import { DeliveredModal } from './deliveredModal';
import { ProcessedModal } from './processingModal';
import { ReadyToShipModal } from './readyToShipModal';
import { CancellationConformModal } from './cancelationConformModal';


type Props = {
    status: orderStatusFlags,
    isActionalble: boolean,
    orderId?: string,
    updateUi: () => void
}

export function OrderStatus(props: Props) {

    const api = useRESTapi()
    const [showShippedModal, setShowShippedModal] = useState<boolean>(false)
    const [showDeliveryModal, setShowDeliveryModal] = useState<boolean>(false)
    const [showCancelconformModal, setshowCancelconformModal] = useState<boolean>(false)
    const [showorderedModal, setShowOrderedModal] = useState<boolean>(false)
    const [showreadyToshipModal, setShowreadyToshipModal] = useState<boolean>(false)
    const [loading, setLoading] = useState(false)

    const changeStatus = async () => {
        if (!props.isActionalble) {
            return;
        }

        if (props.orderId) {
            let success: boolean = false
            switch (props.status) {

                case 'processing': {
                    setLoading(true)
                    const res = await api.post<{ success: boolean, data: string }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/confirm_order_admin`,
                        body: {
                            orderId: props.orderId
                        },
                        mode: 'admin',
                    })
                    setLoading(false)
                    success = res.data.success
                    if (success) {
                        setShowOrderedModal(false)
                    }
                    break
                }
                case 'readyToShip': {
                    setLoading(true)
                    const res = await api.post<{ success: boolean, data: string }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/order_ready_to_ship`,
                        body: {
                            orderId: props.orderId
                        },
                        mode: 'admin',
                    })
                    setLoading(false)
                    success = res.data.success
                    if (success) {
                        setShowreadyToshipModal(false)
                    }
                    break
                }
                case 'shipped': {
                    setShowShippedModal(true)

                    break
                }
                case 'delivered': {
                    setShowDeliveryModal(true)
                    break
                }
                case 'CancelledPending':{
                    setshowCancelconformModal(true)
                }
            }

            if (success) {
                props.updateUi()
            }
        }
    }

    switch (props.status) {
        case 'ordered':

            return <>
                <div onClick={changeStatus} className=' px-2 py-1 bg-red-800 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Order Recieved</div>
            </>
        case 'processing':

            return <>
                <div onClick={()=>setShowOrderedModal(true)} className=' px-2 py-1 bg-red-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Order processed</div>
                <ProcessedModal isLoading={loading} setProcessing={changeStatus} setShow={setShowOrderedModal} show={showorderedModal} />
            </>
        case 'readyToShip':

            return <>
                <div onClick={()=>setShowreadyToshipModal(true)} className=' px-2 py-1 bg-orange-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Ready to ship</div>
                <ReadyToShipModal isLoading={loading} setProcessing={changeStatus} setShow={setShowreadyToshipModal} show={showreadyToshipModal} />
            </>
        case 'shipped':

            return (
                <>
                    <ShippedModal orderId={props.orderId} show={showShippedModal} setShow={setShowShippedModal} updateUi={props.updateUi} />
                    <div onClick={changeStatus} className=' px-2 py-1 bg-light-green-300 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>shipped</div>
                </>
            )
        case 'delivered':

            return <>
                <DeliveredModal orderId={props.orderId} show={showDeliveryModal} setShow={setShowDeliveryModal} updateUi={props.updateUi} />
                <div onClick={changeStatus} className=' px-2 py-1 bg-light-green-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Delivered</div>
            </>
        case 'cancelled':

            return <>
                <div onClick={changeStatus} className=' px-2 py-1 bg-red-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Cancelled</div>
            </>
       
        case 'CancelledPending':

            return <>
                <CancellationConformModal setLoading={setLoading} isLoading={loading}  orderId={props.orderId} show={showCancelconformModal} setShow={setshowCancelconformModal} updateUi={props.updateUi} />
                {
                    
                    <div onClick={changeStatus} className=' px-2 py-1 bg-red-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>{
                        props.isActionalble
                        ?'Confirm Cancellation':'Cancellation Pending'}</div>
                   }
            </>

        default:
            return <div className=' px-2 py-1 bg-green-500 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Unavailable</div>
    }


}
