import { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { ProductCategory, TaxRates, TaxType, TaxTypeEnum, pagination } from '../../../../../types'
import { AddTaxModal } from './component/addmodal'
import { Button } from '@/src/components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { DeleteModal } from './component/deleteModal'

interface TaxTypeResponse extends TaxType {
  rates: TaxRates[],
}
interface TaxTypeOverview extends TaxTypeResponse {
  delete: any,
  edit: any,
  page_number: number
}

type Props = {
  setSelectedId: (id: string) => any
}

export function TaxConfig(props: Props) {

  const [products, set_products] = useState<TaxTypeOverview[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const [loading_category, set_loading_category] = useState(false)
  const alertModal = useAlertModal()
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)



  const header: {
    label: TaxTypeEnum | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: TaxTypeEnum.T01_tax_type_name,
        name: "name"
      },
      // {
      //   label: 'image',
      //   name: "image"
      // },
      {
        label: 'edit',
        name: ''
      },
      // {
      //   label: 'delete',
      //   name: ''
      // },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id: number | string) {

    try {
      setIsDeleteLoading(true)
      const res = await api.Delete<{ success: boolean, data: TaxTypeOverview, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_categories/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success === true) {
        alertModal.open_alert_modal({
          content: res.data.message || 'Category deleted succesfully',
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'Deleted'
        })
        setshowDeleteModal(false)
      } else {
        alertModal.open_alert_modal({
          content: res.data?.message || 'Something went wrong',
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'Error'
        })
      }


    } catch (error) {
      console.log(error)
      alertModal.open_alert_modal({
        content: 'Server Error',
        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
        title: 'Error'
      })
    }
    setIsDeleteLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {

      props.setSelectedId('O1')

      const initData = async () => {
        set_loading_category(true)
        try {
          const res = await api.get<{ success: boolean, data: ({data:TaxTypeResponse[]}&pagination), message: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/tax`,
            params: {
              perPage: per_page,
              page: page_selected
            },
            mode: 'admin',

          })

          if (res.data.success === true) {
            // console.log(res.data)
            
            set_products(res.data.data.data.map(
              (item, index) => {

                return ({
                  ...item,
                  page_number: ((page_selected - 1) * per_page) + (index + 1),
                  delete: <ButtonIcon icon={<Trash className="h-4 w-4" />} onClick={() => { setshowDeleteModal(true); set_selected_product(String(item.T01_tax_type_id)) }}></ButtonIcon>,
                  edit: <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={() => clickEditButton(String(item.T01_tax_type_id))}></ButtonIcon>
                });
              }
            ))
            set_loading_category(false)
            // set_products_total(res.data.data.total)
          } else {
            alertModal.open_alert_modal({
              content: res.data.message,
              footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
              title: 'Error'
            })
          }
        } catch (error) {
          console.log(error)
          alertModal.open_alert_modal({
            content: 'Server Error',
            footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
            title: 'Error'
          })

        }

      }

      initData()

    }, [update]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddTaxModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          delete_product(selectedEmployeeId!);

        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' p-2 text-2xl font-bold'>Tax Configuration</div>
      <div className=' w-full flex justify-end' >
        {/* <Button onClick={open_add_modal} className=' '>Add Category</Button> */}
      </div>
      <div className=' overflow-auto w-full border rounded-md'>
        {
          loading_category ?
            <SkeletonTableAdmin />
            : <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )

                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => { set_page_selected(e); updateUi() }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
