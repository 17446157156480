import React, { useEffect, useState } from 'react'
import "./loginPage.css"
import { Link, useNavigate } from 'react-router-dom'
import { useRESTapi } from 'src/hooks/rest_API'
import { localStorageKeys } from 'src/types/local_storage'
import { Customer } from 'src/types'
import useFormValidatonCustom, { validationObject } from 'src/hooks/form_validation_hook'
import { IconArrowBadgeLeft, IconArrowNarrowLeft, IconLoader } from '@tabler/icons-react'
import { setCustomer, setCustomerLoggedIn } from 'src/redux/customer/customer_slice'
import { useDispatch, useSelector } from 'react-redux'
import { useCartApi } from 'src/hooks/cart_api'
import { RootState } from 'src/redux/store'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/src/components/ui/input-otp'
import { REGEXP_ONLY_DIGITS_AND_CHARS } from 'input-otp'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { ResendOTPButton } from './components/resentOTP'

export default function LoginPage() {

    const api = useRESTapi()
    const [email, setEmail] = useState('')
    const [otp_mode, set_otp_mode] = useState(false)
    const [otp, set_otp] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { clear_cart, add_to_cart_by_product } = useCartApi()
    const cartItems = useSelector((state: RootState) => state.cart.cart)

    const validationObject = {
        email: {
            message: '',
            validation: [
                (value: string) => {
                    if (!value) {
                        return 'required'
                    }
                    if (value?.length === 0) {
                        return 'required'
                    }
                    if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) === false) {
                        return 'enter valid email'
                    }
                    return true
                },

            ]
        },
        otp: {
            message: '',
            validation: [
                (value: string) => {
                    if (otp_mode === true) {
                        if (!value) {
                            return 'required'
                        }
                        if (value?.length === 0) {
                            return 'required'
                        }
                    }
                    return true
                },

            ]
        }
    }

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<{
        email: string,
        otp: string
    }>({
        validationObject: validationObject
    })


    const sent_otp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        if (performAllValidation({ email, otp }) === true) {

            try {
                const res = await api.post<{ success: boolean, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/send_otp`,
                    body: {
                        email: email
                    },
                    mode: 'customer'
                })

                if (res.data.success === true) {
                    set_otp_mode(true)
                } else {
                    alert(res.data.message)
                }
            } catch (error) {
                console.log(error)
                alert('something went wrong')
            }
        }
        setIsLoading(false)
    }

    const resent_otp = async () => {
        try {
            const res = await api.post<{ success: boolean, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/send_otp`,
                body: {
                    email: email
                },
                mode: 'customer'
            })

            if (res.data.success === true) {
                set_otp_mode(true)
            } else {
                alert(res.data.message)
            }
        } catch (error) {
            console.log(error)
            alert('something went wrong')
        }
    }

    const verify_otp = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setIsLoading(true)
        if (performAllValidation({ email, otp }) === true) {

            try {
                const res = await api.post<{ success: boolean, data: Customer, message: string, access_token: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/verify_otp`,
                    mode: 'customer',
                    body: {
                        email: email,
                        M01_OTP: otp
                    }
                })

                if (res.data.success === true) {
                    localStorage.setItem(process.env.REACT_APP_TOKEN!, res.data.access_token)
                    localStorage.setItem(localStorageKeys.customerLoggedIn, '1')
                    dispatch(setCustomer(res.data.data))

                    // push to cart
                    const res1 = await add_to_cart_by_product()
                    navigate('/')

                } else {
                    alert(res.data.message)
                }
            } catch (error) {
                console.log(error)
                alert('something went wrong')
            }
        }
        setIsLoading(false)
    }

    const checklogin = async () => {
        setIsLoading(true)
        try {
            const res = await api.post<{ success: boolean, data: Customer, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/verify_token`,
                mode: 'customer',
            })

            if (res.data.success === true) {

                dispatch(setCustomer(res.data.data))
                await clear_cart()
                localStorage.setItem(localStorageKeys.user_mode, 'customer')
                localStorage.setItem(localStorageKeys.customerLoggedIn, '1')
                dispatch(setCustomerLoggedIn('1'))
                setIsLoading(false)
                navigate('/')
                return;
            }

        } catch (error) {
            console.log(error)
            alert('authentication error')
        }

        localStorage.setItem(localStorageKeys.customerLoggedIn, '0')

        setIsLoading(false)

    }

    useEffect(
        () => {
            checklogin()
        },
        []
    )

    return (
        <div className='relative h-[100vh] font-roboto  flex justify-center'>
            {isLoading === true && <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                <div className=' animate-spin '><IconLoader /></div>
            </div>}
            <div className='loginMainDiv h-[90%] w-[75%]  flex  z-10 self-center rounded-[62px] overflow-hidden shadow-md border-[2px] '>


                <div className='imageDiv w-[40%] h-full bg-[#cacbc1]'>
                    <img src={'/assets/loginpage/S.png'} alt="" className='absolute right-[36%] top-[18%] w-[67%] h-[70%] z-40' />
                </div>
                <div className='formParentDiv h-full w-[65%] sm:w-[75%]  flex justify-center items-center z-50' >
                    <div className='formChildDiv h-[80%]  w-[60%] flex flex-col gap-6 justify-center'>
                        {
                            otp_mode && <ButtonIcon
                                icon={<IconArrowNarrowLeft />}
                                toolTipText='Back'
                                onClick={() => set_otp_mode(false)}
                            />
                        }
                        <h1 className='text-[20px] font-bold'>Log in.</h1>
                        <h1 className='text-[33px] font-bold'>Welcome Back!</h1>
                        {
                            otp_mode === false ?

                                <form onSubmit={sent_otp} className='flex flex-col mt-6 gap-3'>
                                    <label htmlFor="">Mail ID</label>
                                    <Input placeholder='Enter Email' name='email' type="text" value={email} onChange={e => setEmail(e.target.value)} />
                                    <div className=' text-red-500'>{errorData.email}</div>
                                    {/* <label htmlFor="">Password</label>
                            <input placeholder='Password' type="password" />
                            <div className='self-end underline'><a href="">Forgot Password</a></div> */}
                                    <Button className='shadow-2xl rounded-md bg-[#cacbc1]'  >Sent OTP</Button>

                                </form>
                                :
                                <form onSubmit={verify_otp} className='flex flex-col text-center items-center mt-6 gap-3'>
                                    <label htmlFor="">OTP</label>
                                    {/* <input type="text" placeholder='otp' value={otp} onChange={e => set_otp(e.target.value)} /> */}
                                    <InputOTP value={otp} onChange={e => set_otp(e)} maxLength={6} pattern={REGEXP_ONLY_DIGITS_AND_CHARS}>
                                        <InputOTPGroup>
                                            <InputOTPSlot index={0} />
                                            <InputOTPSlot index={1} />
                                            <InputOTPSlot index={2} />
                                            <InputOTPSlot index={3} />
                                            <InputOTPSlot index={4} />
                                            <InputOTPSlot index={5} />
                                        </InputOTPGroup>
                                    </InputOTP>
                                    <div className=' text-red-500'>{errorData.otp}</div>

                                    <Button className='shadow-2xl rounded-md bg-[#cacbc1]' >Verify OTP</Button>
                                    <ResendOTPButton
                                        onResend={resent_otp}
                                        initialTime={30}

                                    />

                                </form>
                        }
                        {/* <div className="flex items-center w-[80%] self-center">
                            <span className="border-b border-gray-400 flex-grow"></span>
                            <span className="px-4">Or</span>
                            <span className="border-b border-gray-400 flex-grow"></span>
                        </div> */}
                        <div className='gap-10 flex flex-col'>
                            {/* <div className='flex gap-3 items-center justify-center cursor-pointer'>
                                <img className='h-6' src="https://lh3.googleusercontent.com/COxitqgJr1sJnIDe8-jiKhxDx1FrYbtRHKJ9z_hELisAlapwE9LUPh6fcXIfb5vwpbMl4xl9H9TRFPc5NOO8Sb3VSgIBrfRYvW6cUA" alt="G" />
                                <p>Continue with Google</p>
                            </div> */}
                            <div className='flex justify-center '>
                                <p className=' underline'>Don't have an account?<Link to={'/signup'} className='font-bold'>Create now</Link></p>
                            </div>

                        </div>



                    </div>
                </div>
            </div>
        </div>

    )
}
