import React, { useEffect, useState } from 'react'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import outlined from '@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined';
import { edit_variation_props } from '../variationComponent';
import { Input } from '@/src/components/ui/input';
import { Button } from '@/src/components/ui/button';
import { useRESTapi } from '@/src/hooks/rest_API';
import { ProductVariation } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Loader2 } from 'lucide-react';

type props = {
    delete_variation_modal_config: edit_variation_props,
    set_delete_variation_modal_config: (value: edit_variation_props) => any,
    reload_product: () => any
}

type formDataType = {
    variationName: string
}
export  function VariationDeleteModal(props: props) {


    const [is_loading, set_is_loading] = useState(false)
    const api = useRESTapi()



    const deleteVariation = async () => {
        set_is_loading(true)
        try {

            const res = await api.Delete<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                mode: 'admin',
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variations/${props.delete_variation_modal_config.variation?.id}`,
                
            })
            if (res.data.success === true) {
                props.set_delete_variation_modal_config({ show: false,showDeleteModal:false })
                props.reload_product()
            } else {
                alert(res.data.message)
            }

        } catch (error) {
            console.log(error)
            alert('server error')
        }

        set_is_loading(false)
    }


    return (
        <AdminModal show={props.delete_variation_modal_config.showDeleteModal} setShow={(show) => {
            if (is_loading === false) {
                props.set_delete_variation_modal_config({
                    show: false,
                    variation: show ? props.delete_variation_modal_config.variation : undefined,
                    showDeleteModal:show
                })
            }
        }} >
            <AdminModalContent disableCloseButton={is_loading} >
                <AdminModalHeader>
                    <AdminModalTitle>
                        Delete Variation
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-48 !min-w-48'>
                    Do you Want to delete Variation?
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={is_loading} onClick={() => props.set_delete_variation_modal_config({ show: false,showDeleteModal:false })} variant={'outline'}>Cancel</Button>
                    <Button disabled={is_loading} onClick={deleteVariation}>{is_loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Delete</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
