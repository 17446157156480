import { Button } from '@/src/components/ui/button'
import { Input } from '@/src/components/ui/input'
import { Switch } from '@/src/components/ui/switch'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { CarouselTypeId } from '@/src/types/databaseFeeds'
import { IconLoader, IconPlus } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../redux/store'
import { CarouselSmall, CarouselSmallEnum, CustomFile } from '../../../../../../types'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'




type CarouselOverview = CarouselSmall
type imageIds = string[]



export function AddRightCarouselModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any
        }
) {


    const validationObject = {
        [CarouselSmallEnum.C303_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CarouselSmallEnum.C303_carousel_duration]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    if (isNaN(value)) {
                        return 'enter valid duration in seconds'
                    }
                    if (Number(value) < 4) {
                        return 'minimum duration is 4 seconds'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<CarouselOverview>({
        C303_C302_carousel_type: CarouselTypeId.Image,
        C303_source_path: '',
        C303_is_active: 1,
        C303_carousel_duration: 6,
        C303_name: '',
        C303_order_index: null,
        C303_P309_promotion_group_id: null
    })

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<CarouselOverview>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isSpinLoading, setSpinLoading] = useState(false)

    const [images_url_deleted, set_image_url_deleted] = useState<imageIds>([])

    const image_input_ref = useRef<HTMLInputElement>(null)
    const [largeImageImage, setlargeImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })
    const [add_progress, set_add_progress] = useState(0)


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: CarouselOverview;

        switch (e.target.name) {
            case CarouselSmallEnum.C303_C302_carousel_type:
                if (image_input_ref.current) {
                    image_input_ref.current.value = ''
                }
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value,
                    C303_source_path: ""
                }
                break
            case CarouselSmallEnum.C303_source_path:

                if (e.target.files && e.target.files.length > 0) {
                    setSpinLoading(true)
                    const file = e.target.files[0];

                    if (formData.C303_C302_carousel_type == CarouselTypeId.Image) {
                        const image = new Image();
                        image.onload = () => {
                            const aspectRatio = image.width / image.height;
                            console.log(aspectRatio)
                            if (aspectRatio >= 1.86 && aspectRatio <= 1.88) {
                                const uniqueId = uuidv4();
                                const url = URL.createObjectURL(file)
                                setlargeImageImage({
                                    imageUrl: url,
                                    mode: 'local'
                                })
                                const total_image: CustomFile = {
                                    file: file,
                                    id: uniqueId
                                };

                                formdata = {
                                    ...formData,
                                    C303_source_path: total_image
                                };



                                setFormData(formdata);
                                performValidationOf(e.target.name, formdata);
                                console.log(formdata);
                            } else {
                                if (image_input_ref.current) {
                                    image_input_ref.current.value = ''
                                }
                                alert("Image aspect ratio must be 1.87");
                            }
                            setSpinLoading(false)
                        };

                        image.src = URL.createObjectURL(file);
                        formdata = {
                            ...formData,
                            C303_source_path: ''
                        };
                    } else {
                        const uniqueId = uuidv4();
                        const url = URL.createObjectURL(file)
                        setlargeImageImage({
                            imageUrl: url,
                            mode: 'local'
                        })
                        const total_video: CustomFile = {
                            file: file,
                            id: uniqueId
                        };

                        formdata = {
                            ...formData,
                            C303_source_path: total_video
                        };
                        setSpinLoading(false)
                    }




                } else {
                    formdata = {
                        ...formData,
                        C303_source_path: ''
                    }
                }


                break;


            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_carousel() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case CarouselSmallEnum.C303_source_path:
                                const images = data[1] as CustomFile
                                formDataReal.append(`${data[0]}`, images.file)
                                break;

                            case CarouselSmallEnum.C303_C302_carousel_type:
                            case CarouselSmallEnum.C303_P309_promotion_group_id:
                            case CarouselSmallEnum.C303_carousel_duration:
                            case CarouselSmallEnum.C303_carousel_id:
                            case CarouselSmallEnum.C303_is_active:
                            case CarouselSmallEnum.C303_name:
                            case CarouselSmallEnum.C303_order_index:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: CarouselOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel_small`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {
                    closeModal()
                    updateUi();
                    alert('added carousel')


                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {
                            case CarouselSmallEnum.C303_source_path:
                                const images = data[1] as CustomFile
                                formDataReal.append(`${data[0]}`, data[1] ? images.file:'')
                                break;

                            case CarouselSmallEnum.C303_C302_carousel_type:
                            case CarouselSmallEnum.C303_P309_promotion_group_id:
                            case CarouselSmallEnum.C303_carousel_duration:
                            case CarouselSmallEnum.C303_carousel_id:
                            case CarouselSmallEnum.C303_is_active:
                            case CarouselSmallEnum.C303_name:
                            case CarouselSmallEnum.C303_order_index:
                                formDataReal.append(data[0], data[1] ? String(data[1]) : '')
                                break;
                        }
                    }

                )

                images_url_deleted.forEach(
                    item => {
                        formDataReal.append('delete_image_ids[]', item)
                    }
                )


                // const anyFormData  = formData as any

                // for (const key in formData) {
                //     formDataReal.append(key, String(anyFormData[key]))
                // }

                // console.log(images_url_deleted)

                const res = await api.post<{ success: boolean, data: CarouselOverview, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel_small/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {

                    updateUi();
                    closeModal();
                    alert(res.data?.message || 'updated Carousel')

                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }

                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }

    const load_carousel = async () => {

        const res = await api.get<{
            success: boolean, data: CarouselSmall
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/carousel_small/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            setlargeImageImage({
                imageUrl: `${res.data.data.C303_source_path}`,
                mode: 'cloude'
            })
            setFormData(res.data.data)
        } else {
            throw res.data
        }
    }


    const clear_datas = () => {
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
        setErrorData({})
        setFormData({
            C303_C302_carousel_type: CarouselTypeId.Image,
            C303_source_path: '',
            C303_is_active: 1,
            C303_carousel_duration: 6,
            C303_name: '',
            C303_order_index: null,
            C303_P309_promotion_group_id: null
        })
    }



    useEffect(
        () => {
            clear_datas()
            const initListSetup = () => {
                set_loading(true)
                setFormData({
                    C303_C302_carousel_type: CarouselTypeId.Image,
                    C303_source_path: '',
                    C303_is_active: 1,
                    C303_carousel_duration: 6,
                    C303_name: '',
                    C303_order_index: null,
                    C303_P309_promotion_group_id: null
                })

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)

                Promise.all(
                    [load_carousel()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )


    const delete_image_large = () => {
        formData.C303_source_path = ''
        setFormData({ ...formData })
        setlargeImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }


    const onclick_add_image_input_large = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }



    return (
        <AdminModal show={show} setShow={setShow}>
            <AdminModalContent disableCloseButton={isLoading}>

                <AdminModalHeader progressValue={add_progress}>
                    <AdminModalTitle >Add Right Carousel</AdminModalTitle>

                </AdminModalHeader>
                <AdminModalMiddle>
                    {
                        isSpinLoading
                        &&
                        <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                            <div className=' animate-spin '><IconLoader /></div>
                        </div>
                    }
                    {
                        loading === true
                            ?
                            <div className=' bg-white p-3'>Loading ...</div>
                            :
                            < >
                                <Input disabled={loading || isLoading} value={formData.C303_name} onChange={handleChangeInput} type="text" name={CarouselSmallEnum.C303_name} placeholder='Name' />
                                <div className=' text-red-500'>{errorData.C303_name ? errorData.C303_name : null}</div>
                                <div className=' h-3'></div>
                                <div className=''>Duration (seconds) </div>
                                <Input disabled={loading || isLoading} value={formData.C303_carousel_duration} onChange={handleChangeInput} type="number" name={CarouselSmallEnum.C303_carousel_duration} placeholder='Name' />
                                <div className=' text-red-500'>{errorData.C303_carousel_duration ? errorData.C303_carousel_duration : null}</div>
                                <div>Carousel Type</div>
                                <SelectShadcn
                                    disabled={loading || isLoading}
                                    placeHolder='Carousel Type'
                                    value={String(formData.C303_C302_carousel_type)}
                                    name={CarouselSmallEnum.C303_C302_carousel_type}
                                    onChange={handleChangeInput}
                                    options={[
                                        {
                                            name: 'image',
                                            value: String(CarouselTypeId.Image)
                                        },
                                        {
                                            name: 'video',
                                            value: String(CarouselTypeId.Video)
                                        }
                                    ]}
                                />
                                {formData.C303_C302_carousel_type == CarouselTypeId.Image
                                    ?
                                    <>
                                        <div>Image (ratio 1.87) </div>
                                        {largeImageImage.imageUrl ?
                                            <div className=' relative h-20 aspect-[1.87] group'>
                                                <img src={`${largeImageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/storage/${largeImageImage.imageUrl}` : largeImageImage.imageUrl}`} alt="" className=' h-full w-full' />
                                                <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                                    <Button disabled={loading || isLoading} onClick={() => delete_image_large()} >delete</Button>
                                                </div>
                                            </div>
                                            : <div onClick={onclick_add_image_input_large} className=' h-20 aspect-[1.87] border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}
                                        <Input disabled={loading || isLoading} className=' hidden' ref={image_input_ref} onChange={handleChangeInput} type="file" accept="image/*" name={CarouselSmallEnum.C303_source_path} placeholder='Image' />
                                        <div className=' text-red-500'>{errorData.C303_source_path ? errorData.C303_source_path as string : null}</div>
                                    </>
                                    :
                                    <>
                                        <div>Video (ratio 1.87 recommended)</div>
                                        {largeImageImage.imageUrl ?
                                            <div className=' relative h-20 aspect-[1.87] group'>
                                                <video
                                                    src={`${largeImageImage.mode === 'cloude' ? `${process.env.REACT_APP_BACKEND_URL}/storage/${largeImageImage.imageUrl}` : largeImageImage.imageUrl}`}
                                                    // autoPlay
                                                    // loop
                                                    muted
                                                    playsInline
                                                    className=" h-full w-full"
                                                >
                                                    Your browser does not support the video tag.
                                                </video>
                                                <div className=' absolute inset-0 bg-black/50 z-10 opacity-0 group-hover:opacity-100 flex flex-col items-center justify-center '>
                                                    <Button disabled={loading || isLoading} onClick={() => delete_image_large()} >delete</Button>
                                                </div>
                                            </div>
                                            : <div onClick={onclick_add_image_input_large} className=' h-20 aspect-[1.87] border-2 border-dotted flex items-center justify-center cursor-pointer' ><IconPlus /></div>}
                                        <Input disabled={loading || isLoading} className=' hidden' ref={image_input_ref} onChange={handleChangeInput} type="file" accept="video/*" name={CarouselSmallEnum.C303_source_path} placeholder='Video' />
                                        <div className=' text-red-500'>{errorData.C303_source_path ? errorData.C303_source_path as string : null}</div>
                                    </>
                                }


                                <div className=' flex gap-1'>
                                    <Switch disabled={loading || isLoading} checked={formData.C303_is_active == 1 ? true : false} onCheckedChange={checked => handleChangeInput({
                                        target: {
                                            value: checked ? '1' : '0',
                                            name: CarouselSmallEnum.C303_is_active
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)} />
                                    <div>is active</div>
                                </div>
                            </>
                    }
                </AdminModalMiddle>
                <AdminModalFooter>
                    {
                        mode === 'add' ?
                            <Button disabled={isLoading} onClick={add_carousel} >{isLoading ? 'adding...' : 'Add'}</Button>
                            :
                            <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )

}
