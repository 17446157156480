import React, { useState, useRef, useEffect } from 'react';
import './mobile_input.css'
type props = {
    name:string,
    disabled?:boolean,
    className?:string,
    value?:string,
    onChange:React.ChangeEventHandler<HTMLInputElement>
}

const PhoneNumberInput: React.FC<props> = (props) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('+971');
  const dropdownRef = useRef<HTMLButtonElement>(null);

  const handleDropdownToggle = () => {
    setDropdownOpen((prev) => !prev);
  };

  const handleCountryCodeSelect = (code: string) => {
    setSelectedCountryCode(code);
    setDropdownOpen(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`w-full min-w-[200px] ${props.className}`}>
      <div className="relative ">
        <div className="absolute top-2 left-0 flex items-center pl-3">
          <button
            id="dropdownButton"
            className="h-full text-sm flex justify-center items-center bg-transparent text-slate-700 focus:outline-none"
            // onClick={handleDropdownToggle}
            ref={dropdownRef}
          >
            <span id="dropdownSpan">{selectedCountryCode}</span>
            {/* <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-4 w-4 ml-1"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg> */}
          </button>
          <div className="h-6 border-l border-slate-200 ml-2"></div>
          {dropdownOpen && (
            <div
              id="dropdownMenu"
              className="min-w-[150px] overflow-hidden absolute left-0 w-full mt-10 bg-white border border-slate-200 rounded-md shadow-lg z-10"
            >
              <ul id="dropdownOptions">
                {/* <li
                  className="px-4 py-2 text-slate-800 hover:bg-slate-100 text-sm cursor-pointer"
                  onClick={() => handleCountryCodeSelect('+971')}
                >
                  France (+971)
                </li> */}
                {/* <li
                  className="px-4 py-2 text-slate-800 hover:bg-slate-100 text-sm cursor-pointer"
                  onClick={() => handleCountryCodeSelect('+49')}
                >
                  Germany (+49)
                </li>
                <li
                  className="px-4 py-2 text-slate-800 hover:bg-slate-100 text-sm cursor-pointer"
                  onClick={() => handleCountryCodeSelect('+34')}
                >
                  Spain (+34)
                </li>
                <li
                  className="px-4 py-2 text-slate-800 hover:bg-slate-100 text-sm cursor-pointer"
                  onClick={() => handleCountryCodeSelect('+1')}
                >
                  USA (+1)
                </li> */}
              </ul>
            </div>
          )}
        </div>
        <input
          type="number"
          value={props.value}
          name={props.name}
          disabled={props.disabled||false}
          onChange={props.onChange}
          className={`hideNormalInputBehavour w-full h-10 pl-20 bg-transparent placeholder:text-slate-400 text-slate-700 text-sm border border-slate-200 rounded-md px-3 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-400 shadow-sm focus:shadow-md  `}
          placeholder="50 123 4567"
        />
      </div>
    </div>
  );
};

export  {PhoneNumberInput};
