
type customizationOptionType = 'dropDown' | 'normal'


export type CustomizationOption = {
    id: string,
    name: string,
    optionId:string,
    value: string | null, //value is only acceptable if it is dropDown, else it is null
    type: customizationOptionType,
    varientID: string,
    isSlab: boolean | null,
    rate: number | null,       //rate of price hike percentage
    hikePerc: number | null,   //break point of parameter percentage, take percentage of minVal
    hikePercInRate: number | null,     //hike percentage of rate
    minVal: number | null,
    maxVal: number | null,
    order: number,    //order of arrangement
    baseRate:number|null,   //base rate. this rate is addition every time
    isDefault: boolean | null  // only one varient is default true, other variants are null
}

export enum CustomizationOptionKeys {
    id = 'id',
    name = 'name',
    optionId = 'optionId',
    value = 'value',
    type = 'type',
    varientID = 'varientID',
    isSlab = 'isSlab',
    rate = 'rate',
    hikePerc = 'hikePerc',
    hikePercInRate = 'hikePercInRate',
    minVal = 'minVal',
    maxVal = 'maxVal',
    order = 'order',
    baseRate = 'baseRate',
    isDefault = 'isDefault'
}

export enum CustomizationOptionStoredKeys {
    id = 'id',
    name = 'name',
    optionId = 'optionId',
    value = 'value',
    type = 'type',
    varientID = 'varientID',
    isSlab = 'isSlab',
    rate = 'rate',
    hikePerc = 'hikePerc',
    hikePercInRate = 'hikePercInRate',
    minVal = 'minVal',
    maxVal = 'maxVal',
    order = 'order',
    baseRate = 'baseRate',
    isDefault = 'isDefault',
    Inputvalue= 'Inputvalue'

}


export type CustomizationOptionStored = CustomizationOption & {
    Inputvalue:number
}

export type CartLocalstorateType = {
    productItemId:string,
    quantity:number,
    isActive:1|0,    //ticked or not
    customizationOption?:CustomizationOptionStored[],
}

export type customerLoggedInLocalStorageType = '1'|'0'



export type localStorageType = {

    accesstocken:string,
    user_mode:'customer'|'employee',
    customerCart:CartLocalstorateType[],
    customerLoggedIn:customerLoggedInLocalStorageType           //customer loggedin or not

}


export enum localStorageKeys  {
    accesstocken = 'accesstocken',
    user_mode = 'user_mode',
    customerCart = 'customerCart',
    customerLoggedIn = 'customerLoggedIn'
}