import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

export  function TabProfile({to,name}:{to:string,name:string}) {

    const [isSelected,setIsSelected] = useState(false)

  return (
    <div className=' relative w-min px-3 font-semibold text-gray-600 h-full flex items-center'>
        <NavLink to={to} className={(props)=>{
            setIsSelected(props.isActive)
            return ''
        }}>{name}</NavLink>
        {isSelected&&<div className=' absolute left-0 right-0 bottom-0 h-1 bg-blue-gray-500 rounded-t-[10px]'></div>}
    </div>
  )
}
