import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const ProductSkeleton = () => {
  return (
    <div className="md2:flex-row flex-col flex justify-end px-4 lg:px-16 py-6">
      <div className="relative md2:sticky md2:top-10 h-min">
        <div className="lg:h-[400px] h-[350px] w-min md2:flex hidden overflow-hidden rounded-lg md2:gap-1">
          <div className="relative w-20 h-full border-r rounded-lg overflow-hidden">
            <Skeleton height={80} width={80} count={4} />
          </div>
          <div className="h-[350px] lg:h-[400px] w-[350px] lg:w-[400px] relative rounded-lg bg-white overflow-hidden shadow-lg">
            <Skeleton height="100%" width="100%" />
          </div>
        </div>
        <div className="md2:hidden block">
          <div className="relative bg-transparent aspect-1 h-[350px] w-full rounded-lg overflow-hidden" role="region" aria-roledescription="carousel">
            <Skeleton height="100%" width="100%" />
          </div>
        </div>
      </div>
      <div className="md2:pl-6 grow lg:grid md2:max-w-[60%] lg:grid-cols-1 lg:grid-rows-[auto,auto,1fr] gap-2">
        <div className="lg:col-span-2">
          <Skeleton height={30} width="60%" />
        </div>
        <div className="mt-4 lg:row-span-3 lg:mt-0">
          <div className="flex flex-col">
            <Skeleton height={25} width="40%" />
            <Skeleton height={30} width="60%" />
            <Skeleton height={20} width="50%" />
          </div>
          <div className="w-full max-w-md flex flex-col mt-4">
            <Skeleton height={25} width="50%" />
            <div className="mt-2">
              <Skeleton height={35} width="100%" />
            </div>
            <div className="mt-2">
              <Skeleton height={35} width="100%" />
            </div>
            <Skeleton height={25} width="50%" />
          </div>
          <form className="mt-10">
            <div className="mt-4">
              <Skeleton height={25} width="50%" />
              <div className="flex gap-2 mt-2">
                <Skeleton height={35} width={53} count={3} />
              </div>
            </div>
            <Skeleton height={25} width="50%" />
            <div className="w-full flex gap-2 fixed bottom-1 md2:relative right-0 left-0 px-2 md2:px-0">
              <Skeleton height={50} width="100%" />
              <Skeleton height={50} width="100%" />
            </div>
          </form>
        </div>
        <div className="py-10 lg:col-span-2 lg:col-start-1 lg:border-gray-200 lg:pb-16 lg:pr-8 lg:pt-6">
          <Skeleton height={30} width="30%" />
          <div className="space-y-6">
            <Skeleton height={20} count={4} />
          </div>
          <div className="mt-5">
            <Skeleton height={30} width="30%" />
            <div className="space-y-6">
              <Skeleton height={20} count={4} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export  {ProductSkeleton};
