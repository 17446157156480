
export type Country = {
    M03_CountryID: number | string,
    M03_CountryName: string,
    M03_M05_CurrencyID: number | string
}


export type employee = {
    M15_Employee_id?: number | string,
    email: string,
    M15_Name: string,
    M15_Phone: string,
    M15_Password?: string,
    M15_Hire_date: string,
    M15_M13_role_id: number | string | undefined,
    M15_is_active: number,
    M15_created_by: number | string,
    created_at?: string,
    updated_at?: string,
    deleted_at?: string | null,
    M15_EmployeeCode: string,
    M15_M15_ReportingTo: number | string,
    M15_M03_CountryID: number | string,
    M15_I01_warehouseId?: number | string,
    M15_Designation: string,
    M15_DOB: string,
    M15_MaritalStatus: string,
    M15_Gender: string,
    M15_Qualification: string,
    M15_Address: string,
    M15_JoiningDate: string,
    M15_TerminationDate?: string,
    M15_BloodGroup: string,
    M15_AllowLogin: number,
    M15_OTP?: string,
    M15_OTP_expire_time?: string
}


export type CancelReasonCustomer = {
    I33_reason_id: string | number;
    I33_reason: string;
    I33_is_presetted: number;
    I33_M08_product_item_id: string | number;
    I33_is_active: number;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
};

export type TaxType = {
    T01_tax_type_id?: string | number;
    T01_tax_type_name: string;
    T01_M03_country_id: string | number;
    T01_is_active: 1|0;
}

export type Shipper = {
    I07_Shipper_id?: string | number;
    I07_Shipper_name: string;
    I07_image_url: string | CustomFile; // Optional field
    I07_Shipper_address_line_1?: string;
    I07_Shipper_address_line_2?: string; // Optional field
    I07_Shipper_city?: string;
    I07_Shipper_state_province?: string;
    I07_Shipper_postal_code?: string;
    I07_Shipper_country_code?: string;
    I07_Shipper_contact_name?: string;
    I07_Shipper_contact_email?: string;
    I07_Shipper_contact_phone?: string;
    I07_Shipper_tracking_url_template?: string; // Optional field
    I07_is_active: 1 | 0;
};


export type TaxRates = {
    T02_tax_rate_id?:string|number;
    T02_T01_tax_type_id:string|number;
    T02_tax_rate:number;
    T02_is_gross_taxable:1|0;
    T01_is_active:1|0;
}



export type role = {
    M13_Role_Id: number | string;
    M13_RoleName: string;
    FM13_Status: string;
    FM13_StatusDate: string;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
}

export type CustomFile = {
    id: string;
    file: File
}

export type OrderType = {
    I19_order_id: string | number;
    I19_M01_Customer_id: string | number;
    I19_customer_address_name: string;
    I19_customer_address: string;
    I19_description: string;
    I19_phone_number: string;
    I19_email: string;
    I19_M15_cancelled_by: string | number;
    I19_cancel_by_admin: boolean;
    I19_P15_payment_type_id: string | number;
    I19_I33_reason_id: string | number;
    I19_P16_payment_method_id: string | number;
    I19_I20_shiping_method: string;
    I19_Delivery_charge: number;
    I19_total_price: number;
    I19_discount_price: number;
    I19_tax_price: number;
    I19_sale_date: Date;
    I19_completed: boolean;
    I19_cancelled: boolean;
    I19_order_type: string;
    I19_order_source: string;
    I19_I07_ship_via: string;
    I19_I030_order_status: string;
  }
  

export type Product = {
    M11_ProductID?: number | string;
    M11_Name: string;
    M11_Description: string;
    M11_M04_CategoryID: number | string;
    M11_ProductImages: string[] | CustomFile[] | string;
    M11_M14_brand_id: number | string;
    M11_created_by: number | string;
    M11_created_on?: string;
    M11_is_active: number;
    images?: ProductImage[]
}

export type ProductItem = {
    M08_ProductItemID?: number | string;
    M08_ProductItemName: string;
    M08_Description: string;
    M08_M11_ProductID: number | string;
    M08_Vendor_Sku: number | string;
    M08_SKU: number | string;
    // M08_Quantity: number;
    M08_ProductImages: string[] | CustomFile[] | string;
    M08_ProductImage?: string[] | CustomFile[] | CustomFile | string;
    M08_is_customization_available: 1 | 0,
    M08_M12_Supplier_id: number | string;
    M08_is_Promotion_applicable: number;
    M08_M21_product_customizationId?: string | number
    M08_is_active: number;
    images?: ProductItemImage[];
    M08_15_is_created_by: string | number,

}

export type ProductPrice = {
    M10_Product_Price_id?: number | string;
    M10_M08_Product_Item_id: number | string;
    M10_List_price: string;
    M10_T02_Tax_Rate_id: number | string;
    M10_Start_date: string;
    M10_End_date: string;
    M10_MRP_price: string;
    M10_M01_Created_by: number | string;
    M10_Is_Active: number;
    created_at?: null | string;
    updated_at?: null | string;
    deleted_at?: null | string;
};

export type ProductItemHighlights = {
    M24_highlight_id?: string | number,
    M24_M08_product_item_id?: string | number,
    M24_highlight_text: string
}


export type Supplier = {
    M12_Supplier_id: number;
    M12_Name: string;
    M12_Address: string;
    M12_Contact_person: string;
    M12_Phone_number: string;
    M12_City: string;
    M12_M03_Country: number;
    M12_Is_active: number;
    created_at: string;
    updated_at: string;
    deleted_at: null;
}


export type Customer = {
    M01_CustomerID?: string | number;
    email: string;
    M01_CustomerPhone: string;
    M01_CustomerUsername: string;
    M01_CustomerPreferences?: string;
    M01_M18_Role_id?: string;
    M01_Customeris_active?: string | number,
    M01_M20_emirateID: string
};

export type CustomerAddress = {
    M02_CustomerAddressID?: string | number;
    M02_M01_CustomerID?: string | number;
    M02_Name: string;
    M02_phone_no: string;
    M02_StreetNo: string;
    M02_City: string;
    M02_Region: string;
    M02_PostalCode: string;
    M02_M03_CountryID: string | number | null;
    M02_M20_emirateID: string | number;
    M02_IsDefault: 0 | 1;
    M02_email: string;

};

export type ProductImage = {
    P13_ProductImagesId?: string | number;
    P13_M11_Product_id?: number;
    P13Image_path: string;
    created_at?: string; // Can be Date if you want proper date handling
    updated_at?: string; // Can be Date if you want proper date handling
    deleted_at?: null | Date; // Allows null or a Date object,
    mode: 'cloude' | 'local'   //image from cloude or uploaded from local
}

export type ProductItemImage = {
    M18_ImageID?: string;
    M18_M08_ProductItemId?: string;
    M18_ImagePath: string;
    M18_IsPrimary?: boolean;
    mode: 'cloude' | 'local'   //image from cloude or uploaded from local
};


// 

export type ProductCategory = {
    M04_ProductCategoryId?: number | string | null;
    M04_ProductCategoryName: string;
    M04_ProductCategoryImage: string | CustomFile;
    M04_ProductCategoryMetaTitle: string;
    M04_ProductCategorySlug: string;
    M04_ParentCategoryId?: number | string | null;
    M04_is_active: 1 | 0
}


//   
export type Brand = {
    M14_Brand_id?: number | string;
    M14_Title: string;
    M14_Summary: string;
    M14_is_brand_promoted: 1 | 0,
    M14_Brand_image: string | CustomFile;
    M14_Created_at?: string; // Assuming this is a string representation of a date (e.g., '2024-04-22')
    M14_Updated_at?: string; // Assuming this is a string representation of a date (e.g., '2024-04-22')
    M14_Content: string;
    M14_is_Active: 1 | 0;
    M14_M15_created_by: string | number
}

export enum BrandKeys {
    M14_Brand_id = "M14_Brand_id",
    M14_Title = "M14_Title",
    M14_Summary = "M14_Summary",
    M14_is_brand_promoted = "M14_is_brand_promoted",
    M14_Brand_image = "M14_Brand_image",
    M14_Created_at = "M14_Created_at",
    M14_Updated_at = "M14_Updated_at",
    M14_Content = "M14_Content",
    M14_M15_created_by = 'M14_M15_created_by',
    M14_is_Active = 'M14_is_Active'
}

export enum ShipperKeys {
    I07_Shipper_id = "I07_Shipper_id",
    I07_Shipper_name = "I07_Shipper_name",
    I07_Shipper_address_line_1 = "I07_Shipper_address_line_1",
    I07_Shipper_address_line_2 = "I07_Shipper_address_line_2",
    I07_Shipper_city = "I07_Shipper_city",
    I07_Shipper_state_province = "I07_Shipper_state_province",
    I07_image_url = "I07_image_url",
    I07_Shipper_postal_code = "I07_Shipper_postal_code",
    I07_Shipper_country_code = "I07_Shipper_country_code",
    I07_Shipper_contact_name = "I07_Shipper_contact_name",
    I07_Shipper_contact_email = "I07_Shipper_contact_email",
    I07_Shipper_contact_phone = "I07_Shipper_contact_phone",
    I07_Shipper_tracking_url_template = "I07_Shipper_tracking_url_template",
    I07_is_active = "I07_is_active"
}


// 
export type ProductVariation = {
    M06_VariationID?: number | string;
    M06_M04_CategoryID: number | string;
    M06_Name: string;
    M06_M11_ProductID?: number | string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
}

export type Warehouse = {
    I01_Warehouse_Id?: string | number;
    I01_Warehouse_name: string;
    I01_Warehouse_email: string;
    I01_Warehouse_address: string;
    I01_Warehouse_incharge_employee_id?: string | number;
    I01_Warehouse_capacity: number;
    I01_Warehouse_is_active: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
};

export type Store = {
    I02_Store_id?: number | string;
    I02_Store_name: string;
    I02_I01_Warehouse_Id: number | string;
    I02_Is_Active: number;
    I02_M15_created_by: any;
    created_at?: any;
    updated_at?: string;
    deleted_at?: any;
};

export type Rack = {
    I03_Warehouse_RackId?: number | string;
    I03_Warehouse_Rackname: string;
    I03_I02_Warehouse_store_id: number | string;
    I03_I01_Warehouse_id: number | string;
    I03_is_active: 1 | 0;
    I03_M15_created_by: number | string;
    created_at?: string;
    updated_at?: string;
    deleted_at?: null | string;
};


export type PurchaseOrder = {
    I12_purchase_order_id?: string | number;
    I12_M12_supplier_id: string | number;
    I12_order_date: string;
    I12_M01_created_by: string | number;
    I12_total_amount: number;
    I12_I030_order_status_id: string | number | null;
    I12_is_active: number;
};

export type PurchaseOrderLine = {
    I22_Purchase_order_line_id?: string | number;
    I22_M08_Product_item_id?: string | number;
    I22_I12_Purchase_order_id?: string | number;
    I22_Order_quantity: number;
    I22_Received_quantity: number | null;
    I22_Price: number | null;
    I22_Total_cost: number | null;
    I22_I030_order_status_id?: string | number;
    I22_Is_active: number;
};

export type ShoppingCart = {
    C01_shoping_cart_ID?: string | number;  // Assuming it's a string, adjust type if needed
    C01_M01_customerID?: string | number;   // Assuming it's a string, adjust type if needed
    C01_created_at?: string;         // Assuming it's a Date object, adjust type if needed
    C01_is_active: boolean;       // Assuming it's a boolean, adjust type if needed
}



export type ShoppingCartItem = {
    C02_shoping_cart_item_ID?: string | number;
    C02_C01_cart_id?: string | number;
    C02_M08_product_item_ID: string | number;
    C02_quantity: number;
    C02_is_active: 1 | 0;
};

export type Carousel = {
    C301_carousel_id?: string | number;
    C301_name: string;
    C301_image_path: string | CustomFile;
    C301_small_screen_image: string | CustomFile;
    C301_C302_carousel_type: string | number;
    C301_image_most_used_color: string;
    C301_small_screen_image_most_color: string;
    C301_order_index?: number | null;
    C301_P309_promotion_group_id?: string | number | null;
    C301_is_active: 1|0;
};

export type CarouselSmall = {
    C303_carousel_id?: string;
    C303_name: string;
    C303_source_path: string | CustomFile;
    C303_C302_carousel_type: string | number;
    C303_carousel_duration: number;
    C303_order_index: number | null;
    C303_P309_promotion_group_id: string | number | null;
    C303_is_active: 1 | 0;
};

export enum CarouselSmallEnum {
    C303_carousel_id = 'C303_carousel_id',
    C303_name = 'C303_name',
    C303_source_path = 'C303_source_path',
    C303_C302_carousel_type = 'C303_C302_carousel_type',
    C303_carousel_duration = 'C303_carousel_duration',
    C303_order_index = 'C303_order_index',
    C303_P309_promotion_group_id = 'C303_P309_promotion_group_id',
    C303_is_active = 'C303_is_active',
};


export type CarouselType = {
    C302_carousel_type_id?: string | number;
    C302_name: string
}

export type ProductVariationOption = {
    M07_VariationOptionID?: number | string;
    M07_Value: string;
    M07_M06_VariationID?: number | string;
    M07_M011_ProductId?: number | string;
}

export type StockItem = {
    I04_Stock_id?: string | number;
    I04_I08_bin_id: string | number;
    I04_I02_Store_id: string | number;
    I04_M08_product_item_id: string | number;
    I04_I26_Stock_Type_id: string | number;
    I04_Quantity: number | undefined;
    I04_M15_CreatedBy: string;
    I04_is_product_serial_available: number;
    I04_Reserved_quantity: number;
    I04_Is_active: number;
};

export interface CustomerStock {
    I06_Customer_Stock_id?: string | number;
    I06_I04_Stock_id?: string | number;
    I06_M08_Product_item_id: string | number;
    I06_Available_quantity: number;
    I06_Batch: string;
    I06_Allow_reorder: number;
    I06_Reorder_level: number;
    I06_Is_active: number;
    I06_CreatedBy: string | number
}


export type BinItem = {
    I08_Bin_id?: string | number;
    I08_I03_Rack_id?: string | number;
    I08_Name: string;
    I08_M15_created_by: string | number;
    I08_Description: string;
    I08_Capacity: number;
    I03_is_active: 1 | 0;
};



export type pagination = {
    first_page_url?: string;
    from?: number;
    last_page?: number;
    last_page_url?: string;
    next_page_url?: string | null;
    path?: string;
    per_page: number;
    prev_page_url?: string | null;
    to?: number;
    total: number;
}

export type SalesOrder = {
    I19_order_id?: string | number;
    I19_M01_Customer_id: string | number;
    I19_customer_address_name: string;
    I19_email:string;
    I19_phone_number:string;
    I19_customer_address: string;
    I19_P16_payment_method_id: string | number;
    I19_I20_shiping_method: string;
    I19_Delivery_charge: number;
    I19_total_price: number;
    I19_description?:string;
    I19_discount_price: number;
    I19_tax_price:number;
    
    I19_sale_date: string;
    I19_completed: boolean;
    I19_cancelled: boolean;
    I19_order_source: string;
    I19_I07_ship_via: string | number;
    I19_I030_order_status: string | number;
}

export type SalesOrderLine = {
    I21_Order_id?: string | number; // Assuming ID fields are strings, but they could be numbers if preferred
    I21_M08_Product_item_id?: string | number;
    I21_I19_Sales_order_id?: string | number;
    I21_Quantity: number; // Assuming Quantity is a number
    I21_Price: number; // Assuming Price is a number
    I21_I030_order_status: string;
    I21_MRP: number;
    I21_ListPrice: number;
    I21_discount_price: number;
    I21_is_Active: 0 | 1;
}

export type orderStatusFlags = 'ordered' | 'processing' | 'readyToShip' | 'shipped' | 'delivered' | 'bookedService' | 'confirmedService' | 'completedService' | 'cancelled' | 'CancelledPending'


export type OrderStatus = {
    I030_order_status_id?: string | number;
    I030_order_name: orderStatusFlags;
}

export type SalesOrderStatusHistory = {
    I31_status_change_id?: string;
    I31_I19_order_id: string;
    I31_I030_status: string | number;
    I31_status_change_date: string;
};

export type customizationOptionType = 'dropDown' | 'normal'


export type ProductCustomizationOption = {
    M22_customization_option_id?: number | string;
    M22_M21_product_customization_id: number | string;
    M22_customization_name: string;
    M22_value: string;  //value is only acceptable if it is dropDown, else it is null. it is dropdown value
    M22_type: customizationOptionType;
    M22_varientID: string;
    M22_isSlab: 1 | 0; // Assuming this can be of any type as it's null in the example
    M22_rate: string | null; //rate of price hike percentage
    M22_hikePerc: string | null; //break point of parameter percentage, take percentage of minVal
    M22_hikePercInRate: string | null;  //hike percentage of rate
    M22_minVal: string | null; // Assuming this can be of any type as it's null in the example
    M22_maxVal: string | null; // Assuming this can be of any type as it's null in the example
    M22_order: number;  //order of arrangement
    M22_baseRate: number | null; //base rate. this rate is addition every time
    M22_isDefault: 1 | 0; // only one varient is default true, other variants are null
}

export type ProductCustomizationOptionCart = {
    M23_cart_customization_id?: string | number;
    M23_M22_customization_option_id?: string | number;
    M23_inputValue: string;
    M23_C02_shoping_cart_item_id?: string | number;
    M23_customization_name: string;
    M23_value: string | null;
    M23_type: customizationOptionType;
    M23_varientID: string;
    M23_isSlab: 1 | 0;
    M23_rate: string | null;
    M23_hikePerc: string | null;
    M23_hikePercInRate: string | null;
    M23_minVal: string | null;
    M23_maxVal: string | null;
    M23_order: number;
    M23_baseRate: number | null;
};

export type ProductCustomizationOptionOrder = {
    M25_order_customization_id?: number | string;
    M25_M22_customization_option_id?: number | string;
    M25_inputValue: string; // Assuming inputValue is a string
    M25_I21_Order_id?: number | string;
    M25_customization_name: string;
    M25_value: string | null;  // value is only acceptable if it is dropDown, else it is null
    M25_type: customizationOptionType;
    M25_varientID: string;
    M25_isSlab: 1 | 0; // Assuming this can be 1 or 0 based on previous context
    M25_rate: string | null; // rate of price hike percentage
    M25_hikePerc: string | null; // break point of parameter percentage, take percentage of minVal
    M25_hikePercInRate: string | null; // hike percentage of rate
    M25_minVal: string | null; // minimum value
    M25_maxVal: string | null; // maximum value
    M25_order: number; // order of arrangement
    M25_baseRate: number | null; // base rate. this rate is addition every time
};

export type Currency = {
    M05_CurrencyID?: string | number;
    M05_CurrencySymbol: string;
    M05_CurrencyName: string;
    M05_Conversions_rate: number;
    M05_IsActive: number;
    M05_M15_CreatedBy: string | number;
    M05_CreatedDate?: Date;
};

export type Emirates = {
    M20_Emirate_id?: string | number;
    M20_Emirate_name: string
}


export enum TaxTypeEnum {
    T01_tax_type_id = 'T01_tax_type_id',
    T01_tax_type_name = 'T01_tax_type_name',
    T01_M03_country_id = 'T01_M03_country_id',
    T01_is_active = 'T01_is_active'
}

export enum TaxRatesEnum {
    T02_tax_rate_id = 'T02_tax_rate_id',
    T02_T01_tax_type_id = 'T02_T01_tax_type_id',
    T02_tax_rate = 'T02_tax_rate',
    T02_is_gross_taxable = 'T02_is_gross_taxable',
    T01_is_active = 'T01_is_active'
}


export enum ProductCustomizationOptionKeys {
    M22_customization_option_id = "M22_customization_option_id",
    M22_M21_product_customization_id = "M22_M21_product_customization_id",
    M22_customization_name = "M22_customization_name",
    M22_value = "M22_value",
    M22_type = "M22_type",
    M22_varientID = "M22_varientID",
    M22_isSlab = "M22_isSlab",
    M22_rate = "M22_rate",
    M22_hikePerc = "M22_hikePerc",
    M22_hikePercInRate = "M22_hikePercInRate",
    M22_minVal = "M22_minVal",
    M22_maxVal = "M22_maxVal",
    M22_order = "M22_order",
    M22_baseRate = "M22_baseRate",
    M22_isDefault = "M22_isDefault"
}

export enum CartProductCustomizationOptionKeys {
    M23_cart_customization_id = 'M23_cart_customization_id',
    M23_M22_customization_option_id = 'M23_M22_customization_option_id',
    M23_inputValue = 'M23_inputValue',
    M23_C02_shoping_cart_item_id = 'M23_C02_shoping_cart_item_id',
    M23_customization_name = 'M23_customization_name',
    M23_value = 'M23_value',
    M23_type = 'M23_type',
    M23_varientID = 'M23_varientID',
    M23_isSlab = 'M23_isSlab',
    M23_rate = 'M23_rate',
    M23_hikePerc = 'M23_hikePerc',
    M23_hikePercInRate = 'M23_hikePercInRate',
    M23_minVal = 'M23_minVal',
    M23_maxVal = 'M23_maxVal',
    M23_order = 'M23_order',
    M23_baseRate = 'M23_baseRate'
}


export enum CustomerStockKeys {
    I06_Customer_Stock_id = "I06_Customer_Stock_id",
    I06_I04_Stock_id = "I06_I04_Stock_id",
    I06_M08_Product_item_id = "I06_M08_Product_item_id",
    I06_Available_quantity = "I06_Available_quantity",
    I06_Batch = "I06_Batch",
    I06_Allow_reorder = "I06_Allow_reorder",
    I06_Reorder_level = "I06_Reorder_level",
    I06_Is_active = "I06_Is_active",
    I06_M15_CreatedBy = "I06_CreatedBy",

}

export const enum employeeKeys {
    M15_Employee_id = "M15_Employee_id",
    email = "email",
    M15_Name = "M15_Name",
    M15_Phone = "M15_Phone",
    M15_Password = "M15_Password",
    M15_Hire_date = "M15_Hire_date",
    M15_M13_Role_id = "M15_M13_role_id",
    M15_Is_Active = "M15_is_active",
    M15_created_by = "M15_created_by",
    created_at = "created_at",
    updated_at = "updated_at",
    deleted_at = "deleted_at",
    M15_Email = "M15_Email",
    M15_EmployeeCode = "M15_EmployeeCode",
    M15_M15_ReportingTo = "M15_M15_ReportingTo",
    M15_M03_CountryID = "M15_M03_CountryID",
    M15_I01_warehouseId = "M15_I01_warehouseId",
    M15_Designation = "M15_Designation",
    M15_DOB = "M15_DOB",
    M15_MaritalStatus = "M15_MaritalStatus",
    M15_Gender = "M15_Gender",
    M15_Qualification = "M15_Qualification",
    M15_Address = "M15_Address",
    M15_JoiningDate = "M15_JoiningDate",
    M15_TerminationDate = "M15_TerminationDate",
    M15_BloodGroup = "M15_BloodGroup",
    M15_AllowLogin = "M15_AllowLogin",
    M15_OTP = "M15_OTP",
    M15_OTP_expire_time = "M15_OTP_expire_time"
}

export enum ProductVariationOptionKeys {
    M07_VariationOptionID = "M07_VariationOptionID",
    M07_Value = "M07_Value",
    M07_M06_VariationID = "M07_M06_VariationID",
    M07_M11_ProductID = "M07_M011_ProductId"
}


export enum StockKeys {
    I04_Stock_id = "I04_Stock_id",
    I04_I08_bin_id = "I04_I08_bin_id",
    I04_I02_Store_id = "I04_I02_Store_id",
    I04_M08_product_item_id = "I04_M08_product_item_id",
    I04_I26_Stock_Type_id = "I04_I26_Stock_Type_id",
    I04_Quantity = "I04_Quantity",
    I04_M15_CreatedBy = "I04_M15_CreatedBy",
    I04_is_product_serial_available = "I04_is_product_serial_available",
    I04_Reserved_quantity = "I04_Reserved_quantity",
    I04_Is_active = "I04_Is_active",
}


export enum ProductPriceKeys {
    M10_Product_Price_id = 'M10_Product_Price_id',
    M10_M08_Product_Item_id = 'M10_M08_Product_Item_id',
    M10_List_price = 'M10_List_price',
    M10_T02_Tax_Rate_id = 'M10_T02_Tax_Rate_id',
    M10_Start_date = 'M10_Start_date',
    M10_End_date = 'M10_End_date',
    M10_MRP_price = 'M10_MRP_price',
    M10_M01_Created_by = 'M10_M01_Created_by',
    M10_Is_Active = 'M10_Is_Active',
    created_at = 'created_at',
    updated_at = 'updated_at',
    deleted_at = 'deleted_at',
}


export enum CustomerKeys {
    M01_CustomerID = "M01_CustomerID",
    email = "email",
    M01_CustomerPhone = "M01_CustomerPhone",
    M01_CustomerUsername = "M01_CustomerUsername",
    M01_CustomerPreferences = "M01_CustomerPreferences",
    M01_M19_Role_id = "M01_M19_Role_id",
    M01_M20_emirateID = "M01_M20_emirateID"
}

export enum CustomerAddressKeys {
    M02_CustomerAddressID = "M02_CustomerAddressID",
    M02_M01_CustomerID = "M02_M01_CustomerID",
    M02_StreetNo = "M02_StreetNo",
    M02_City = "M02_City",
    M02_Region = "M02_Region",
    M02_PostalCode = "M02_PostalCode",
    M02_M03_CountryID = "M02_M03_CountryID",
    M02_M20_emirateID = 'M02_M20_emirateID',
    M02_IsDefault = "M02_IsDefault",
    M02_name = 'M02_Name',
    M02_phone_no = 'M02_phone_no',
    M02_email = 'M02_email'
}

export const enum ProductKeys {
    M11_Product_id = "M11_ProductID",
    M11_Name = "M11_Name",
    M11_Description = "M11_Description",
    M11_M04_CategoryID = "M11_M04_CategoryID",
    M11_ProductImages = "M11_ProductImages",
    M11_M14_brand_id = "M11_M14_brand_id",
    M11_created_by = 'M11_created_by',
    M11_created_on = "M11_created_on",
    M11_is_active = "M11_is_active",
}

export const enum ProductItemKeys {
    M08_ProductItemID = "M08_ProductItemID",
    M08_ProductItemName = "M08_ProductItemName",
    M08_M11_ProductID = "M08_M11_ProductID",
    M08_Vendor_Sku = "M08_Vendor_Sku",
    M08_SKU = "M08_SKU",
    M08_Description = 'M08_Description',
    // M08_Quantity = "M08_Quantity",
    M08_ProductImages = "M08_ProductImages",
    M08_ProductImage = 'M08_ProductImage',
    // M08_purchase_price = "M08_purchase_price",
    M08_M12_Supplier_id = "M08_M12_Supplier_id",
    M08_is_Promotion_applicable = "M08_is_Promotion_applicable",
    M08_is_active = "M08_is_active",
    M08_15_is_created_by = 'M08_15_is_created_by',
    M08_is_customization_available = "M08_is_customization_available"
}



export enum ProductCategoryKeys {
    M04_ProductCategoryId = 'M04_ProductCategoryId',
    M04_ProductCategoryName = 'M04_ProductCategoryName',
    M04_ProductCategoryImage = 'M04_ProductCategoryImage',
    M04_ProductCategoryMetaTitle = 'M04_ProductCategoryMetaTitle',
    M04_ProductCategorySlug = 'M04_ProductCategorySlug',
    M04_ParentCategoryId = 'M04_ParentCategoryId',
    M04_is_active = 'M04_is_active'
}



export enum CarouselKeys {
    C301_carousel_id = "C301_carousel_id",
    C301_name = "C301_name",
    C301_image_path = "C301_image_path",
    C301_small_screen_image = "C301_small_screen_image",
    C301_C302_carousel_type = "C301_C302_carousel_type",
    C301_image_most_used_color = "C301_image_most_used_color",
    C301_small_screen_image_most_color = "C301_small_screen_image_most_color",
    C301_order_index = "C301_order_index",
    C301_P309_promotion_group_id = "C301_P309_promotion_group_id",
    C301_is_active = "C301_is_active"
}


export enum ShoppingCartItemKey {
    C02_shoping_cart_item_ID = "C02_shoping_cart_item_ID",
    C02_C01_cart_id = "C02_C01_cart_id",
    C02_M08_product_item_ID = "C02_M08_product_item_ID",
    C02_quantity = "C02_quantity",
    C02_is_active = "C02_is_active"
};






export enum PurchaseOrderKeys {
    I12_purchase_order_id = 'I12_purchase_order_id',
    I12_M12_supplier_id = 'I12_M12_supplier_id',
    I12_order_date = 'I12_order_date',
    I12_M01_created_by = 'I12_M01_created_by',
    I12_total_amount = 'I12_total_amount',
    I12_I030_order_status_id = 'I12_I030_order_status_id',
    I12_is_active = 'I12_is_active'
};

export enum PurchaseOrderLineKeys {
    I22_Purchase_order_line_id = 'I22_Purchase_order_line_id',
    I22_M08_Product_item_id = 'I22_M08_Product_item_id',
    I22_I12_Purchase_order_id = 'I22_I12_Purchase_order_id',
    I22_Order_quantity = 'I22_Order_quantity',
    I22_Received_quantity = 'I22_Received_quantity',
    I22_Price = 'I22_Price',
    I22_Total_cost = 'I22_Total_cost',
    I22_I030_order_status_id = 'I22_I030_order_status_id',
    I22_Is_active = 'I22_Is_active'
};

export enum BinItemKeys {
    I08_Bin_id = "I08_Bin_id",
    I08_I03_Rack_id = "I08_I03_Rack_id",
    I08_Name = "I08_Name",
    I08_M15_created_by = "I08_M15_created_by",
    I08_Description = "I08_Description",
    I08_Capacity = "I08_Capacity",
    I03_is_active = "I03_is_active"
}


export enum RackKeys {
    I03_Warehouse_RackId = "I03_Warehouse_RackId",
    I03_Warehouse_Rackname = "I03_Warehouse_Rackname",
    I03_I02_Warehouse_store_id = "I03_I02_Warehouse_store_id",
    I03_I01_Warehouse_id = "I03_I01_Warehouse_id",
    I03_is_active = "I03_is_active",
    I03_M15_created_by = "I03_M15_created_by",
    created_at = "created_at",
    updated_at = "updated_at",
    deleted_at = "deleted_at",
}

export enum StoreKeys {
    I02_Store_id = "I02_Store_id",
    I02_Store_name = "I02_Store_name",
    I02_I01_Warehouse_Id = "I02_I01_Warehouse_Id",
    I02_Is_Active = "I02_Is_Active",
    I02_M15_created_by = "I02_M15_created_by",
    created_at = "created_at",
    updated_at = "updated_at",
    deleted_at = "deleted_at",
}


export enum WarehouseKeys {
    I01_Warehouse_Id = 'I01_Warehouse_Id',
    I01_Warehouse_name = 'I01_Warehouse_name',
    I01_Warehouse_email = 'I01_Warehouse_email',
    I01_Warehouse_address = 'I01_Warehouse_address',
    I01_Warehouse_incharge_employee_id = 'I01_Warehouse_incharge_employee_id',
    I01_Warehouse_capacity = 'I01_Warehouse_capacity',
    I01_Warehouse_is_active = 'I01_Warehouse_is_active',
    created_at = 'created_at',
    updated_at = 'updated_at',
    deleted_at = 'deleted_at',
}

export enum ProductVariationKeys {
    M06_VariationID = "M06_VariationID",
    M06_M04_CategoryID = "M06_M04_CategoryID",
    M06_M11_ProductID = "M06_M11_ProductID",
    M06_Name = "M06_Name",
    created_at = "created_at",
    updated_at = "updated_at",
    deleted_at = "deleted_at"
}





export enum ProductCustomizationEnum {
    M17_ProductCustomizationID = 'M17_ProductCustomizationID',
    M17_M08_ProductItemID = 'M17_M08_ProductItemID',
    M17_customization_option = 'M17_customization_option',
    M17_price = 'M17_price',
    M17_unit = 'M17_unit'
}

