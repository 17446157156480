import React from 'react'
import { useRESTapi } from './rest_API'
import { useAlertModal } from './alert_modal';
import { Button } from '@/src/components/ui/button';

type calculateTaxType  = {
    netAmount:number,
    grossAmount:number
}



type response = {
    message:string,
    success:boolean,
    data?:{
        tax_amount:number
    }
}

export  function useTaxCalculation() {


    const api = useRESTapi();
    const alertModal = useAlertModal()


    const calculateTax = async ({
        grossAmount,
        netAmount
    }:calculateTaxType):Promise<response>=>{



        try {
            const res = await api.post<{ success: boolean, message: string,data:{tax_amount:number} }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/calculate_tax`,
                mode: 'customerPrivate',
                body:{
                    gross_amount:grossAmount,
                    net_amount:netAmount
                }
            })
            if(res.data.success===true){
                
                return {
                    message:res.data.message,
                    success:true,
                    data:res.data.data
                }
            }else{
                return {
                    message:res.data.message,
                    success:false,
                }
            }
        } catch (error) {
            console.log(error)
            alertModal.open_alert_modal({
                content: 'Server Error',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
              })
        }

        return {
            message:'Server Error',
            success:false,
        }

    }


  return {
    calculateTax
  }
}
