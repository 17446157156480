
import { customizationOptionType } from '@/src/types';
import { useEffect, useRef } from 'react'
import AddOptionModal from './components/modal/add_variation_modal';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/src/components/ui/tabs';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '@/src/components/ui/card';

import { AddVariationOptionModal } from './components/modal/add_variation_option_modal';
import { v4 as uuidv4 } from 'uuid';
import { Step2Component } from './components/step2component';


import React, { useState } from 'react';
import { Button } from '@/src/components/ui/button';
import { Label } from '@/src/components/ui/label';
import { Input } from '@/src/components/ui/input';
import { Textarea } from '@/src/components/ui/textarea';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { useRESTapi } from '@/src/hooks/rest_API';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { Loader2Icon } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@/src/redux/store';
import { PhoneNumberInput } from '@/src/components/Inputs/mobile_input';



export type ProductCustomizationOptionClient = {
    customization_name: string;
    value: string | null;  //value is only acceptable if it is dropDown, else it is null
    type: customizationOptionType;
    varientID: string;
    isSlab: 1 | 0; // Assuming this can be of any type as it's null in the example
    rate: number | null; //rate of price hike percentage
    hikePerc: number | null; //break point of parameter percentage, take percentage of minVal
    hikePercInRate: number | null;  //hike percentage of rate
    minVal: number | null; // Assuming this can be of any type as it's null in the example
    maxVal: number | null; // Assuming this can be of any type as it's null in the example
    order: number | null;  //order of arrangement
    baseRate: number | null; //base rate. this rate is addition every time
    isDefault: 1 | 0; // only one varient is default true, other variants are null
}

export enum ProductCustomizationOptionEnumClient {
    customization_name = "customization_name",
    value = "value",
    type = "type",
    varientID = "varientID",
    isSlab = "isSlab",
    rate = "rate",
    hikePerc = "hikePerc",
    hikePercInRate = "hikePercInRate",
    minVal = "minVal",
    maxVal = "maxVal",
    order = "order",
    baseRate = "baseRate",
    isDefault = "isDefault",
}

type customizationVariation = {
    index: number,
    variationName: string,
    selectedValue: string,
    variationOptions: ProductCustomizationOptionClient[]
}

type customizationVariationLevels = customizationVariation[]
type step = 'variationStep' | 'optionStep'




type FormDataType = {
    name: string;
    address: string;
    phoneNo: string;
    email: string;
    description: string;
    order_source: string;
    product: {
        productItemId: string;
    };
};

export function CustomServicePage() {
    const api = useRESTapi();
    const [loading, setIsLoading] = useState(false);
    const navigate = useNavigate()
    const { close_alert_modal, open_alert_modal } = useAlertModal();
    const customerData = useSelector((state: RootState) => state.customer.customerData)

    const [formData, setFormData] = useState<FormDataType>({
        address: '',
        description: '',
        email: '',
        name: '',
        order_source: 'e-commerce website',
        phoneNo: '',
        product: {
            productItemId: '60000'
        }
    });

    const validationObject = {
        address: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required';
                    }
                    return true;
                }
            ]
        },
        description: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required';
                    }
                    return true;
                }
            ]
        },
        email: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required';
                    }
                    return true;
                }
            ]
        },
        name: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required';
                    }
                    return true;
                }
            ]
        },
        order_source: {
            message: '',
            validation: [
                (value) => {
                    if (!value) {
                        return 'required';
                    }
                    return true;
                }
            ]
        },
        phoneNo: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required';
                    }
        
                    // UAE phone number validation
                    const uaePhoneNumberRegex = /^(\+971|0)?(50|51|52|54|55|56|58)\d{7}$/;
                    if (!uaePhoneNumberRegex.test(value)) {
                        return 'Invalid UAE phone number';
                    }
        
                    return true;
                }
            ]
        },
    } satisfies validationObject;

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<FormDataType>({
        validationObject: validationObject
    });

    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        const formdata = {
            ...formData,
            [e.target.name]: e.target.value
        };

        performValidationOf(e.target.name, formdata);
        setFormData(formdata);
    }

    async function bookService() {
        if (performAllValidation(formData) === true) {
            setIsLoading(true);

            const res = await api.post<{ success: boolean; message: string; errors: any }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/services`,
                mode: 'customerPrivate',
                body: {
                    address: formData.address,
                    name: formData.name,
                    email: formData.email,
                    phoneNo: formData.phoneNo,
                    description: formData.description,
                    order_source: formData.order_source,
                    product: {
                        productItemId: formData.product.productItemId
                    },
                    userId: customerData.M01_CustomerID
                }
            });

            if (res.data.success === true) {
                console.log('Service Booked');
                // updateUi();
                navigate('/')
                open_alert_modal({
                    content: 'Service Booked Successfully',
                    footer: <div><Button onClick={() => {
                        close_alert_modal();
                    }}>Ok</Button></div>,
                    title: 'Service Booked'
                });
            } else {
                if (res.data.errors) {
                    const errs = res.data.errors;
                    const errData: any = {};

                    for (const key in errs) {
                        errData[key] = errs[key][0];
                    }

                    setErrorData(errData);
                } else {
                    open_alert_modal({
                        content: res.data.message,
                        footer: <div><Button onClick={close_alert_modal}>Ok</Button></div>,
                        title: 'Error'
                    });
                }
            }

            setIsLoading(false);
        }
    }

    return (
        <div className="min-h-screen bg-gradient-to-br from-blue-gray-100 to-blue-gray-100 flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
            <div className="max-w-4xl w-full space-y-8">
                <div className="text-center">
                    <h1 className="text-4xl font-extrabold text-gray-800 mb-2">
                        Custom Service Booking
                    </h1>
                    <p className="text-xl text-gray-800 mb-8">
                        Tailored solutions for your unique needs
                    </p>

                </div>

                <div className="bg-white shadow-2xl rounded-2xl p-8">
                    <h2 className="text-2xl font-bold text-gray-900 mb-6">Book Your Service</h2>
                    <form className="space-y-6" onSubmit={(e) => e.preventDefault()}>
                        <div>
                            <Label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</Label>
                            <Input
                                id="name"
                                disabled={loading}
                                onChange={handleChangeInput}
                                name="name"
                                placeholder="Your full name"
                                className="mt-1"
                            />
                            {errorData.name && <div className="text-red-500 text-sm mt-1">{errorData.name}</div>}
                        </div>
                        <div className=' flex flex-col md:flex-row w-full gap-2 '>
                            <div className=' w-full md:w-1/2'>
                                <Label htmlFor="phoneNo" className="block text-sm font-medium text-gray-700">Phone Number</Label>
                                <PhoneNumberInput
                                    disabled={loading}
                                    onChange={handleChangeInput}
                                    name="phoneNo"
                                    className="mt-1"
                                />
                                {errorData.phoneNo && <div className="text-red-500 text-sm mt-1">{errorData.phoneNo}</div>}
                            </div>
                            <div className=' w-full md:w-1/2'>
                                <Label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</Label>
                                <Input
                                    id="email"
                                    disabled={loading}
                                    onChange={handleChangeInput}
                                    name="email"
                                    placeholder="Your email address"
                                    className="mt-1"
                                />
                                {errorData.email && <div className="text-red-500 text-sm mt-1">{errorData.email}</div>}
                            </div>
                        </div>
                        <div>
                            <Label htmlFor="description" className="block text-sm font-medium text-gray-700">Service Description</Label>
                            <Textarea
                                id="description"
                                disabled={loading}
                                onChange={handleChangeInput}
                                name="description"
                                placeholder="Describe the service you need"
                                className="mt-1"
                                rows={4}
                            />
                            {errorData.description && <div className="text-red-500 text-sm mt-1">{errorData.description}</div>}
                        </div>
                        <div>
                            <Label htmlFor="address" className="block text-sm font-medium text-gray-700">Address</Label>
                            <Textarea
                                id="address"
                                disabled={loading}
                                name="address"
                                onChange={handleChangeInput}
                                placeholder="Your service address"
                                className="mt-1"
                                rows={3}
                            />
                            {errorData.address && <div className="text-red-500 text-sm mt-1">{errorData.address}</div>}
                        </div>
                        <Button
                            disabled={loading}
                            onClick={bookService}
                        >
                            {loading && <Loader2Icon className="mr-2 h-4 w-4 animate-spin" />}
                            Book Now
                        </Button>
                    </form>
                </div>
            </div>
        </div>
    );

}