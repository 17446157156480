import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, ProductCategoryKeys, role, TaxType, TaxRates, TaxTypeEnum, TaxRatesEnum } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { useAlertModal } from '@/src/hooks/alert_modal'
import { Switch } from '@/src/components/ui/switch'
import { Label } from '@/src/components/ui/label'




interface TaxTypeResponse extends TaxType {
    rates: TaxRates[],
}

type imageIds = string[]



export function AddTaxModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow

    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {


    const validationObject = {
        [TaxTypeEnum.T01_tax_type_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        }

    } satisfies validationObject
    const validationObjectTaxRate = {
        [TaxRatesEnum.T02_tax_rate]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        }

    } satisfies validationObject

    const api = useRESTapi();
    const [formData, setFormData] = useState<TaxTypeResponse>({
        T01_tax_type_id: '',
        T01_tax_type_name: '',
        T01_M03_country_id: '',
        T01_is_active: 1,
        rates: [
            {
                T02_tax_rate_id: '',
                T02_T01_tax_type_id: '',
                T02_tax_rate: NaN,
                T02_is_gross_taxable: 0,
                T01_is_active: 1
            }
        ]
    })

    const [formDataTaxRate, SetformDataTaxRate] = useState<TaxRates>({
        T01_is_active: 1,
        T02_is_gross_taxable: 0,
        T02_T01_tax_type_id: '',
        T02_tax_rate: NaN,
        T02_tax_rate_id: ''
    })
    const [selectedTaxRate, setSelectedTaxRateId] = useState<string | number>()

    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<TaxType>({
        validationObject: validationObject
    })

    const validationTaxRate = useFormValidatonCustom<TaxRates>({
        validationObject: validationObjectTaxRate
    })
    const [loading, set_loading] = useState(false)
    const [add_progress, set_add_progress] = useState(0)
    const alertModal = useAlertModal()

    const [isSpinLoading, setSpinLoading] = useState(false)
    const [imageImage, setImageImage] = useState<{
        imageUrl: string,
        mode: 'local' | 'cloude'
    }>({
        imageUrl: '',
        mode: 'local'
    })
    const image_input_ref = useRef<HTMLInputElement>(null)


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: TaxTypeResponse;
        let formdataTaxRate: TaxRates;


        switch (e.target.name) {

            case TaxRatesEnum.T02_tax_rate:
                formdataTaxRate = {
                    ...formDataTaxRate,
                    [e.target.name]: e.target.value as unknown as number

                }

                formdata = {
                    ...formData,
                    // [e.target.name]: e.target.value
                }
                break;
            case TaxRatesEnum.T02_is_gross_taxable:
                formdataTaxRate = {
                    ...formDataTaxRate,
                    [e.target.name]: e.target.value == '1' ? 1 : 0

                }

                formdata = {
                    ...formData,
                    // [e.target.name]: e.target.value
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                formdataTaxRate = {
                    ...formDataTaxRate,

                }
                break;
        }




        setFormData(formdata)
        SetformDataTaxRate(formdataTaxRate)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function add_catogery() {

        setIsLoading(true)
        // formData.M11_created_by = employee.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                console.log(formData)
                Object.entries(formData).map(
                    data => {

                        console.log(data[0])
                        switch (data[0]) {
                            case ProductCategoryKeys.M04_ProductCategoryImage:
                                const images = data[1] as CustomFile
                                formDataReal.append(`${data[0]}`, images.file)
                                break;
                            case ProductCategoryKeys.M04_ProductCategorySlug:
                                formDataReal.append(data[0], String(data[1]) + new Date().toISOString())
                                break;

                            case ProductCategoryKeys.M04_ProductCategoryMetaTitle:
                            case ProductCategoryKeys.M04_ProductCategoryName:
                            case ProductCategoryKeys.M04_ParentCategoryId:
                            case ProductCategoryKeys.M04_is_active:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                        }
                    }

                )



                const res = await api.post<{ success: boolean, data: ProductCategory, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_categories`,
                    body: formDataReal,
                    mode: 'admin',
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / (progressEvent.total || 0))
                        set_add_progress(percentCompleted)
                    }
                })

                if (res.data.success === true) {
                    updateUi();
                    alertModal.open_alert_modal({
                        content: res.data?.message || 'Category Created',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Created'
                    })
                    closeModal()


                } else {
                    alertModal.open_alert_modal({
                        content: res.data?.message || 'something went wrong',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Error'
                    })
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
            set_add_progress(0)
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        if (performAllValidation(formData) === true && validationTaxRate.performAllValidation(formDataTaxRate) === true) {

            try {

                const formDataReal = formData
                formDataReal.rates.forEach(
                    (item, index) => {
                        formDataReal.rates[index].T02_tax_rate = Number(formDataTaxRate.T02_tax_rate) / 100
                        formDataReal.rates[index].T02_is_gross_taxable = formDataTaxRate.T02_is_gross_taxable
                    }
                )


                const res = await api.put<{ success: boolean, data: ProductCategory, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/tax/${id}`,
                    body: formDataReal,
                    mode: 'admin',
                })

                if (res.data.success === true) {

                    updateUi();
                    alertModal.open_alert_modal({
                        content: res.data?.message || 'Category Updated',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Updated'
                    })
                    closeModal()

                } else {

                    alertModal.open_alert_modal({
                        content: res.data?.message || 'something went wrong',
                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                        title: 'Error'
                    })
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
                alertModal.open_alert_modal({
                    content: 'Server Error',
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
            }
            set_add_progress(0)

        }
        setIsLoading(false)

    }

    const load_tax = async () => {

        const res = await api.get<{ success: boolean, data: TaxTypeResponse, message?: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/tax/${id}`,
            mode: 'admin',

        })
        if (res.data.success === true) {

            const taxDetails = res.data.data

            taxDetails.rates.forEach(
                item=>{
                    item.T02_tax_rate = Number(item.T02_tax_rate)*100
                }
            )

            setFormData(
                taxDetails
            )
            if (res.data.data.rates.length) {
                setSelectedTaxRateId(res.data.data.rates[0].T02_tax_rate_id)
                SetformDataTaxRate(res.data.data.rates[0])
            }

        } else {
            throw res.data
        }

    }

    const clear_data = () => {
        setFormData(
            {
                T01_tax_type_id: '',
                T01_tax_type_name: '',
                T01_M03_country_id: '',
                T01_is_active: 1,
                rates: [
                    {
                        T02_tax_rate_id: '',
                        T02_T01_tax_type_id: '',
                        T02_tax_rate: NaN,
                        T02_is_gross_taxable: 0,
                        T01_is_active: 1
                    }
                ]
            }
        )
        setErrorData({})
        validationTaxRate.setErrorData({})
        set_add_progress(0)
        setImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }



    useEffect(
        () => {

            const initListSetup = () => {
                clear_data()
                set_loading(true)


                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)
                clear_data()
                Promise.all(
                    [load_tax()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()

            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )

    const onclick_add_image_input = () => {
        if (isLoading || loading)
            return;
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }


    const delete_image = () => {
        setImageImage({
            imageUrl: '',
            mode: 'local'
        })
    }


    return (
        <AdminModal show={show} setShow={setShow} >
            {

                <AdminModalContent className=''>


                    <AdminModalHeader progressValue={add_progress}>
                        <AdminModalTitle>{mode === 'add' ? 'Add Tax' : 'Edit Tax'}</AdminModalTitle>
                    </AdminModalHeader>
                    <AdminModalMiddle>
                        <div>Category Name</div>
                        <Input disabled={isLoading || loading} value={formData.T01_tax_type_name} onChange={handleChangeInput} type="text" name={TaxTypeEnum.T01_tax_type_name} placeholder='Name' />
                        <div className=' text-red-500'>{errorData.T01_tax_type_name ? errorData.T01_tax_type_name : null}</div>

                        {/* <Input onChange={handleChangeInput} type="file" name={ProductCategoryKeys.M04_ProductCategoryImage} placeholder='Description' /> */}
                        {/* <div className=' text-red-500'>{errorData.M04_ProductCategoryImage as string ? errorData.M04_ProductCategoryImage as string : null}</div> */}
                        <div className=' h-2'></div>
                        {/* <Label>Tax rates</Label> */}
                        <div className=' h-2'></div>
                        {
                            formData.rates.map(
                                item => (
                                    <div className=' flex flex-col gap-1'>
                                        <div>Percent Rate</div>
                                        <Input disabled={isLoading || loading || selectedTaxRate != item.T02_tax_rate_id} value={selectedTaxRate == item.T02_tax_rate_id ? formDataTaxRate.T02_tax_rate : item.T02_tax_rate} onChange={e => {
                                            if (selectedTaxRate == item.T02_tax_rate_id) {
                                                handleChangeInput(e)
                                            }
                                        }} type="number" name={TaxRatesEnum.T02_tax_rate} placeholder='rate' />
                                        {selectedTaxRate == item.T02_tax_rate_id ? <div className=' text-red-500'>{validationTaxRate.errorData.T02_tax_rate ? validationTaxRate.errorData.T02_tax_rate : null}</div> : null}
                                        <div className="h-2"></div>
                                        <div className=' flex gap-1'>
                                            <Switch
                                                disabled={loading || isLoading || selectedTaxRate != item.T02_tax_rate_id}
                                                checked={selectedTaxRate == item.T02_tax_rate_id ? formDataTaxRate.T02_is_gross_taxable == 1 ? true : false : item.T02_is_gross_taxable == 1 ? true : false}
                                                name={TaxRatesEnum.T02_is_gross_taxable}
                                                onCheckedChange={
                                                    (checked) => {
                                                        if (selectedTaxRate == item.T02_tax_rate_id) {
                                                            handleChangeInput({
                                                                target: {
                                                                    name: TaxRatesEnum.T02_is_gross_taxable,
                                                                    value: checked ? 1 : '0',
                                                                    checked: checked
                                                                }
                                                            } as React.ChangeEvent<HTMLInputElement>)
                                                        }
                                                    }
                                                }

                                            // onChange={handleChangeInput}
                                            />
                                            <div>to gross amount</div>
                                        </div>
                                    </div>
                                )
                            )
                        }

                        <div className=' h-2'></div>
                        <div className=' flex gap-1'>
                            <Switch
                                disabled={loading || isLoading}
                                checked={formData.T01_is_active == 1 ? true : false}
                                name={TaxTypeEnum.T01_is_active}
                                onCheckedChange={
                                    (checked) => handleChangeInput({
                                        target: {
                                            name: TaxTypeEnum.T01_is_active,
                                            value: checked ? 1 : '0',
                                            checked: checked
                                        }
                                    } as React.ChangeEvent<HTMLInputElement>)
                                }

                            // onChange={handleChangeInput}
                            />
                            <div>is active</div>
                        </div>
                    </AdminModalMiddle>
                    <AdminModalFooter>
                        <div className=' flex justify-end'>
                            {
                                mode === 'add' ?
                                    <Button disabled={isLoading || loading} onClick={add_catogery} >{isLoading ? 'adding...' : 'Add'}</Button>
                                    :
                                    <Button disabled={isLoading || loading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                        </div>
                    </AdminModalFooter>

                </AdminModalContent>
            }
        </AdminModal>
    )
}
