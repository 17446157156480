import React, { useEffect } from 'react';
import { VideoSection } from './components/top_video';
import { Link } from 'react-router-dom';
import SolutionSection from './components/SolutionSection';
import gsap from "gsap";

import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import HeaderSection from './components/headerSection';
import Footer from '../../components/Footer/Footer';
import ServiceSection from './components/serviceSection';
import ProcessSection from './components/processSection';

gsap.registerPlugin(ScrollTrigger);

const AboutPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="bg-gray-50">
            {/* Header Section */}
            <HeaderSection/>

            {/* Main Image Section */}
            <VideoSection videoSrc='/assets/aboutusPage/aboutusvideo.mp4' />

            {/* Kitchenware Solutions Section */}
            
            <SolutionSection/>


            {/* Services Section */}
            <ServiceSection/>

            {/* Team Section */}
            <section className="container mx-auto py-16">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold">Meet Our Kitchenware Experts</h2>
                </div>
                <div className="grid md:grid-cols-3 gap-8">
                    <div className="bg-gray-200 h-64 rounded"></div> {/* Placeholder for image */}
                    <div className="bg-gray-200 h-64 rounded"></div> {/* Placeholder for image */}
                    <div className="bg-gray-200 h-64 rounded"></div> {/* Placeholder for image */}
                </div>
            </section>

            {/* Case Study Section */}
            <section className="container mx-auto py-16">
                <div className="text-center mb-16">
                    <h2 className="text-3xl font-bold">Our Success Stories</h2>
                    <p className="mt-4 max-w-2xl mx-auto">
                        Discover how our kitchenware solutions have transformed the culinary operations of top hotels and homes alike.
                    </p>
                </div>
                <div className="relative h-64 bg-gray-200 rounded"></div> {/* Placeholder for image */}
            </section>

            {/* Process Section */}
            <ProcessSection/>
            <Footer/>
        </div>
    );
};

export { AboutPage };
