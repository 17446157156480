import { useEffect, useState } from 'react'
import { TablePagination } from '../../../../../components/table'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Product, ProductItem, ProductKeys, StockItem, StockKeys, Store, pagination, Warehouse, WarehouseKeys, Rack, BinItem } from '../../../../../types'
import { AddStockModal } from './component/addmodal'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { Button } from '@/src/components/ui/button'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'
import { DeleteModal } from './component/deleteModal'
import { FilterComponent } from './component/filterComponent'

type Props = {
  setSelectedId: (id: string) => any
}



export type filterObjectType = {
  ProductItemId?: string
}

export enum filterObjectTypeKeys {
  ProductItemId = 'ProductItemId',
  pageNo = 'pageNo'
}

export type StockResponse = StockItem & {
  product_item: Partial<ProductItem>,
  store: Partial<Store & {
    warehouse: Warehouse & (
      { racks: (Rack & { bins: BinItem[] })[] }
    )
  }>
}

type StockOveview = {
  page_number: number,
  stockId: string | number,
  warehouseId: string | number,
  warehouseName: string,
  storeId: string,
  storeName: string,
  quantity: string | number,
  delete: any,
  edit: any,
  // imagePath:string,
  sku: any

}

export function StockCRUD(props: Props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [products, set_products] = useState<StockOveview[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const api = useRESTapi()
  const [showDeleteModal, setshowDeleteModal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(searchParams.get(filterObjectTypeKeys.pageNo) ? Number(searchParams.get(filterObjectTypeKeys.pageNo)) :1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const navigate = useNavigate();
  const [showFilterPopover, setShowFilterPopover] = useState(false)

  const [isOrderLoading, setisOrderLoading] = useState(false)
  const [isDeleteoading, setIsDeleteLoading] = useState(false)

  const [filterObject, setfilterObject] = useState<filterObjectType>({
    ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId) ? searchParams.get(filterObjectTypeKeys.ProductItemId)! : '',
  })

  const header: {
    label: StockKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: 'stockId',
        name: "Id"
      },
      {
        label: 'warehouseName',
        name: "WareHouse Name"
      },
      {
        label: 'sku',
        name: "SKU"
      },
      {
        label: 'quantity',
        name: 'quantity'
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }


  async function delete_product(id: number | string) {

    setIsDeleteLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: StockOveview[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success) {
        setshowDeleteModal(false)
        alert(res.data.message)
      } else {
        alert(res.data.message)
      }


    } catch (error) {
      console.log(error)
    }
    setIsDeleteLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {

      props.setSelectedId('A6')

      const initData = async () => {
        setisOrderLoading(true)
        const res = await api.get<{ success: boolean, data: { data: StockResponse[] } & pagination }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock`,
          params: {
            perPage: per_page,
            page: page_selected,
            ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId),
          },
          mode: 'admin',

        })

        if (res.data.success === true) {
          // console.log(res.data)
          set_products(res.data.data.data.map(
            (item, index) => {

              const stockOne: StockOveview = {
                page_number: ((page_selected - 1) * per_page) + (index + 1),
                quantity: item.I04_Quantity!,
                stockId: item.I04_Stock_id!,
                storeId: String(item.I04_I02_Store_id),
                storeName: String(item.store.I02_Store_name),
                warehouseId: String(item.store.I02_I01_Warehouse_Id),
                warehouseName: item.store.I02_Store_name!,
                delete: <ButtonIcon icon={<Trash className="h-4 w-4" />} onClick={() => { set_selected_product(String(item.I04_Stock_id!)); setshowDeleteModal(true) }}></ButtonIcon>,
                edit: <ButtonIcon icon={<Pencil className="h-4 w-4" />} onClick={() => clickEditButton(String(item.I04_Stock_id))}></ButtonIcon>,
                sku: (
                  <div className=' flex flex-col'>
                    <img className=' h-5 w-5' src={`${process.env.REACT_APP_BACKEND_URL}/${item?.product_item?.M08_ProductImage}`} />
                    <div>{item?.product_item?.M08_ProductItemName}</div>
                  </div>
                )
              }
              return stockOne;
            }
          ))
          set_products_total(res.data.data.total)
          setisOrderLoading(false)
        }

      }

      initData()

    }, [update]
  )

  return (
    <div className='  grow flex flex-col items-center  overflow-hidden py-5 gap-2 px-1 sm:px-5 '>
      <AddStockModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}

      />
      <DeleteModal
        show={showDeleteModal}
        setShow={setshowDeleteModal}
        onDelete={async () => {
          await delete_product(selectedEmployeeId!);
        }}
        isDeleteing={isDeleteoading}
      />
      <div className=' p-2 text-2xl font-bold'>Stock list</div>
      <div className=' w-full flex justify-end gap-2' >
        <FilterComponent
          filterObj={filterObject}
          setFilterObj={setfilterObject}
          updateUi={updateUi}
          show={showFilterPopover}
          setshow={setShowFilterPopover}
        />
        <Button onClick={open_add_modal} className=' '>Add Stock</Button>
      </div>
      <div className='grow overflow-auto  w-full border rounded-md'>
        {
          isOrderLoading
            ?
            <SkeletonTableAdmin />
            :
            <Table className='styled-table w-full'>
              <TableHeader >
                <TableRow>
                  {
                    header.map(
                      (header, index) => <TableHead key={index}>{header.name}</TableHead>
                    )
                  }
                </TableRow>
              </TableHeader>
              <TableBody>
                {
                  products.length === 0
                    ?
                    'No data'
                    :
                    products.map(
                      (row: any, index) => (
                        <TableRow key={index}>
                          {
                            header.map(
                              (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                            )
                          }
                        </TableRow>
                      )
                    )
                }
              </TableBody>
            </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => {
          set_page_selected(e);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.set(filterObjectTypeKeys.pageNo, e ? String(e) : '');
          navigate(`?${newSearchParams.toString()}`, { replace: true });
          updateUi()
        }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
