import React, { useEffect, useState } from 'react';
import { Offcanvas, Ripple, initTWE } from 'tw-elements';
import { IconAdjustments, IconPlus } from '@tabler/icons-react';

type FilterCategory = {
  name: string;
  filters: string[];
};

const BottomModal: React.FC = () => {
  useEffect(() => {
    initTWE({ Offcanvas, Ripple });
  }, []);

  const [filterCategories, setFilterCategories] = useState<FilterCategory[]>([
    {
      name: 'Order Status',
      filters: ['On the way', 'Delivered', 'Cancelled', 'Returned'],
    },
    {
      name: 'Order Time',
      filters: ['Last 1 week', 'Last 30 days', 'Last 1 year'],
    },
  ]);

  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const toggleFilter = (filter: string) => {
    setActiveFilters((prev) =>
      prev.includes(filter) ? prev.filter((f) => f !== filter) : [...prev, filter]
    );
  };

  const isApplyActive = activeFilters.length > 0;

  const handleApplyClick = () => {
    console.log('Apply button clicked');
    console.log('Active filters:', activeFilters);
  };

  return (
    <>
      <button
        type="button"
        data-twe-offcanvas-toggle
        data-twe-target="#offcanvasBottom"
        aria-controls="offcanvasBottom"
        data-twe-ripple-init
        data-twe-ripple-color="light"
        className="inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-primary-3 transition duration-150 ease-in-out hover:bg-primary-accent-300 hover:shadow-primary-2 focus:bg-primary-accent-300 focus:shadow-primary-2 focus:outline-none focus:ring-0 active:bg-primary-600 active:shadow-primary-2 dark:shadow-black/30 dark:hover:shadow-dark-strong dark:focus:shadow-dark-strong dark:active:shadow-dark-strong"
      >
        <IconAdjustments />
      </button>

      <div
        className="invisible fixed bottom-0 left-0 right-0 z-[1045] flex h-1/3 max-h-full max-w-full translate-y-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out data-[twe-offcanvas-show]:transform-none dark:bg-body-dark dark:text-white"
        tabIndex={-1}
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
        data-twe-offcanvas-init
      >
        <div className="flex items-center justify-between p-4">
          <h5 className="mb-0 font-semibold leading-normal" id="offcanvasBottomLabel">
            Filter
          </h5>
          <button
            type="button"
            className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            data-twe-offcanvas-dismiss
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          {filterCategories.map((category) => (
            <div key={category.name} className="mb-4">
              <div className='py-3'>{category.name}</div>
              <div className="flex flex-wrap space-x-2 mb-2">
                {category.filters.map((filter) => (
                  <button
                    key={filter}
                    className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full ${activeFilters.includes(filter) ? 'bg-red-500 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'}`}
                    onClick={() => toggleFilter(filter)}
                  >
                    {filter}
                    {activeFilters.includes(filter) && <IconPlus className="ml-2" />}
                  </button>
                ))}
              </div>
            </div>
          ))}
          <button
            className={`text-white font-bold py-2 px-4 rounded ${isApplyActive ? 'bg-green-500' : 'bg-gray-500'}`}
            onClick={handleApplyClick}
            disabled={!isApplyActive}
          >
            Apply
          </button>
        </div>
      </div>
    </>
  );
};

export default BottomModal;
