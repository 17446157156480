import React, { useState } from "react";
import { Button } from "@/src/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/src/components/ui/dialog";
import { Input } from "@/src/components/ui/input";
import { Label } from "@/src/components/ui/label";

type PropsModal = {
  show: boolean;
  setShow: (show: boolean) => void;
  children: React.ReactNode;
};

export function CustomerModal(props: PropsModal) {
  return (
    <Dialog  open={props.show} onOpenChange={props.setShow}>
      {props.children}
    </Dialog>
  );
}

type PropsContent = {
  children: React.ReactNode;
  className?: string;
};

export function CustomerModalContent(props: PropsContent) {
  return (
    <DialogContent className="p-0 overflow-hidden w-fit h-fit max-w-none gap-0 ">
      {props.children}
    </DialogContent>
  );
}

export function CustomerModalMiddle(props: PropsContent) {
  return (
    <div
      className={`sm:max-h-[80vh] max-h-[80vh] min-w-[100vw] sm:min-w-[600px] sm:max-w-[600px] overflow-auto p-2 sm:p-5 ${props.className}`}
    >
      {props.children}
    </div>
  );
}

type PropsHeader = {
  children: React.ReactNode;
};

export function CustomerModalHeader(props: PropsHeader) {
  return (
    <DialogHeader className="p-2 border-b shadow-sm">
      {props.children}
    </DialogHeader>
  );
}

type PropsTitle = {
  children: React.ReactNode;
};

export function CustomerModalTitle(props: PropsTitle) {
  return <DialogTitle>{props.children}</DialogTitle>;
}

type PropsFooter = {
  children: React.ReactNode;
};

export function CustomerModalFooter(props: PropsFooter) {
  return (
    <DialogFooter className="bg-white m-0 p-2 border-t">
      {props.children}
    </DialogFooter>
  );
}
