import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { ScrollArea } from '@/src/components/ui/scroll-area'
import { Separator } from '@/src/components/ui/separator'
import { ProductItemHighlights } from '@/src/types'
import { Pencil, Plus, Trash } from 'lucide-react'
import React, { useState } from 'react'
import { ModalAddHighlights } from './modalAdd'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { productHighlightOverview, updateHighlightsType } from '../addmodal'

type Props = {
    highlights: productHighlightOverview[],
    setHighlights: (hightlights: productHighlightOverview[]) => any,
    delete_highlights_ids: string[],
    set_delete_highlights_ids: (ids: string[]) => any,
    update_highlights: updateHighlightsType[],
    setUpdate_highlights: (updated_highlights: updateHighlightsType[]) => any,
    disabled: boolean
}

export function HightlightsComponent(props: Props) {

    // const [localHighlights, setLocalHighlights] = useState<ProductItemHighlights[]>([])
    const [showAddModal, setSowAddModal] = useState<boolean>(false)
    const [modalMode, setModalMode] = useState<'add' | 'edit'>('add')
    const [selectedHighlight, setSelectedHighlight] = useState<productHighlightOverview>()
    const [selectedIndex, setSelectedIndex] = useState<number>()
    const addHighlights = (highlight: productHighlightOverview) => {
        props.highlights.push({ ...highlight })
        props.setHighlights([...props.highlights])

    }

    const deletehighlight = (index: number) => {
        const highlight = props.highlights[index]
        if (highlight.mode === 'cloude') {
            props.delete_highlights_ids.push(String(highlight.M24_highlight_id))
            props.set_delete_highlights_ids(props.delete_highlights_ids)
        }
        props.highlights.splice(index, 1)
        props.setHighlights([...props.highlights])

    }
    const editHighlight = (index: number, highlight: productHighlightOverview) => {
        const highlightInline = props.highlights[index]
        if (highlight.mode === 'cloude') {

            const updatedIndex = props.update_highlights.findIndex(item => item.id == highlightInline.M24_highlight_id)

            if (updatedIndex < 0) {
                props.update_highlights.push({
                    id: String(highlightInline.M24_highlight_id),
                    text: highlight.M24_highlight_text
                })
            } else {
                props.update_highlights[updatedIndex] = {
                    ...props.update_highlights[updatedIndex],
                    text: highlight.M24_highlight_text
                }
            }

            props.setUpdate_highlights([...props.update_highlights])


        }
        props.highlights[index] = { ...highlight }
        props.setHighlights([...props.highlights])
    }

    return (
        <div className=' flex flex-col pt-3 gap-2'>
            <ModalAddHighlights
                show={showAddModal}
                setShow={setSowAddModal}
                addHighlight={addHighlights}
                mode={modalMode}
                selectedHighlight={selectedHighlight}
                selectedIndex={selectedIndex}
                editHighlight={editHighlight}
            />
            <Label className=' font-semibold'>highlights (optional)</Label>
            <ScrollArea viewportclassname='max-h-72' className=" w-full rounded-md border ">
                <div className="p-4">
                    {
                        props.highlights.length ?
                            props.highlights.map((highlight, index) => (
                                <>
                                    <div className=' flex justify-between'>
                                        <div key={index} className="text-sm">
                                            {highlight.M24_highlight_text}
                                        </div>
                                        <div className=' flex gap-1'>
                                            <ButtonIcon 
                                            disabled={props.disabled}
                                            onClick={() => {
                                                setSelectedHighlight(highlight)
                                                setSelectedIndex(index)
                                                setModalMode('edit')
                                                setSowAddModal(true)

                                            }} icon={<Pencil className='h-4 w-4' />} />
                                            <ButtonIcon disabled={props.disabled} onClick={() => deletehighlight(index)} icon={<Trash className='h-4 w-4' />} />
                                        </div>
                                    </div>
                                    {props.highlights.length - 1 !== index && <Separator className="my-2" />}
                                </>
                            ))
                            :
                            <div>No highlights</div>
                    }
                </div>
            </ScrollArea>
            <div className=' p-1'><Button
                disabled={props.disabled}
                onClick={() => {
                    setSelectedHighlight(undefined);
                    setModalMode('add')
                    setSelectedIndex(undefined)
                    setSowAddModal(true)
                }}><Plus />Add Highlight</Button></div>
        </div>
    )
}
