import React, { useState } from 'react'
import { Button } from '@/src/components/ui/button';
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from '@/src/components/ui/dialog';
import { Input } from '@/src/components/ui/input';
import { Label } from '@/src/components/ui/label';
import { customizationOptionType } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Switch } from '@/src/components/ui/switch';
import { v4 as uuidv4 } from 'uuid';
import { ProductCustomizationOptionClient, ProductCustomizationOptionEnumClient } from '../../../addmodal';
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn';
import { Plus } from 'lucide-react';

// Create a type with all properties set to boolean
type Displayble = {
    [K in keyof ProductCustomizationOptionClient]: boolean;
}



type props = {
    variantId: string,
    onCreateVariation: (variaton: ProductCustomizationOptionClient) => void,
    open: boolean,
    setOpen: (open: boolean) => any,
    disabled:boolean
}

// function generateId() {
//     return uuidv4().substring(0, 32); // Truncate to 32 characters
// }

export default function AddOptionModal({
    variantId,
    onCreateVariation,
    open,
    setOpen,
    disabled
}: props) {


    const [formData, setFormData] = useState<ProductCustomizationOptionClient>({
        uniqueId:'',
        baseRate: 0,
        customization_name: '',
        hikePerc: null,
        hikePercInRate: null,
        isDefault: 0,
        isSlab: 1,
        maxVal: null,
        minVal: null,
        order: null,
        rate: null,
        type: 'dropDown',
        value: "",
        varientID: ""
    })

    const [displayable, setDisplayable] = useState<Displayble>({
        uniqueId:false,
        baseRate: false,
        customization_name: false,
        hikePerc: false,
        hikePercInRate: false,
        isDefault: false,
        isSlab: false,
        minVal: false,
        maxVal: false,
        order: false,
        rate: false,
        type: false,
        value: false,
        varientID: false
    })

    const validationObject = {
        [ProductCustomizationOptionEnumClient.customization_name]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.value]: {
            message: '',
            validation: [
                (value) => {
                    if (!value && formData.type === 'dropDown') {
                        return 'required in dropDown'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.type]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'please select a type'
                    }
                    return true
                }
            ]
        },
        // [ProductCustomizationOptionEnumClient.varientID]: {
        //     message: '',
        //     validation: [
        //         (value: any[]) => {
        //             if (!value) {
        //                 return 'please create varientID'
        //             }
        //             return true
        //         }
        //     ]
        // },
        [ProductCustomizationOptionEnumClient.rate]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal') {
                        return 'please enter rate'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.hikePerc]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal' && formData.isSlab == 1) {
                        return 'please enter hike percentage'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.hikePercInRate]: {
            message: '',
            validation: [
                (value: any[]) => {
                    if (!value && formData.type === 'normal' && formData.isSlab == 1) {
                        return 'please enter rate hike percentage'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.minVal]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value && formData.type === 'normal') {
                        return 'please enter minimum value'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.maxVal]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value && formData.type === 'normal') {
                        return 'please enter maximum value'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.baseRate]: {
            message: '',
            validation: [
                (value: any) => {
                    if (value == 0) {
                        return true
                    }
                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    if (!value) {
                        return 'please enter base rate'
                    }
                    return true
                }
            ]
        },
        [ProductCustomizationOptionEnumClient.isDefault]: {
            message: '',
            validation: [
                (value: any) => {

                    if (Number(value) < 0) {
                        return 'enter valid number'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<ProductCustomizationOptionClient>({
        validationObject: validationObject
    })


    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: ProductCustomizationOptionClient;

        switch (e.target.name) {

            case ProductCustomizationOptionEnumClient.isSlab:

                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value == '1' ? 1 : 0
                }
                break;

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)

    }

    function handleDisplayable() {

        switch (formData.type) {

            case 'dropDown':
                displayable.baseRate = true
                displayable.customization_name = true
                displayable.hikePerc = false
                displayable.hikePercInRate = false
                displayable.isDefault = false
                displayable.isSlab = false
                displayable.minVal = false
                displayable.maxVal = false
                displayable.order = false
                displayable.rate = false
                displayable.type = false
                displayable.value = true
                displayable.varientID = false
                break;

            case 'normal':
                displayable.baseRate = true
                displayable.customization_name = true
                displayable.hikePerc = true
                displayable.hikePercInRate = true
                displayable.isDefault = false
                displayable.isSlab = true
                displayable.minVal = true
                displayable.maxVal = true
                displayable.order = false
                displayable.rate = true
                displayable.type = true
                displayable.value = false
                displayable.varientID = false
                break;

        }

    }


    function clearData(){
        setFormData(
            {
                uniqueId:'',
                baseRate: 0,
                customization_name: '',
                hikePerc: null,
                hikePercInRate: null,
                isDefault: 0,
                isSlab: 1,
                maxVal: null,
                minVal: null,
                order: null,
                rate: null,
                type: 'dropDown',
                value: "",
                varientID: ""
            }
        )
        setErrorData({})
    }

    function add_option() {
        // formData.varientID = generateId()
        if (performAllValidation(formData) === true) {
            onCreateVariation(formData)
            clearData()
            setOpen(false)
        }
    }

    handleDisplayable()

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button disabled={disabled} ><Plus />Add Variation</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px] max-h-[90vh] overflow-auto">
                <DialogHeader>
                    <DialogTitle>Create Variation</DialogTitle>
                </DialogHeader>
                <div className="grid gap-4 py-4 ">
                    {displayable.type && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="typeCustomization" className="text-right col-span-2">
                            type
                        </Label>
                        <div className='col-span-4'>
                            <SelectShadcn
                                options={[
                                    { name: 'Dropdown', value: 'dropDown' },
                                    { name: 'normal', value: 'normal' },
                                ]}
                                value={formData.type}
                                onChange={handleChangeInput}
                                name={ProductCustomizationOptionEnumClient.type}
                            />
                        </div>
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.value}</div>
                    </div>}
                    {displayable.customization_name && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="name" className="text-right col-span-2">
                            Name
                        </Label>
                        <Input
                            id="name"
                            name={ProductCustomizationOptionEnumClient.customization_name}
                            value={formData.customization_name}
                            placeholder='customization name'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.customization_name}</div>
                    </div>}
                    {displayable.value && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="value" className="text-right col-span-2">
                            Value
                        </Label>
                        <Input
                            id="value"
                            name={ProductCustomizationOptionEnumClient.value}
                            value={formData.value === null ? undefined : formData.value}
                            placeholder='value'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.value}</div>
                    </div>}
                    {displayable.isSlab && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <div className='col-span-2 flex justify-end'>

                            <Switch onCheckedChange={(isChecked) => {
                                handleChangeInput({
                                    target: {
                                        name: ProductCustomizationOptionEnumClient.isSlab,
                                        value: isChecked ? '1' : '0'
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }} id="isSlab" className='' checked={formData.isSlab === 1 ? true : false} />
                        </div>
                        <Label className=' col-span-4' htmlFor="isSlab">Is Slab</Label>
                    </div>}
                    {displayable.rate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="rate" className="text-right col-span-2">
                            Rate
                        </Label>
                        <Input
                            id="rate"
                            name={ProductCustomizationOptionEnumClient.rate}
                            value={formData.rate === null ? undefined : formData.rate}
                            placeholder='rate'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.rate}</div>
                    </div>}
                    {displayable.hikePerc && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="hikePerc" className="text-right col-span-2">
                            hike percentage
                        </Label>
                        <Input
                            id="hikePerc"
                            name={ProductCustomizationOptionEnumClient.hikePerc}
                            value={formData.hikePerc === null ? undefined : formData.hikePerc}
                            placeholder='hike percentage'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.hikePerc}</div>
                    </div>}
                    {displayable.hikePercInRate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="hikePercInRate" className="text-right col-span-2">
                            hike percentage in rate
                        </Label>
                        <Input
                            id="hikePercInRate"
                            name={ProductCustomizationOptionEnumClient.hikePercInRate}
                            value={formData.hikePercInRate === null ? undefined : formData.hikePercInRate}
                            placeholder='hike percentage'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.hikePercInRate}</div>
                    </div>}
                    {displayable.minVal && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="minValueModal" className="text-right col-span-2">
                            Minmum Value
                        </Label>
                        <Input
                            id="minValueModal"
                            name={ProductCustomizationOptionEnumClient.minVal}
                            value={formData.minVal === null ? undefined : formData.minVal}
                            placeholder='minimum value'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.minVal}</div>
                    </div>}
                    {displayable.maxVal && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="maxValueModal" className="text-right col-span-2">
                            Maximum Value
                        </Label>
                        <Input
                            id="maxValueModal"
                            name={ProductCustomizationOptionEnumClient.maxVal}
                            value={formData.maxVal === null ? undefined : formData.maxVal}
                            placeholder='maximum value'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.maxVal}</div>
                    </div>}
                    {displayable.baseRate && <div className="grid grid-cols-6 items-center gap-x-4 gap-y-1">
                        <Label htmlFor="baseRateModal" className="text-right col-span-2">
                            Base Rate
                        </Label>
                        <Input
                            id="baseRateModal"
                            name={ProductCustomizationOptionEnumClient.baseRate}
                            value={formData.baseRate === null ? undefined : formData.baseRate}
                            placeholder='Base Rate'
                            onChange={handleChangeInput}
                            className=' col-span-4'
                        />
                        <div className='col-span-2'></div>
                        <div className=' text-sm text-red-500 col-span-4'>{errorData.baseRate}</div>
                    </div>}

                </div>
                <DialogFooter>
                    <Button onClick={add_option} type="submit">Save changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
