import React, { useRef } from 'react'
import gsap from "gsap";
import { useGSAP } from "@gsap/react";


export default function SolutionSection() {



    useGSAP(() => {
        // GSAP code here...
        gsap.fromTo(
            '#premiumImage'
            ,
            { x: '100%', opacity: 0 },
            { x: 0, opacity: 1, duration:1, scrollTrigger: { trigger:'#premiumImage' , start: 'top 80%', end: 'bottom 60%',scrub:true } }
        );

        gsap.fromTo(
           '#ReliableImage',
            { x: '-100%', opacity: 0 },
            { x: 0, opacity: 1,duration:1, scrollTrigger: { trigger:'#ReliableImage',start: 'top 80%',  end: 'bottom 60%',scrub:true } }
        );
        gsap.fromTo(
           '#premiumTitle',
            {  opacity: 0 },
            { opacity: 1,duration:1, scrollTrigger: { trigger:'#premiumTitle',start: 'top 80%',  end: 'bottom 60%',scrub:true } }
        );
        gsap.fromTo(
           '#reliableTitle',
            {  opacity: 0 },
            { opacity: 1,duration:1, scrollTrigger: { trigger:'#reliableTitle',start: 'top 80%',  end: 'bottom 60%',scrub:true } }
        );

    

    });


    return (
        <section className="container mx-auto pt-32 pb-16 overflow-x-hidden">
            <div className="text-center mb-20">
                <h2 className="text-3xl sm:text-6xl font-bold">Top-notch Kitchenware Solutions</h2>
                <p className="mt-4 max-w-2xl sm:text-xl mx-auto">
                    We cater to all your kitchenware needs with a range of products and services tailored for hotels, homes, and large-scale orders.
                </p>
            </div>

            <div className="space-y-16 max-w-[1000px] mx-auto">
                {/* Premium Products Section */}
                <div  className="flex flex-col-reverse  lg:flex-row items-center  justify-center gap-8 lg:gap-0 space-y-4 lg:space-y-0 lg:space-x-8 relative">
                    <div id='premiumTitle' className="text-center lg:text-left ">
                        <h3 className="text-2xl sm:text-4xl font-semibold">Premium Products</h3>
                        <p className="mt-4 sm:text-lg ">
                            Our products are crafted with the highest quality materials, ensuring durability and superior performance.
                        </p>
                    </div>
                    <div id='premiumImage' style={{
                        backgroundImage: 'url(/assets/aboutusPage/premiumProduct.jpg)'
                    }} className=" bg-contain  w-full min-w-[250px] max-w-[250px] h-[250px] lg:min-w-[400px] lg:max-w-[400px] lg:h-[400px] bg-gray-200 "></div> {/* Placeholder for 400px square image */}
                </div>

                {/* Reliable Services Section */}
                <div  className="flex flex-col lg:flex-row items-center  justify-center  gap-8 lg:gap-0 space-y-4 lg:space-y-0 lg:space-x-8 relative">
                    <div id='ReliableImage' style={{
                        backgroundImage: 'url(/assets/aboutusPage/reliableService.jpg)'
                    }} className="bg-contain w-full min-w-[250px] max-w-[250px] h-[250px] lg:min-w-[400px] lg:max-w-[400px] lg:h-[400px] bg-gray-200 "></div> {/* Placeholder for 400px square image */}
                    <div id='reliableTitle' className="text-center lg:text-left">
                        <h3 className="text-2xl sm:text-4xl font-semibold">Reliable Services</h3>
                        <p className="mt-4 sm:text-lg ">
                            We offer reliable services, including bulk orders, customized kitchenware, and efficient delivery options.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    )
}
