import React, { useEffect } from 'react';
import Footer from '../../components/Footer/Footer';

const ReturnRefundPolicy = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    return (
        <div className="bg-gray-50 min-h-screen ">
            <div className=" md:container mx-auto py-12 px-6 lg:px-8">
                <div className="bg-white shadow-lg rounded-lg overflow-hidden  relative">
                    <div className="flex">
                        <div className=' z-0 lg:w-1/2'>
                            <div className=' inset-0 absolute bg-black bg-no-repeat bg-cover'
                                style={{
                                    backgroundImage:'url(/assets/return_policy_image.jpg)'
                                }}
                            ></div>
                        </div>
                        <div className="w-full p-8 z-10 bg-white/50 lg:w-1/2  backdrop-blur-sm">
                            <h1 className="text-4xl font-bold text-gray-800 mb-4">
                                Return and Refund Policy
                            </h1>
                            <p className="text-gray-900 mb-6">
                                Thank you for shopping with us. If you are not entirely satisfied with your purchase, we're here to help.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Returns</h2>
                            <p className="text-gray-900 mb-4">
                                You have 30 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it. Your item must be in the original packaging and you need to provide the receipt or proof of purchase.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Refunds</h2>
                            <p className="text-gray-900 mb-4">
                                Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item. If your return is approved, we will initiate a refund to your credit card (or original method of payment). You will receive the credit within a certain amount of days, depending on your card issuer's policies.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Shipping</h2>
                            <p className="text-gray-900 mb-4">
                                You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.
                            </p>

                            <h2 className="text-2xl font-semibold text-gray-800 mb-2">Contact Us</h2>
                            <p className="text-gray-900">
                                If you have any questions on how to return your item to us, contact us at 
                                <a href="mailto:support@yourcompany.com" className="text-blue-500 hover:underline ml-1">
                                    support@chefssouq.com
                                </a>.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />

        </div>
    );
};

export  {ReturnRefundPolicy};
