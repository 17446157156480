import React, { useEffect, useState } from 'react'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { employee } from '../../../../../types'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { Button } from '@/src/components/ui/button'
import { AddEmployeeModal } from './components/modal'
import { DeleteModal } from './components/deleteModal'
import { SkeletonTableAdmin } from '../../../components/skeltonTable'

interface employeelist extends employee {
    delete: any,
    edit: any,
}

type Props = {
    setSelectedId: (id: string) => any
}

export default function EmployeeList(props: Props) {

    const [employees, setEmployees] = useState<employee[]>([])
    const api = useRESTapi()
    const [mode, set_mode] = useState<'edit' | 'add'>('add')
    const [update, setUpdate] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [selectedEmployeeId, setSelectedEmployeeId] = useState<string>()
    const [showDeleteModal,setshowDeleteModal] = useState(false)
    const [isDeleteoading,setIsDeleteLoading] = useState(false)
    const [loadingTable,setLoadingTable] = useState(false)

    const header = [
        {
            label: 'email',
            name: "Email"
        },
        {
            label: 'M15_Name',
            name: "Name"
        },
        {
            label: 'M15_Phone',
            name: "Phone"
        },
        {
            label: 'M15_M13_Role_id',
            name: "Role"
        },
        {
            label: 'edit',
            name: ''
        },
        {
            label: 'delete',
            name: ''
        },
    ]

    const updateUi = () => {
        setUpdate(!update)
    }



    function clickEditButton(id: string) {
        setSelectedEmployeeId(id)
        set_mode('edit')
        setShowModal(true)
    }

    function closeModal() {
        setShowModal(false)
    }

    async function delete_product() {

        setIsDeleteLoading(true)
        try {
    
          const res = await api.Delete<{ success: boolean, data: any, message: string }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees/${selectedEmployeeId}`,
            mode:'admin',
    
          })
          updateUi();
          if (res.data.success) {
            alert(res.data.message)
            setshowDeleteModal(false)
          } else {
            alert(res.data.message)
          }
    
    
        } catch (error) {
          alert('server error')
          console.log(error)
        }
    
        setIsDeleteLoading(false)
    
      }


    useEffect(
        () => {
            props.setSelectedId('C2')
            const initData = async () => {
                setLoadingTable(true)
                const res = await api.get<{ success: boolean, data: employeelist[] }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees`,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    setEmployees(res.data.data.map(
                        item => {
                            item.delete = <ButtonIcon varient={'destructive'} icon={<Trash className=' h-4 w-4' />} onClick={() => {setSelectedEmployeeId(String(item.M15_Employee_id!));setshowDeleteModal(true)}} />
                            item.edit = <ButtonIcon icon={<Pencil className=' h-4 w-4' />} onClick={() => clickEditButton(String(item.M15_Employee_id))} />
                            return item;
                        }
                    ))
                    setLoadingTable(false)
                }


            }

            initData()

        }, [update]
    )


    return (
        <div className=' w-full h-full flex flex-col gap-1 px-1 sm:px-5'>
            <AddEmployeeModal
                id={selectedEmployeeId}
                show={showModal}
                closeModal={() => setShowModal(false)}
                updateUi={updateUi}
                mode={mode}
                setShow={setShowModal}

            />
            {/* DELETE MODAL */}
            <DeleteModal
                isDeleteing={isDeleteoading}
                onDelete={delete_product}
                setShow={setshowDeleteModal}
                show={showDeleteModal}
            />
            <div className=' p-2 text-2xl font-bold text-center'>Employee list</div>
            <div className=' flex justify-end'><Button onClick={()=>{setShowModal(true);set_mode('add')}} >Add employee</Button></div>
            <div className=' overflow-auto   w-full border rounded-md'>
                {
                    loadingTable?
                    <SkeletonTableAdmin/>
                    :
                    <Table className='styled-table '>
                    <TableHeader >
                        <TableRow>
                            {
                                header.map(
                                    (header, index) => <TableHead key={index}>{header.name}</TableHead>
                                )
                            }
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {
                            employees.length === 0
                                ?
                                'No data'
                                :
                                employees.map(
                                    (row: any, index) => (
                                        <TableRow key={index}>
                                            {
                                                header.map(
                                                    (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                                                )
                                            }
                                        </TableRow>
                                    )
                                )

                        }
                    </TableBody>
                </Table>
                }
            </div>
        </div>
    )
}
