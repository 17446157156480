import { IconX } from '@tabler/icons-react'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { RootState } from '../../../../../../redux/store'
import { BinItem, CustomerStock, CustomerStockKeys, ProductItem, ProductItemKeys, Rack, StockItem, Store, Warehouse, employee, pagination } from '../../../../../../types'
import { CustomSelectCustomOptionOption, SelectCustomLoadingSearching, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps } from '../../../components/input_loading_searching'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { Switch } from '@/src/components/ui/switch'


type EcomStockOverview = CustomerStock & {
    I06_M15_CreatedBy:string|number
}

type StockOverview = StockItem & {
    product_item: ProductItem,
    store: Store & {
        warehouse: Warehouse & {
            racks:(Rack & {
                bins:BinItem[]
            })[]
        }
    },
}


export function AddEcomStockModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {


    const validationObject = {
        [CustomerStockKeys.I06_Allow_reorder]: {
            message: '',
            validation: [
                (value) => {
                    if (value == 0) {
                        return true
                    }
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerStockKeys.I06_Available_quantity]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerStockKeys.I06_Reorder_level]: {
            message: '',
            validation: [
                (value) => {
                    if (value == 0) {
                        return true
                    }
                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerStockKeys.I06_M08_Product_item_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },
        [CustomerStockKeys.I06_I04_Stock_id]: {
            message: '',
            validation: [
                (value) => {

                    if (!value) {
                        return 'required'
                    }
                    return true
                }
            ]
        },

    } satisfies validationObject

    const api = useRESTapi();
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<EcomStockOverview>({
        I06_Allow_reorder: 0,
        I06_Available_quantity: 0,
        I06_Batch: '',
        I06_Is_active: 1,
        I06_Reorder_level: 0,
        I06_CreatedBy: '',
        I06_M08_Product_item_id: '',
        I06_M15_CreatedBy:''
    })
    const [isLoading, setIsLoading] = useState(false)
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<EcomStockOverview>({
        validationObject: validationObject
    })
    const [loading, set_loading] = useState(false)
    const [images_url, set_image_url] = useState<Partial<ProductItem>>({
        M08_ProductImages: [],
        images: []
    })
    const [thumbnail_image, set_thumbnail_image] = useState<{
        imageID: string,
        imagePath: string,
        mode: 'local' | 'cloud'
    }>()

    const image_input_ref = useRef<HTMLInputElement>(null)
    const thumbnail_image_input_ref = useRef<HTMLInputElement>(null)

    //load all product options
    const load_stock_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<StockOverview & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: StockOverview[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search,
                ProductItemId: formData.I06_M08_Product_item_id
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.I04_Stock_id!,
                            name: ''
                        } satisfies CustomSelectCustomOptionOption & StockOverview
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    //load all product options
    const load_product_items: (props: loadOptionProps) => Promise<loadOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async ({
        pagination,
        search = ''
    }: {
        pagination: pagination,
        search?: string
    }) => {
        // set_product_search_query(e.target.value);
        const res = await api.get<{ success: boolean, data: { data: ProductItem[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items`,
            params: {
                perPage: pagination.per_page,
                page: 1,
                searchTerm: search
            },
            mode: 'admin',

        })


        const pagination_return: pagination = {
            per_page: res.data.data.per_page,
            total: res.data.data.total
        }

        if (res.data.success === true) {
            // console.log(res.data)
            return {
                options: res.data.data.data.map(
                    (item, index) => {
                        return {
                            ...item,
                            value: item.M08_ProductItemID!,
                            name: item.M08_ProductItemName
                        } satisfies CustomSelectCustomOptionOption & ProductItem
                    }
                ),
                pagination: pagination_return
            }

        } else {
            return {
                options: [],
                pagination: {
                    total: 30,
                    per_page: 30
                } as pagination
            }
        }
    }

    const fetch_selected_product_item: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: ProductItem, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                // alert(res.data.message)
                // set_selected_product(res.data.data)
                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.M08_ProductItemName,
                        value: res.data.data.M08_ProductItemID,

                    } as (ProductItem & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }
    const fetch_selected_stock: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<StockOverview & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
        if (props.value) {
            const res = await api.get<{ success: boolean, data: StockOverview, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/actual_stock/${props.value}`,
                mode: 'admin',

            })

            if (res.data.success === false) {

                alert(res.data?.message || 'something went wrong')
                return {
                    options: null
                }
            } else {

                return {
                    options: {
                        ...res.data.data,
                        name: res.data.data.I04_Stock_id,
                        value: '',

                    } as (StockOverview & CustomSelectCustomOptionOption)
                }
            }
        }
        return {
            options: null
        }
    }



    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata: EcomStockOverview;

        switch (e.target.name) {

            default:
                formdata = {
                    ...formData,
                    [e.target.name]: e.target.value
                }
                break;
        }




        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }



    async function add_customer_stock() {

        setIsLoading(true)
        formData.I06_CreatedBy = String(employee.employeeData.M15_Employee_id)!

        if (performAllValidation(formData) === true) {


            try {

                const formDataReal = new FormData();

                // console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {

                            case CustomerStockKeys.I06_I04_Stock_id:
                            case CustomerStockKeys.I06_Available_quantity:
                            case CustomerStockKeys.I06_Batch:
                            case CustomerStockKeys.I06_Allow_reorder:
                            case CustomerStockKeys.I06_Reorder_level:
                            case CustomerStockKeys.I06_M08_Product_item_id:
                            case CustomerStockKeys.I06_Is_active:
                            case CustomerStockKeys.I06_M15_CreatedBy:
                                formDataReal.append(data[0], String(data[1]))
                                break;
                            default:

                                break;
                        }

                    }
                )



                const res = await api.post<{ success: boolean, data: StockItem, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/customer_stock`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === true) {
                    updateUi();
                    alert(res.data.message)
                    closeModal()

                } else {
                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                }
            } catch (error) {
                console.log(error)
            }
        }
        setIsLoading(false)

    }
    async function edit_product() {

        setIsLoading(true)
        formData.I06_CreatedBy = String(employee.employeeData.M15_Employee_id)!
        formData.I06_M15_CreatedBy = String(employee.employeeData.M15_Employee_id)!

        if (performAllValidation(formData) === true) {

            try {

                const formDataReal = new FormData();
                formDataReal.append('_method', "PUT")

                // console.log(formData)
                Object.entries(formData).map(
                    data => {

                        switch (data[0]) {

                            case CustomerStockKeys.I06_I04_Stock_id:
                            case CustomerStockKeys.I06_Available_quantity:
                            case CustomerStockKeys.I06_Batch:
                            case CustomerStockKeys.I06_Allow_reorder:
                            case CustomerStockKeys.I06_Reorder_level:
                            case CustomerStockKeys.I06_M08_Product_item_id:
                            case CustomerStockKeys.I06_Is_active:
                            case CustomerStockKeys.I06_M15_CreatedBy:
                            case 'I06_M15_CreatedBy':
                                formDataReal.append(data[0], String(data[1]))
                                break;
                            default:

                                break;
                        }

                    }
                )





                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/customer_stock/${id}`,
                    body: formDataReal,
                    mode: 'admin',

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)

                    }
                } else {
                    updateUi();
                    alert(res.data?.message || 'updated product')
                }
            } catch (error) {
                console.log(error)
            }
        } else {
            alert('validation error')
        }
        setIsLoading(false)

    }


    const load_warehouse = async () => {

        const res = await api.get<{
            success: boolean, data: EcomStockOverview
        }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/customer_stock/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {


            setFormData(res.data.data)

        } else {
            throw res.data
        }


    }



    const clear_form = () => {
        setFormData({
            I06_Allow_reorder: 0,
            I06_Available_quantity: 0,
            I06_Batch: '',
            I06_Is_active: 1,
            I06_Reorder_level: 0,
            I06_CreatedBy: '',
            I06_M08_Product_item_id: '',
            I06_M15_CreatedBy:''
        })

    }

    useEffect(
        () => {

            const initListSetup = () => {
                // set_loading(true)
                clear_form()
                set_loading(false)


            }
            const initEditSetup = () => {
                set_loading(true)
                clear_form()
                Promise.all(
                    [load_warehouse()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        console.log(err)
                        set_loading(false)

                    }
                )
                // set_loading(false)


            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)
                set_image_url({
                    M08_ProductImages: [],
                    images: []
                })
            }


        }, [show]
    )

    const onclick_add_image_input = () => {
        image_input_ref.current?.click();
        image_input_ref.current!.value = '';

    }
    const onclick_add_thumbnailImage_image_input = () => {
        thumbnail_image_input_ref.current?.click();
        thumbnail_image_input_ref.current!.value = '';
    }


    if (show) {
        return (
            <AdminModal show={show} setShow={setShow} >
                <AdminModalContent >
                    {
                        loading === true
                            ?
                            <div className=' bg-white p-3'>Loading ...</div>
                            :
                            <div >
                                {/* <div onClick={closeModal} className=" absolute right-0 top-0 p-1 cursor-pointer"><IconX /></div> */}
                                <AdminModalHeader>
                                    <AdminModalTitle>{mode === 'edit' ? 'Edit Customer Stock' : 'Add Customer Stock'}</AdminModalTitle>
                                </AdminModalHeader>

                                <AdminModalMiddle>
                                    <SelectCustomLoadingSearching<ProductItem & CustomSelectCustomOptionOption>
                                        loadOption={
                                            load_product_items
                                        }
                                        fetchSelectOption={fetch_selected_product_item}
                                        disable={mode === 'edit' ? true : false}
                                        onselect={handleChangeInput}
                                        value={formData.I06_M08_Product_item_id}

                                        customItem={{
                                            element: (props) => {
                                                return <div key={props.key} onClick={() => {
                                                    props.select_item()
                                                    props.close_menu()
                                                }} className='px-2 py-2  hover:bg-gray-400 cursor-pointer flex items-center gap-1'>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                                                    <div>{props.M08_ProductItemName}</div>
                                                </div>
                                            },
                                            valueName: CustomerStockKeys.I06_M08_Product_item_id,
                                            selectedItem: (props) => {
                                                return (<div className=' w-full p-2 border flex relative'>
                                                    <div style={{ display: mode === 'edit' ? 'none' : 'block' }} onClick={props.close} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                                                    <div>{props.M08_ProductItemName}</div>
                                                </div>)
                                            }
                                        }}
                                        title='Product'

                                        // value={}

                                        name={CustomerStockKeys.I06_M08_Product_item_id}
                                        placeholder='product'
                                    />
                                    <div className=' text-red-500'>{errorData.I06_M08_Product_item_id ? errorData.I06_M08_Product_item_id : null}</div>


                                    <SelectCustomLoadingSearching<StockOverview & CustomSelectCustomOptionOption>
                                        loadOption={
                                            load_stock_options
                                        }
                                        fetchSelectOption={fetch_selected_stock}
                                        onselect={handleChangeInput}
                                        customItem={{
                                            element: (props) => {
                                                return <div key={props.key} onClick={() => {
                                                    props.select_item()
                                                    props.close_menu()
                                                }} className='px-2 py-2 border hover:bg-gray-400 cursor-pointer flex flex-col  gap-1'>
                                                  
                                                    <div className=' flex'>
                                                        <p>Warehouse:</p>
                                                        <p className='font-bold ml-1'>{props.store.warehouse.I01_Warehouse_name}</p>
                                                    </div>
                                                    <div className=' flex'>
                                                        <p>Store:</p>
                                                        <p className='font-bold ml-1'>{props.store.I02_Store_name}</p>
                                                    </div>
                                                    <div className=' flex'>
                                                        <p>Rack:</p>
                                                        <p className='font-bold ml-1'>{props.store.warehouse.racks[0].I03_Warehouse_Rackname}</p>
                                                    </div>
                                                    <div className=' flex'>
                                                        <p>Bin:</p>
                                                        <p className='font-bold ml-1'>{props.store.warehouse.racks[0].bins[0].I08_Name}</p>
                                                    </div>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props.product_item.M08_ProductImage}`} alt="" height={50} width={40} />
                                                </div>
                                            },
                                            valueName: CustomerStockKeys.I06_M08_Product_item_id,
                                            selectedItem: (props) => {
                                                return (<div className=' w-full p-2 border flex relative flex-col'>
                                                    <div style={{ display: mode === 'edit' ? 'none' : 'block' }} onClick={props.close} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                                                    <div className=' flex'>
                                                        <p>Stock Id:</p>
                                                        <p className='font-bold ml-1'>{props.I04_Stock_id}</p>
                                                    </div>
                                                    <div className=' flex'>
                                                        <p>Warehouse:</p>
                                                        <p className='font-bold ml-1'>{props.store.warehouse.I01_Warehouse_name}</p>
                                                    </div>
                                                    <div className=' flex'>
                                                        <p>Store:</p>
                                                        <p className='font-bold ml-1'>{props.store.I02_Store_name}</p>
                                                    </div>
                                                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props.product_item.M08_ProductImage}`} alt="" height={50} width={40} />
                                                </div>)
                                            }
                                        }}
                                        value={formData.I06_I04_Stock_id}
                                        title='Choose Stock'
                                        name={CustomerStockKeys.I06_I04_Stock_id}
                                        placeholder='Stock Id'
                                    />
                                    <div className=' text-red-500'>{errorData.I06_I04_Stock_id ? errorData.I06_I04_Stock_id : null}</div>



                                    <div>Quantity</div>
                                    <Input value={formData.I06_Available_quantity} onChange={handleChangeInput} type="number" name={CustomerStockKeys.I06_Available_quantity} placeholder='quantity' />
                                    <div className=' text-red-500'>{errorData.I06_Available_quantity ? errorData.I06_Available_quantity : null}</div>

                                    <div className=' flex gap-1'>
                                        {/* <Input checked={formData.I06_Is_active === 1 ? true : false} type="checkbox" name={CustomerStockKeys.I06_Is_active} onChange={handleChangeInput} /> */}
                                        <Switch
                                            checked={formData.I06_Is_active == 1 ? true : false}
                                            name={CustomerStockKeys.I06_Is_active}
                                            onCheckedChange={
                                                (checked) => handleChangeInput({
                                                    target: {
                                                        name: CustomerStockKeys.I06_Is_active,
                                                        value: checked ? 1 : '0',
                                                        checked: checked
                                                    }
                                                } as React.ChangeEvent<HTMLInputElement>)
                                            }

                                        // onChange={handleChangeInput}
                                        />
                                        <div>is active</div>
                                    </div>
                                </AdminModalMiddle>

                                <AdminModalFooter>
                                    {
                                        mode === 'add' ?
                                            <Button disabled={isLoading} onClick={add_customer_stock} >{isLoading ? 'adding...' : 'Add'}</Button>
                                            :
                                            <Button disabled={isLoading} onClick={edit_product}  >{isLoading ? 'editing...' : 'edit'}</Button>}

                                </AdminModalFooter>
                            </div>
                    }
                </AdminModalContent>
            </AdminModal>
        )
    } else {
        return null
    }
}
