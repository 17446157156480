import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Route, Routes } from 'react-router-dom'
import { initcartRedux } from 'src/redux/cart/cart_slice'
import ProductsPage from './Pages/AllProducts/ProductsPage'
import { CheckoutFromCart } from './Pages/CheckoutPageMultiple/checkout'
import Checkout from './Pages/Chekout/checkout'
import PagenotFound from './Pages/PagenotFound'
import { CartPage } from './Pages/cart/page'
import {CategoryProductsPage} from './Pages/categoryPage/catogery_productPage'
import Home from './Pages/homePage/home'
import Order from './Pages/orderPage/order'
import { ServicePage as ServiceDetailsPage } from './Pages/productPage copy/servicePage'
import { ProductPage } from './Pages/productPage/productPage'
import { ServiceAndSpareParts } from './Pages/serviceAndSpareParts/catogery_productPage'
import { ServicePage } from './Pages/servicePage/service'
import { SingleService } from './Pages/singleServicePage/singleServicePage'
import Singleorder from './Pages/singleorder/Singleorder'
import NavBar from './components/navBar/navBar'
import { CommonOrderPage } from './Pages/commonOrderPage/order'
import { ReturnRefundPolicy } from './Pages/refundAndRefund/page'
import { PrivacyPolicy } from './Pages/privacyPolicy/privacyPolicy'
import { AboutPage } from './Pages/aboutUsPage/aboutUsPage'


export function CustomerRoutes() {

  const dispatch = useDispatch()

  const initRedux = ()=>{
    dispatch(initcartRedux({
      mode:'notLoggedIn'
    }))
  }

  useEffect(
    ()=>{
      // initialize redux
      initRedux()
    },[]
  )

  return (
    <div className=' w-full z-0 absolute '>
      <NavBar />
      {/* <CategoryTab/> */}
      <Routes>
        <Route index element={<Home />}></Route>
        <Route path='/product/:productItemId' element={<ProductPage />}></Route>
        <Route path='/servicePage/:productItemId' element={<ServiceDetailsPage />}></Route>
        <Route path='/allproducts/*' element={<ProductsPage />}></Route>
        <Route path='/cart/*' element={<CartPage />}></Route>
        <Route path='/orderPage' element={<Order/>} />
        <Route path='/CommonOrderPage' element={<CommonOrderPage/>} />
        <Route path='/ReturnRefundPolicy' element={<ReturnRefundPolicy/>} />
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy/>} />
        <Route path='/services' element={<ServicePage/>} />
        <Route path='/aboutUs' element={<AboutPage/>} />
        <Route path='/serviceAndSpareParts/*' element={<ServiceAndSpareParts/>} />
        <Route path='/singleorder/:orderId' element={<Singleorder/>} />
        <Route path='/singleServiceDetails/:orderId' element={<SingleService/>} />
        <Route path='/checkoutProduct/:productId/*' element={<Checkout/>} />
        <Route path='/checkout_from_cart/*' element={<CheckoutFromCart/>} />
        <Route path='/categery_page/:categoryId' element={<CategoryProductsPage/>} />
        {/* <Route path='/address' element={<UserAddress/>} /> */}
        <Route path='/*' element={<PagenotFound />}></Route>
      </Routes>
    </div>
  )
}
