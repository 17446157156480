import { Button } from "@/src/components/ui/button"
import { Label } from "@/src/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/src/components/ui/popover"
import { filterObjectType, filterObjectTypeKeys } from "../page"
import { useAlertModal } from "@/src/hooks/alert_modal"
import { useRESTapi } from "@/src/hooks/rest_API"
import { SelectShadcn } from "@/src/pages/customer/components/Select/selectShadcn"
import { Brand, ProductCategory } from "@/src/types"
import { useEffect, useState } from "react"
import { useNavigate, useSearchParams } from "react-router-dom"
import { Input } from "@/src/components/ui/input"

type props = {
  filterObj: filterObjectType,
  setFilterObj: (obj: filterObjectType) => any,
  updateUi: () => any,
  show: boolean,
  setshow: (show: boolean) => any
}

type selectOptionType = {
  name: string,
  value: string
}

export function FilterComponent(props: props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [brands, setBrands] = useState<selectOptionType[]>([])
  const [OrderStatuses, setOrderStatuses] = useState<selectOptionType[]>([])
  const [loading, set_laoding] = useState(true)
  const alertModal = useAlertModal()

  const api = useRESTapi()
  const navigate = useNavigate();

  

  async function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    let formdata: filterObjectType = {
      ...props.filterObj
    }

    switch (e.target.name) {

     

      case filterObjectTypeKeys.status:
        if (e.target.value === '!!!!!!!!!') {
          formdata = {
            ...formdata,
            [e.target.name]: ''
          }
        } else {
          formdata = {
            ...formdata,
            [e.target.name]: e.target.value
          }
        }
        break

     

      default:
        formdata = {
          ...formdata,
          [e.target.name]: e.target.value
        }
        break;
    }




    props.setFilterObj(formdata)
    console.log(formdata)

  }

  const handleSearch = (params: filterObjectType) => {
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set(filterObjectTypeKeys.status, params.status ? params.status : '');
    newSearchParams.set(filterObjectTypeKeys.startDate, params.start_date ? params.start_date : '');
    newSearchParams.set(filterObjectTypeKeys.endDate, params.end_date ? params.end_date : '');
    navigate(`?${newSearchParams.toString()}`, { replace: true });
    // searchParams.set('productid',params.productId?params.productId:'')
    props.updateUi()
    props.setshow(false)
  }

  const loadStatuses = async () => {

    // const res = await api.get<{ success: boolean, data: ProductCategory[] }>({
    //   url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_categories`,
    //   mode: 'admin',

    // })


    // if (res.data.success === true) {
      setOrderStatuses([
        {
          name: 'ALL',
          value: '!!!!!!!!!'
        },
        {
          name:'order recieved',
          value:'1'
        },
        {
          name:'order processed',
          value:'2'
        },
        {
          name:'ready to ship',
          value:'8'
        },
        {
          name:'shipped',
          value:'6'
        },
        {
          name:'Delivered',
          value:'7'
        },
        // ...res.data.data.map(item => ({ name: item.M04_ProductCategoryName, value: String(item.M04_ProductCategoryId) }))
      ])
    // } else {
    //   throw res.data
    // }

  }

  useEffect(
    () => {
      if (props.show) {
        set_laoding(true)
        Promise.all(
          [loadStatuses()]
        ).then(
          () => {
            set_laoding(false)
            props.setFilterObj({
              ...props.filterObj,
              status: searchParams.get(filterObjectTypeKeys.status) ? searchParams.get(filterObjectTypeKeys.status)! : '',
              start_date: searchParams.get(filterObjectTypeKeys.startDate) ? searchParams.get(filterObjectTypeKeys.startDate)! : '',
              end_date: searchParams.get(filterObjectTypeKeys.endDate) ? searchParams.get(filterObjectTypeKeys.endDate)! : '',
            })
          }
        ).catch(
          (err) => {
            console.log(err)
            alertModal.open_alert_modal({
              title: 'Error',
              content: 'Error loading data',
              footer: <Button onClick={alertModal.close_alert_modal}>ok</Button>
            })
          }
        )
      }
    }, [props.show]
  )



  return (
    <Popover open={props.show} onOpenChange={props.setshow} >
      <PopoverTrigger asChild>
        <Button variant={'white'} >Filter</Button>
      </PopoverTrigger>
      <PopoverContent onOpenAutoFocus={event => event.preventDefault()} className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Filters</h4>
            <p className="text-sm text-muted-foreground">
              These are available filters
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <Label>Order Status</Label>
            <SelectShadcn
              value={props.filterObj.status===''?'!!!!!!!!!':props.filterObj.status}
              name={filterObjectTypeKeys.status}
              onChange={handleChangeInput}
              options={OrderStatuses}
              placeHolder='Select category'
              disabled={loading}
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>Start Date</Label>
            <Input
              value={props.filterObj.start_date}
              name={filterObjectTypeKeys.startDate}
              onChange={handleChangeInput}
              placeholder='Start date'
              disabled={loading}
              type="date"
            />
          </div>
          <div className="flex flex-col gap-1">
            <Label>End Date</Label>
            <Input
              value={props.filterObj.end_date}
              name={filterObjectTypeKeys.endDate}
              onChange={handleChangeInput}
              placeholder='End date'
              disabled={loading}
              type="date"
            />
          </div>
          <Button onClick={() => handleSearch(props.filterObj)}>Search</Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
