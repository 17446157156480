import React, { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom'
import { RootState } from 'src/redux/store'
import { TabProfile } from './tab'
import { AddressPage } from './pages/addressPage/addressPage'

type tab = {
    name: string,
    link: string,
    component: ReactNode
}

export function ProfilePage() {
    const { customerData: customer, customerLoggedIn } = useSelector((state: RootState) => state.customer)

    const tabs: tab[] = [
        { name: 'Address', link: '/profile/address', component: <div>address</div> }
    ]

    return (
        <div >
            <div className="mt-14 bg-white">
                <div>
                    <div className=' w-full flex justify-center'>
                        {/* profile image */}
                        <div className=' w-24 h-24 min-w-24 min-h-24 rounded-full bg-orange-500 flex items-center justify-center font-bold text-3xl text-white uppercase'>{customer.email[0]}</div>
                    </div>

                    {/* // INFOS */}
                    <div className="flex justify-center flex-col mt-5">
                        <h1 className="text-center font-bold text-3xl">{customer.M01_CustomerUsername}</h1>
                        <p className=' text-gray-600 text-center'>{customer.email}</p>
                        {/* <a href="#" className="text-center text-blue-700 font-semibold">Add Bio</a> */}
                        {/* <hr className="full flex self-center w-2/3 mt-2" /> */}
                    </div>
                    {/* // END INFOS */}

                    <div>
                        {/* // TABS */}
                        <div className="w-full flex justify-center sticky top-0 ">
                            <div className="flex justify-between mb-2.5 bg-white w-full pt-2 shadow">
                                <div className=' mx-auto flex'>
                                    <ul className="flex px-5  ">
                                        {
                                            tabs.map(
                                                tab => {
                                                    return <li ><TabProfile name={tab.name} to={tab.link} /></li>

                                                }
                                            )
                                        }
                                    </ul>
                                    <ul className="flex mb:pl-14">

                                        <li className="px-2 font-semibold">
                                            <button className="bg-gray-200 px-5 py-1 rounded-lg text-black font-semibold">
                                                <i className="bx bx-edit-alt mr-2 text-xl"></i>
                                                Edit Profile
                                            </button>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* // END TABS */}

                        <Routes>
                            <Route index element={<Navigate replace to={'address'} />} />
                            <Route path='address' element={<AddressPage />} />
                        </Routes>


                    </div>



                </div>
            </div>
        </div>
    )
}
