import { useState } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import SideNav from './components/side_nav'
import TopNavBar from './components/top_navbar'
import EcommerceRoot from './e-commerce/page'
import EmployeeRoot from './employee/page'
import OrderRoot from './orders/page'
import OthersRoot from './others/page'
import { PromotionRoot } from './promotions/page'

export default function AdminDashboard() {

    const [selectedId, setSelectedId] = useState<string>('')

    return (
        <div className=' fixed inset-0 flex flex-col bg-adminPrimary-50 '>
            <TopNavBar />
            <div className=' flex grow overflow-auto px-1 sm:px-2 md:px-3 lg:px-4'>
                <SideNav selectedId={selectedId} />
                <div className=' flex grow overflow-auto'>
                    <Routes>
                        <Route index element={<Navigate to={'inventory'} />} />
                        <Route path='inventory/*' element={<EcommerceRoot setSelectedId={setSelectedId} />} />
                        <Route path='order/*' element={<OrderRoot setSelectedId={setSelectedId} />} />
                        <Route path='promotions/*' element={<PromotionRoot setSelectedId={setSelectedId} />} />
                        <Route path='employee/*' element={<EmployeeRoot setSelectedId={setSelectedId} />} />
                        <Route path='others/*' element={<OthersRoot setSelectedId={setSelectedId} />} />
                    </Routes>
                </div>
            </div>
        </div>
    )
}
