import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, ProductCategoryKeys, role, SalesOrderLine, OrderStatus, SalesOrder, Customer, ProductCustomizationOptionOrder } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconLoader, IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { Table, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'




interface SalesOrderDetailsResponse extends SalesOrder {
    orderLines: ({
        product: Partial<ProductItem>,
        customization?: ProductCustomizationOptionOrder[]
    } & SalesOrderLine)[],
    orderStatus: OrderStatus,
    customer: Partial<Customer>
}

type salesOrderOverview = {
    orderLines: ({
        quantity: number,
        price: number,
        productName: string,
        productImage: string,
        customization?: ProductCustomizationOptionOrder[]
    })[],
    orderId: string,
    description:string,
    date: string,
    orderSource: string,
    orderStatus: string,
    address: string,
    addressName: string,
    customerName: string,
    phoneNo:string,
    customerEmail: string
}




export function AddCategoryModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow

    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {

    const api = useRESTapi();
    const [orderDetails, setOrderDetails] = useState<salesOrderOverview>()

    const [loading, set_loading] = useState(false)



    const loadOrderDetails = async () => {

        const res = await api.get<{ success: boolean, data: SalesOrderDetailsResponse }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/sales_order/${id}`,
            mode: 'admin',

        })

        if (res.data.success === true) {
            const order = res.data.data
            const eachData: salesOrderOverview = {
                date: order.I19_sale_date,
                orderId: String(order.I19_order_id),
                orderSource: order.I19_order_source,
                orderStatus: order.orderStatus.I030_order_name,
                address: order.I19_customer_address,
                addressName: order.I19_customer_address_name,
                customerEmail: order.customer.email!,
                customerName: order.customer.M01_CustomerUsername!,
                description:order.I19_description!,
                phoneNo:order.I19_phone_number,
                orderLines: order.orderLines.map(
                    order => ({
                        price: order.I21_Price,
                        productImage: order.product.M08_ProductImage! as string,
                        productName: order.product.M08_ProductItemName!,
                        quantity: order.I21_Quantity,
                        customization: order.customization

                    })
                )

            }
            setOrderDetails(eachData)
        }

        // console.log(res.data)
        // const order = {
        //     I19_order_id: '001',
        //     I19_M01_Customer_id: 'C001',
        //     I19_customer_address_name: 'John Doe',
        //     I19_customer_address: '123 Main St',
        //     I19_P16_payment_method_id: 'PM01',
        //     I19_I20_shiping_method: 'SM01',
        //     I19_sale_date: '2023-06-08',
        //     I19_completed: true,
        //     I19_cancelled: false,
        //     I19_order_source: 'Online',
        //     I19_I07_ship_via: 'FedEx',
        //     I19_I030_order_status: 'Completed',
        //     orderStatus: {
        //         I030_order_status_id: 'OS01',
        //         I030_order_name: 'ordered',
        //     },
        //     orderLines: [
        //         {
        //             I21_Order_id: '001',
        //             I21_M08_Product_item_id: 'P001',
        //             I21_I19_Sales_order_id: 'SO001',
        //             I21_Quantity: 2,
        //             I21_Price: 20.0,
        //             I21_I030_order_status: 'OS01',
        //             I21_is_Active: 1,
        //             product: {
        //                 M08_ProductItemID: 'P001',
        //                 M08_M11_ProductID: 'PR001',
        //                 M08_ProductImage: 'image1.jpg',
        //                 M08_ProductItemName: 'Product 1',
        //             },
        //             customization: [
        //                 {
        //                     "M25_inputValue": "Red",
        //                     "M25_customization_name": "Color",
        //                     "M25_value": "Red",
        //                     "M25_type": "dropDown",
        //                     "M25_varientID": "VAR001",
        //                     "M25_isSlab": 0,
        //                     "M25_rate": null,
        //                     "M25_hikePerc": null,
        //                     "M25_hikePercInRate": null,
        //                     "M25_minVal": null,
        //                     "M25_maxVal": null,
        //                     "M25_order": 1,
        //                     "M25_baseRate": null
        //                 },
        //                 {
        //                     "M25_inputValue": "",
        //                     "M25_customization_name": "height",
        //                     "M25_value": '60',
        //                     "M25_type": "normal",
        //                     "M25_varientID": "VAR001",
        //                     "M25_isSlab": 1,
        //                     "M25_rate": "10",
        //                     "M25_hikePerc": "5",
        //                     "M25_hikePercInRate": "2",
        //                     "M25_minVal": "50",
        //                     "M25_maxVal": "100",
        //                     "M25_order": 2,
        //                     "M25_baseRate": 100
        //                 },
        //                 {
        //                     "M25_inputValue": "",
        //                     "M25_customization_name": "depth",
        //                     "M25_value": '80',
        //                     "M25_type": "normal",
        //                     "M25_varientID": "VAR001",
        //                     "M25_isSlab": 1,
        //                     "M25_rate": "10",
        //                     "M25_hikePerc": "5",
        //                     "M25_hikePercInRate": "2",
        //                     "M25_minVal": "30",
        //                     "M25_maxVal": "80",
        //                     "M25_order": 2,
        //                     "M25_baseRate": 100
        //                 },
        //             ]
        //         },
        //     ],
        //     customer: {
        //         email: 'mhdfavascheru@gmail.com',
        //         M01_CustomerPhone: '79940202923',
        //         M01_CustomerUsername: 'favas'
        //     }
        // } satisfies SalesOrderDetailsResponse
        //   const order = res.data.data

        // set_products_total(res.data.data.total)
        //   }

    }



    useEffect(
        () => {

            const initListSetup = () => {
                set_loading(true)
                setOrderDetails(undefined)

                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }
            const initEditSetup = () => {
                set_loading(true)
                loadOrderDetails()
                Promise.all(
                    []
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            if (mode === 'add' && show === true) {
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                initEditSetup()
            } else {
                set_loading(true)

            }


        }, [show]
    )



    return (
        <AdminModal show={show} setShow={setShow} >
            {

                <AdminModalContent className=''>
                    {loading === true
                        ?
                        <div className=' bg-white p-3'>Loading ...</div>
                        :
                        <>

                            <AdminModalHeader>
                                <AdminModalTitle>OrderId: {orderDetails?.orderId}</AdminModalTitle>
                            </AdminModalHeader>
                            <AdminModalMiddle>
                                <div className=' flex flex-col'>
                                    {/* customer and address */}
                                    <div className=' font-bold text-lg'>Customer Details</div>
                                    <div className='p-1 sm:p-4'>
                                        <Label className='font-bold text-gray-600'>Customer Name</Label>
                                        <div>{orderDetails?.customerName}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Email</Label>
                                        <div>{orderDetails?.customerEmail}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Phone</Label>
                                        <div>{orderDetails?.phoneNo}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Address</Label>
                                        <div className=' font-bold'>{orderDetails?.addressName}</div>
                                        <div>{orderDetails?.address}</div>
                                    </div>
                                    <div className=' font-bold text-lg'>Order Details</div>
                                    <div className='p-1 sm:p-4'>
                                        <Label className='font-bold text-gray-600'>Order Source</Label>
                                        <div>{orderDetails?.orderSource}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Description</Label>
                                        <div>{orderDetails?.description}</div>
                                        <div className='h-2'></div>
                                        <Label className='font-bold text-gray-600'>Products</Label>
                                        <div>
                                            {
                                                orderDetails?.orderLines.map(
                                                    lines => (
                                                        <div className=' flex flex-col border shadow-sm p-3 rounded-md gap-2'>
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Product name</div>
                                                                <div>{lines.productName}</div>
                                                            </div>
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Quantity</div>
                                                                <div>{lines.quantity}</div>
                                                            </div>
                                                            {
                                                                lines.customization ?
                                                                    <div className=''>
                                                                        <div className='font-semibold text-gray-700'>Customization</div>
                                                                        <table>
                                                                            {
                                                                                lines.customization.map(
                                                                                    obj => (
                                                                                        <tr>
                                                                                            <td className='px-2'>{obj.M25_customization_name}</td>
                                                                                            <td className='px-2'>{obj.M25_value}</td>
                                                                                        </tr>
                                                                                    )
                                                                                )
                                                                            }
                                                                        </table>
                                                                    </div>
                                                                    : null
                                                            }
                                                            <div className=''>
                                                                <div className='font-semibold text-gray-700'>Price</div>
                                                                <div>AED <span className=' text-lg font-bold'>{lines.price}</span></div>
                                                            </div>
                                                        </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                            </AdminModalMiddle>
                            <AdminModalFooter>
                                <div className=' flex justify-end'>
                                    <Button variant={'destructive'} onClick={closeModal} >close</Button>

                                </div>
                            </AdminModalFooter>

                        </>}
                </AdminModalContent>
            }
        </AdminModal>
    )
}
