
import React from 'react'
import { Button } from "@material-tailwind/react";
import { Link } from 'react-router-dom';
export default function PagenotFound() {
    return (
        <div className='flex h-[100vh] justify-center items-center flex-col'>
            <h2 className='font-bold text-[29px] mb-6'>Page Not Found</h2>
            <img className='min-w-60' src='https://img.freepik.com/free-vector/404-error-with-landscape-concept-illustration_114360-7898.jpg?w=900&t=st=1715323055~exp=1715323655~hmac=1fb4e8bfebf348be15b2c147d2692591f3fb54e42d49a33973a8f4a87605522f' alt="" width={'40%'} height={200}/>
            <Link to='/'>
            <Button variant="outlined" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Go to home</Button>
            </Link>
            </div>
    )
}
