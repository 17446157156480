import React, { useEffect, useRef, useState } from 'react';
// import UserAddress from '../../components/userAddress/userAddress';
import { useProductSKU } from '@/src/hooks/product_sku';
import { Link, useNavigate, useParams, useLocation, Route, Routes } from 'react-router-dom';
import { CustomizationOptionStored } from '@/src/types/local_storage';
import { CustomerAddress, OrderType, ProductVariation, ProductVariationOption } from '@/src/types';
import { RootState } from '@/src/redux/store';
import { useSelector } from 'react-redux';
import { IconLoader } from '@tabler/icons-react';
import { StandardCartCard } from '../../components/cartCard';
import axios from 'axios';
import { useRESTapi } from '@/src/hooks/rest_API';
import { Button } from '@/src/components/ui/button';
import { Hotel, Loader2 } from 'lucide-react';
import { ConformedModal } from './components/conformedModal';
import { Step } from '../Chekout/checkout';
import { useCartApi } from '@/src/hooks/cart_api';
import { useCustomizationApi } from '@/src/hooks/customizaton_api';
import { useAlertModal } from '@/src/hooks/alert_modal';
import { customerFeeds, paymentTypeId } from '@/src/types/databaseFeeds';
import { useTaxCalculation } from '@/src/hooks/tax_ calculation';
import { useDeliveryChargeCalculation } from '@/src/hooks/delivery_charge_ calculation';
import { AddressComponent } from '../Chekout/components/address';
import { WhatsappButtonList, WhatsappHoverList } from '@/src/components/buttons/buttonIcon';
// import AddressComponent from './components/address'; 


type selectedProduct = {
    productItemId: string,
    priceMRP: number,
    priceOg: number,
    quantity: number,
    name: string,
    availableQuantity: number,
    customizationOption?: CustomizationOptionStored[],
    thumbnailImage: string,
    variations: ({
        variation_option: ProductVariationOption
    } & ProductVariation)[],
    customizationAvailable: boolean,
    customizationId: string | null,
    sku: string
}

type totals = {
    totalPrice: number;
    discount: number;
    deliveryCharge: number;
    tax: number,
    totalMRP: number,
}


export function CheckoutFromCart() {
    const [quantity, setQuantity] = useState(1);
    const [step, setStep] = useState<Step>('address')
    const customerData = useSelector((state: RootState) => state.customer.customerData)
    const [orderDetails, setOrderDetails] = useState<OrderType>()
    const { productId } = useParams()
    const [isLoading, setIsLoading] = useState(false)
    const [addressLoading, setAddressLoading] = useState(false)
    const [cartProducts, setcartProducts] = useState<selectedProduct[]>([])
    const [selectedAddress, setSelectedAddress] = useState<CustomerAddress>()
    const [orderConformingLoading, setOrderConformingLoading] = useState<boolean>()
    const taxConfig = useTaxCalculation()
    const deliveryConfig = useDeliveryChargeCalculation()
    const alertModal = useAlertModal()
    const [totalPrices, setTotalPrices] = useState<totals>({
        deliveryCharge: NaN,
        discount: NaN,
        totalPrice: NaN,
        tax: NaN,
        totalMRP: NaN
    })
    const {
        close_alert_modal,
        open_alert_modal
    } = useAlertModal()
    const { findTotalValue } = useCustomizationApi()
    const { load_original_cart, load_cart_from_cart } = useCartApi()
    const paymentMethodRef = useRef<HTMLDivElement>(null)
    const navigate = useNavigate()
    const api = useRESTapi();
    const currency = useSelector((state: RootState) => state.configuration.currencyDetails)
    const {
        get_skus_by_ids
    } = useProductSKU()
    const locationRouter = useLocation()
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<string | number>(paymentTypeId.CashOnDelivery)
    const paymentMethods = [
        {
            P15_PaymentTypeID: 1,
            P15_Value: "cash on delivery",
            P15_provider_key: ""
        },
        // {
        //     P15_PaymentTypeID: 2,
        //     P15_Value: "apple pay",
        //     P15_provider_key: "apple_pay"
        // },
        // {
        //     P15_PaymentTypeID: 3,
        //     P15_Value: "google pay",
        //     P15_provider_key: "google_pay"
        // },
        {
            P15_PaymentTypeID: 4,
            P15_Value: "Online payment",
            P15_provider_key: "card"
        }
    ]
    async function addQuantity(itemId: string) {
        if (cartProducts.length) {
            const newProduct = cartProducts.map(
                product => {

                    if (product.productItemId == itemId && (product.quantity < product.availableQuantity || product.customizationAvailable)) {
                        const productNew: selectedProduct = {
                            ...product,
                            quantity: product!.quantity + 1
                        }

                        return productNew
                    }

                    return product

                }
            )
            setcartProducts(newProduct);
            await calculateTotal(newProduct)
        }
    }

    async function lessQuantity(itemId: string) {
        if (cartProducts.length) {
            const newProduct = cartProducts.map(
                product => {

                    if (product.productItemId == itemId && product.quantity > 1) {
                        const productNew: selectedProduct = {
                            ...product,
                            quantity: product!.quantity - 1
                        }

                        return productNew
                    }

                    return product

                }
            )
            setcartProducts(newProduct);
            await calculateTotal(newProduct)
        }
    }


    function changeStep(step: Step) {
        setStep(step);
    }

    const calculateTotal = async (productDetails: selectedProduct[]) => {
        setIsLoading(true)

        let discount = 0;
        let totalPrice = 0

        discount = productDetails.reduce(
            (acc, item) => acc + ((item.priceMRP - item.priceOg) * item.quantity), discount
        )

        totalPrice = productDetails.reduce(
            (acc, item) => (item.priceOg * item.quantity) + acc, 0
        )

        const totals: totals = {
            deliveryCharge: NaN,
            discount: discount,
            totalPrice: totalPrice,
            totalMRP: NaN,
            tax: NaN
        }
        totals.totalMRP = totals.totalPrice + totals.discount

        // DELIVERY CHARGE CALCULATION
        const res2 = await deliveryConfig.calculateDeliveryCharge({
            grossAmount: totals.totalPrice,
        })

        if (res2.success === true) {
            totals.deliveryCharge = res2.data!.delivery_charge
        } else {
            alertModal.open_alert_modal({
                content: res2.message || 'Error calculating delivery charge',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }

        // TAX CHARGE CALCULATION
        const res = await taxConfig.calculateTax({
            grossAmount: totals.totalPrice,
            netAmount: totals.deliveryCharge + totals.totalPrice
        })
        if (res.success === true) {
            totals.tax = res.data!.tax_amount
        } else {
            alertModal.open_alert_modal({
                content: res.message || 'Error loading tax',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })
            navigate('/')
        }
        setTotalPrices(totals)
        setIsLoading(false)

    }

    const loadProductsFromCart = async () => {

        if (customerData.M01_CustomerID == customerFeeds.logoutCustomer.M01_CustomerID) {
            load_cart_from_cart().then(
                async (cartItems) => {
                    console.log(cartItems)
                    if (cartItems && cartItems.length) {
                        const products: selectedProduct[] = []
                        cartItems.forEach(
                            cartItem => {
                                const product: selectedProduct = {
                                    // availableQuantity: cartItem.product_item.customer_stock_quantity,
                                    availableQuantity: cartItem.customer_stock_quantity,
                                    priceOg: Number(cartItem.price?.M10_List_price),
                                    priceMRP: Number(cartItem.price?.M10_MRP_price),
                                    quantity: Number(cartItem.C02_quantity),
                                    thumbnailImage: `${process.env.REACT_APP_BACKEND_URL}/${cartItem.M08_ProductImage! as string}`,
                                    variations: cartItem.variations ? cartItem.variations : [],
                                    customizationOption: cartItem.customizationOption
                                        ?
                                        cartItem.customizationOption.map(option => ({
                                            baseRate: option.baseRate,
                                            hikePerc: option.hikePerc ? Number(option.hikePerc) : null,
                                            hikePercInRate: option.hikePercInRate ? Number(option.hikePercInRate) : null,
                                            id: String(option.id),
                                            Inputvalue: Number(option.Inputvalue),
                                            isDefault: true,
                                            isSlab: option.isSlab,
                                            maxVal: option.maxVal ? Number(option.maxVal) : null,
                                            minVal: option.minVal ? Number(option.minVal) : null,
                                            name: option.name,
                                            optionId: String(option.optionId),
                                            order: Number(option.order),
                                            rate: option.rate ? Number(option.rate) : null,
                                            type: option.type,
                                            value: option.value,
                                            varientID: option.varientID


                                        } satisfies CustomizationOptionStored))
                                        : [],
                                    name: cartItem.M08_ProductItemName,
                                    productItemId: String(cartItem.M08_ProductItemID),
                                    customizationAvailable: cartItem.customizationOption && cartItem.customizationOption.length ? true : false,
                                    customizationId: cartItem.M08_M21_product_customizationId ? String(cartItem.M08_M21_product_customizationId) : null,
                                    sku: cartItem.M08_SKU as string
                                }
                                if (product.availableQuantity > 0 || product.customizationAvailable) {

                                    if (product.customizationAvailable) {
                                        if (product.customizationOption) {
                                            const totalVal = findTotalValue(product.priceOg, product.customizationOption!)
                                            product.priceMRP = totalVal + product.priceMRP - product.priceOg
                                            product.priceOg = totalVal
                                        } else {
                                            if (!locationRouter.pathname.includes('/paymentSuccessful'))
                                                open_alert_modal({
                                                    content: 'customization not selected for this product',
                                                    footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
                                                    title: 'No customization found'
                                                })
                                            navigate('/')
                                        }
                                    }
                                    products.push(product)
                                } else {
                                    alertModal.open_alert_modal({
                                        content: 'Some product is out of stock',
                                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                                        title: 'Out of Stock'
                                    })
                                    navigate('/')
                                }
                            }
                        )

                        await calculateTotal(products)
                        setcartProducts(
                            products
                        )
                    }

                }
            )
        } else {
            load_original_cart().then(
                async (cartItems) => {
                    console.log(cartItems)
                    if (cartItems && cartItems.length) {
                        const products: selectedProduct[] = []
                        cartItems.forEach(
                            cartItem => {
                                const product: selectedProduct = {
                                    // availableQuantity: cartItem.product_item.customer_stock_quantity,
                                    availableQuantity: cartItem.product_item.customer_stock_quantity,
                                    priceOg: Number(cartItem.product_item.price?.M10_List_price),
                                    priceMRP: Number(cartItem.product_item.price?.M10_MRP_price),
                                    quantity: Number(cartItem.C02_quantity),
                                    thumbnailImage: `${process.env.REACT_APP_BACKEND_URL}/${cartItem.product_item.M08_ProductImage! as string}`,
                                    variations: cartItem.product_item.variations ? cartItem.product_item.variations : [],
                                    customizationOption: cartItem.customizations.map(option => ({
                                        baseRate: option.M23_baseRate,
                                        hikePerc: option.M23_hikePerc ? Number(option.M23_hikePerc) : null,
                                        hikePercInRate: option.M23_hikePercInRate ? Number(option.M23_hikePercInRate) : null,
                                        id: String(option.M23_M22_customization_option_id),
                                        Inputvalue: Number(option.M23_inputValue),
                                        isDefault: true,
                                        isSlab: option.M23_isSlab == 1 ? true : false,
                                        maxVal: option.M23_maxVal ? Number(option.M23_maxVal) : null,
                                        minVal: option.M23_minVal ? Number(option.M23_minVal) : null,
                                        name: option.M23_customization_name,
                                        optionId: String(option.M23_M22_customization_option_id),
                                        order: Number(option.M23_order),
                                        rate: option.M23_rate ? Number(option.M23_rate) : null,
                                        type: option.M23_type,
                                        value: option.M23_value,
                                        varientID: option.M23_varientID


                                    } satisfies CustomizationOptionStored)),
                                    name: cartItem.product_item.M08_ProductItemName,
                                    productItemId: String(cartItem.product_item.M08_ProductItemID),
                                    customizationAvailable: cartItem.customizations && cartItem.customizations.length ? true : false,
                                    customizationId: cartItem.product_item.M08_M21_product_customizationId ? String(cartItem.product_item.M08_M21_product_customizationId) : null,
                                    sku: cartItem.product_item.M08_SKU as string
                                }
                                if (product.availableQuantity > 0 || product.customizationAvailable) {

                                    if (product.customizationAvailable) {
                                        if (product.customizationOption) {
                                            const totalVal = findTotalValue(product.priceOg, product.customizationOption!)
                                            product.priceMRP = totalVal + product.priceMRP - product.priceOg
                                            product.priceOg = totalVal
                                        } else {
                                            if (!locationRouter.pathname.includes('/paymentSuccessful'))
                                                open_alert_modal({
                                                    content: 'customization not selected for this product',
                                                    footer: <div><Button onClick={close_alert_modal} >Ok</Button></div>,
                                                    title: 'No customization found'
                                                })
                                            navigate('/')
                                        }
                                    }
                                    products.push(product)
                                } else {
                                    alertModal.open_alert_modal({
                                        content: 'Some product is out of stock',
                                        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                                        title: 'Out of Stock'
                                    })
                                    navigate('/')
                                }
                            }
                        )

                        await calculateTotal(products)
                        setcartProducts(
                            products
                        )
                    }

                }
            )
        }
    }

    const reviewPurchase = async () => {
        setOrderConformingLoading(true)
        try {
            if (cartProducts.length && selectedAddress) {

                let requiestData: any
                if (selectedPaymentMethod == paymentTypeId.CashOnDelivery) {
                    requiestData = {
                        shippingMethod: 1,
                        customerAddress: String(selectedAddress.M02_CustomerAddressID),
                        order_source: 'e-commerce website',
                        shipvia: 1,
                        products:
                            cartProducts.map(

                                selectedProduct => {
                                    if (selectedProduct.customizationAvailable) {
                                        return ({
                                            productItemId: selectedProduct.productItemId,
                                            quantity: selectedProduct.quantity,
                                            customizationID: selectedProduct.customizationId,
                                            varientID: selectedProduct.customizationOption![0].varientID,
                                            customizationOption: selectedProduct.customizationOption!.map(
                                                (item) => ({
                                                    customization_option_id: item.id,
                                                    inputValue: item.Inputvalue,
                                                    customization_name: item.name,
                                                    value: item.value,
                                                    type: item.type,
                                                    varientID: item.varientID,
                                                    isSlab: item.isSlab ? 1 : 0,
                                                    rate: item.rate,
                                                    hikePerc: item.hikePerc,
                                                    hikePercInRate: item.hikePercInRate,
                                                    minVal: item.minVal,
                                                    maxVal: item.maxVal,
                                                    order: item.order,
                                                    baseRate: item.baseRate
                                                })
                                            )
                                        })
                                    }
                                    return ({
                                        productItemId: selectedProduct.productItemId,
                                        quantity: selectedProduct.quantity
                                    })
                                }
                            )
                        ,
                        finalPrice: totalPrices.totalPrice,
                        discountPrice: totalPrices.discount,
                        deliveryCharge: totalPrices.deliveryCharge,
                        taxAmount: totalPrices.tax,
                        userId: customerData.M01_CustomerID

                    }
                } else {
                    requiestData = {
                        shippingMethod: 1,
                        paymentMethod: selectedPaymentMethod,
                        customerAddress: String(selectedAddress.M02_CustomerAddressID),
                        order_source: 'e-commerce website',
                        shipvia: 1,
                        cancelUrl: `${process.env.REACT_APP_FRONTEND_URL}${locationRouter.pathname}`,
                        successUrl: `${process.env.REACT_APP_FRONTEND_URL}${locationRouter.pathname}/paymentSuccessfull`,
                        products:
                            cartProducts.map(

                                selectedProduct => {
                                    if (selectedProduct.customizationAvailable) {
                                        return ({
                                            productItemId: selectedProduct.productItemId,
                                            quantity: selectedProduct.quantity,
                                            customizationID: selectedProduct.customizationId,
                                            varientID: selectedProduct.customizationOption![0].varientID,
                                            customizationOption: selectedProduct.customizationOption!.map(
                                                (item) => ({
                                                    customization_option_id: item.id,
                                                    inputValue: item.Inputvalue,
                                                    customization_name: item.name,
                                                    value: item.value,
                                                    type: item.type,
                                                    varientID: item.varientID,
                                                    isSlab: item.isSlab ? 1 : 0,
                                                    rate: item.rate,
                                                    hikePerc: item.hikePerc,
                                                    hikePercInRate: item.hikePercInRate,
                                                    minVal: item.minVal,
                                                    maxVal: item.maxVal,
                                                    order: item.order,
                                                    baseRate: item.baseRate
                                                })
                                            ),
                                        })
                                    }
                                    return ({
                                        productItemId: selectedProduct.productItemId,
                                        quantity: selectedProduct.quantity
                                    })
                                }
                            )
                        ,
                        finalPrice: totalPrices.totalPrice,
                        discountPrice: totalPrices.discount,
                        deliveryCharge: totalPrices.deliveryCharge,
                        taxAmount: totalPrices.tax,
                        userId: customerData.M01_CustomerID

                    }
                }


                console.log(requiestData)

                if (selectedPaymentMethod == paymentTypeId.CashOnDelivery) {
                    const res = await api.post<{ success: boolean, message: string, data: OrderType }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/perform_buy`,
                        mode: 'customerPrivate',
                        body: requiestData
                    })
                    if (res.data.success) {
                        navigate(`${locationRouter.pathname}/paymentSuccessfull`, { relative: 'path' })
                        setStep('ordered')
                        setOrderDetails(res.data.data)
                    } else {
                        open_alert_modal({
                            title: 'Error Placing Orders',
                            content: res.data.message,
                            footer: <Button onClick={close_alert_modal}>Ok</Button>
                        })
                    }
                } else {
                    const res = await api.post<{ success: boolean, message: string, sessionUrl: string, sessionId: string, data: OrderType }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/perform_buy_prepaid`,
                        mode: 'customerPrivate',
                        body: requiestData
                    })
                    if (res.data.success) {
                        window.location.href = res.data.sessionUrl
                        setStep('ordered')
                        setOrderDetails(res.data.data)
                    } else {
                        open_alert_modal({
                            title: 'Error Placing Orders',
                            content: res.data.message,
                            footer: <Button onClick={close_alert_modal}>Ok</Button>
                        })
                    }
                }

            }
        } catch (error) {
            alert('server error')
        }
        setOrderConformingLoading(false)

    }

    const on_proceed_click = () => {
        if (selectedAddress && cartProducts.length && paymentMethodRef.current) {
            paymentMethodRef.current.scrollIntoView({ behavior: 'smooth' })
            setStep('paymentOption')
        }
    }

    useEffect(
        () => {

            loadProductsFromCart()

        }, []
    )

    console.log(selectedAddress, cartProducts)

    const onChangePaymentMethod = (e: React.ChangeEvent<HTMLInputElement>) => {

        setSelectedPaymentMethod(e.target.value)


    }


    return (
        <div className=' relative py-[46px] bg-[#cfd8dc] w-full min-h-[100vh] h-auto px-6 xl:px-32'>
            {cartProducts.length ? <WhatsappHoverList
                products={cartProducts.map(
                    cartProduct => ({
                        link: `${process.env.REACT_APP_FRONTEND_URL}/product/${cartProduct.productItemId}`,
                        sku: cartProduct.sku
                    })
                )}
            /> : null}
            {(isLoading || addressLoading === true) && <div className=' absolute inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
                <div className=' animate-spin '><IconLoader /></div>
            </div>}
            <div className='text-lg font-bold pt-3 pb-6'>Secure Checkout</div>
            <div className='md2:flex-row flex flex-col  gap-6'>
                <div className='md2:w-[60%] w-[100%] gap-2 flex flex-col'>

                    {/* Address section  */}
                    <AddressComponent
                        isFold={step !== 'address'}
                        changeStep={changeStep}
                        setAddressLoading={setAddressLoading}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                    />
                    {/* product overview with fold container */}
                    <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                        <div className="p-5">
                            <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                                <span className='font-bold'>ORDER SUMMERY</span>
                                {step !== 'orderSummery' && selectedAddress !== undefined && <button
                                    type="button"
                                    onClick={() => changeStep('orderSummery')}
                                    className="text-blue-500"
                                >
                                    Show
                                </button>}
                            </h5>
                        </div>
                        <div className={`overflow-hidden transition-all duration-1000 ease-in-out ${step === 'orderSummery' ? 'max-h-[4000px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            <div className="p-5 gap-2 flex-col flex">


                                {cartProducts
                                    ? cartProducts.map(
                                        selectedProduct => (
                                            <StandardCartCard
                                                product_id={selectedProduct.productItemId}
                                                checked
                                                image={selectedProduct?.thumbnailImage}
                                                onDicreaseCount={() => lessQuantity(selectedProduct.productItemId)}
                                                onIncreaseCount={() => addQuantity(selectedProduct.productItemId)}
                                                onSelect={() => { }}
                                                price={selectedProduct.priceOg}
                                                productName={selectedProduct.name}
                                                quantity={selectedProduct.quantity}
                                                removeFromCart={() => { }}
                                                savePrice={selectedProduct.priceMRP - selectedProduct.priceOg}
                                                variations={selectedProduct.variations.map(
                                                    variation => ({
                                                        option: {
                                                            option_id: String(variation.variation_option.M07_VariationOptionID),
                                                            option_name: variation.variation_option.M07_Value
                                                        },
                                                        variation_id: String(variation.M06_VariationID),
                                                        variation_name: variation.M06_Name,

                                                    })
                                                )}
                                                customizationOption={selectedProduct.customizationOption}
                                                availableQuantity={selectedProduct.availableQuantity ? selectedProduct.availableQuantity : 0}
                                                isCustomizationAvailable={selectedProduct.customizationAvailable ? 1 : 0}
                                            />
                                        )
                                    )
                                    : null}
                                <div>
                                    <button onClick={() => changeStep('paymentOption')} className='px-4 py-2 bg-[orange] rounded-md'>Continue</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* payment method  */}

                    <div className="block rounded-lg bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                        <div className="p-5">
                            <h5 className="mb-2 text-base font-medium leading-tight text-neutral-800 dark:text-neutral-50 flex justify-between">
                                <span className='font-bold'>PAYMENT METHODS</span>
                                {step !== 'paymentOption' && selectedAddress !== undefined && <button
                                    type="button"
                                    onClick={() => changeStep('paymentOption')}
                                    className="text-blue-500"
                                >
                                    Show
                                </button>}
                            </h5>
                        </div>
                        <div ref={paymentMethodRef} className={`overflow-hidden transition-all duration-1000 ease-in-out ${step === 'paymentOption' ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'}`}>
                            <div className="p-5 flex flex-col gap-2">
                                {cartProducts.length ? <WhatsappButtonList
                                    products={cartProducts.map(
                                        cartProduct => ({
                                            link: `${process.env.REACT_APP_FRONTEND_URL}/product/${cartProduct.productItemId}`,
                                            sku: cartProduct.sku
                                        })
                                    )}
                                /> : null}
                                {
                                    paymentMethods.map(
                                        method => (
                                            <div className="block rounded-lg p-5 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] dark:bg-neutral-700">
                                                <div className='flex h-full w-full gap-2'>

                                                    <input type="radio" value={method.P15_PaymentTypeID} onChange={onChangePaymentMethod} checked={selectedPaymentMethod == String(method.P15_PaymentTypeID)} />
                                                    {method.P15_Value}
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                <button disabled={orderConformingLoading} onClick={reviewPurchase} className='px-4 py-2 bg-[orange] rounded-md flex justify-center'>{orderConformingLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Confirm Order</button>

                            </div>

                        </div>

                    </div>

                </div>


                {/* OrderSummery div right  */}
                <div className="mx-auto  w-[100%] md2:max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
                    <div className="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm dark:border-gray-700 dark:bg-gray-800 sm:p-6">
                        <p className="text-xl font-semibold text-gray-900 dark:text-white">Order summary</p>
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Original price</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.totalPrice + totalPrices.discount}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Savings</dt>
                                    <dd className="text-base font-medium text-green-600">{totalPrices.discount}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Store Pickup</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.deliveryCharge}</dd>
                                </dl>
                                <dl className="flex items-center justify-between gap-4">
                                    <dt className="text-base font-normal text-gray-500 dark:text-gray-400">Tax</dt>
                                    <dd className="text-base font-medium text-gray-900 dark:text-white">{totalPrices.tax}</dd>
                                </dl>
                            </div>
                            <dl className="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 dark:border-gray-700">
                                <dt className="text-base font-bold text-gray-900 dark:text-white">Total</dt>
                                <dd className="text-base font-bold text-gray-900 dark:text-white"><span className=' text-xs font-normal pr-1'>{currency.M05_CurrencySymbol}</span>{cartProducts.length ? totalPrices.deliveryCharge + totalPrices.totalPrice + totalPrices.tax : NaN}</dd>
                            </dl>
                        </div>
                        {
                            step !== 'paymentOption' && step !== 'payment' && (
                                <>
                                    <Button
                                        disabled={cartProducts.length === 0 || selectedAddress === undefined}
                                        className=" flex w-full items-center justify-center rounded-lg bg-primary-700 px-5 py-2.5 text-sm font-medium text-white hover:bg-primary-800 focus:outline-none focus:ring-4 focus:ring-primary-300 dark:bg-primary-600 disabled:bg-primary-300 disabled:cursor-not-allowed dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                                        onClick={on_proceed_click}
                                    >Proceed to Buy</Button>
                                    <div className="flex items-center justify-center gap-2">
                                        <span className="text-sm font-normal text-gray-500 dark:text-gray-400"> or </span>
                                        <Link to={'/'} className="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
                                            Continue Shopping
                                            <svg className="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 12H5m14 0-4 4m4-4-4-4" />
                                            </svg>
                                        </Link>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>

                <Routes>
                    <Route path='/paymentSuccessfull' element={<ConformedModal orderDetails={orderDetails} isOpen />} />
                </Routes>


            </div>
        </div>
    );
}
