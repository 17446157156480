import React, { ReactNode, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useRESTapi } from 'src/hooks/rest_API'
import { setEmployee } from 'src/redux/employee/employee_slice';
import { employee } from 'src/types';

export  function ProtectedRouteAdmin({
  children
}: {
  children: ReactNode
}) {

  const api = useRESTapi();
  const [is_checking_auth, set_is_checking_auth] = useState(false)
  const dispatch = useDispatch()

  const checklogin = async () => {
    set_is_checking_auth(true)
    try {
      const res = await api.post<{ success: boolean, data: employee, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/verify-token`,
        mode: 'admin',
      })

      if(res.data.success===true){

        dispatch(setEmployee(res.data.data))

      }

    } catch (error) {
      console.log(error)
      alert('authentication error')
    }
    set_is_checking_auth(false)

  }

  useEffect(
    () => {
      checklogin()
    }, []
  )

  return (
    
      is_checking_auth===true
      ?
      <div>Loading...</div>
      :<div>{children}</div>
    
  )
}
