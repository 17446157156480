import { ChangeEvent, useState } from "react";
import {
  CustomerModal,
  CustomerModalContent,
  CustomerModalHeader,
  CustomerModalTitle,
  CustomerModalMiddle,
  CustomerModalFooter,
} from "../../components/Modal/cosModal"; // Adjust path as necessary
import { SelectShadcn } from "../../components/Select/selectShadcn";
import { Button } from "@/src/components/ui/button";

type Props = {
  show: boolean;
  setShow: (show: boolean) => any;
};

export function ReturnModal(props: Props) {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [textareaVisible, setTextareaVisible] = useState<boolean>(false);

  const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOption(value);
    setTextareaVisible(value === '6');
  };

  const handleClose = () => props.setShow(false);

  const handleSubmit = () => {
    console.log("Submit", selectedOption);
    props.setShow(false);
  };

  const options = [
    { name: "Damaged", value: "1" },
    { name: "Not as described", value: "2" },
    { name: "Wrong item received", value: "3" },
    { name: "Better price available", value: "4" },
    { name: "No longer needed", value: "5" },
    { name: "Other", value: "6" },
  ];

  return (
    <CustomerModal show={props.show} setShow={props.setShow}>
      <CustomerModalContent>
        <CustomerModalHeader>
          <CustomerModalTitle>Return</CustomerModalTitle>
        </CustomerModalHeader>
        <CustomerModalMiddle>
          <div>
            {/* <label htmlFor="returnReason">Select Reason for Return</label> */}
            <div>
            <SelectShadcn
              options={options}
              value={selectedOption}
              onChange={handleSelectChange}
              classNameOuter="w-1/2 min-w-[250px]"
              placeHolder="Reason"
            />
            </div>
            {textareaVisible && (
              <div className="mt-4">
                <label htmlFor="otherReason">Please specify</label>
                <textarea
                  id="otherReason"
                  rows={4}
                  className="mt-1 block w-1/2 min-w-[250px] border-blue-gray-200 border-2 rounded-md"
                  required  // Add this if the field is required
                />
              </div>
            )}
          </div>
        </CustomerModalMiddle>
        <CustomerModalFooter>
          <Button className="bg-blue-gray-300 hover:bg-blue-gray-600" onClick={handleClose}>Close</Button>
          <Button className="bg-green-500 hover:bg-green-800" onClick={handleSubmit}>Submit</Button>
        </CustomerModalFooter>
      </CustomerModalContent>
    </CustomerModal>
  );
}
