import React, { useCallback, useEffect, useState } from 'react'
import DropdownShadecn from '../dropdownShadecn/DropdownShadecn'
// import CarauselShadecn from './carausel/CarauselShadecn'
// import { AvatarShadecn } from './Avatar/Avatar'
// import { IconLogin } from '@tabler/icons-react';
import { IconShoppingCart } from '@tabler/icons-react';
import { IconSearch } from '@tabler/icons-react';
import { IconUser } from '@tabler/icons-react';
import "./navBar.css"
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { useNavigate } from 'react-router-dom';
import CategoryTab from '../catagoryTab/categoryTab';

import { useRESTapi } from '@/src/hooks/rest_API';


export default function NavBar() {

    const cart = useSelector((state: RootState) => state.cart)
    const [prevScrollPos, setPrevScrollPos] = useState<number>(0);
    const [searchString, setSearchString] = useState('')
    const [visible, setVisible] = useState<boolean>(true);
    const [scrollUp, setScrollUp] = useState<boolean>(false)
    const [isShowsuggenstions, setisShowSuggestions] = useState<boolean>(false)
    const [suggenstions, setSuggestions] = useState<{
        suggestion: string
    }[]>([])
    const api = useRESTapi()
    const [open_small_screen_search, set_open_small_screen_search] = useState(false)

    const navigate = useNavigate()

    const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchString(e.target.value)
        debouncedFetchData(e.target.value)
    }

    function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: ReturnType<typeof setTimeout>;
        return (...args: Parameters<T>) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    }
    const fetchData = async (query: string) => {
        try {
            if (query) {
                const response = await api.get<{ success: boolean, Message: string, data: string[] }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/search_product`,
                    mode: 'customer',
                    params: {
                        searchTerm: query
                    }
                });
                setSuggestions(response.data.data.map(
                    item => ({
                        suggestion: item
                    })
                ));
            } else {
                setSuggestions([])
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const debouncedFetchData = useCallback(debounce(fetchData, 500), []);

    const onSearch = (e?: React.FormEvent<HTMLFormElement>) => {
        if (e)
            e.preventDefault()
        const params = new URLSearchParams({
            searchString: searchString
        }).toString();

        navigate(`/allproducts?${params}`);
        set_open_small_screen_search(false)
    }

    useEffect(() => {
        const handleScroll = () => {
            const currentScrollPos = window.scrollY;

            // Check if the user is scrolling up or down
            const scrollingUp = currentScrollPos < prevScrollPos;
            // if(currentScrollPos>200){
            //   set_category_tab_theme({theme:'colorful'})
            // }else{
            //   set_category_tab_theme({theme:'transparent'})
            // }
            // Update visibility based on scroll direction and position
            // setVisible(scrollingUp || currentScrollPos < 10); // Show tab when scrolling up or close to the top
            if (scrollingUp) {
                setVisible(true)
                setScrollUp(true)
            } else {
                setScrollUp(false)
                if (currentScrollPos > 200) {
                    setVisible(false)
                }
            }
            // Update the previous scroll position
            setPrevScrollPos(currentScrollPos);
        };

        // Add event listener for scroll event
        window.addEventListener('scroll', handleScroll);

        // Clean up by removing event listener when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [prevScrollPos]);

    return (
        <div className=' w-full sticky top-0 z-[8000000] transition-transform duration-100  '
            style={{
                transform: visible ?
                    window.scrollY < 200 ?
                        scrollUp ?
                            'translateY(0px)'
                            : `translateY(-${prevScrollPos}px)`
                        : 'translateY(0px)'
                    : 'translateY(-200px)'
            }}
        >
            <div className='bg-white   w-full ' >
                <div className=' mx-auto max-w-[1400px] w-full p-1 h-auto gap-10 flex justify-between items-center z-[500] px-3'>
                    <div className='flex gap-3 justify-center'>
                        <div className='flex gap-1 cursor-pointer' onClick={() => navigate('/')}>
                            <img className='h-[full] w-[170px] sm:block hidden' src="/assets/Logo/logoBig.png" alt="Logo" />
                            <img className='w-[90px]  sm:hidden block' src="/assets/Logo/logoMob.png" alt="Logo" />

                        </div>

                    </div>

                    <div className='sm:w-[70%] flex justify-center gap-2  items-center py-1'>

                        {/* small screen search icon */}
                        <IconSearch onClick={() => set_open_small_screen_search(true)} className=' sm:hidden' size={22} />
                        {/* small screen search */}
                        <div style={{ display: open_small_screen_search ? 'flex' : 'none' }} className=' flex items-center gap-1 fixed sm:hidden z-[100] left-0 top-0 right-0 p-2 bg-white'>

                            <div className=" w-full">
                                <form className="relative w-full" onSubmit={onSearch}>
                                    <input value={searchString} onChange={onChangeSearch} type="text" placeholder='Search your products' className='bg-[#f0f5ff] w-full pl-3 pr-12 py-2 h-8 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200'
                                        onFocus={() => setisShowSuggestions(true)}
                                        onBlur={() => setTimeout(() => setisShowSuggestions(false), 300)}
                                    />
                                    <button className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                                        <IconSearch size={22} />
                                    </button>
                                </form>
                                <div style={{ display: isShowsuggenstions ? 'block' : 'none' }} className='absolute left-0 right-0 bg-white top-10 rounded-lg border shadow-md  overflow-hidden'>
                                    {/* <CommandEmpty>No results found.</CommandEmpty> */}
                                    <div className=' flex flex-col w-full max-h-60 overflow-auto'>
                                        {
                                            suggenstions.length > 0 ?
                                                suggenstions.map(
                                                    suggestiong => (
                                                        <div className='p-2 hover:bg-slate-300' onClick={() => {
                                                            console.log('hiii')
                                                            setSearchString(suggestiong.suggestion)
                                                            const params = new URLSearchParams({
                                                                searchString: searchString
                                                            }).toString();

                                                            navigate(`/allproducts?${suggestiong.suggestion}`);
                                                            set_open_small_screen_search(false)
                                                            setisShowSuggestions(false)
                                                        }} >
                                                            <span>{suggestiong.suggestion}</span>
                                                        </div>
                                                    )
                                                )
                                                :
                                                <div className=' w-full p-3'>No Suggestion</div>
                                        }

                                    </div>
                                    {/* <CommandSeparator /> */}
                                </div>
                            </div>
                            <div onClick={() => set_open_small_screen_search(false)} className='text-blue-500 rounded-sm hover:bg-gray-500/20 p-1 '>Cancel</div>
                        </div>
                        {/* big screen search */}
                        <div className=' hidden sm:flex w-[75%] relative'>

                            <div className=" w-full">
                                <form className="relative w-full" onSubmit={onSearch}>
                                    <input value={searchString} onChange={onChangeSearch} type="text" placeholder='Search your products' className='bg-[#f0f5ff] w-full pl-3 pr-12 py-2 h-8 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200'
                                        onFocus={() => setisShowSuggestions(true)}
                                        onBlur={() => setTimeout(() => setisShowSuggestions(false), 300)}
                                    />
                                    <button className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                                        <IconSearch size={22} />
                                    </button>
                                </form>
                                <div style={{ display: isShowsuggenstions ? 'block' : 'none' }} className='absolute left-0 right-0 z-30 bg-white top-10 rounded-lg border shadow-md  overflow-hidden'>
                                    {/* <CommandEmpty>No results found.</CommandEmpty> */}
                                    <div className=' flex flex-col w-full max-h-60 overflow-auto'>
                                        {
                                            suggenstions.length > 0 ?
                                                suggenstions.map(
                                                    suggestiong => (
                                                        <div className='p-2 hover:bg-slate-300' onClick={() => {
                                                            console.log('hiii')
                                                            setSearchString(suggestiong.suggestion)
                                                            const params = new URLSearchParams({
                                                                searchString: searchString
                                                            }).toString();

                                                            navigate(`/allproducts?${suggestiong.suggestion}`);
                                                            setisShowSuggestions(false)
                                                        }} >
                                                            <span>{suggestiong.suggestion}</span>
                                                        </div>
                                                    )
                                                )
                                                :
                                                <div className=' w-full p-3'>No Suggestion</div>
                                        }

                                    </div>
                                    {/* <CommandSeparator /> */}
                                </div>
                            </div>
                        </div>

                        <div className='flex justify-center items-center gap-4 '>
                            <DropdownShadecn />

                            <div className='font-bold leading-none cursor-pointer block' onClick={() => navigate('/serviceAndSpareParts')}>Services<br /><span className='font-bold text-base leading-none text-gray-600 whitespace-nowrap'>& SpareParts</span></div>

                            <div className='flex relative cursor-pointer' onClick={() => navigate('/cart')} >
                                {cart.cart.length > 0 && <div className=' rounded-full h-[15px] w-[15px] text-[9px] font-medium flex items-center justify-center text-white text-opacity-87 bg-[#cd0843] absolute -top-1 right-7'>{cart.cart.length}</div>}
                                <IconShoppingCart />
                                <p className='font-bold'>Cart</p>
                            </div>
                        </div>

                    </div>

                </div>
                {/* <div className='absolute w-full bottom-[-46px] z-0'> */}
                {/* </div> */}


                {/* <div className=' flex md:hidden w-[100%]   pl-3 pr-3 justify-center items-center pt-3 '>
                    <form className="relative w-full" onSubmit={onSearch}>
                        <button className="w-[44px] absolute inset-y-0 left-0 pl-3 pt-2 pb-2 pr-2flex items-center text-gray-400">
                            <IconSearch size={22} />
                        </button>
                        <input value={searchString} type="text" onChange={onChangeSearch} placeholder='Search your products' className='bg-[#f0f5ff] w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:border-blue-500 focus:ring focus:ring-blue-200' />
                    </form>
                </div> */}
                <CategoryTab />
            </div>
        </div>
    )
}












