import { Button } from '@/src/components/ui/button';
import { useRESTapi } from '@/src/hooks/rest_API';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import { ProductVariation, ProductVariationOption } from '@/src/types';
import { Loader2 } from 'lucide-react';
import { useState } from 'react';
import { edit_option_props } from '../variationComponent';

type props = {
    edit_option_modal_config: edit_option_props,
    set_edit_option_modal_config: (value: edit_option_props) => any,
    reload_product: () => any
}


export  function OptionDeleteModal(props: props) {


    const [is_loading, set_is_loading] = useState(false)
    const api = useRESTapi()



    const deleteOption = async () => {
        set_is_loading(true)
        try {

            const res = await api.Delete<{ success: boolean, data: ProductVariationOption, errors: any, message: string }>({
                mode: 'admin',
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variation_options/${props.edit_option_modal_config.option?.id}`,
                
            })
            if (res.data.success === true) {
                props.set_edit_option_modal_config({ show: false,showDeleteModal:false })
                props.reload_product()
            } else {
                alert(res.data.message)
            }

        } catch (error) {
            console.log(error)
            alert('server error')
        }

        set_is_loading(false)
    }


    return (
        <AdminModal show={props.edit_option_modal_config.showDeleteModal} setShow={(show) => {
            if (is_loading === false) {
                props.set_edit_option_modal_config({
                    show: false,
                    option: show ? props.edit_option_modal_config.option : undefined,
                    showDeleteModal:show,
                    
                })
            }
        }} >
            <AdminModalContent disableCloseButton={is_loading} >
                <AdminModalHeader>
                    <AdminModalTitle>
                        Delete Option
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-48 !min-w-48'>
                    Do you Want to delete Option?
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={is_loading} onClick={() => props.set_edit_option_modal_config({ show: false,showDeleteModal:false })} variant={'outline'}>Cancel</Button>
                    <Button disabled={is_loading} onClick={deleteOption}>{is_loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Delete</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
