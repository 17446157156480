import { configureStore } from '@reduxjs/toolkit'
import { employeeSliceReducer } from './employee/employee_slice'
import { customerSliceReducer } from './customer/customer_slice'
import {  cartSliceReducer } from './cart/cart_slice'
import {  ConfigurationSliceReducer } from './configuration/configuration'
import { modalSliceReducer } from './modals/modal_slice'

export const store = configureStore({
  reducer: {
    employee:employeeSliceReducer,
    customer:customerSliceReducer,
    cart:cartSliceReducer,
    configuration:ConfigurationSliceReducer,
    modal:modalSliceReducer
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch