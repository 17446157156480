import { useEffect, useState } from 'react'
import { AddPriceModal } from './components/addmodal'
import { useRESTapi } from '../../../../../../../hooks/rest_API'
import { Product, ProductKeys, ProductPrice, ProductPriceKeys, employee, pagination } from '../../../../../../../types'
import { TablePagination } from '../../../../../../../components/table'
import { Link, useParams } from 'react-router-dom'
import { IconLoader } from '@tabler/icons-react'
import { Table, TableCell, TableHead, TableHeader, TableRow } from '@/src/components/ui/table'
import { Button } from '@/src/components/ui/button'
import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { Pencil, Trash } from 'lucide-react'
import { SkeletonTableAdmin } from '@/src/pages/admin/components/skeltonTable'
import { useDateFormatDate } from '@/src/hooks/format_date'

interface PriceList extends ProductPrice {
  delete: any,
  edit: any,
  page_number: number
}

type Props = {
  setSelectedId: (id: string) => any
}

export function PricingPage(props: Props) {

  const [products, set_products] = useState<ProductPrice[]>([])
  const [mode, set_mode] = useState<'edit' | 'add'>('add')
  const [isLoading, setIsLoading] = useState(false)
  const api = useRESTapi()
  const [update, setUpdate] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [selectedEmployeeId, set_selected_product] = useState<string>()
  const [page_selected, set_page_selected] = useState<number>(1)
  const [products_total, set_products_total] = useState<number>(1)
  const [per_page, set_per_page] = useState<number>(30)
  const { id: productIitemId } = useParams()
  const [current_price_id, set_current_price_id] = useState<string|number>() 
  const {
    convertToIndianFormat
  } = useDateFormatDate();


  const header: {
    label: ProductKeys | string,
    name: string
  }[] = [
      {
        label: 'page_number',
        name: "No"
      },
      {
        label: ProductPriceKeys.M10_List_price,
        name: "List Price"
      },
      {
        label: ProductPriceKeys.M10_MRP_price,
        name: "MRP Price"
      },
      {
        label: ProductPriceKeys.M10_Start_date,
        name: "Start Date"
      },
      {
        label: ProductPriceKeys.M10_End_date,
        name: "End Date"
      },
      {
        label: 'edit',
        name: ''
      },
      {
        label: 'delete',
        name: ''
      },
    ]

  const updateUi = () => {
    setUpdate(!update)
  }

  async function delete_product(id: number | string) {
    setIsLoading(true)
    try {

      const res = await api.Delete<{ success: boolean, data: PriceList[], message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/prices/${id}`,
        mode: 'admin',

      })
      updateUi();
      if (res.data.success) {
        alert(res.data.message)
      } else {
        alert('somthing went wrong...')
      }


    } catch (error) {
      console.log(error)
    }
    setIsLoading(false)

  }

  function clickEditButton(id: string) {
    set_selected_product(id)
    setShowModal(true)
    set_mode('edit')
  }
  function open_add_modal() {
    set_selected_product(undefined)
    setShowModal(true)
    set_mode('add')
  }

  useEffect(
    () => {
      props.setSelectedId('A4')

      const initData = async () => {
        setIsLoading(true)
        const res = await api.get<{ success: boolean, data: { data: PriceList[] } & pagination&{active_price:string|number} }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/prices_by_id/${productIitemId}`,
          params: {
            perPage: per_page,
            page: page_selected
          },
          mode: 'admin',
        })

        set_current_price_id(res.data.data.active_price)

        if (res.data.success === true) {
          // console.log(res.data)
          set_products(res.data.data.data.map(
            (item, index) => {
              item.M10_Start_date = <div className=' whitespace-nowrap'>{convertToIndianFormat(item.M10_Start_date,{
                day:'2-digit',
                month:'short',
                year:'numeric'
              })}</div> as unknown as string 
              item.M10_End_date = <div className=' whitespace-nowrap'>{convertToIndianFormat(item.M10_End_date,{
                day:'2-digit',
                month:'short',
                year:'numeric'
              })}</div> as unknown as string 
              item.page_number = ((page_selected - 1) * per_page) + (index + 1)
              item.delete = <ButtonIcon varient={'destructive'} icon={<Trash className=' h-4 w-4' />} onClick={() => delete_product(String(item.M10_Product_Price_id!))}/>
              item.edit = <ButtonIcon icon={<Pencil className=' h-4 w-4' />} onClick={() => clickEditButton(item.M10_Product_Price_id!.toString())}/>
              return item;
            }
          ))
          set_products_total(res.data.data.total)
        }

        setIsLoading(false)

      }

      initData()

    }, [update]
  )

  return (
    <div className='  w-full h-full flex flex-col items-center  overflow-auto py-10 gap-2 '>
      {/* {isLoading === true && <div className=' fixed inset-0 flex justify-center items-center z-[500] bg-gray-800/20'>
        <div className=' animate-spin '><IconLoader /></div>
      </div>} */}
      <AddPriceModal
        id={selectedEmployeeId}
        show={showModal}
        closeModal={() => setShowModal(false)}
        updateUi={updateUi}
        mode={mode}
        setShow={setShowModal}
      />
      <div className=' flex gap-1 w-full font-bold'>
        <Link to={'../'}>Product SKUs /</Link>
        <div>Pricing</div>
      </div>
      <div className=' p-2 text-2xl font-bold'>Prcing list</div>
      <div className=' w-full flex justify-end' >
        <Button onClick={open_add_modal} className=' '>Add Pricing</Button>
      </div>
      <div className=' w-full overflow-auto'>
        {
          isLoading?
          <SkeletonTableAdmin/>
          :
          <Table className='styled-table w-full'>
          <TableHeader >
            <TableRow>
              {
                header.map(
                  (header, index) => <TableHead key={index}>{header.name}</TableHead>
                )
              }
            </TableRow>
          </TableHeader>
          <tbody>
            {
              products.length === 0
                ?
                'No data'
                :
                products.map(
                  (row: any, index) => (
                    <TableRow className={`${current_price_id==row.M10_Product_Price_id?' bg-blue-300/40 ':''}`} key={index}>
                      {
                        header.map(
                          (header, index) => <TableCell key={index}>{row[header.label]}</TableCell>
                        )
                      }
                    </TableRow>
                  )
                )

            }
          </tbody>
        </Table>
        }
      </div>
      <div>
        <TablePagination totalRows={products_total} rowsPerPage={per_page} onClickIndex={(e) => { set_page_selected(e); updateUi() }} selectedPage={page_selected} />
      </div>
    </div>
  )
}
