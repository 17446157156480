import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { AddEmployee } from './add_employee/page'
import EmployeeList from './list_employee/page'


type Props = {
  setSelectedId:(id:string)=>any
}
export default function EmployeeRoot(props:Props) {
  return (
    <Routes>
        <Route path='add_employee' element={<AddEmployee setSelectedId={props.setSelectedId}/>} />
        <Route path='list_employee' element={<EmployeeList setSelectedId={props.setSelectedId}/>} />
    </Routes>
  )
}
