import { orderStatusFlags } from '@/src/types'
import React, { useState } from 'react'
import { OrderStatus } from './orderStatus'
import { CancelByAdminModal } from './cancelbyAdminModal'


type props = {
    currentStatus: orderStatusFlags,
    orderID: string,
    updateUi: () => void
}

export function OrderStatusAction(props: props) {
    const [showCancelModal, setShowCancel] = useState(false)

    const statusesList: ({
        status: orderStatusFlags
    })[] = [
            {
                status: 'bookedService'
            },
            {
                status: 'confirmedService'
            },
            {
                status: 'completedService'
            }
        ]

    const StartingIndex = statusesList.findIndex(item => item.status === props.currentStatus)

    const statusListOg = statusesList.slice(StartingIndex + 1)

    switch (props.currentStatus) {

        case 'cancelled': {
            return null
        }


        default:
            return (
                <div className=' flex flex-col gap-1'>
                    {
                        statusListOg.map(
                            item => (
                                <OrderStatus updateUi={props.updateUi} orderId={props.orderID} isActionalble status={item.status} />
                            )
                        )
                    }
                    {
                        statusListOg.length > 0 && (
                            <>
                                <CancelByAdminModal show={showCancelModal} setShow={setShowCancel} orderID={props.orderID} updateUi={props.updateUi} />
                                <div onClick={() => setShowCancel(true)} className=' px-2 py-1 bg-red-400 text-white rounded-full w-min whitespace-nowrap cursor-pointer'>Cancell Service</div>
                            </>
                        )
                    }
                </div>
            )

    }


}
