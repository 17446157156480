import axios, { AxiosProgressEvent, AxiosResponse } from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setCustomer, setCustomerLoggedIn } from "src/redux/customer/customer_slice";
import { localStorageKeys } from "src/types/local_storage";
import { useAlertModal } from "./alert_modal";
import { Button } from "../components/ui/button";
import { customerFeeds } from "../types/databaseFeeds";


type mode = 'admin' | 'customer' |'customerPrivate'

export function useRESTapi() {


    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        close_alert_modal,
        open_alert_modal
    } = useAlertModal();

    const currentPath = location.pathname;

    function generateRandomId() {
        const timestamp = Date.now();
        const randomSuffix = Math.random().toString(36).substr(2);
        return `${timestamp}-${randomSuffix}`;
    }

    const  logout= (mode:mode) =>{

        switch (mode) {
            case 'admin':
            
                navigate('/admin/admin_login')

                break;
            case 'customer':
                localStorage.removeItem(localStorageKeys.accesstocken)
                dispatch(setCustomerLoggedIn('0'))
                // logout user
                dispatch(setCustomer({
                    email:'',
                    M01_CustomerPhone:'',
                    M01_CustomerUsername:'',
                    M01_M20_emirateID:'',
                    M01_CustomerID:customerFeeds.logoutCustomer.M01_CustomerID,    // logout mode
                }))
                // navigate('/login')
                break;
            case 'customerPrivate':
                localStorage.removeItem(localStorageKeys.accesstocken)
                dispatch(setCustomerLoggedIn('0'))
                // logout user
                dispatch(setCustomer({
                    email:'',
                    M01_CustomerPhone:'',
                    M01_CustomerUsername:'',
                    M01_M20_emirateID:'',
                    M01_CustomerID:customerFeeds.logoutCustomer.M01_CustomerID,    // logout mode
                }))
                navigate('/login',{
                    replace:true
                })
                open_alert_modal(
                    {
                        content:'Please log in first',
                        title:'UnAuthenticated',
                        footer:<div>
                            <Button onClick={close_alert_modal} >Ok</Button>
                        </div>
                    }
                )
                break;
        
            default:
                break;
        }

    }


    const [uid, setUid] = useState(generateRandomId())

    function errorHandle(code: string) {

        switch (code) {
            case 'ERR_NETWORK':{
                // navigate('/networkError')
                open_alert_modal(
                    {
                        content:'Network Error',
                        title:'Error',
                        footer:<div>
                            <Button onClick={close_alert_modal} >Ok</Button>
                        </div>
                    }
                )
                return 'Network Error';
            }

            default:
                break;
        }

    }



    async function get<T>({
        url = '',
        body = {},
        headers = {},
        params = {},
        responseType,
        withCredentials = false,
        mode
    }: {
        url: string,
        body?: any,
        headers?: any,
        params?: any,
        responseType?: any,
        withCredentials?: boolean,
        mode: mode
    }): Promise<AxiosResponse<T, any>> {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN!)}`,
        };

        try {

            const res = await axios.get<T>(url, {
                headers: {
                    ...header,
                    headers,

                },
                responseType,
                params,
                withCredentials

            })
            if (res.status === 401) {   //authentication error
                logout(mode)
            }

            return res;

        } catch (error: any) {
            console.log(error)
            const errResMsg = errorHandle(error.code)
            if (error.response?.status === 401) {
                logout(mode)
            }

            if (!error.response) {
                error.response = {}
            }

            if (!error.response.data) {
                error.response.data = {}
                error.response.data.success = false
            }
            error.response.data.success = false
            if(errResMsg){
                console.log(errResMsg)
                error.response.data.message = errResMsg
            }
            return error.response

        }

    }

    async function post<T>({
        url = '',
        body = {},
        headers = {},
        onUploadProgress,
        mode
    }:
        {
            url: string,
            body?: any,
            headers?: any,
            onUploadProgress?: (progressEvent: AxiosProgressEvent) => void,
            mode: mode

        }): Promise<AxiosResponse<T, any>> {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN!)}`,
        };

        try {
            const res = await axios.post<T>(url, body, {
                headers: {
                    ...header,
                    headers
                },
                onUploadProgress: onUploadProgress
            })
            if (res.status === 401) {   //authentication error
                logout(mode)
                if(mode=='customer'){
                    const res1:any = res
                    res1.data.success = true
                    return res1;
                }
            }
            
            return res;

        } catch (error: any) {
            console.log(error)

            const errResMsg = errorHandle(error.code)
            if (error.response?.status === 401) {
                logout(mode)
            }
            if(!error.response){
                error.response={}
            }
            if (!error.response.data) {
                error.response.data = {}
                error.response.data.success = false
            }
            error.response.data.success = false
            if(errResMsg){
                error.response.data.message = errResMsg
            }
            return error.response

        }

    }

    async function put<T>({
        url = '',
        body = {},
        headers = {},
        mode
    }:
        {
            url: string,
            body?: any,
            headers?: any,
            mode: mode

        }): Promise<AxiosResponse<T, any>> {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN!)}`,
        };

        try {

            const res = await axios.put(url, body, {
                headers: {
                    ...header,
                    headers
                }
            })
            if (res.status === 401) {   //authentication error
                logout(mode)
            }


            return res;

        } catch (error: any) {
            console.log(error)
            const errResMsg = errorHandle(error.code)
            if (error.response?.status === 401) {
                logout(mode)
            }
            if(!error.response){
                error.response={}
            }
            if (!error.response.data) {
                error.response.data = {}
                error.response.data.success = false
            }
            error.response.data.success = false
            if(errResMsg){
                console.log(errResMsg)
                error.response.data.message = errResMsg
            }
            return error.response

        }

    }
    async function Delete<T>({
        url = '',
        body = {},
        headers = {},
        mode
    }:
        {
            url: string,
            body?: any,
            headers?: any,
            mode: mode

        }): Promise<AxiosResponse<T, any>> {

        const header = {
            Authorization: `Bearer ${localStorage.getItem(process.env.REACT_APP_TOKEN!)}`,
        };

        try {

            const res = await axios.delete<T>(url, {
                headers: {
                    ...header,
                    headers,
                },
                data:body
            })
            if (res.status === 401) {   //authentication error
                logout(mode)
            }

            return res;

        } catch (error: any) {
            console.log(error)
            const errResMsg = errorHandle(error.code)
            if (error.response?.status === 401) {
                logout(mode)
            }
            if(!error.response){
                error.response={}
            }
            if (!error.response.data) {
                error.response.data = {}
                error.response.data.success = false
            }
            error.response.data.success = false
            if(errResMsg){
                console.log(errResMsg)
                error.response.data.message = errResMsg
            }
            return error.response

        }

    }

    return ({
        get,
        post,
        put,
        Delete
    })


}