import { Navigate, Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { SpareParts } from "./pages/spareparts/spareparts";
import { ServicesPage } from "./pages/services/services";
// import { Switch } from "@/src/components/ui/switch";
import { useState, useEffect } from "react";
import { Switch } from "@/src/components/ui/switchLrg";

export function ServiceAndSpareParts() {
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Sync switch state with URL path
  useEffect(() => {
    if (location.pathname === '/serviceAndSpareParts/service') {
      setIsChecked(true);
    } else if (location.pathname === '/serviceAndSpareParts/spareparts') {
      setIsChecked(false);
    }
  }, [location]);

  function onclickSwitch(check: boolean) {
    setIsChecked(check);
    if (check) {
      navigate('/serviceAndSpareParts/service');
    } else {
      navigate('/serviceAndSpareParts/spareparts');
    }
  }

  return (
    <div className="bg-[#cfd8dc]">
      <div className="w-full relative h-[60px] ">
        <div className=" absolute h-full left-1/2 -translate-x-1/2 top-0 flex items-center justify-center">
          <div className=" uppercase absolute left-0 -translate-x-[104%] top-1/2 -translate-y-1/2 whitespace-nowrap font-bold text-lg md:text-2xl ">Spare Parts</div>
          <Switch checked={isChecked} onCheckedChange={onclickSwitch} />
          <div className=" uppercase absolute right-0 translate-x-[104%] top-1/2 -translate-y-1/2 whitespace-nowrap font-bold text-lg md:text-2xl ">Services</div>
        </div>
        {/* <div> {isChecked ? "Services" : "Spare & Parts"}</div> */}
      </div>
      <Routes>
        <Route index element={<Navigate replace to={"spareparts"} />} />
        <Route path="spareparts" element={<SpareParts />} />
        <Route path="service" element={<ServicesPage />} />
      </Routes>
    </div>
  );
}
