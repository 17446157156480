import React, { useEffect, useRef, useState } from 'react'
import { Product, pagination } from '../../../../types'
import { Input } from '@/src/components/ui/input'




type CustomSelectCustomOptionProps = {
    close_menu: () => void,
    key: string | number,
    select_item: () => void        //select the item
}

export type CustomSelectCustomOptionOption = {      //the default option
    value: string | number,     //value key that required in option
    name: string
}

export type loadOptionProps = {
    pagination: pagination,
    search?: string
}
export type loadSelectedOptionProps = {
    searchQuery?: string,
    value: string | number
}
export type loadOptionReturnProps<T> = {
    pagination: pagination,
    options: T[]
}
export type loadSelectedOptionReturnProps<T> = {
    options: T | null
}

export type CustomSelectLoadingSearchingOnSelectProps<T> = {
    target: {
        object: T | undefined
    }
} & React.ChangeEvent<HTMLInputElement>

export type customeComponentProps<T extends CustomSelectCustomOptionOption> = {
    options: T[],
    searchQuery?: string,
    fetchSelectOption?: () => Promise<loadSelectedOptionReturnProps<T>>,
    loadOption: (props: loadOptionProps) => Promise<loadOptionReturnProps<T>>,
    close_menu: () => void,
    start_loading: () => void,
    end_laoding: () => void
}

/*

    searching supported
    load_more supported

*/


export function SelectCustomLoadingSearching<T extends CustomSelectCustomOptionOption>({
    className = '',
    loadOption,
    fetchSelectOption,
    onChangesearchQuery,   //changing the search bar
    searchQuery,
    name,
    value,
    title,
    placeholder,
    onselect = (e) => { },   //when select the item
    onOpen = () => { },
    customItem,
    customeComponent,
    onDeselect,
    disable=false
}: {
    loadOption: (props: loadOptionProps) => Promise<loadOptionReturnProps<T>>         //load option function. this function call on opening the menu
    fetchSelectOption?: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<T>>,
    className?: string,
    onChangesearchQuery?: (value: React.ChangeEvent<HTMLInputElement>) => void,
    searchQuery?: string,
    name: string,
    value?: string | number,
    placeholder: string,
    title?: string,
    onselect?: (value: CustomSelectLoadingSearchingOnSelectProps<T>) => void,
    onOpen?: () => void,
    customItem?: {
        element: React.FC<T & CustomSelectCustomOptionProps>,   // custom item styling
        valueName: string            // name of the unique key (like value in default case), this name must be one key in the option item,
        selectedItem: React.FC<T & {      //selected item custom style
            close: () => void      //close or unselect selected item
        }> // selected element styling,
    },
    customeComponent?: React.FC<customeComponentProps<T>>,      //custom component that renders bottom of list event it is empty
    onDeselect?: () => void ,       //call this when closing the selected element
    disable?:boolean
}) {

    const inputRef = useRef<HTMLInputElement>(null);
    const menuRef = useRef<HTMLDivElement>(null);
    const [selected_value_object, set_selected_value_object] = useState<T>()    //inbuild selected item
    const [options, set_options] = useState<(T & CustomSelectCustomOptionOption)[]>([])
    const [pagination, set_pagination] = useState<pagination>({
        total: 30,
        per_page: 30
    })
    const debounceTimeout = useRef<{
        id: string | number | NodeJS.Timeout | undefined
    }>({
        id: undefined
    });
    const [search_query_inbuild, set_search_query_inbuild] = useState<string>('')
    const [value_inbuild, set_value_inbuild] = useState<string | number>('')
    const [load_more_loading, set_load_more_loading] = useState<boolean>(false)
    const [is_option_laoding, set_is_option_loading] = useState<boolean>(false)      //loading option from online
    const [show, set_show] = useState(false)
    const load_option_function = async (searchQuery: string, pagination: pagination) => {

        onOpen()
        set_is_option_loading(true)
        try {
            const response = await loadOption({
                pagination: pagination,
                search: searchQuery
            })
            set_options(response.options)
            set_pagination(response.pagination)
        } catch (error) {
            console.log(error)
            alert('error')
        }
        set_is_option_loading(false)
    }

    const handleFocus = () => {
        if (show === false) {    //only when  the menu is close
            set_show(true)
            // console.log('opening')
            load_option_function('', pagination)
        }
    };

    // fetch the selected option
    const fetch_select_option: () => Promise<loadSelectedOptionReturnProps<T>> = async () => {
        if (fetchSelectOption) {
            const res = await fetchSelectOption({
                value: value ? value : value_inbuild!,
                searchQuery: searchQuery ? searchQuery! : search_query_inbuild!
            })
            return res
        }
        return {
            options: null
        }

    }

    const load_more_product = async () => {
        set_load_more_loading(true)
        if (pagination.per_page < pagination.total) {

            const newPerPage = pagination.per_page + 30
            pagination.per_page = newPerPage
            set_pagination({
                ...pagination,
            })

        }
        load_option_function(searchQuery != undefined ? searchQuery : search_query_inbuild, pagination)
            .then(res => set_load_more_loading(false))
            .catch(res => set_load_more_loading(false))



    }

    const deselect_all = () => {
        set_value_inbuild('')
        set_selected_value_object(undefined)
        onselect(
            {
                target: {
                    name: name,
                    value: '',
                    object: undefined
                }
            } as CustomSelectLoadingSearchingOnSelectProps<T>
        )
        if (onDeselect) {
            onDeselect()
        }
    }

    useEffect(() => {




        //DETECT CLICK OUTSIDE MENU
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && inputRef.current && !menuRef.current.contains(event.target as Node) && !inputRef.current.contains(event.target as Node) && show === true) {
                set_show(false)
            }
        };

        // Attach event listeners on component mount
        if (inputRef.current) {
            inputRef.current.addEventListener('focus', handleFocus);
            // inputRef.current.addEventListener('blur', handleBlur);
        }

        document.addEventListener('mousedown', handleClickOutside);

        // Clean up event listeners on component unmount (prevents memory leaks)
        return () => {
            inputRef.current?.removeEventListener('focus', handleFocus);
            document.removeEventListener('mousedown', handleClickOutside);
            // inputRef.current?.removeEventListener('blur', handleBlur);
        };
    });


    // select option according to value
    useEffect(

        () => {

            const selectOption = async () => {
                // if no value, then deselect
                set_search_query_inbuild('')

                if (!value) {
                    set_value_inbuild('')
                    set_selected_value_object(undefined)
                    return;
                }
                const selectedOptionIndex = options.findIndex(
                    (item) => {
                        return item.value == value
                    }
                )
                console.log(String(value))

                if (selectedOptionIndex < 0) {
                    const res = await fetch_select_option()
                    if (res.options) {
                        onselect(
                            {
                                target: {
                                    name: res.options?.name,
                                    value: String(res.options.value),
                                    object: res.options
                                }
                            } as CustomSelectLoadingSearchingOnSelectProps<T>
                        )
                        set_selected_value_object(res.options)
                    }
                }

                if (selectedOptionIndex >= 0) {
                    set_selected_value_object(options[selectedOptionIndex])
                }
            }

            selectOption()

        }, [String(value)]
    )


    return (
        <div className={`flex flex-col min-w-[100px] ${className}`}>
            {title ? <div>{title}</div> : null}
            <div className={`min-w-[100px] flex flex-col  relative`}>

                <div className={` w-full border ${show ? 'ring-1' : ''} rounded-sm  `}>
                    <Input disabled={disable} value={searchQuery !== undefined ? searchQuery : search_query_inbuild} name={name} ref={inputRef} placeholder={placeholder} type="text" className={`border-none w-full outline-none ${selected_value_object && Object.keys(selected_value_object).length > 0 ? 'hidden' : ''}`} onChange={e => {
                        clearTimeout(debounceTimeout.current.id);
                        if (onChangesearchQuery) {
                            onChangesearchQuery(e)
                        }
                        set_search_query_inbuild(e.target.value)
                        // Set a new timeout to update debouncedValue after a delay
                        debounceTimeout.current.id = setTimeout(async () => {
                            load_option_function(e.target.value, pagination)
                        }, 800);
                    }} />
                    {/* SELECTED ITEM DISPLAY */}
                    <div className=''>
                        {
                            customItem
                                ?
                                selected_value_object && Object.keys(selected_value_object).length > 0 ? customItem.selectedItem({ ...selected_value_object as T, close: deselect_all }) : null
                                :
                                selected_value_object && Object.keys(selected_value_object).length > 0 ? <div className=' w-full p-2 border text-center relative' >
                                    <div>{selected_value_object.name}</div>
                                    <div onClick={deselect_all} style={{display:disable?'none':'block'}} className=' absolute right-0 top-0 bottom-0 cursor-pointer px-1 flex items-center justify-center'>
                                        <div>x</div>
                                    </div>
                                </div> : null
                        }
                    </div>
                </div>
                {/* OPTOINS MENU */}
                <div ref={menuRef} style={{ display: show ? 'block' : 'none' }} className=' z-10 w-full left-0 right-0 top-10 absolute max-h-80 border border-black bg-white rounded-md shadow-md overflow-hidden flex'>
                    {
                        is_option_laoding === false
                            ?
                            <div className=' max-h-72 overflow-auto'>
                                {
                                    options.length === 0
                                        ?
                                        <div className='w-full p-5'>No items</div>
                                        :
                                        options.map(
                                            (item, index) => {
                                                //IF THERE IS CUSTOM LIST

                                                if (customItem) {
                                                    const itemOrigin = { ...item } as T & CustomSelectCustomOptionProps;
                                                    itemOrigin.close_menu = () => {
                                                        set_show(false);
                                                    }
                                                    itemOrigin.select_item = () => {

                                                        set_value_inbuild(itemOrigin.value)
                                                        set_selected_value_object(itemOrigin)
                                                        onselect(
                                                            {
                                                                target: {
                                                                    name: name,
                                                                    value: itemOrigin.value,
                                                                    object: item
                                                                }
                                                            } as CustomSelectLoadingSearchingOnSelectProps<T>
                                                        )
                                                    }
                                                    itemOrigin.key = index
                                                    return customItem.element(itemOrigin)
                                                }
                                                // DEFAULT STYLE

                                                return <div
                                                    key={index}
                                                    onClick={() => {
                                                        set_show(false)
                                                        set_value_inbuild(item.value)
                                                        set_selected_value_object(item)
                                                        onselect(
                                                            {
                                                                target: {
                                                                    name: name,
                                                                    value: item.value,
                                                                    object: item
                                                                }
                                                            } as CustomSelectLoadingSearchingOnSelectProps<T>
                                                        )
                                                    }} className=' w-full p-2 cursor-pointer hover:bg-gray-300'>
                                                    {item.name}
                                                </div>
                                            }

                                        )

                                }
                                {/* custom component in the bottom of list */}
                                {customeComponent ? customeComponent({
                                    options,
                                    searchQuery: searchQuery ? searchQuery : search_query_inbuild,
                                    loadOption,
                                    fetchSelectOption: fetch_select_option,
                                    close_menu: () => {
                                        set_show(false);
                                    },
                                    start_loading: () => set_is_option_loading(true),
                                    end_laoding: () => set_is_option_loading(false)
                                }) : null}
                                {/* loading button */}
                                <div className=' w-full p-2 text-center'>
                                    {load_more_loading === true
                                        ?
                                        <div>Loading...</div>
                                        :
                                        pagination.total > pagination.per_page && <div onClick={load_more_product} className=' hover:text-blue-500 text-blue-300 cursor-pointer'>Load more</div>}
                                </div>
                            </div>
                            :
                            <div className='text-center p-2 py-3'>Loading...</div>
                    }
                </div>

            </div>
        </div>
    )
}
