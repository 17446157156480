import { ButtonIcon } from '@/src/components/buttons/buttonIcon'
import { CustomerAddress } from '@/src/types'
import { Pencil, Trash2 } from 'lucide-react'
import React from 'react'

type Props = {
  showEditModal: (id: string) => void,
  customerAddress: CustomerAddress,
  deleteAddress: (id: string) => void
}


export function AddressCard({
  showEditModal,
  customerAddress,
  deleteAddress
}: Props) {
  return (
    <div className="w-full max-w-sm p-4   bg-white border border-gray-200 rounded-lg shadow sm:p-8 dark:bg-gray-800 dark:border-gray-700 gap-2">
      <div className='h-full flex  gap-2 justify-end'>
        <ButtonIcon
          onClick={() => showEditModal(String(customerAddress.M02_CustomerAddressID))}
          icon={<Pencil className='h-4 w-4' />} toolTipText='Edit' />
        <ButtonIcon icon={<Trash2 className='h-4 w-4' />} toolTipText='Delete' onClick={() => deleteAddress(String(customerAddress.M02_CustomerAddressID))} />
      </div>
      <div className="text-gray-700 dark:text-gray-300 grow">
        <div className="mb-2">
          <span className="font-bold">Name:</span> {customerAddress.M02_Name}
        </div>
        <div className="mb-2">
          <span className="font-bold">Phone No:</span> {customerAddress.M02_phone_no}
        </div>
        <div className="mb-2">
          <span className="font-bold">E-mail:</span> {customerAddress.M02_email}
        </div>
        <div className="mb-2">
          <span className="font-bold">Emirates:</span> {customerAddress.M02_M20_emirateID}
        </div>
        <div className="mb-2">
          <span className="font-bold">City:</span>{customerAddress.M02_City}
        </div>
        <div className="mb-2">
          <span className="font-bold">Postal Code:</span> {customerAddress.M02_PostalCode}
        </div>
        <div className="mb-2">
          <span className="font-bold">Region:</span> {customerAddress.M02_Region}
        </div>
      </div>
    </div>
  )
}
