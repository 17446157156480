import React from 'react'
import { useRESTapi } from './rest_API'
import { localStorageKeys } from 'src/types/local_storage'
import { useDispatch } from 'react-redux'
import { setCustomerLoggedIn } from 'src/redux/customer/customer_slice'
import { useCartApi } from './cart_api'
import { setCurrencyDetails, updateCategoryTabConfig } from 'src/redux/configuration/configuration'
import { Currency } from '../types/index'

export function useConfiguration() {
    const dispatch = useDispatch()
    const api = useRESTapi()

    //hide the category tab
    const hide_category_tab = () => {

        dispatch(updateCategoryTabConfig({
            show: false
        }))

    }
    //hide the category tab
    const show_category_tab = () => {

        dispatch(updateCategoryTabConfig({
            show: true
        }))

    }

    // set the theme of category tab
    const set_category_tab_theme = ({
        theme
    }: {
        theme: 'transparent' | 'colorful'
    }
    ) => {
        dispatch(updateCategoryTabConfig({
            colorMode:theme
        }))
    }
    // set the theme of category tab
    const set_category_tab_position = ({
        position
    }: {
        position: 'absolute'|'block'
    }
    ) => {
        dispatch(updateCategoryTabConfig({
            position:position
        }))
    }

    const get_user_currency:()=>Promise<false|Currency> = async ()=>{

        try {
            const res = await api.get<{ success: boolean, data: Currency, errors: any, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/currency`,
                mode: 'customer',

            })
            if (res.data.success === true) {
                dispatch(setCurrencyDetails(res.data.data))
                return res.data.data
            } else {
                alert(res.data.message || 'something went wrong')
                return false
            }

        } catch (error) {
            console.log(error)
            alert('server error')
            return false
        }

    }

    return {
        hide_category_tab,
        set_category_tab_theme,
        show_category_tab,
        set_category_tab_position,
        get_user_currency
    }
}
