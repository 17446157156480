import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { WarehouseDetails } from './warehouse_details/page'
import { StoreDetails } from './warehouse_details/store/page'
import { RackDetails } from './warehouse_details/rack/page'
import { BinDetails } from './warehouse_details/bin/page'

type Props = {
  setSelectedId: (id: string) => any
}
export default function WarehouseRoot(props: Props) {
  useEffect(
    () => {
      props.setSelectedId('A1')
    }, []
  )
  return (
    <Routes>
      <Route index element={<WarehouseDetails />} />
      <Route path=':warehouseId' >
        <Route index element={<StoreDetails />} />
        <Route path=':storeId' >
          <Route index element={<RackDetails />}/>
          <Route  path=':rackId' element={<BinDetails />} />
        </Route>

      </Route>
      <Route path='*' element={<div className=' grow flex items-center justify-center font-bold text-3xl'>404</div>} />
    </Routes>
  )
}
