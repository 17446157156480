import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import useFormValidatonCustom, { validationObject } from '../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../hooks/rest_API'
import { Country, employee, employeeKeys, pagination, role, Warehouse } from '../../../../../types'
import { url } from 'inspector'
import { Input } from '@/src/components/ui/input'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'
import { Button } from '@/src/components/ui/button'
import { Textarea } from '@/src/components/ui/textarea'
import { Switch } from '@/src/components/ui/switch'
import { useSelector } from 'react-redux'
import { RootState } from '@/src/redux/store'

type Props = {
  setSelectedId: (id: string) => any
}

type employeeOverView = employee

export function AddEmployee(props: Props) {


  const validationObject: validationObject = {
    [employeeKeys.M15_Name]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.email]: {
      message: '',
      validation: [(value) => !value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toString()) ? 'not valid email' : true]
    },
    [employeeKeys.M15_M13_Role_id]: {
      message: '',
      validation: [(value) => !value ? 'please select role' : true]
    },
    [employeeKeys.M15_Phone]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Password]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Hire_date]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Address]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_BloodGroup]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Designation]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_DOB]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_EmployeeCode]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Gender]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_JoiningDate]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_M03_CountryID]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_M15_ReportingTo]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_MaritalStatus]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_Qualification]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
    [employeeKeys.M15_I01_warehouseId]: {
      message: '',
      validation: [(value) => !value ? 'required' : true]
    },
  } satisfies validationObject

  const api = useRESTapi();
  const [formData, setFormData] = useState<employeeOverView>({
    M15_Name: '',
    M15_created_by: '',
    email: '',
    M15_Phone: '',
    M15_Password: '',
    M15_Hire_date: '',
    M15_M13_role_id: undefined,
    M15_Address: '',
    M15_BloodGroup: '',
    M15_Designation: '',
    M15_DOB: '',
    M15_EmployeeCode: '',
    M15_Gender: '',
    M15_JoiningDate: '',
    M15_M03_CountryID: '',
    M15_M15_ReportingTo: '',
    M15_MaritalStatus: '',
    M15_Qualification: '',
    M15_is_active: 1,
    M15_AllowLogin: 1,
  })
  const [roles, setRoles] = useState<role[]>([])
  const [country, setCountry] = useState<Country[]>([])
  const [warehouses, setwarehouses] = useState<Warehouse[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<employeeOverView>({
    validationObject: validationObject
  })
  const employee = useSelector((state: RootState) => state.employee)

  function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    let formdata
    if (e.target.name === employeeKeys.M15_Is_Active) {
      formdata = {
        ...formData,
        [e.target.name]: e.target.checked ? 1 : 0
      }
    } else {
      formdata = {
        ...formData,
        [e.target.name]: e.target.value
      }
    }

    setFormData(formdata)
    performValidationOf(e.target.name, formdata)
    console.log(formdata)

  }

  async function addemployee() {
    setIsLoading(true)
    formData.M15_M15_ReportingTo = employee.employeeData.M15_Employee_id!
    formData.M15_created_by = employee.employeeData.M15_Employee_id!
    if (performAllValidation(formData) === true) {

      try {
        const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
          url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees`,
          body: formData,
          mode: 'admin',

        })

        if (res.data.success === false) {

          alert(res.data?.message || 'something went wrong')
          if (res.data.errors) {

            const errs = res.data.errors;
            const errData: any = {}

            for (const key in errs) {
              errData[key] = errs[key][0]
            }

            setErrorData(errData)


          }
        } else {
          setFormData({
            M15_Name: '',
            M15_created_by: '',
            email: '',
            M15_Phone: '',
            M15_Password: '',
            M15_Hire_date: '',
            M15_M13_role_id: undefined,
            M15_Address: '',
            M15_BloodGroup: '',
            M15_Designation: '',
            M15_DOB: '',
            M15_EmployeeCode: '',
            M15_Gender: '',
            M15_JoiningDate: '',
            M15_M03_CountryID: '',
            M15_M15_ReportingTo: '',
            M15_MaritalStatus: '',
            M15_Qualification: '',
            M15_is_active: 1,
            M15_AllowLogin: 1,
          })
          alert(res.data.message)
        }
      } catch (error) {
        console.log(error)
      }
    }
    setIsLoading(false)

  }

  useEffect(
    () => {
      props.setSelectedId('C1')
      const loadRole = async () => {

        try {
          const res = await api.get<{ success: boolean, data: role[] }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/roles`,
            mode: 'admin',

          })

          if (res.data.success === true) {
            setRoles(res.data.data)
          }

        } catch (error) {
          console.log(error)
        }

      }

      const loadCountry = async () => {

        try {
          const res = await api.get<{ success: boolean, data: { data: Country[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/country`,
            mode: 'admin',

          })

          setCountry(res.data.data.data)

        } catch (error) {
          console.log(error)
        }

      }
      const loadWarehouse = async () => {

        try {
          const res = await api.get<{ success: boolean, data: { data: Warehouse[] } & pagination }>({
            url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse`,
            mode: 'admin',

          })

          setwarehouses(res.data.data.data)

        } catch (error) {
          console.log(error)
        }

      }

      loadRole()
      loadCountry()
      loadWarehouse()

    }, []
  )


  return (
    <div className='  w-full flex  my-5 justify-center '>
      <div className=' p-3 border rounded-md flex flex-col w-[300px] gap-2 '>
        <div className=' text-2xl font-bold'>Add Employee</div>
        <Input value={formData.M15_Name} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Name} placeholder='name' />
        <div className=' text-red-500'>{errorData.M15_Name ? errorData.M15_Name : null}</div>
        <Input value={formData.email} onChange={handleChangeInput} type="text" name={employeeKeys.email} placeholder='email' />
        <div className=' text-red-500'>{errorData.email ? errorData.email : null}</div>
        <Input value={formData.M15_Phone} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Phone} placeholder='phone No' />
        <div className=' text-red-500'>{errorData.M15_Phone ? errorData.M15_Phone : null}</div>
        <Input value={formData.M15_Password} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Password} placeholder='Password' />
        <div className=' text-red-500'>{errorData.M15_Password ? errorData.M15_Password : null}</div>
        <div>hire date</div>
        <Input value={formData.M15_Hire_date} onChange={handleChangeInput} type="date" name={employeeKeys.M15_Hire_date} placeholder='hire Date' />
        <div className=' text-red-500'>{errorData.M15_Hire_date ? errorData.M15_Hire_date : null}</div>
        <div>Role</div>
        <select value={formData.M15_M13_role_id} name={employeeKeys.M15_M13_Role_id} onChange={handleChangeInput as any} >
          <option disabled value={''} selected  >select</option>
          {
            roles.map(
              (role, index) => <option key={index} value={role.M13_Role_Id}>{role.M13_RoleName}</option>
            )
          }
        </select>
        <div className=' text-red-500'>{errorData.M15_M13_role_id ? errorData.M15_M13_role_id : null}</div>

        <div>Address</div>
        <Textarea value={formData.M15_Address} onChange={handleChangeInput as any as React.ChangeEventHandler<HTMLTextAreaElement>} name={employeeKeys.M15_Address} placeholder='Address' />
        <div className=' text-red-500'>{errorData.M15_Address ? errorData.M15_Address : null}</div>

        <div>Blood Group</div>
        <Input value={formData.M15_BloodGroup} onChange={handleChangeInput} name={employeeKeys.M15_BloodGroup} placeholder='Blood group' />
        <div className=' text-red-500'>{errorData.M15_BloodGroup ? errorData.M15_BloodGroup : null}</div>


        <div>Designation</div>
        <Input value={formData.M15_Designation} onChange={handleChangeInput} name={employeeKeys.M15_Designation} placeholder='Designation' />
        <div className=' text-red-500'>{errorData.M15_Designation ? errorData.M15_Designation : null}</div>

        <div>DOB</div>
        <Input value={formData.M15_DOB} onChange={handleChangeInput} type="date" name={employeeKeys.M15_DOB} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_DOB ? errorData.M15_Designation : null}</div>

        <div>Employee code</div>
        <Input value={formData.M15_EmployeeCode} onChange={handleChangeInput} name={employeeKeys.M15_EmployeeCode} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_EmployeeCode ? errorData.M15_Designation : null}</div>

        <div>Gender</div>
        <Input value={formData.M15_Gender} onChange={handleChangeInput} name={employeeKeys.M15_Gender} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_Gender ? errorData.M15_Designation : null}</div>

        <div>Joining Date</div>
        <Input value={formData.M15_JoiningDate} onChange={handleChangeInput} type="date" name={employeeKeys.M15_JoiningDate} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_JoiningDate ? errorData.M15_Designation : null}</div>
        <div>Country</div>
        <select value={formData.M15_M03_CountryID} name={employeeKeys.M15_M03_CountryID} onChange={handleChangeInput as any} >
          <option disabled value={''} selected  >select</option>
          {
            country.map(
              (country, index) => <option key={index} value={country.M03_CountryID}>{country.M03_CountryName}</option>
            )
          }
        </select>
        <div className=' text-red-500'>{errorData.M15_M03_CountryID ? errorData.M15_M03_CountryID : null}</div>
        <div>Warehouse</div>
        <select value={formData.M15_I01_warehouseId} name={employeeKeys.M15_I01_warehouseId} onChange={handleChangeInput as any} >
          <option disabled value={''} selected  >select</option>
          {
            warehouses.map(
              (warehouse, index) => <option key={index} value={warehouse.I01_Warehouse_Id}>{warehouse.I01_Warehouse_name}</option>
            )
          }
        </select>
        <div className=' text-red-500'>{errorData.M15_I01_warehouseId ? errorData.M15_I01_warehouseId : null}</div>

        <div>Marital Status</div>
        <Input value={formData.M15_MaritalStatus} onChange={handleChangeInput} name={employeeKeys.M15_MaritalStatus} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_MaritalStatus ? errorData.M15_Designation : null}</div>

        <div>Qualification</div>
        <Input value={formData.M15_Qualification} onChange={handleChangeInput} name={employeeKeys.M15_Qualification} placeholder='DOB' />
        <div className=' text-red-500'>{errorData.M15_Qualification ? errorData.M15_Designation : null}</div>

        <div className=' flex gap-1'>
          <Switch
            checked={formData.M15_is_active == 1 ? true : false}
            name={employeeKeys.M15_Is_Active}
            onCheckedChange={
              (checked) => handleChangeInput({
                target: {
                  name: employeeKeys.M15_Is_Active,
                  value: checked ? 1 : '0',
                  checked: checked
                }
              } as React.ChangeEvent<HTMLInputElement>)
            }

          // onChange={handleChangeInput}
          />
          <div>is active</div>
        </div>
        <div className=' flex gap-1'>
          <Switch
            checked={formData.M15_AllowLogin == 1 ? true : false}
            name={employeeKeys.M15_AllowLogin}
            onCheckedChange={
              (checked) => handleChangeInput({
                target: {
                  name: employeeKeys.M15_AllowLogin,
                  value: checked ? 1 : '0',
                  checked: checked
                }
              } as React.ChangeEvent<HTMLInputElement>)
            }

          // onChange={handleChangeInput}
          />
          <div>allow login</div>
        </div>

        <div className=' flex justify-end'>
          <Button disabled={isLoading} onClick={addemployee} >{isLoading ? 'adding...' : 'Add'}</Button>
        </div>
      </div>
    </div>
  )
}
