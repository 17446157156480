import { useRESTapi } from "@/src/hooks/rest_API";
import { CarouselSmall, pagination } from "@/src/types";
import { CarouselTypeId } from "@/src/types/databaseFeeds";
import { Pause, Play, Volume2, VolumeX } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { TECarousel, TECarouselItem } from "tw-elements-react";

export function OfferCarousel() {
    const [carouselList, setCarouselList] = useState<CarouselSmall[]>([]);
    const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
    const [playingStates, setPlayingStates] = useState<boolean[]>([]);
    const [mutedStates, setMutedStates] = useState<boolean[]>([]);
    const carouselRef = useRef<any>(null);

    const api = useRESTapi();

    const loadCarousel = async () => {
        const res = await api.get<{ success: boolean, data: { data: CarouselSmall[] } & pagination }>({
            mode: 'customer',
            url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER}/carousel_small`
        });
        setCarouselList(res.data.data.data);
        setPlayingStates(new Array(res.data.data.data.length).fill(false));
        setMutedStates(new Array(res.data.data.data.length).fill(true));
    };

    useEffect(() => {
        loadCarousel();
    }, []);

    useEffect(() => {
        videoRefs.current.forEach((video, index) => {
            if (video) {
                video.muted = mutedStates[index];
            }
        });
    }, [mutedStates]);

    const togglePlay = (index: number) => {
        setPlayingStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });

        if (videoRefs.current[index]) {
            if (videoRefs.current[index]!.paused) {
                videoRefs.current[index]!.play();
            } else {
                videoRefs.current[index]!.pause();
            }
        }
    };

    const toggleMute = (index: number) => {
        setMutedStates(prevStates => {
            const newStates = [...prevStates];
            newStates[index] = !newStates[index];
            return newStates;
        });

        if (videoRefs.current[index]) {
            const video = videoRefs.current[index]!;
            video.muted = !video.muted;
            if (!video.muted) {
                video.volume = 1; // Ensure volume is set when unmuting
            }
        }
    };

    const pauseAllVideos = () => {
        videoRefs.current.forEach((video, index) => {
            if (video && !video.paused) {
                video.pause();
                setPlayingStates(prevStates => {
                    const newStates = [...prevStates];
                    newStates[index] = false;
                    return newStates;
                });
            }
        });
    };

    const handleSlide = () => {
        pauseAllVideos();
    };

    return (
        <>
            {carouselList.length > 0 ? (
                <TECarousel
                    ref={carouselRef}
                    showControls
                    showIndicators
                    crossfade
                    ride="carousel"
                    onSlide={handleSlide}
                    interval={false}
                    className="h-full rounded-lg overflow-hidden"
                >
                    <div className="relative aspect-[1.87] w-full overflow-hidden after:clear-both after:block after:content-['']">
                        {carouselList.map((item, index) => (
                            <TECarouselItem
                                itemID={index + 1}
                                key={index + 1}
                                className="relative float-left -mr-[100%] hidden h-full w-full !transform-none transition-opacity duration-[600ms] ease-in-out motion-reduce:transition-none"
                            >
                                {item.C303_C302_carousel_type === CarouselTypeId.Video ? (
                                    <div className="relative w-full h-full">
                                        <video
                                            ref={el => videoRefs.current[index] = el}
                                            src={`${process.env.REACT_APP_BACKEND_URL}/storage/${item.C303_source_path}`}
                                            className="block w-full h-full object-cover"
                                            loop
                                            muted={mutedStates[index]}
                                            playsInline
                                        />

                                        <div
                                            onClick={() => togglePlay(index)}
                                            className="absolute inset-0 flex items-center justify-center cursor-pointer"
                                        >
                                            {playingStates[index] ? (
                                                <Pause className="w-16 h-16 text-white opacity-70 hover:opacity-100 transition-opacity" />
                                            ) : (
                                                <Play className="w-16 h-16 text-white opacity-70 hover:opacity-100 transition-opacity" />
                                            )}
                                        </div>

                                    </div>
                                ) : (
                                    <img
                                        src={`${process.env.REACT_APP_BACKEND_URL}/storage/${item.C303_source_path}`}
                                        className="block w-full h-full object-cover"
                                        alt={item.C303_name}
                                    />
                                )}

                                {/* Mute Button */}
                                {/* <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        toggleMute(index);
                                    }}
                                    className="absolute bottom-4 right-4 cursor-pointer"
                                >
                                    {mutedStates[index] ? (
                                        <VolumeX className="w-8 h-8 text-white opacity-70 hover:opacity-100 transition-opacity" />
                                    ) : (
                                        <Volume2 className="w-8 h-8 text-white opacity-70 hover:opacity-100 transition-opacity" />
                                    )}
                                </div> */}
                            </TECarouselItem>
                        ))}
                    </div>
                </TECarousel>
            ) : (
                <div className="h-full w-full bg-gray-500 animate-pulse"></div>
            )}
        </>
    );
}