import React, { useEffect, useState } from 'react'
import { CustomizationOption } from 'src/types/local_storage'
import { useConfiguration } from 'src/hooks/configuration_hook';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';






// const options: CustomizationOption[] = [
//     { id: '1', value: '1.2mm', name: 'thickness', type: 'dropDown', varientID: '1', isSlab: null, rate: null, hikePerc: null, hikePercInRate: null, minVal: null, maxVal: null, order: 1, isDefault: true,baseRate:null },
//     { id: '2', value: null, name: 'height', type: 'normal', varientID: '1', isSlab: true, rate: 500, hikePerc: 10, hikePercInRate: 30, minVal: 100, maxVal: 200, order: 3, isDefault: true,baseRate:null },
//     { id: '10', value: null, name: 'width', type: 'normal', varientID: '1', isSlab: true, rate: 200, hikePerc: 10, hikePercInRate: 30, minVal: 50, maxVal: 200, order: 4, isDefault: true,baseRate:null },
//     //
//     { id: '4', value: '5mm', name: 'thickness', type: 'dropDown', varientID: '2', isSlab: null, rate: null, hikePerc: null, hikePercInRate: null, minVal: null, maxVal: null, order: 4, isDefault: null,baseRate:null },
//     { id: '5', value: null, name: 'height', type: 'normal', varientID: '2', isSlab: true, rate: 600, hikePerc: 10, hikePercInRate: 30, minVal: 101, maxVal: 200, order: 6, isDefault: null,baseRate:null },
//     { id: '3', value: null, name: 'width', type: 'normal', varientID: '2', isSlab: true, rate: 220, hikePerc: 10, hikePercInRate: 30, minVal: 50, maxVal: 200, order: 4, isDefault: null,baseRate:null },
//     //
//     // {id:'10',value:'5mm',name:'thickness',type:'dropDown',varientID:'4',isSlab:null,rate:null,hikePerc:null,hikePercInRate:null,minVal:null,maxVal:null,order:10,isDefault:null},
//     // {id:'11',value:'5m',name:'depth',type:'dropDown',varientID:'4',isSlab:null,rate:null,hikePerc:null,hikePercInRate:null,minVal:null,maxVal:null,order:11,isDefault:null},
//     // {id:'12',value:null,name:'height',type:'normal',varientID:'4',isSlab:true,rate:560,hikePerc:10,hikePercInRate:30,minVal:100,maxVal:200,order:12,isDefault:null},
// ]


type DropDown = {
    name: string,
    selectedValue: string,
    options: CustomizationOption[],
    selectedId: string,
    selectedVarientId: string,
    baseRate: number | null
}

type masterNormalType = {
    Inputvalue: number | '',
} & CustomizationOption

type MasterObject = {
    dropDowns: DropDown[],
    normals: masterNormalType[]
}

export function CustomizationComponent({
    options,
    basePrice,
    onChangeValues
}: {
    options: CustomizationOption[],
    basePrice: number,
    onChangeValues: (masterObject: MasterObject, isEveryThingValid: boolean) => void
}) {

    const [masterObject, setMasterObject] = useState<MasterObject>()
    const [isEveryThingValid, setIsEverythingValid] = useState<boolean>(true)
    const currency = useSelector((state: RootState) => state.configuration.currencyDetails)



    const createMasterObject = (variationId?: string): MasterObject => {
        const dropDownDefaults: { [name: string]: { selectedId: string, selectedVarientId: string, name: string, baseRate: number | null } } = {};
        const dropDownOptions: { [name: string]: CustomizationOption[] } = {};
        const normals: CustomizationOption[] = [];
        let realvariationId: string = '';
        let realOptionId: string = ''


        // Step 1: Collect default dropdown values and all possible dropdown options
        options.forEach(option => {
            if (option.type === 'dropDown') {
                if (!dropDownOptions[option.name]) {
                    dropDownOptions[option.name] = [];
                }
                dropDownOptions[option.name].push(option);
            } else if (option.type === 'normal') {
                normals.push(option);
            }
        });

        // Step 2: Find default dropdown values based on isDefault or specific variationId
        if (variationId) {
            const variationOptions = options.filter(option => option.varientID === variationId);
            variationOptions.forEach(option => {
                if (option.type === 'dropDown') {
                    dropDownDefaults[option.name] = { name: option.value || '', selectedId: option.id, selectedVarientId: option.varientID, baseRate: option.baseRate };
                    realOptionId = option.id
                }
            });
            realvariationId = variationId
        } else {
            options.forEach(option => {
                if (option.type === 'dropDown' && option.isDefault) {
                    dropDownDefaults[option.name] = { name: option.value || '', selectedId: option.id, selectedVarientId: option.varientID, baseRate: option.baseRate };
                    realvariationId = option.varientID
                    realOptionId = option.id
                }
            });

        }

        // Step 3: Find compatible dropdown options based on other selected values
        const compatibleOptions: { [name: string]: CustomizationOption[] } = {};
        Object.keys(dropDownDefaults).forEach(name => {
            compatibleOptions[name] = [];
        });

        options.forEach(option => {
            if (option.type === 'dropDown') {
                const currentVariant = options.filter(o => o.varientID === option.varientID);
                let isCompatible = true;
                Object.keys(dropDownDefaults).forEach(name => {
                    if (name !== option.name) {
                        const selectedValue = dropDownDefaults[name].name;
                        const matchingOption = currentVariant.find(o => o.name === name && o.value === selectedValue);
                        if (!matchingOption) {
                            isCompatible = false;
                        }
                    }
                });
                if (isCompatible) {
                    compatibleOptions[option.name].push(option);
                }
            }
        });

        // Step 4: Construct dropdowns with selected values and compatible options
        const dropDowns: DropDown[] = Object.keys(dropDownDefaults).map(name => ({
            name,
            selectedValue: dropDownDefaults[name].name,
            options: compatibleOptions[name],
            selectedId: dropDownDefaults[name].selectedId,
            selectedVarientId: dropDownDefaults[name].selectedVarientId,
            baseRate: dropDownDefaults[name].baseRate
        }));

        // step5: setting normal values
        let originalNormals: masterNormalType[] = []

        if (variationId) {
            originalNormals = normals.filter(option => option.varientID == variationId).map(item => ({ ...item, Inputvalue: item.minVal } as masterNormalType))
        } else {
            originalNormals = normals.filter(option => option.isDefault).map(item => ({ ...item, Inputvalue: item.minVal } as masterNormalType))
        }
        //setting the previouse inputValue
        let isValid: boolean = true
        if (masterObject && masterObject.normals.length > 0 && originalNormals.length > 0) {
            originalNormals.forEach(
                normals => {
                    const index = masterObject.normals.findIndex(
                        item => item.name == normals.name
                    )
                    if (index >= 0) {
                        normals.Inputvalue = masterObject.normals[index].Inputvalue
                        if (Number(normals.Inputvalue) > Number(normals.maxVal) || Number(normals.Inputvalue) < Number(normals.minVal)) {
                            isValid = false
                        }
                    }
                }
            )
        }
        setIsEverythingValid(isValid)
        onChangeValues({
            dropDowns,
            normals: originalNormals
        }, isValid)



        return {
            dropDowns,
            normals: originalNormals
        };
    };

    const findTotalValue = (basePrice: number, masterObjectProp: MasterObject): number => {

        let Total = basePrice;

        // finding totals for base price for dropdowns
        masterObjectProp.dropDowns.forEach(
            dropDown => {
                if (dropDown.baseRate) {
                    Total += Number(dropDown.baseRate)
                }
            }
        )

        masterObjectProp.normals.forEach(
            normal => {

                // if there is base rate, add it to total
                if (normal.baseRate) {
                    Total += Number(normal.baseRate)
                }
                // if not slab
                if (normal.isSlab === false || normal.isSlab === null) {
                    Total += normal.rate! * Number(normal.Inputvalue)
                } else {
                    // if slab

                    const percMinVal = (normal.minVal! / 100) * normal.hikePerc!
                    const percRate = (normal.rate! / 100) * normal.hikePercInRate!

                    const priceDiff = Number(normal.Inputvalue) - normal.minVal!
                    const nubmerOfCountPerc = Math.trunc(priceDiff / percMinVal)
                    Total += percRate * nubmerOfCountPerc

                }


            }
        )
        console.log(Total)

        return Total

    }

    const onchangeInput = (id: string, value: string) => {

        if (masterObject) {
            const index = masterObject.normals.findIndex(
                item => item.id == id
            )

            if (index >= 0) {
                masterObject.normals[index].Inputvalue = value ? Number(value) : ''

            }
            // checking is valid or not
            let isValid = true
            for (let i = 0; i < masterObject.normals.length; i++) {
                if (Number(masterObject.normals[i].Inputvalue) > Number(masterObject.normals[i].maxVal) || Number(masterObject.normals[i].Inputvalue) < Number(masterObject.normals[i].minVal)) {
                    isValid = false
                }
            }
            setIsEverythingValid(isValid)

            setMasterObject(
                {
                    ...masterObject
                }
            )
            onChangeValues({ ...masterObject }, isValid)
        }

    }

    useEffect(() => {
        // Example: Setting the masterObject for variantID '1' when the component mounts
        const masterObj = createMasterObject();
        console.log(masterObj)
        findTotalValue(basePrice, masterObj)
        setMasterObject(masterObj);
    }, []);

    return (
        <div className="p-6 bg-gray-100 rounded-lg shadow-lg">
            <h1 className="font-bold text-2xl  mb-4 text-center">Customization</h1>
            {masterObject && (
                <div className='grid md2:grid-cols-2 gap-2 grid-cols-1'>
                    {masterObject.dropDowns.map((dropdown, index) => {
                        if (dropdown.options.length > 1) {
                            return (
                                <div key={index} className="flex flex-col mb-4 ">
                                    <label className="font-bold mb-2 text-gray-700">{dropdown.name}</label>
                                    <select
                                        value={dropdown.selectedVarientId}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            console.log('variation is', newValue);
                                            const masterObj = createMasterObject(newValue);
                                            console.log(masterObj);
                                            setMasterObject(masterObj);
                                        }}
                                        className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    >
                                        {dropdown.options.map(option => (
                                            <option
                                                key={option.id}
                                                value={option.varientID || ''}
                                                className="py-2 px-4 hover:bg-blue-500 hover:text-white rounded-md cursor-pointer"
                                            >
                                                {option.value}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            );
                        }
                        return null;
                    })}
                    {masterObject.normals.map(option => (
                        <div key={option.id} className="flex flex-col mb-4">
                            <label className="font-bold mb-2 text-gray-700">{option.name}</label>
                            <input
                                type="number"
                                placeholder={option.name}
                                className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                                value={option.Inputvalue}
                                onChange={(e) => onchangeInput(option.id, e.target.value)}
                            />
                            <div className="text-red-500 mt-1">
                                {Number(option.Inputvalue) > option.maxVal!
                                    ? `Maximum value is ${option.maxVal}`
                                    : Number(option.Inputvalue) < option.minVal!
                                        ? `Minimum value is ${option.minVal}`
                                        : null}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <div className="mt-6 text-end">
                {isEveryThingValid ? (
                    <div className="font-bold text-xl text-green-600">
                        Total Price:<span className='text-base'>{currency.M05_CurrencySymbol}</span> {masterObject ? findTotalValue(basePrice, masterObject) : basePrice}
                        {/* <span className=' text-base pr-1'></span> */}     
                    </div>
                ) : (
                    <div className="font-bold text-xl text-red-600">Enter valid parameters</div>
                )}
            </div>
        </div>


    );
}
