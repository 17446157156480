import React, { useEffect, useState } from 'react'
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal';
import outlined from '@material-tailwind/react/theme/components/timeline/timelineIconColors/outlined';
import { edit_variation_props, variation_add_modal_props } from '../variationComponent';
import { Input } from '@/src/components/ui/input';
import { Button } from '@/src/components/ui/button';
import { useRESTapi } from '@/src/hooks/rest_API';
import { ProductVariation } from '@/src/types';
import useFormValidatonCustom, { validationObject } from '@/src/hooks/form_validation_hook';
import { Loader2 } from 'lucide-react';

type props = {
    add_variation_config: variation_add_modal_props,
    set_add_variation_config: (value: variation_add_modal_props) => any,
    reload_product: () => any,
    clearInput:()=>any
}

type formDataType = {
    variationName: string
}
export function VariationAddModal(props: props) {


    const [is_loading, set_is_loading] = useState(false)
    const api = useRESTapi()




    const addVariaton = async () => {
        set_is_loading(true)
        try {

            const res = await api.post<{ success: boolean, data: ProductVariation, errors: any, message: string }>({
                mode: 'admin',
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/variations`,
                body: {
                    M06_Name:props.add_variation_config.variation_name,
                    M06_M011_ProductId: props.add_variation_config.productId,
                    optionValue: [props.add_variation_config.option_name],
                }
            })
            if (res.data.success === true) {
                props.set_add_variation_config({ show: false,option_name:'',productId:'',variation_name:'' })
                props.clearInput()
                props.reload_product()
            } else {
                alert(res.data.message)
            }

        } catch (error) {
            console.log(error)
            alert('server error')
        }

        set_is_loading(false)
    }

    

    return (
        <AdminModal show={props.add_variation_config.show} setShow={(show) => {
            if (is_loading === false) {
                props.set_add_variation_config({
                    ...props.add_variation_config,
                    show: show,
                })
            }
        }} >
            <AdminModalContent disableCloseButton={is_loading} >
                <AdminModalHeader>
                    <AdminModalTitle>
                        Add Variation
                    </AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=' !max-w-48 !min-w-48'>
                   Do you want to add variaton?
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={is_loading} onClick={() => props.set_add_variation_config({ show: false, option_name:'',productId:'',variation_name:'' })} variant={'outline'}>Cancel</Button>
                    <Button disabled={is_loading} onClick={addVariaton}>{is_loading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Yes</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
