import React, { useState } from 'react'

interface ValidationFunction {
    (value: string | boolean | number| any): boolean | string;
}

interface ValidationItem {
    message: string;
    validation: ValidationFunction[];
}

type formdata = {
    [key: string]: any
}

export type validationObject = {
    [key: string]: ValidationItem
}

type props = {
    validationObject: validationObject,
}

type errObj = {
    [key:string]:string
}

export default function useFormValidatonCustom<T extends object>({
    validationObject,
}: props) {


    const [errorData,setErrorData] = useState<Partial<T>>({})

    const performAllValidation = (formdata:formdata) => {

        let errObj: any = {}
        let errNotOccured = true;

        for (const key in validationObject) {

            let isPass: string | boolean = true;

            for (let i = 0; i < validationObject[key].validation.length; i++) {
                isPass = validationObject[key].validation[i](formdata[key])
                if (isPass !== true) {
                    errNotOccured = false
                    errObj[key] = isPass;
                    break;
                }
            }



        }

        if(errNotOccured===false){
            console.log(errObj)
            setErrorData(errObj)
            return errObj
        }
        setErrorData({})

        return errNotOccured;

    }

    const performValidationOf = (name: string,formdata:formdata) => {
        let isPass: string | boolean = true;
        let errObj: any = {}
        let errNotOccured = true;

        for (let i = 0; i < validationObject[name]?.validation.length; i++) {
            isPass = validationObject[name].validation[i](formdata[name])
            if (isPass !== true) {
                errNotOccured = false
                errObj[name] = isPass;
                break;
            }
        }

        if(errNotOccured===false){
            setErrorData({...errorData,...errObj})
            return {...errorData,...errObj} 
        }
        setErrorData({...errorData,[name]:''})
         
    }

    // console.log(formdata)

    return {
        performAllValidation,
        performValidationOf,
        errorData,
        setErrorData
    }
}
