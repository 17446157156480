import { Button } from "../ui/button";
import React from "react";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../ui/tooltip";

export function ButtonIcon(
    {
        icon,
        toolTipText,
        onClick,
        varient = 'outline',
        disabled = false,
        className
    }:
        {
            icon: React.ReactNode,
            toolTipText?: string,
            onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
            varient?: "link" | "default" | "destructive" | "outline" | "secondary" | "ghost" | null,
            disabled?: boolean,
            className?: string
        }
) {
    return (

        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button className={className} disabled={disabled} onClick={onClick} variant={varient} size="icon">
                        {icon}
                    </Button>
                </TooltipTrigger>
                {
                    toolTipText ?
                        <TooltipContent>
                            <p>{toolTipText}</p>
                        </TooltipContent>
                        : null
                }
            </Tooltip>
        </TooltipProvider>

    )
}
export function ButtonIconRounded(
    {
        icon,
        toolTipText,
        onclick,
        className
    }:
        {
            icon: React.ReactNode,
            toolTipText?: string,
            onclick?: () => void,
            className?: string
        }
) {
    return (

        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger asChild>
                    <Button onClick={onclick} className={` p-2 rounded-full ${className}`} variant="secondary" size="iconBig">
                        {icon}
                    </Button>
                </TooltipTrigger>
                {
                    toolTipText ?
                        <TooltipContent>
                            <p>{toolTipText}</p>
                        </TooltipContent>
                        : null
                }
            </Tooltip>
        </TooltipProvider>

    )
}


export function WhatsappHover(
    {
        productsku,
        productLink
    }: {
        productsku: string,
        productLink: string
    }

) {
    return (
        <div className="fixed  right-3 bottom-20 md2:bottom-4 z-50">


            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <a
                            href={`https://wa.me/971569186717?text=${encodeURIComponent(
                                `Hello, I'm interested in this product and would like to inquire more about it. Here are the details:\n\n` +
                                `Product Link: ${productLink}\n\n` +
                                `Product SKU: ${productsku}\n\n` +
                                `Could you please provide more information about the availability, pricing, and delivery options?`
                            )}`}
                            target="_blank" rel="noreferrer">
                            <div className='w-[50px] h-[50px]  rounded-full overflow-hidden'>
                                <img src="/assets/whatsappLogo.jpg" alt="Logo" className='w-full h-full' />
                            </div>
                        </a>
                    </TooltipTrigger>

                    <TooltipContent className="mr-4">
                        <p>Purchase Through Whatsapp</p>
                    </TooltipContent>


                </Tooltip>
            </TooltipProvider>
        </div>
    )
}

export function WhatsappHoverList(
    {
        products
    }: {
        products: { sku: string, link: string }[]
    }

) {
    return (
        <div className="fixed right-3 bottom-20 md2:bottom-4 z-50">
            <TooltipProvider>
                <Tooltip>
                    <TooltipTrigger asChild>
                        <a
                            href={`https://wa.me/971569186717?text=${encodeURIComponent(
                                `Hello, I'm interested in the following products and would like to inquire more about them. Here are the details:\n\n` +
                                products.map(product =>
                                    `Product Link: ${product.link}\n` +
                                    `Product SKU: ${product.sku}\n\n`
                                ).join('') +
                                `Could you please provide more information about the availability, pricing, and delivery options for these products?`
                            )}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <div className='w-[50px] h-[50px] rounded-full overflow-hidden'>
                                <img src="/assets/whatsappLogo.jpg" alt="Logo" className='w-full h-full' />
                            </div>
                        </a>
                    </TooltipTrigger>

                    <TooltipContent className="mr-4">
                        <p>Purchase Through Whatsapp</p>
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        </div>
    )
}

export function WhatsappButtonList(
    {
        products
    }: {
        products: { sku: string, link: string }[]
    }

) {
    return (

        <a
            className="flex items-center bg-green-500 rounded-lg p-5 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
            href={`https://wa.me/971569186717?text=${encodeURIComponent(
                `Hello, I'm interested in the following products and would like to inquire more about them. Here are the details:\n\n` +
                products.map((product,index) =>
                    `${index + 1}. Product Link: ${product.link}\n` +
                    `   Product SKU: ${product.sku}\n\n`
                ).join('') +
                `Could you please provide more information about the availability, pricing, and delivery options for these products?`
            )}`}
            // target="_blank"
            rel="noreferrer"
        >
            <div className='w-[30px] h-[30px] rounded-full overflow-hidden'>
                <img src="/assets/whatsappLogo.jpg" alt="Logo" className='w-full h-full' />
            </div>
            <p className=" pl-1 text-white font-bold">Through Whatsapp</p>
        </a>
    )
}
