import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import { useAlertModal } from "@/src/hooks/alert_modal"
import useFormValidatonCustom, { validationObject } from "@/src/hooks/form_validation_hook"
import { useRESTapi } from "@/src/hooks/rest_API"
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from "@/src/pages/admin/components/modal"
import { RootState } from "@/src/redux/store"
import { ProductCategory } from "@/src/types"
import { IconReload } from "@tabler/icons-react"
import { useState } from "react"
import { useSelector } from "react-redux"

type props = {
    show: boolean,
    setShow: (show: boolean) => any,
    isLoading: boolean,
    setLoading:(boolean:boolean)=>any,
    orderId?: string,
    updateUi: () => void,


}


export function CancellationConformModal(props: props) {


    const api = useRESTapi();
    const alertModal = useAlertModal()
    const employee = useSelector((state:RootState)=>state.employee.employeeData)

    const cancellUserRequist = async () => {

        props.setLoading(true)
        try {

            const res = await api.post<{ success: boolean, data: string, message: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/cancel_order`,
                mode: 'admin',
                body:{
                    orderId:props.orderId,
                    cancelledBy:employee.M15_Employee_id
                }
            })
            if (res.data.success === true) {
                props.updateUi()
                props.setShow(false)
                
            } else {
                alertModal.open_alert_modal({
                    content: res.data.message,
                    footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                    title: 'Error'
                })
            }

        } catch (error) {
            console.log(error)
            alertModal.open_alert_modal({
                content: 'Server error',
                footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
                title: 'Error'
            })

        }
        props.setLoading(false)

    }


    return (
        <AdminModal show={props.show} setShow={props.setShow}>
            <AdminModalContent disableCloseButton={props.isLoading} className=" !max-w-[500px] !min-w-[500px]">
                <AdminModalHeader>
                    <AdminModalTitle>Confirm?</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className=" !max-w-[500px] !min-w-[500px]">
                    <Label>Are you sure to cancel user cancel requist?</Label>
                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button onClick={cancellUserRequist} disabled={props.isLoading} >{props.isLoading && <IconReload className="mr-2 h-4 w-4 animate-spin" />}Yes</Button>
                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>)


}