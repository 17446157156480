import React, { useEffect, useState } from 'react';
import { Offcanvas, Ripple, initTWE } from 'tw-elements';
import { IconAdjustments } from '@tabler/icons-react';
import { orderStatusesIds } from '@/src/types/databaseFeeds';

const BottomModal: React.FC<{
  orderStatus: string | number | undefined,
  onchangeFilter: React.ChangeEventHandler<HTMLInputElement>
}> = (props) => {
  useEffect(() => {
    initTWE({ Offcanvas, Ripple });
  }, []);

  const [activeFilter, setActiveFilter] = useState<string>('');

  const toggleFilter = (filter: string) => {
    setActiveFilter((prev) => (prev === filter ? '' : filter));
    document.querySelector('[data-twe-offcanvas-dismiss]')?.dispatchEvent(new Event('click'));
  };

  return (
    <>
      <button
        type="button"
        data-twe-offcanvas-toggle
        data-twe-target="#offcanvasBottom"
        aria-controls="offcanvasBottom"
        data-twe-ripple-init
        data-twe-ripple-color="light"
        className="inline-block rounded"
      >
        <IconAdjustments />
      </button>

      <div
        className="invisible fixed bottom-0 left-0 right-0 z-[1045] flex h-1/3 max-h-full max-w-full translate-y-full flex-col border-none bg-white bg-clip-padding text-neutral-700 shadow-sm outline-none transition duration-300 ease-in-out data-[twe-offcanvas-show]:transform-none dark:bg-body-dark dark:text-white"
        tabIndex={-1}
        id="offcanvasBottom"
        aria-labelledby="offcanvasBottomLabel"
        data-twe-offcanvas-init
      >
        <div className="flex items-center justify-between p-4">
          <h5 className="mb-0 font-semibold leading-normal" id="offcanvasBottomLabel">
            Filter
          </h5>
          <button
            type="button"
            className="box-content rounded-none border-none text-neutral-500 hover:text-neutral-800 hover:no-underline focus:text-neutral-800 focus:opacity-100 focus:shadow-none focus:outline-none dark:text-neutral-400 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
            data-twe-offcanvas-dismiss
            aria-label="Close"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div className="flex-grow overflow-y-auto p-4">
          <div className="mb-4">
            <div className="py-3">Order Status</div>
            <div className="flex flex-wrap space-x-2 mb-2 gap-2">
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == undefined || props.orderStatus == ''
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                onClick={() => props.onchangeFilter(
                  {
                    target: {
                      name: 'orderStatus',
                      value: ''
                    }
                  } as React.ChangeEvent<HTMLInputElement>
                )}
              >
                All
              </button>
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == orderStatusesIds.cancelled
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                onClick={() => props.onchangeFilter(
                  {
                    target: {
                      name: 'orderStatus',
                      value: orderStatusesIds.cancelled
                    }
                  } as React.ChangeEvent<HTMLInputElement>
                )}
              >
                Cancelled
              </button>
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == orderStatusesIds.processing
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => props.onchangeFilter(
                    {
                      target: {
                        name: 'orderStatus',
                        value: orderStatusesIds.processing
                      }
                    } as React.ChangeEvent<HTMLInputElement>
                  )}
              >
                Processing
              </button>
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == orderStatusesIds.readyToShip
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => props.onchangeFilter(
                    {
                      target: {
                        name: 'orderStatus',
                        value: orderStatusesIds.readyToShip,
                      }
                    } as React.ChangeEvent<HTMLInputElement>
                  )}
              >
                Ready To Ship
              </button>
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == orderStatusesIds.shipped
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => props.onchangeFilter(
                    {
                      target: {
                        name: 'orderStatus',
                        value: orderStatusesIds.shipped
                      }
                    } as React.ChangeEvent<HTMLInputElement>
                  )}
              >
                Shipped
              </button>
              <button
                className={`flex items-center justify-center text-grey font-bold py-2 px-4 rounded-full border-2 ${props.orderStatus == orderStatusesIds.delivered
                  ? 'border-blue-500 bg-blue-100 text-blue-700'
                  : 'border-gray-200 bg-gray-200 text-gray-700 hover:bg-gray-300'
                  }`}
                  onClick={() => props.onchangeFilter(
                    {
                      target: {
                        name: 'orderStatus',
                        value: orderStatusesIds.delivered
                      }
                    } as React.ChangeEvent<HTMLInputElement>
                  )}
              >
                Delivered
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BottomModal;
