import { ChangeEvent, useState } from "react";
import {
  CustomerModal,
  CustomerModalContent,
  CustomerModalHeader,
  CustomerModalTitle,
  CustomerModalMiddle,
  CustomerModalFooter,
} from "../../components/Modal/cosModal"; // Adjust path as necessary
import { SelectShadcn } from "../../components/Select/selectShadcn";
import { Button } from "@/src/components/ui/button";
import { useRESTapi } from "@/src/hooks/rest_API";
import { useAlertModal } from "@/src/hooks/alert_modal";

type Props = {
  show: boolean;
  setShow: (show: boolean) => any;
  options:({
    name:string,
    value:string
  })[],
  orderId:string,
  updateUi:()=>any
};

export function CancelModal(props: Props) {
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [textareaVisible, setTextareaVisible] = useState<boolean>(false);
  const api = useRESTapi()
  const alertModal = useAlertModal()

  const handleSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOption(value);
    // setTextareaVisible(value === '6');
  };

  const handleClose = () => props.setShow(false);

  const handleSubmit = async() => {
    try {
      const res = await api.post<{
        success: boolean;
        data: any;
        message: string;
      }>({
        url: `${process.env.REACT_APP_BACKEND_URL_CUSTOMER!}/cancel_order`,
        mode:'customerPrivate',
        body:{
          reasonId:selectedOption,
          isPresetted:1,
          orderId:props.orderId
        }
      })
      if(res.data.success===true){
        alertModal.open_alert_modal({
          content: 'order cancelled',
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'cancelled'
        })
        props.setShow(false);
        props.updateUi()
      }else{
        alertModal.open_alert_modal({
          content: res.data.message,
          footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
          title: 'Error'
        })
      }
    } catch (error) {
      console.log(error)
      alertModal.open_alert_modal({
        content: 'Server error',
        footer: <Button onClick={alertModal.close_alert_modal}>Ok</Button>,
        title: 'Error'
      })
    }
  };

  // const options = [
  //   { name: "Item no longer available", value: "1" },
  //   { name: "Ordered by mistake", value: "2" },
  //   { name: "Found a better deal elsewhere", value: "3" },
  //   { name: "Delivery delayed", value: "4" },
  //   { name: "Changed mind", value: "5" },
  //   { name: "Other", value: "6" },
  // ];

  return (
    <CustomerModal show={props.show} setShow={props.setShow}>
      <CustomerModalContent>
        <CustomerModalHeader>
          <CustomerModalTitle>Cancel Order</CustomerModalTitle>
        </CustomerModalHeader>
        <CustomerModalMiddle>
          <div>
            {/* <label htmlFor="returnReason">Select Reason for Return</label> */}
            <div>
            <SelectShadcn
              options={props.options}
              value={selectedOption}
              onChange={handleSelectChange}
              classNameOuter="w-1/2 min-w-[250px]"
              placeHolder="Reason"
            />
            </div>
            {textareaVisible && (
            <div className="mt-4">
                <label htmlFor="otherReason">Please specify</label>
                <textarea
                  id="otherReason"
                  rows={4}
                  className="mt-1 block w-1/2 min-w-[250px] border-blue-gray-200 border-2 rounded-md"
                  required
                />
            </div>
            )}
          </div>
        </CustomerModalMiddle>
        <CustomerModalFooter>
          <Button className="bg-blue-gray-300 hover:bg-blue-gray-600" onClick={handleClose}>Close</Button>
          <Button className="bg-green-500 hover:bg-green-800" onClick={handleSubmit}>Submit</Button>
        </CustomerModalFooter>
      </CustomerModalContent>
    </CustomerModal>
  );
}
