import { useState, useEffect } from "react";
import ScrollableDiv from "./ScrollableDiv";
import CarouselForMob from "./CarouselForMob";

interface CarouselProps {
    children: React.ReactElement[];
    autoSlide?: boolean;
    autoSlideInterval?: number;
    thumbnails: string[]
}

export function ProductCarousel({
    children: slides,
    autoSlide = false,
    autoSlideInterval = 3000,
    thumbnails
}: CarouselProps) {
    const [curr, setCurr] = useState<number>(0);

    // const prev = () =>
    //     setCurr((curr) => (curr === 0 ? slides.length - 1 : curr - 1));
    const next = () =>
        setCurr((curr) => (curr === slides.length - 1 ? 0 : curr + 1));

    useEffect(() => {
        if (!autoSlide) return;
        const slideInterval = setInterval(next, autoSlideInterval);
        return () => clearInterval(slideInterval);
    }, []);


    return (
        // <div className="bg-white rounded-lg shadow-md p-4 md:p-8 mx-auto max-w-4xl">
        //     <div className="flex flex-col md:flex-row gap-4">
        //         <div className="w-full md:w-1/4 flex flex-col gap-2">
        //             {thumbnails.map((t, i) => (
        //                 <div
        //                     onClick={() => setCurr(i)}
        //                     key={i}
        //                     className={`rounded-lg p-1 hover:cursor-pointer focus:opacity-70 transition-opacity duration-300 ${curr === i && "border-2 border-blue-500"}`}
        //                 >
        //                     <img
        //                         className={`rounded-md w-full h-auto ${curr === i && "opacity-50"}`}
        //                         src={t}
        //                         alt=""
        //                     />
        //                 </div>
        //             ))}
        //         </div>
        //         <div className="w-full md:w-3/4 relative aspect-video rounded-lg bg-gray-100 overflow-hidden">
        //             <div
        //                 className="flex transition-transform ease-out duration-500 h-full"
        //                 style={{ transform: `translateX(-${curr * 100}%)` }}
        //             >
        //                 {slides.map((slide, index) => (
        //                     <div
        //                         key={index}
        //                         className="w-full h-full flex-shrink-0 flex justify-center items-center"
        //                     >
        //                         {slide}
        //                     </div>
        //                 ))}
        //             </div>
        //         </div>
        //     </div>
        // </div>



        // p-6 bg-gray-100 rounded-lg shadow-lg
        <>
            <div className="lg:h-[400px] h-[350px] w-min md2:flex hidden  overflow-hidden rounded-lg  md2:gap-1">
                <ScrollableDiv thumbnails={thumbnails} curr={curr} setCurr={setCurr} />
                <div className="  h-[350px] lg:h-[400px] w-[350px] lg:w-[400px] relative rounded-lg  bg-white overflow-hidden shadow-lg">
                    <div
                        className="flex transition-transform ease-out duration-500 h-full"
                        style={{ transform: `translateX(-${curr * 100}%)` }}
                    >   
                        {slides.map((slide, index) => (
                            <div
                                key={index}
                                className="w-full h-full flex-shrink-0 flex justify-center items-center"
                            >
                                {slide}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="md2:hidden block">
                <CarouselForMob thumbnails={thumbnails} />
            </div>
        </>

    )
};
