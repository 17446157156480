import React from 'react';
import { IconCheck, IconAlertCircle } from "@tabler/icons-react";
import { OrderStatus, SalesOrderStatusHistory } from '@/src/types';
import { useNavigate } from 'react-router-dom';

interface OrderBusProps {
  fullStatuses: OrderStatus[];
  currentStatuses: SalesOrderStatusHistory[];
  currentStop: string;
  expectedDeliveryDate: string | null;
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

const OrderBus: React.FC<OrderBusProps> = ({ fullStatuses, currentStatuses, currentStop, expectedDeliveryDate }) => {
  let stopReached = false; // flag to track if the currentStop has been reached

  const baseStatuses: (OrderStatus & { date?: string })[] = [
    { I030_order_name: 'ordered', I030_order_status_id: '' },
    { I030_order_name: 'processing', I030_order_status_id: '' },
    { I030_order_name: 'readyToShip', I030_order_status_id: '' },
    { I030_order_name: 'shipped', I030_order_status_id: '' },
    { I030_order_name: 'delivered', I030_order_status_id: '' }
  ];

  const navigate = useNavigate();

  if (fullStatuses.length) {
    // storing the delivery status in correct order and checking the server has delivery status
    let error = false;

    baseStatuses.forEach(statusOur => {
      const newStatus = fullStatuses.find(status => status.I030_order_name === statusOur.I030_order_name);
      if (newStatus) {
        statusOur.I030_order_status_id = newStatus.I030_order_status_id;
      } else {
        error = true;
      }
    });

    if (error) {
      alert('delivery status mismatch');
      navigate('/');
    }
  }

  // Merge dates into baseStatuses
  baseStatuses.forEach(statusOur => {
    const currentStatus = currentStatuses.find(status => status.I31_I030_status === statusOur.I030_order_status_id);
    if (currentStatus) {
      statusOur.date = currentStatus.I31_status_change_date;
    }
  });

  // Extract cancelled and CancelledPending statuses and merge them based on date
  const cancelStatuses = currentStatuses.filter(status => {
    const statusName = fullStatuses.find(fs => fs.I030_order_status_id === status.I31_I030_status)?.I030_order_name;
    return statusName === 'cancelled' || statusName === 'CancelledPending';
  }).map(status => ({
    I030_order_name: fullStatuses.find(fs => fs.I030_order_status_id === status.I31_I030_status)?.I030_order_name!,
    I030_order_status_id: status.I31_I030_status,
    date: status.I31_status_change_date
  }));

  // Combine baseStatuses and cancelStatuses
  const ourStatuses = [...baseStatuses, ...cancelStatuses];

  // Sort the combined statuses by date
  ourStatuses.sort((a, b) => {
    const dateA = a.date ? new Date(a.date).getTime() : Infinity;
    const dateB = b.date ? new Date(b.date).getTime() : Infinity;
    return dateA - dateB;
  });

  return (
    <>
      {ourStatuses.map((item, index) => {
        const isCurrentStop = currentStop === item.I030_order_name;
        const isCancelStatus = item.I030_order_name === 'cancelled' || item.I030_order_name === 'CancelledPending';

        // Determine the color based on whether the current stop has been reached
        let spanColor = 'bg-green-500';
        if (stopReached) {
          spanColor = 'bg-gray-500'; // change color to grey for items after the current stop or if it's a cancel status
        }else if(isCancelStatus){
          spanColor = 'bg-red-500'
        }

        // Mark that the current stop has been reached
        if (isCurrentStop) {
          stopReached = true;
        }

        return (
          <li key={index} className="mb-10 ml-6 relative list-none">
            <span className={`absolute left-[-2.30rem] flex h-6 w-6 items-center justify-center rounded-full ${spanColor} dark:ring-gray-800 text-white`}>
              {isCancelStatus ? <IconAlertCircle stroke={2} /> : <IconCheck stroke={2} />}
            </span>
            <h4 className="mb-0.5 text-base font-semibold text-gray-900 dark:text-white ms-5">
              {currentStop === 'shipped' && item.I030_order_name === 'delivered' && expectedDeliveryDate
                ? (
                  <>
                    <div>Expected Delivery :</div>
                    <div>{formatDate(expectedDeliveryDate)}</div>
                  </>
                )
                : item.date
                  ? formatDate(item.date)
                  : 'Date not confirmed'}
            </h4>
            <p className="text-sm font-normal text-gray-500 dark:text-gray-400 ms-5">
              {item.I030_order_name}
            </p>
          </li>
        );
      })}
    </>
  );
};

export default OrderBus;
