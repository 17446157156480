import React, { useEffect, useRef, useState } from 'react'
import useFormValidatonCustom, { validationObject } from '../../../../../../hooks/form_validation_hook'
import { useRESTapi } from '../../../../../../hooks/rest_API'
import { Brand, Country, CustomFile, Product, ProductCategory, ProductImage, ProductItem, ProductItemKeys, ProductKeys, Warehouse, employee, employeeKeys, pagination, role } from '../../../../../../types'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../../redux/store'
import { IconPlus, IconX } from '@tabler/icons-react'
import { v4 as uuidv4 } from 'uuid';
import { AdminModal, AdminModalContent, AdminModalFooter, AdminModalHeader, AdminModalMiddle, AdminModalTitle } from '@/src/pages/admin/components/modal'
import { Input } from '@/src/components/ui/input'
import { SelectShadcn } from '@/src/pages/customer/components/Select/selectShadcn'
import { Switch } from '@/src/components/ui/switch'
import { Button } from '@/src/components/ui/button'
import { Label } from '@/src/components/ui/label'
import { Textarea } from '@/src/components/ui/textarea'
import { Loader2 } from 'lucide-react'



type employeeOverView = employee


type imageIds = string[]



export function AddEmployeeModal(
    {
        show,
        closeModal,
        id,
        updateUi,
        mode,
        setShow
    }:
        {
            show: boolean,
            closeModal: () => void,
            id?: string,
            updateUi: () => void,
            mode: 'add' | 'edit',
            setShow: (show: boolean) => any

        }
) {


    const validationObject: validationObject = {
        [employeeKeys.M15_Name]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.email]: {
            message: '',
            validation: [(value) => !value || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toString()) ? 'not valid email' : true]
        },
        [employeeKeys.M15_M13_Role_id]: {
            message: '',
            validation: [(value) => !value ? 'please select role' : true]
        },
        [employeeKeys.M15_Phone]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_Password]: {
            message: '',
            validation: [(value) => !value&&mode=='add' ? 'required' : true]
        },
        [employeeKeys.M15_Hire_date]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_Address]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_BloodGroup]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_Designation]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_DOB]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_EmployeeCode]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_Gender]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_JoiningDate]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_M03_CountryID]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_M15_ReportingTo]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_MaritalStatus]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_Qualification]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
        [employeeKeys.M15_I01_warehouseId]: {
            message: '',
            validation: [(value) => !value ? 'required' : true]
        },
    } satisfies validationObject

    const api = useRESTapi();
    const [loading, set_loading] = useState(false)
    const [isAdding, setisAdding] = useState(false)
    const employee = useSelector((state: RootState) => state.employee)
    const [formData, setFormData] = useState<employeeOverView>({
        M15_Name: '',
        M15_created_by: '',
        email: '',
        M15_Phone: '',
        M15_Password: '',
        M15_Hire_date: '',
        M15_M13_role_id: '',
        M15_Address: '',
        M15_BloodGroup: '',
        M15_Designation: '',
        M15_DOB: '',
        M15_EmployeeCode: '',
        M15_Gender: '',
        M15_JoiningDate: '',
        M15_M03_CountryID: '',
        M15_M15_ReportingTo: '',
        M15_MaritalStatus: '',
        M15_Qualification: '',
        M15_is_active: 1,
        M15_AllowLogin: 1,
    })

    const [roles, setRoles] = useState<role[]>([])
    const [country, setCountry] = useState<Country[]>([])
    const [warehouses, setwarehouses] = useState<Warehouse[]>([])
    const { performAllValidation, performValidationOf, errorData, setErrorData } = useFormValidatonCustom<employeeOverView>({
        validationObject: validationObject
    })

    function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

        let formdata
        if (e.target.name === employeeKeys.M15_Is_Active) {
            formdata = {
                ...formData,
                [e.target.name]: e.target.checked ? 1 : 0
            }
        } else {
            formdata = {
                ...formData,
                [e.target.name]: e.target.value
            }
        }

        setFormData(formdata)
        performValidationOf(e.target.name, formdata)
        console.log(formdata)

    }

    async function addemployee() {
        set_loading(true)
        formData.M15_M15_ReportingTo = employee.employeeData.M15_Employee_id!
        formData.M15_created_by = employee.employeeData.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {
                const res = await api.post<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees`,
                    body: formData,
                    mode: 'admin',

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)


                    }
                } else {
                    setFormData({
                        M15_Name: '',
                        M15_created_by: '',
                        email: '',
                        M15_Phone: '',
                        M15_Password: '',
                        M15_Hire_date: '',
                        M15_M13_role_id: '',
                        M15_Address: '',
                        M15_BloodGroup: '',
                        M15_Designation: '',
                        M15_DOB: '',
                        M15_EmployeeCode: '',
                        M15_Gender: '',
                        M15_JoiningDate: '',
                        M15_M03_CountryID: '',
                        M15_M15_ReportingTo: '',
                        M15_MaritalStatus: '',
                        M15_Qualification: '',
                        M15_is_active: 1,
                        M15_AllowLogin: 1,
                    })
                    updateUi()
                    closeModal()
                    alert(res.data.message)
                }
            } catch (error) {
                console.log(error)
            }
        }
        set_loading(false)

    }
    async function editEmployee() {
        set_loading(true)
        formData.M15_M15_ReportingTo = employee.employeeData.M15_Employee_id!
        formData.M15_created_by = employee.employeeData.M15_Employee_id!
        if (performAllValidation(formData) === true) {

            try {
                const res = await api.put<{ success: boolean, data: employee, errors: any, message: string }>({
                    url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees/${id}`,
                    body: formData,
                    mode: 'admin',

                })

                if (res.data.success === false) {

                    alert(res.data?.message || 'something went wrong')
                    if (res.data.errors) {

                        const errs = res.data.errors;
                        const errData: any = {}

                        for (const key in errs) {
                            errData[key] = errs[key][0]
                        }

                        setErrorData(errData)


                    }
                } else {
                    setFormData({
                        M15_Name: '',
                        M15_created_by: '',
                        email: '',
                        M15_Phone: '',
                        M15_Password: '',
                        M15_Hire_date: '',
                        M15_M13_role_id: undefined,
                        M15_Address: '',
                        M15_BloodGroup: '',
                        M15_Designation: '',
                        M15_DOB: '',
                        M15_EmployeeCode: '',
                        M15_Gender: '',
                        M15_JoiningDate: '',
                        M15_M03_CountryID: '',
                        M15_M15_ReportingTo: '',
                        M15_MaritalStatus: '',
                        M15_Qualification: '',
                        M15_is_active: 1,
                        M15_AllowLogin: 1,
                    })
                    alert(res.data.message)
                    updateUi()
                    closeModal()
                }
            } catch (error) {
                console.log(error)
            }
        }
        set_loading(false)

    }

    const clear_data = () => {
        setFormData({
            M15_Name: '',
            M15_created_by: '',
            email: '',
            M15_Phone: '',
            M15_Password: '',
            M15_Hire_date: '',
            M15_M13_role_id: undefined,
            M15_Address: '',
            M15_BloodGroup: '',
            M15_Designation: '',
            M15_DOB: '',
            M15_EmployeeCode: '',
            M15_Gender: '',
            M15_JoiningDate: '',
            M15_M03_CountryID: '',
            M15_M15_ReportingTo: '',
            M15_MaritalStatus: '',
            M15_Qualification: '',
            M15_is_active: 1,
            M15_AllowLogin: 1,
        })
        setErrorData({})
        setisAdding(false)
    }


    const load_product = async () => {

        try {

            const res = await api.get<{ success: boolean, data: employeeOverView, message?: string }>({
                url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/employees/${id}`,
                mode: 'admin',

            })
            if (res.data.success === true) {

                setFormData(res.data.data)

            } else {
                throw res.data
            }


        } catch (error) {
            console.log(error)
            alert('server error')
        }

    }



    useEffect(
        () => {

            const loadRole = async () => {

                try {
                    const res = await api.get<{ success: boolean, data: role[] }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/roles`,
                        mode: 'admin',

                    })

                    if (res.data.success === true) {
                        setRoles(res.data.data)
                    }

                } catch (error) {
                    console.log(error)
                }

            }

            const loadCountry = async () => {

                try {
                    const res = await api.get<{ success: boolean, data: { data: Country[] } & pagination }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/country`,
                        mode: 'admin',

                    })

                    setCountry(res.data.data.data)

                } catch (error) {
                    console.log(error)
                }

            }
            const loadWarehouse = async () => {

                try {
                    const res = await api.get<{ success: boolean, data: { data: Warehouse[] } & pagination }>({
                        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/warehouse`,
                        mode: 'admin',

                    })

                    setwarehouses(res.data.data.data)

                } catch (error) {
                    console.log(error)
                }

            }

            const initEditSetup = () => {
                set_loading(true)
                Promise.all(
                    [loadCountry(), loadRole(), loadWarehouse(), load_product()]
                ).then(
                    () => {
                        set_loading(false)

                    }
                ).catch(
                    (err) => {
                        set_loading(false)

                    }
                )

            }

            const initListSetup = () => {
                set_loading(true)
                clear_data()

                Promise.all(
                    [loadCountry(), loadRole(), loadWarehouse()]
                ).then(
                    () => {
                        set_loading(false)
                    }
                ).catch(
                    (err) => {
                        set_loading(false)
                    }
                )

            }

            if (mode === 'add' && show === true) {
                setErrorData({})
                initListSetup()
            } else if (mode === 'edit' && show === true) {
                clear_data()
                initEditSetup()
            } else {
                set_loading(true)
            }


        }, [show]
    )



    return (
        <AdminModal show={show} setShow={(show) => {
            setShow(show)
            if (show === false) clear_data()
        }}  >
            <AdminModalContent  >
                <AdminModalHeader  >
                    <AdminModalTitle >{mode==='add'?'Add Employee':"Edit Employee"}</AdminModalTitle>
                </AdminModalHeader>
                <AdminModalMiddle className={`${isAdding || loading ? 'bg-gray-500/30' : ''}`}>
                    <Label>Name</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_Name} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Name} placeholder='name' />
                    <div className=' text-red-500'>{errorData.M15_Name ? errorData.M15_Name : null}</div>
                    <div className='h-2'></div>
                    <Label>Email</Label>
                    <Input disabled={isAdding || loading} value={formData.email} onChange={handleChangeInput} type="text" name={employeeKeys.email} placeholder='email' />
                    <div className=' text-red-500'>{errorData.email ? errorData.email : null}</div>
                    <div className='h-2'></div>

                    <Label>Phone No</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_Phone} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Phone} placeholder='phone No' />
                    <div className=' text-red-500'>{errorData.M15_Phone ? errorData.M15_Phone : null}</div>
                    <div className='h-2'></div>

                    {mode==='add'&&<>
                        <Label>Password</Label>
                        <Input disabled={isAdding || loading} value={formData.M15_Password} onChange={handleChangeInput} type="text" name={employeeKeys.M15_Password} placeholder='Password' /></>}
                    <div className=' text-red-500'>{errorData.M15_Password ? errorData.M15_Password : null}</div>
                    <div className='h-2'></div>

                    <Label>hire date</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_Hire_date} onChange={handleChangeInput} type="date" name={employeeKeys.M15_Hire_date} placeholder='hire Date' />
                    <div className=' text-red-500'>{errorData.M15_Hire_date ? errorData.M15_Hire_date : null}</div>
                    <div className='h-2'></div>

                    <Label>Role</Label>
                    <SelectShadcn
                        options={roles.map(role => ({
                            name: role.M13_RoleName,
                            value: String(role.M13_Role_Id)
                        }))}
                        name={employeeKeys.M15_M13_Role_id}
                        onChange={handleChangeInput as any}
                        placeHolder='Select role'
                        disabled={isAdding || loading}
                        value={String(formData.M15_M13_role_id)}
                    />
                    <div className=' text-red-500'>{errorData.M15_M13_role_id ? errorData.M15_M13_role_id : null}</div>
                    <div className='h-2'></div>

                    <Label>Address</Label>
                    <Textarea disabled={isAdding || loading} value={formData.M15_Address} onChange={handleChangeInput as any as React.ChangeEventHandler<HTMLTextAreaElement>} name={employeeKeys.M15_Address} placeholder='Address' />
                    <div className=' text-red-500'>{errorData.M15_Address ? errorData.M15_Address : null}</div>
                    <div className='h-2'></div>

                    <Label>Blood Group</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_BloodGroup} onChange={handleChangeInput} name={employeeKeys.M15_BloodGroup} placeholder='Blood group' />
                    <div className=' text-red-500'>{errorData.M15_BloodGroup ? errorData.M15_BloodGroup : null}</div>
                    <div className='h-2'></div>


                    <Label>Designation</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_Designation} onChange={handleChangeInput} name={employeeKeys.M15_Designation} placeholder='Designation' />
                    <div className=' text-red-500'>{errorData.M15_Designation ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <Label>DOB</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_DOB} onChange={handleChangeInput} type="date" name={employeeKeys.M15_DOB} placeholder='DOB' />
                    <div className=' text-red-500'>{errorData.M15_DOB ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <Label>Employee code</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_EmployeeCode} onChange={handleChangeInput} name={employeeKeys.M15_EmployeeCode} placeholder='Employee Code' />
                    <div className=' text-red-500'>{errorData.M15_EmployeeCode ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <Label>Gender</Label>
                    <SelectShadcn
                        options={[
                            {
                                name:'Male',
                                value:'male'
                            },
                            {
                                name:'Female',
                                value:'female'
                            }
                        ]}
                        name={employeeKeys.M15_Gender}
                        onChange={handleChangeInput as any}
                        placeHolder='Select'
                        disabled={isAdding || loading}
                        value={String(formData.M15_Gender)}
                    />
                    {/* <Input disabled={isAdding || loading} value={formData.M15_Gender} onChange={handleChangeInput} name={employeeKeys.M15_Gender} placeholder='Gender' /> */}
                    <div className=' text-red-500'>{errorData.M15_Gender ? errorData.M15_Gender : null}</div>
                    <div className='h-2'></div>

                    <Label>Joining Date</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_JoiningDate} onChange={handleChangeInput} type="date" name={employeeKeys.M15_JoiningDate} placeholder='Joining Date' />
                    <div className=' text-red-500'>{errorData.M15_JoiningDate ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <Label>Country</Label>
                    <SelectShadcn
                        options={country.map(role => ({
                            name: role.M03_CountryName,
                            value: String(role.M03_CountryID)
                        }))}
                        name={employeeKeys.M15_M03_CountryID}
                        onChange={handleChangeInput as any}
                        placeHolder='Select Country'
                        disabled={isAdding || loading}
                        value={String(formData.M15_M03_CountryID)}
                    />
                    <div className=' text-red-500'>{errorData.M15_M03_CountryID ? errorData.M15_M03_CountryID : null}</div>
                    <div className='h-2'></div>

                    <Label>Warehouse</Label>
                    <SelectShadcn
                        options={warehouses.map(role => ({
                            name: role.I01_Warehouse_name,
                            value: String(role.I01_Warehouse_Id)
                        }))}
                        name={employeeKeys.M15_I01_warehouseId}
                        onChange={handleChangeInput as any}
                        placeHolder='Select Warehouse'
                        disabled={isAdding || loading}
                        value={String(formData.M15_I01_warehouseId)}
                    />

                    <div className=' text-red-500'>{errorData.M15_I01_warehouseId ? errorData.M15_I01_warehouseId : null}</div>
                    <div className='h-2'></div>

                    <Label>Marital Status</Label>
                    <SelectShadcn
                        options={[
                            {
                                name:'Married',
                                value:'married'
                            },
                            {
                                name:'Unmarried',
                                value:'unmarried'
                            }
                        ]}
                        name={employeeKeys.M15_MaritalStatus}
                        onChange={handleChangeInput as any}
                        placeHolder='Select'
                        disabled={isAdding || loading}
                        value={String(formData.M15_MaritalStatus)}
                    />
                    <div className=' text-red-500'>{errorData.M15_MaritalStatus ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <Label>Qualification</Label>
                    <Input disabled={isAdding || loading} value={formData.M15_Qualification} onChange={handleChangeInput} name={employeeKeys.M15_Qualification} placeholder='Qualification' />
                    <div className=' text-red-500'>{errorData.M15_Qualification ? errorData.M15_Designation : null}</div>
                    <div className='h-2'></div>

                    <div className=' flex gap-1'>
                        <Switch
                            checked={formData.M15_is_active == 1 ? true : false}
                            name={employeeKeys.M15_Is_Active}
                            onCheckedChange={
                                (checked) => handleChangeInput({
                                    target: {
                                        name: employeeKeys.M15_Is_Active,
                                        value: checked ? 1 : '0',
                                        checked: checked
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }

                        // onChange={handleChangeInput}
                        />
                        <div>is active</div>
                    </div>
                    <div className='h-2'></div>
                    <div className=' flex gap-1'>
                        <Switch
                            checked={formData.M15_AllowLogin == 1 ? true : false}
                            name={employeeKeys.M15_AllowLogin}
                            onCheckedChange={
                                (checked) => handleChangeInput({
                                    target: {
                                        name: employeeKeys.M15_AllowLogin,
                                        value: checked ? 1 : '0',
                                        checked: checked
                                    }
                                } as React.ChangeEvent<HTMLInputElement>)
                            }

                        // onChange={handleChangeInput}
                        />
                        <div>allow login</div>
                    </div>

                </AdminModalMiddle>
                <AdminModalFooter>
                    <Button disabled={loading} variant={'outline'} onClick={closeModal} >Cancel</Button>

                    {
                        mode === 'add' ?
                            <Button disabled={isAdding || loading} onClick={addemployee} >{isAdding && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Add</Button>
                            :
                            <Button disabled={isAdding || loading} onClick={editEmployee}  >{isAdding && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Edit</Button>}

                </AdminModalFooter>
            </AdminModalContent>
        </AdminModal>
    )
}
