import { Button } from "@/src/components/ui/button"
import { Input } from "@/src/components/ui/input"
import { Label } from "@/src/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/src/components/ui/popover"
import { filterObjectType, filterObjectTypeKeys } from "../page"
import { CustomSelectCustomOptionOption, CustomSelectLoadingSearchingOnSelectProps, loadOptionProps, loadOptionReturnProps, loadSelectedOptionProps, loadSelectedOptionReturnProps, SelectCustomLoadingSearching } from "../../../components/input_loading_searching"
import { Brand, pagination, Product, ProductCategory, ProductItem, ProductItemKeys, ProductKeys } from "@/src/types"
import { useRESTapi } from "@/src/hooks/rest_API"
import { useNavigate, useSearchParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { useAlertModal } from "@/src/hooks/alert_modal"
import { SelectShadcn } from "@/src/pages/customer/components/Select/selectShadcn"

type props = {
  filterObj: filterObjectType,
  setFilterObj: (obj: filterObjectType) => any,
  updateUi: () => any,
  show: boolean,
  setshow: (show: boolean) => any
}

type selectOptionType = {
  name: string,
  value: string
}

export function FilterComponent(props: props) {

  const [searchParams, setSearchParams] = useSearchParams();
  const [brands, setBrands] = useState<selectOptionType[]>([])
  const [categories, setCategories] = useState<selectOptionType[]>([])
  const [loading, set_laoding] = useState(true)
  const alertModal = useAlertModal()

  const api = useRESTapi()
  const navigate = useNavigate();

  //load all product options
  const load_product_options: (props: loadOptionProps) => Promise<loadOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async ({
    pagination,
    search = ''
  }: {
    pagination: pagination,
    search?: string
  }) => {
    // set_product_search_query(e.target.value);
    const res = await api.get<{ success: boolean, data: { data: ProductItem[] } & pagination }>({
      url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items`,
      params: {
        perPage: pagination.per_page,
        page: 1,
        searchTerm: search
      },
      mode: 'admin',

    })


    const pagination_return: pagination = {
      per_page: res.data.data.per_page,
      total: res.data.data.total
    }

    if (res.data.success === true) {
      // console.log(res.data)
      return {
        options: res.data.data.data.map(
          (item, index) => {
            return {
              ...item,
              value: item.M08_ProductItemID!,
              name: item.M08_ProductItemName
            } satisfies CustomSelectCustomOptionOption & ProductItem
          }
        ),
        pagination: pagination_return
      }

    } else {
      return {
        options: [],
        pagination: {
          total: 30,
          per_page: 30
        } as pagination
      }
    }
  }

  const fetch_selected_product: (props: loadSelectedOptionProps) => Promise<loadSelectedOptionReturnProps<ProductItem & CustomSelectCustomOptionOption>> = async (props: loadSelectedOptionProps) => {
    if (props.value) {
      const res = await api.get<{ success: boolean, data: ProductItem, errors: any, message: string }>({
        url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_items/${props.value}`,
        mode: 'admin',

      })

      if (res.data.success === false) {

        alert(res.data?.message || 'something went wrong')
        return {
          options: null
        }
      } else {

        return {
          options: {
            ...res.data.data,
            name: res.data.data.M08_ProductItemName,
            value: res.data.data.M08_M11_ProductID,

          } as (ProductItem & CustomSelectCustomOptionOption)
        }
      }
    }
    return {
      options: null
    }
  }

  async function handleChangeInput(e: React.ChangeEvent<HTMLInputElement>) {

    let formdata: filterObjectType = {
      ...props.filterObj
    }

    switch (e.target.name) {

      case filterObjectTypeKeys.ProductItemId:
        const event = e as CustomSelectLoadingSearchingOnSelectProps<Product>;

        formdata = {
          ...formdata,
          [e.target.name]: e.target.value
        }
        break

      

      default:
        formdata = {
          ...formdata,
          [e.target.name]: e.target.value
        }
        break;
    }




    props.setFilterObj(formdata)
    console.log(formdata)

  }

  const handleSearch = (params: filterObjectType) => {
    if(params.ProductItemId){
      searchParams.set('ProductItemId',params.ProductItemId?params.ProductItemId:'')
    }else{
      searchParams.delete('ProductItemId')
    }
    const newSearchParams = new URLSearchParams(searchParams);
    
    navigate(`?${newSearchParams.toString()}`, { replace: true });
    props.updateUi()
    props.setshow(false)
  }

  const loadCategory = async () => {

    const res = await api.get<{ success: boolean, data: ProductCategory[] }>({
      url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/product_categories`,
      mode: 'admin',

    })


    if (res.data.success === true) {
      setCategories([
        {
          name: 'ALL',
          value: '!!!!!!!!!'
        },
        ...res.data.data.map(item => ({ name: item.M04_ProductCategoryName, value: String(item.M04_ProductCategoryId) }))
      ])
    } else {
      throw res.data
    }

  }
  const loadBrands = async () => {

    const res = await api.get<{ success: boolean, data: Brand[], message: string }>({
      url: `${process.env.REACT_APP_BACKEND_URL_ADMIN!}/brands`,
      mode: 'admin',

    })


    if (res.data.success === true) {
      setBrands([
        {
          name: 'ALL',
          value: '!!!!!!!!!'
        },
        ...res.data.data.map(item => ({ name: item.M14_Title, value: String(item.M14_Brand_id) }))])
    } else {
      throw res.data
    }

  }

  useEffect(
    () => {
      if (props.show) {
        set_laoding(true)
        Promise.all(
          [loadCategory(), loadBrands()]
        ).then(
          () => {
            set_laoding(false)
            props.setFilterObj({
              ProductItemId: searchParams.get(filterObjectTypeKeys.ProductItemId) ? searchParams.get(filterObjectTypeKeys.ProductItemId)! : '',
            })
          }
        ).catch(
          (err) => {
            console.log(err)
            alertModal.open_alert_modal({
              title: 'Error',
              content: 'Error loading data',
              footer: <Button onClick={alertModal.close_alert_modal}>ok</Button>
            })
          }
        )
      }
    }, [props.show]
  )



  return (
    <Popover open={props.show} onOpenChange={props.setshow} >
      <PopoverTrigger asChild>
        <Button variant={'white'} >Filter</Button>
      </PopoverTrigger>
      <PopoverContent onOpenAutoFocus={event => event.preventDefault()} className="w-80">
        <div className="grid gap-4">
          <div className="space-y-2">
            <h4 className="font-medium leading-none">Filters</h4>
            <p className="text-sm text-muted-foreground">
              These are available filters
            </p>
          </div>
          <div className="flex flex-col gap-1">
            <SelectCustomLoadingSearching<ProductItem & CustomSelectCustomOptionOption>

              loadOption={
                load_product_options
              }
              fetchSelectOption={fetch_selected_product}
              onselect={handleChangeInput}
              value={props.filterObj.ProductItemId}

              customItem={{
                element: (props) => {
                  return <div key={props.key} onClick={() => {
                    props.select_item()
                    props.close_menu()
                  }} className='px-2 py-2  hover:bg-gray-400 cursor-pointer flex items-center gap-1'>
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                    <div>{props.M08_ProductItemName}</div>
                  </div>
                },
                valueName: ProductKeys.M11_Product_id,
                selectedItem: (props) => {
                  return (<div className=' w-full p-2 border flex relative'>
                    <div onClick={props.close} className=' absolute right-0 top-0 p-1 cursor-pointer'>x</div>
                    <img src={`${process.env.REACT_APP_BACKEND_URL}/${props?.M08_ProductImage}`} alt="" height={60} width={60} />
                    <div>{props.M08_ProductItemName}</div>
                  </div>)
                }
              }}
              title='Product SKU'

              // value={}
              disable={loading}
              name={filterObjectTypeKeys.ProductItemId}
              placeholder='product'
            />
            
          </div>
          <Button onClick={() => handleSearch(props.filterObj)}>Search</Button>
        </div>
      </PopoverContent>
    </Popover>
  )
}
